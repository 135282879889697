import React from "react";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
const FileSaved = ({ setShowFileSaved }) => {
  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white flex items-center justify-center text-black rounded-2xl  w-[43rem] h-[21rem] relative">
        <Typography
          display="block"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Button
              onClick={() => setShowFileSaved(false)}
              sx={{ position: "absolute", top: 0, left: 630 }}
            >
              <img src={require("../assets/close-icon.png")} />
            </Button>
          </div>
          <img
            src={require("../assets/verified.png")}
            className="h-20 w-20 text-[80px] text-[#26890D]"
          />
          <div className="p-4">Your file is successfully saved.</div>
        </Typography>
      </div>
    </div>
  );
};

export default FileSaved;
