import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import "./Revise.css";
import "./ReviseProductDescription.css";
import greenIcon from "../assets/ReviseIconGreen.svg";
import { getIdToken } from "../utils/translateHelper";
import axios from "axios";
import { REVISE_PROMT_PRODUCT_DESCRIPTION } from "../utils/constants";

const ReviseProductDescription = ({
  GeneratePromtProductDescription,
  productDescriptionContentLocked,
  setIsProductDescriptionLoading,
  setSectionData,
  imagePreview,
  sectionData,
}) => {
  const [revisedInfo, setRevisedInfo] = useState("");

  const ReviseProductDescription = () => {
    setIsProductDescriptionLoading(true);

    let requestBody = {
      revise_comment: revisedInfo,
      product_description: sectionData.productDescription,
      image: imagePreview,
    };

    getIdToken()
      .then((idToken) => {
        axios
          .post(REVISE_PROMT_PRODUCT_DESCRIPTION, requestBody, {
            headers: { Authorization: idToken },
          })
          .then((response) => {
            setSectionData((previousState) => ({
              ...previousState,
              productDescription: response.data.product_description,
            }));

            setIsProductDescriptionLoading(false);
            setRevisedInfo("");
          })
          .catch((error) => {
            console.log(error);
            setIsProductDescriptionLoading(false);
          });
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {/* <div> */}
        {/* <div> */}
          <div className="flex flex-col items-start w-full">
            <div className="flex flex-col revise-container ">
              <p className="text-label mb-2 revise-container text-black ">
                Ask for revisions
              </p>
              <TextField
                fullWidth
                color="tertiary"
                defaultValue=""
                focused
                value={revisedInfo}
                onChange={(e) => {
                  setRevisedInfo(e.target.value);
                }}
                inputProps={{
                  sx: {
                    height: 12,
                  },
                }}
              />
            </div>
            <div className="flex flex-row justify-end w-full">
              <div className="flex flex-wrap justify-center">
                <Button
                sx={{
                fontWeight: 600,
                display: "flex",
                width: "100%",
                maxWidth: "200px",
                height: "45px",
                padding: "8px 10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                borderRadius: "6px",
              }}
                  className="revise-button-productDescription "
                  variant={!revisedInfo ? "contained" : "outlined"}
                  color="secondary"
                  onClick={() => {
                    ReviseProductDescription();
                  }}
                  disabled={
                    !revisedInfo ||
                    productDescriptionContentLocked ||
                    imagePreview === null
                  }
                >
                  <img
                    className="flex mr-1.5  h-6 w-6"
                    src={
                      !revisedInfo
                        ? require("../assets/reviseIcon.png")
                        : greenIcon
                    }
                    alt="Revise"
                  />
                  REVISE
                </Button>
              </div>
            </div>
          </div>
        {/* </div> */}
      {/* </div> */}
      <div className="regenerate-button pt-32">
        <Button
          className="revise-button-productDescription"
          variant="contained"
          color="secondary"
          disabled={productDescriptionContentLocked || imagePreview === null}
          onClick={() => {
            GeneratePromtProductDescription();
          }}
        >
          <img
            className="flex mr-1.5"
            src={require("../assets/regenerateIcon.png")}
            alt="Regenerate"
          />
          REGENERATE
        </Button>
      </div>
    </>
  );
};
export default ReviseProductDescription;
