import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "../App.js";
import AdminPage from "../pages/AdminPage.js";
import GenerativeFill from "../pages/GenerativeFill.js";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage.jsx";
import NotAuthorized from "../components/UnAuthorized/UnAuthorized.jsx";
import ModelTraining from "../pages/ModelTraining.js";
const ProfileContent = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("cred") !== null) {
      if (localStorage.getItem("isAdmin") === "true") {
        setIsAuthorized(true);
      } else setIsAuthorized(false);
    }
  }, []);
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" Component={App} />

          <Route
            exact
            path="/admin-console"
            element={isAuthorized ? <AdminPage /> : <NotAuthorized />}
          />
          <Route
            exact
            path="/generative-fill"
            element={isAuthorized ? <GenerativeFill /> : <NotAuthorized />}
          />

          <Route path="*" Component={NotFoundPage} />
          <Route path="/model-training" element={<ModelTraining/>}/>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default ProfileContent;
