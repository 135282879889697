import React from "react";
import { TextField, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
const DirectReviseMode = ({
  steps,
  handleAddStepEach,
  addEachClicked,
  editEachClicked,
  handleChangeInNewStep,
  handleSaveAfterAddButton,
  handleEditChangeEach,
  handleEditEachButton,
  handleSaveEachButton,
  handleRefreshEachButton,
  handleDeleteEachButton,
  sectionData,
  setShowEditingMode,
  generateDirectSteps,
  regenerateStep,
}) => {
  return (
    <>
      <div className="bg-white p-4 rounded">
  {steps?.map((step, index) => (
    <div key={index}>
      <div className="flex justify-start">
        <Button className="flex justify-start" onClick={() => handleAddStepEach(index)}>
          <div className="p-2 flex justify-center items-center rounded-md mr-2.5">
            <img className="flex" src={require("../assets/addstep1.png")} alt="Add Step" />
          </div>
          Add Step
        </Button>
      </div>
      {addEachClicked[index] && (
        <div className="flex flex-col md:flex-row w-full justify-between">
          <TextField
            sx={{ width: "100%", md: { width: "80%" } }}
            multiline
            defaultValue=""
            placeholder="Add new step here."
            onChange={(e) => handleChangeInNewStep(e, index)}
          />
          <Button
            onClick={() => handleSaveAfterAddButton(index)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "6px",
              border: "1px solid #468529",
              background: "#FFF",
              padding: "8px",
              marginRight: "20px",
              marginBottom: "20px",
              mt: { xs: 2, md: 0 },
            }}
          >
            <SaveIcon />
          </Button>
        </div>
      )}
      <div className="flex w-full flex-col md:flex-row justify-between">
        {!editEachClicked[index] ? (
          <div className="ml-2.5 flex justify-center items-center text-sm">
            {step}{" "}
          </div>
        ) : (
          <TextField
            sx={{ width: "100%", md: { width: "80%" } }}
            multiline
            defaultValue={step}
            onChange={(e) => handleEditChangeEach(e, index)}
          />
        )}
        <div className="flex items-start ml-[30px] mt-2 md:mt-0">
          {!editEachClicked[index] ? (
            <>
              <Button
                onClick={() => handleEditEachButton(index)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  border: "1px solid #468529",
                  background: "#FFF",
                  padding: "4px",
                  paddingRight: "2px",
                  paddingLeft: "8px",
                  marginRight: "16px",
                }}
              >
                <img src={require("../assets/edit-icon.png")} alt="Edit" className="mr-1.5 w-[22px] h-[22px]" />
              </Button>
              <Button
                onClick={() => handleRefreshEachButton(index)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  border: "1px solid #468529",
                  background: "#FFF",
                  padding: "4px",
                  paddingRight: "2px",
                  paddingLeft: "8px",
                  marginRight: "16px",
                }}
              >
                <img src={require("../assets/reload-icon.png")} alt="reload" className="mr-1.5 h-[22px] w-[22px]" />
              </Button>
              <Button
                onClick={() => handleDeleteEachButton(index)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  border: "1px solid #468529",
                  background: "#FFF",
                  padding: "4px",
                  paddingRight: "2px",
                  paddingLeft: "8px",
                  marginRight: "16px",
                }}
              >
                <img src={require("../assets/delete-icon.png")} alt="delete" className="mr-1.5 h-[22px] w-[22px]" />
              </Button>
            </>
          ) : (
            <div className="flex items-center ml-5">
              <Button
                onClick={() => handleSaveEachButton(index)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  border: "1px solid #468529",
                  background: "#468529",
                  padding: "6px 3px 5px 9px",
                  marginRight: "16px",
                }}
              >
                <img src={require("../assets/save-icon.png")} alt="Edit" className="mr-1.5 h-[22px] w-[22px]" />
                s
              </Button>
              <Button
                onClick={() => handleRefreshEachButton(index)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  border: "1px solid #468529",
                  background: "#FFF",
                  padding: "4px",
                  paddingRight: "2px",
                  paddingLeft: "8px",
                  marginRight: "16px",
                }}
              >
                <img src={require("../assets/reload-icon.png")} alt="reload" className="mr-1.5 h-[22px] w-[22px]" />
              </Button>
              <Button
                onClick={() => handleDeleteEachButton(index)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  border: "1px solid #468529",
                  background: "#FFF",
                  padding: "4px",
                  paddingRight: "2px",
                  paddingLeft: "8px",
                  marginRight: "16px",
                }}
              >
                <img src={require("../assets/delete-icon.png")} alt="delete" className="mr-1.5 h-[22px] w-[22px]" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  ))}
  {steps.length > 0 && (
    <div className="flex justify-start">
      <Button className="flex justify-start" onClick={() => handleAddStepEach(steps.length)}>
        <div className="p-2 flex justify-center items-center rounded-md mr-2.5">
          <img className="flex" src={require("../assets/addstep1.png")} alt="Add Step" />
        </div>
        Add Step
      </Button>
    </div>
  )}
  {addEachClicked[steps.length] && (
    <div className="flex w-full flex-col md:flex-row">
      <TextField
        sx={{ width: "100%" }}
        multiline
        defaultValue=""
        placeholder="Add new step here."
        onChange={(e) => handleChangeInNewStep(e, steps.length)}
      />
      <Button
        className="flex-end justify-center items-end mt-2 md:mt-0"
        onClick={() => handleSaveAfterAddButton(steps.length)}
      >
        <SaveIcon />
      </Button>
    </div>
  )}
  <div className="flex justify-end">
    <Button
      sx={{
        marginRight: "2rem",
        fontWeight: 600,
        borderRadius: "6px",
      }}
      variant="contained"
      color="secondary"
      onClick={() => setShowEditingMode(false)}
    >
      Stop Editing
    </Button>
  </div>
</div>
    </>
  );
};

export default DirectReviseMode;
