import React from "react";
import Typography from "@mui/material/Typography";

const CreativeHoverCard = ({
  position,
  territoryTitle = "Something",
  content,
  images,
}) => {
  return (
    <div
      className={`w-[33rem] z-[999] h-max-content fixed left-[48rem] top-[30%] p-4 bg-white border rounded shadow-lg flex flex-col top-[${position.y}px] left-[${position.x}px]`}
    >
      <div className="w-full">
        <Typography
          sx={{ fontWeight: 600, lineHeight: "14px", fontSize: "18px" }}
        >
          {territoryTitle}
        </Typography>
      </div>
      <div className="flex flex-row mt-4">
        <div className="">
          <Typography
            sx={{ fontWeight: 400, fontSize: "16px", marginBottom: "0.5rem" }}
          >
            {content}
          </Typography>
        </div>
        <div className="flex flex-col">
          <img
            className="rounded-lg p-1"
            src={`data:image/jpeg;base64,${images[0]}`}
            alt="image1"
          />
          <img
            className="rounded-lg p-1"
            src={`data:image/jpeg;base64,${images[1]}`}
            alt="image2"
          />
        </div>
      </div>
    </div>
  );
};

export default CreativeHoverCard;
