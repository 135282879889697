import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useState } from "react";
import { loginRequest } from "../authConfigFile/authConfig";
import "./Login.css";
import Video from "../components/Login/Video.jsx";
import { IconButton } from "@mui/material";

import Demo_Deck from "./../assets/Deck.PNG";
import Demo_Best from "./../assets/Best_Practices.PNG";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import pdf from "./../assets/Dummy-Data.pdf";
import guide from "./../assets/GuideDemo.pdf";
import PDFComponent from "../components/Login/PDFComponent.jsx";
import myVideo from "../assets/creativedge.mp4";
import { Transition } from "@headlessui/react";
import imageShadow from "./../assets/Vector 206.png";
import VideoLogin from "./../assets/VideoLogin.png";

const Login = () => {
  const { instance } = useMsal();

  const onClickHandleLoginUsingRedirectHandler = () => {
    instance.loginRedirect(loginRequest).catch(console.log);
  };
  const [isChecked, setIsChecked] = useState(false);
  const [isButtonEnable, setIsButtonEnable] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsButtonEnable(!isButtonEnable);
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const handleDownload = async () => {
    let file;
    let fileName;
    switch (activeTab) {
      case "VIDEO":
        file = myVideo;
        fileName = "Creativedge Video.mp4";
        break;
      case "DECK":
        file = pdf;
        fileName = "Deck.pdf";
        break;
      case "BEST":
        file = guide;
        fileName = "Best Practices.pdf";
        break;
      default:
        file = null;
    }

    if (!file) return;

    const response = await fetch(file);
    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "VIDEO":
        return <Video />;
      case "DECK":
        return <PDFComponent file={pdf} />;
      case "BEST":
        return <PDFComponent file={guide} />;
      default:
        return null;
    }
  };

  const buttonText = {
    VIDEO: "DEMO VIDEO",
    DECK: "DEMO DECK",
    DEFAULT: "DEMO BEST PRACTICES",
  };

  return (
    <div className="login-bg-image min-h-screen w-full flex justify-center items-center">
      <div className="flex justify-center items-center gap-7 p-4">
        <div className={`flex   items-center bg-white rounded-2xl h-[31.625rem] ${isExpanded ? "p-2" :"p-6 space-x-6 justify-between"}`}>
          {isExpanded ? (
            <>
              <Transition
                show={isExpanded}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-[50rem] bg-white h-full rounded-3xl p-6 flex flex-col items-start space-y-4 transition-all duration-300 ease-in-out">
                  <div className="flex justify-between w-full">
                    <div
                      className="flex items-center"
                      onClick={() => setIsExpanded(false)}
                    >
                      <IconButton>
                        <KeyboardArrowLeftIcon className="text-[#458a25ff]" />
                      </IconButton>
                      <button className="text-[#458a25ff] font-semibold text-lg">
                        Back
                      </button>
                    </div>

                    <div className="flex items-center space-x-2">
                      <p
                        className=" font-semibold text-md"
                      
                      >
                        {" "}
                        {buttonText[activeTab] || buttonText.DEFAULT}
                      </p>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          padding: "0.5rem",
                          height: "38px",
                          width: "2.5rem",
                          minWidth: 0,
                          borderRadius: "0.4rem",
                        }}
                        onClick={handleDownload}
                      >
                        <img
                          style={{ height: "1rem", width: "1rem" }}
                          src={require("../assets/download-white-bg.png")}
                          alt="Download Button"
                        />
                      </Button>
                    </div>
                  </div>
                  {renderContent()}
                </div>
              </Transition>
            </>
          ) : (
            <>
              {["VIDEO", "DECK", "BEST"].map((tab) => (
                <div
                  key={tab}
                  className="flex flex-col items-center justify-center bg-white w-60 h-80 rounded-3xl cursor-pointer shadow-[rgba(0,_0,_0,_0.1)_0px_10px_15px_-3px,_rgba(0,_0,_0,_0.05)_0px_4px_6px_-2px] group border-2"
                  onClick={() => {
                    setIsExpanded(true);
                    setActiveTab(tab);
                  }}
                  
                  
                >
                  <div className="w-48 flex flex-col items-center justify-center rounded-2xl shadow-inner group-hover:scale-110 group-hover:-translate-y-4 transition-transform duration-300 ease-in-out relative">
                    <img
                      src={
                        tab === "VIDEO"
                          ? VideoLogin
                          : tab === "DECK"
                          ? Demo_Deck
                          : Demo_Best
                      }
                      alt="icon"
                      className="w-full h-full object-contain rounded-2xl"
                    />
                    <img
                      src={imageShadow}
                      alt="shadow"
                      className="absolute -bottom-7 w-full h-auto"
                    />
                  </div>
                  <button className="text-md mt-8 text-white bg-[#458a25ff] w-max text-center   p-2 rounded-md">
                    {tab === "VIDEO"
                      ? "DEMO VIDEO"
                      : tab === "DECK"
                      ? "DEMO DECK"
                      : "BEST PRACTICES"}
                  </button>
                </div>
              ))}
            </>
          )}
        </div>

        <div className="rounded-2xl shadow-lg bg-white p-4 w-full max-w-[26.25rem] h-max">
          <div className="flex flex-col items-center justify-center h-full">
            <div className="flex justify-center items-center w-[17rem] h-[3rem]">
              <img
                src={require("../assets/NEW_APP_LOGO.png")}
                alt="deloitte-logo"
                className="w-full h-full mt-[3rem]"
              />
            </div>
            <div className="rounded-2xl shadow-md m-5 p-8 border border-gray-200 bg-[#FAFAFA] mt-[3rem] flex flex-col justify-center items-center sm:items-start gap-2 w-full max-w-full">
              <div className="text-xl">Welcome</div>
              <div className="text-xs mt-2 mb-2">Login to generate content</div>
              <Button
                sx={{ size: "1.5rem", fontSize: "0.75rem", fontWeight: 600 }}
                variant="contained"
                color="secondary"
                disabled={!isButtonEnable}
                onClick={onClickHandleLoginUsingRedirectHandler}
              >
                LOGIN / REQUEST ACCESS
              </Button>
              <div className="text-xs text-[#595959] flex items-center space-x-2 my-2">
                <Checkbox
                  color="secondary"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  sx={{ padding: 0 }}
                />
                <p>
                  I agree I will not use any client data when using this tool
                </p>
              </div>
              <div className="disclaimer border border-[#f5f2ca] w-full p-3">
                <div className="text-xs">This tool was made by US AM&C</div>
                <div className="text-xs break-words">
                  Please contact
                  (usconsultingtransformationgenaiautomationleader@deloitte.com)
                  for market activation or to learn more about the tool.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
