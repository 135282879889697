import React from "react";
import { IconButton, Typography } from "@mui/material";
import { Button } from "@mui/material";
const TemplateViewer = ({
  tabName,
  path,
  setShowMagnifiedTemplate,
  orientation,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-10 flex items-center justify-center ">
      <div className="bg-[#F1F1F1] flex items-center justify-center text-black rounded-2xl w-max relative">
        <Typography
          display="block"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className={
              "object-contain h-full w-full max-h-96 max-w-96 rounded-lg m-8"
            }
            src={
              tabName === "uploaded" ? path : `data:image/png;base64,${path}`
            }
            className={`${
              orientation && orientation === "BannerVertical"
                ? "rounded-[0.625rem] w-[90%] h-[90%] max-h-[37rem] max-w-[37rem]"
                : "rounded-[10px] w-[90%] h-[90%] max-h-[37rem] max-w-[37rem]"
            }`}
          />
        </Typography>
        <IconButton
          onClick={() => setShowMagnifiedTemplate(false)}
          sx={{ top: 0, right: 0, width: "max-content", position: "absolute" }}
        >
          <img src={require("../assets/close-icon.png")} />
        </IconButton>
      </div>
    </div>
  );
};

export default TemplateViewer;
