import React from "react";
import { TextField, Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import "./TopInputDirect.css";
const TopInputDirect = ({
  formData,
  setFormData,
  formDataError,
  setGeneratedImagesGlobal,
  setGeneratedImages,
  generateSteps,
  validateDuration,
  handleBlur,
  handleDuration,
  sectionData,
  setShowDialog,
  makeLandingTrue,
}) => {
  const handleHomeButtonClick = () => {
    if (sectionData?.steps?.prev.length) setShowDialog(true);
    else makeLandingTrue();
  };

  return (
    <div className="flex p-2.5">
      <div className="w-full bg-white rounded-lg  px-5 py-8  h-max relative flex justify-between">
        <div className="flex flex-col ">
          <p className="ml-4 text-black font-normal text-xs ">
            Duration in mins
          </p>
          <TextField
            defaultValue=""
            color="tertiary"
            InputLabelProps={{
              sx: {
                color: "#FFF",
              },
            }}
            inputProps={{
              inputMode: "decimal",
              pattern: "^[0-9]*\\.?[0-9]{0,2}$",
              min: "1",
              step: "any",
            }}
            type="number"
            focused
            value={formData?.duration}
            sx={{
              fontWeight: 600,
              marginLeft: "1rem",
              height: "14px",
              marginTop: "0.5rem",
             
            }}
            onChange={(e) => {
              const value = e.target.value;
              if (!value || value.match(/^[0-9]*\.?[0-9]{0,2}$/)) {
                handleDuration(e);
              }
            }}
            error={!!formDataError.duration}
            helperText={formDataError.duration}
            onBlur={handleBlur}
          />
        </div>

        <Button
          onClick={handleHomeButtonClick}
          sx={{
            fontWeight: 600,
            marginTop: "1.75rem",
            marginLeft: "1rem",
            marginRight: { xs: 0, md: "2.3125rem" },
            // position: "absolute",
            // top: 22.5,
            // right: 0,
            height: "2.5rem",
            border: "1px solid #468529",
          }}
        >
          <img
            src={require("../assets/Vector.jpg")}
            alt="home-icon"
            className="h-[1.375rem] w-[1.375rem]"
          />
        </Button>
      </div>
    </div>
  );
};

export default TopInputDirect;
