import React, { useEffect, useState } from "react";
import "./GenFill.css";
import { getIdToken } from "../utils/translateHelper";
import axios from "axios";
import GenFillLoader from "./GenFillLoader";
import { GENERATE_BACKGROUND, FILL_BACKGROUND } from "../utils/constants";

function GenFillPrompt({
  imagePreview,
  setCurrentTab,
  steps,
  setSteps,
  setImage,
  setDownloadPathImageDownload,
}) {
  const editMock = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  };
  const storeMock = {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
  };

  const [isEditing, setIsEditing] = useState(editMock);
  const [editStore, setEditStore] = useState(storeMock);
  const [showAddMore, setShowAddMore] = useState(false);

  const handleEdit = (key, index, value) => {
    if (isEditing[key]) {
      if (editStore[key] != null) {
        setSteps({ ...steps, [key]: editStore[key] });
        setEditStore({ ...editStore, [key]: null });
      }
    }

    setIsEditing({ ...isEditing, [key]: !isEditing[key] });
  };

  const handleDelete = (key) => {
    const newSteps = {};
    const newIsEditing = {};
    const newEditStore = {};

    Object.keys(steps).forEach((k, i) => {
      if (k < key) {
        newSteps[i + 1] = steps[k];
        newIsEditing[i + 1] = isEditing[k];
        newEditStore[i + 1] = editStore[k];
      } else if (k > key) {
        newSteps[i] = steps[k];
        newIsEditing[i] = isEditing[k];
        newEditStore[i] = editStore[k];
      }
    });

    setSteps(newSteps);
    setIsEditing(newIsEditing);
    setEditStore(newEditStore);
  };

  const handleInputChange = (e, key) => {
    setEditStore({ ...editStore, [key]: e.target.value });
  };

  const generativeFillStepsApi = () => {
    let requestBody = {
      image: imagePreview,
    };

    getIdToken()
      .then((idToken) => {
        axios
          .post(GENERATE_BACKGROUND, requestBody, {
            headers: { Authorization: idToken },
          })
          .then((response) => {
            const prompts = response.data?.["Prompts"];
            setSteps(prompts);
            const length = Object.keys(prompts)?.length;
            if (length < 5) setShowAddMore(true);
          })
          .catch((error) => {
            setCurrentTab("Upload");
          });
      })
      .catch((error) => console.log(error));
  };

  const generativeFillBackground = (steps) => {
    setCurrentTab("Select");
    let genSteps = Object.values(steps);
    let requestBody = {
      image: imagePreview,
      prompt: [genSteps[0], genSteps[1], genSteps[2]],
    };

    getIdToken()
      .then((idToken) => {
        axios
          .post(FILL_BACKGROUND, JSON.stringify(requestBody), {
            headers: { Authorization: idToken },
          })
          .then((response) => {
            setImage(response?.data?.Response?.base64_images);
            setDownloadPathImageDownload(
              response?.data?.Response?.output_paths
            );
          })
          .catch((error) => {
            setCurrentTab("Steps");
          });
      })
      .catch((error) => console.log(error));
  };

  const AddMorePrompt = () => {
    const key = Object.keys(steps)?.length + 1;
    setSteps({ ...steps, [key]: "" });
    setEditStore({ ...editStore, [key]: null });
    setIsEditing({ ...isEditing, [key]: true });
  };

  useEffect(() => {
    generativeFillStepsApi();
  }, [imagePreview]);

  useEffect(() => {
    if (steps) {
      const length = Object.keys(steps)?.length;
      if (length < 5) setShowAddMore(true);
      else if (length >= 5) setShowAddMore(false);
    }
  }, [steps]);

  return steps === undefined ? (
    <GenFillLoader />
  ) : (
    <div className="gen_fill_container">
      <div>
        <div>
          {Object.entries(steps).map(([key, value], index) => (
            <div className="prompt_container" key={key}>
              {isEditing[key] ? (
                <input
                  type="text"
                  defaultValue={value}
                  className="prompt_text w-[89%] outline-none "
                  onChange={(e) => handleInputChange(e, key)}
                />
              ) : (
                <div className="prompt_text w-[89%]"> {value}</div>
              )}
              <div className="prompt_btns">
                <div className="mr-4">
                  <div className="w-9 h-9 cursor-pointer">
                    <img
                      src={
                        isEditing[key]
                          ? require("../assets/save.png")
                          : require("../assets/edit.png")
                      }
                      alt="edit"
                      onClick={() => handleEdit(key)}
                    />
                  </div>
                </div>
                <div className="mr-4">
                  <div className="w-9 h-9 cursor-pointer">
                    <img
                      src={require("../assets/delete.png")}
                      alt="delete"
                      onClick={() => handleDelete(key)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {showAddMore && (
          <div className="add_more_btn mt-4 text-xs" onClick={AddMorePrompt}>
            <img src={require("../assets/addstep1.png")} alt="edit" />
            <div className="ml-1">Add</div>
          </div>
        )}
        <div className="generate_fill_container">
          <div
            className="generate_fill_btn"
            onClick={() => generativeFillBackground(steps)}
          >
            GENERATE
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenFillPrompt;
