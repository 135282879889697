import { useEffect, useState } from "react";
import Revise from "../components/Revise";
import Card from "../components/Card";
import Error from "../components/Error";
import { Alert, Button, Typography } from "@mui/material";
import Feedback from "../components/Feedback";
import PageviewIcon from "@mui/icons-material/Pageview";
import TranslateDropdown from "../components/TranslateDropdown";
import SaveCard from "../components/SaveCard";
import AiGeneratedContent from "../components/AiGeneratedContent";
import SignalTitles from "../components/SignalTitles";
import "./SocialMedia.css";
import MenuConceptTheme from "../components/MenuConceptTheme";
import CreativeHoverCard from "../components/CreativeHoverCard";

const SocialMedia = ({
  caption,
  handleGenerateButtonClick,
  revisedData,
  regenerateData,
  setSectionData,
  sectionData,
  activeTab,
  isSocialMediaPersonaEdited,
  setIsSocialMediaPersonaEdited,
  socialMediaRequirementChanged,
  errorMessage,
  setErrorMessage,
  isLoading,
  magnifiedImage,
  setMagnifiedImage,
  setShowMagnifiedTemplate,
  setTabName,
  translateLanguage,
  handleTranslateLanguage,
  setInputText,
  sourceLanguage,
  directTranslate,
  setSourceLanguage,
  setSelectedLanguage,
  setSocialMediaLocked,
  socialMediaLocked,
  socialMediaContentLocked,
  setSocialMediaContentLocked,
  handleSaveButtonClicked,
  titles,
  setSelectedTitle,
  selectedTitle,
  socialMediaImagesCall,
  territoryTitles,
  selectedTheme,
  setSelectedTheme,
  themeWasApplied,
  prevSelectedTheme,
  setPrevSelectedTheme,
  isSidebarOpen,
  handleSaveProjectButtonClicked
}) => {
  const [editIconIsClicked, setEditIconIsClicked] = useState(false);
  const [ranEffect, setRanEffect] = useState(false);
  useEffect(() => {
    if (
      (!caption ||
        isSocialMediaPersonaEdited ||
        socialMediaRequirementChanged ||
        (themeWasApplied &&
          prevSelectedTheme["SocialMedia"] !== selectedTheme) ||
        ranEffect) &&
      !isLoading
    ) {
      setRanEffect(false);
      setPrevSelectedTheme({
        ...prevSelectedTheme,
        SocialMedia: selectedTheme,
      });
      handleGenerateButtonClick();
      setIsSocialMediaPersonaEdited(false);
    }
  }, [selectedTheme]);
  const [selectedValue, setSelectedValue] = useState(-1);
  const [selectedTerritory, setSelectedTerritory] = useState(-1);
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    setMousePosition({
      x: event.clientX,
      y: event.clientY,
    });
  };
  useEffect(() => {
    if (
      sourceLanguage[activeTab][selectedTitle] !==
      translateLanguage[activeTab][selectedTitle]
    ) {
      directTranslate(
        sectionData.socialMedia?.[selectedTitle]?.caption,
        sourceLanguage[activeTab][selectedTitle],
        translateLanguage[activeTab][selectedTitle],
        activeTab
      );
      const newSrc = [...sourceLanguage[activeTab]];
      newSrc[selectedTitle] = translateLanguage[activeTab][selectedTitle];
      setSourceLanguage((prev) => ({
        ...prev,
        [activeTab]: newSrc,
      }));
    }
  }, [translateLanguage[activeTab][selectedTitle]]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleSavingResponseValue = (data, index) => {
    let platform = Object.keys(sectionData.socialMedia[selectedTitle].caption)[
      index
    ];
    setSectionData((prev) => {
      let newSectionData = { ...prev };
      newSectionData.socialMedia[selectedTitle].caption[platform].caption =
        data;
      return newSectionData;
    });
  };

  const handleMagnify = (tabName, index, path) => {
    setTabName(tabName);
    setMagnifiedImage(path);
    setShowMagnifiedTemplate(true);
  };
  const handleLocked = (index, key) => {
    setSocialMediaLocked((previousState) => {
      let lockedState = previousState;
      lockedState[selectedTitle][index][key] =
        !lockedState[selectedTitle][index][key];
      return lockedState;
    });
  };
  return (
    <div
      className={`w-full h-full flex-1 flex flex-col ${
        isSidebarOpen ? "hidden" : "block"
      } md:block`}
    >
      {!caption && (
        <div className="flex flex-col">
          <div className="flex">
            <div className="p-10 m-5 w-full bg-white rounded-lg">
              {errorMessage && (
                <Alert severity="error" sx={{ marginTop: 3, marginBottom: 3 }}>
                  {errorMessage.message}
                </Alert>
              )}
              <Card text="" />
              <div className="w-full pt-7">
                <Revise
                  revisedData={revisedData}
                  regenerateData={regenerateData}
                  selectedTitle={selectedTitle}
                />
              </div>
            </div>
          </div>
          <div className="m-5">
            <SaveCard
              download={true}
              handleSaveButtonClicked={handleSaveButtonClicked}
            />
          </div>
        </div>
      )}
      {caption && (
        <>
          {titles?.length > 1 &&
            sectionData.socialMedia?.[selectedTitle]?.caption && (
              <SignalTitles
                titles={titles}
                selectedTitle={selectedTitle}
                setSelectedTitle={setSelectedTitle}
              />
            )}

          {Object.keys(caption)?.map((item, index) => (
            <div className="flex flex-col">
              <div key={index} className="flex">
                <div
                  key={index}
                  className={`w-full pt-4 px-6 sm:px-10 pb-10 sm:mx-5 my-4 bg-white ${
                    titles?.length > 1 &&
                    sectionData.socialMedia?.[selectedTitle]?.caption
                      ? "mt-0  rounded-b-xl"
                      : " rounded-xl"
                  }`}
                >
                  {errorMessage && (
                    <Error errorMessage={errorMessage.message} />
                  )}
                  {index == 0 && (
                    <div className="flex flex-col justify-center items-center sm:flex-row sm:justify-between">
                      <MenuConceptTheme
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        setIsHovered={setIsHovered}
                        handleMouseMove={handleMouseMove}
                        territoryTitles={territoryTitles[selectedTitle]}
                        selectedTheme={selectedTheme}
                        setSelectedTheme={setSelectedTheme}
                        setSelectedTerritory={setSelectedTerritory}
                        themeWasApplied={themeWasApplied}
                        setRanEffect={setRanEffect}
                        ranEffect={ranEffect}
                        setPrevSelectedTheme={setPrevSelectedTheme}
                      />

                      <TranslateDropdown
                        translateLanguage={translateLanguage}
                        handleTranslateLanguage={handleTranslateLanguage}
                        activeTab={activeTab}
                        selectedTitle={selectedTitle}
                      />
                    </div>
                  )}
                  {isHovered && selectedTerritory !== -1 && (
                    <CreativeHoverCard
                      position={mousePosition}
                      territoryTitle={
                        territoryTitles[selectedTitle]?.[selectedTerritory]
                      }
                      content={
                        sectionData?.creativeConcepts?.[
                          "Concepts and Images"
                        ]?.[selectedTitle]?.[selectedTerritory]?.[
                          "ConceptTerritory"
                        ]
                      }
                      images={
                        sectionData?.creativeConcepts?.[
                          "Concepts and Images"
                        ]?.[selectedTitle]?.[selectedTerritory]?.["Images"]
                      }
                    />
                  )}

                  <Typography sx={{fontWeight:600}} className="py-4">
                    {item === "twitter"
                      ? "X (TWITTER)"
                      : item === "blog"
                      ? "LONG-FORM SOCIAL"
                      : item.toUpperCase()}
                  </Typography>
                  <Card
                    index={index}
                    text={caption[item]?.caption ? caption[item]?.caption : ""}
                    handleSavingResponseValue={handleSavingResponseValue}
                    setInputText={setInputText}
                    sectionData={sectionData}
                    content={sectionData.socialMedia?.[selectedTitle]?.caption}
                    activeTab={activeTab}
                    setSelectedLanguage={setSelectedLanguage}
                    translateLanguage={translateLanguage}
                    isLoading={isLoading}
                    contentLocked={socialMediaContentLocked}
                    setContentLocked={setSocialMediaContentLocked}
                    selectedTitle={selectedTitle}
                    setEditIconIsClicked={setEditIconIsClicked}
                  />
                  <div className="pt-3">
                    {caption[item]?.images ? (
                      <div className="flex flex-nowrap overflow-x-auto w-full mt-5 justify-around items-center ">
                        {caption[item].images?.map((imgItem, key) => (
                          <div className="flex-shrink-0 justify-center pr-4 relative">
                            <img
                              className="h-52 w-52 rounded-lg"
                              key={key}
                              src={`data:image/png;base64,${imgItem}`}
                              alt="fs"
                            />
                            <Button
                              className="locked"
                              onClick={() => handleLocked(index, key)}
                            >
                              <img
                                src={require(`../assets/${
                                  socialMediaLocked[selectedTitle][index][key]
                                    ? "lock"
                                    : "unlock"
                                }.png`)}
                                alt="locked-Icon"
                              />
                            </Button>

                            <Button
                              className="magnify"
                              onClick={() =>
                                handleMagnify("generated", key, imgItem)
                              }
                            >
                              <img
                                src={require("../assets/magnify.png")}
                                alt="magnify-Icon"
                              />
                            </Button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex flex-row justify-end w-full py-4">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => socialMediaImagesCall(index)}
                          sx={{ fontWeight:  600}}
                        >
                          Generate Images
                        </Button>
                      </div>
                    )}
                  </div>
                  {caption && <AiGeneratedContent />}

                  <Revise
                    revisedData={revisedData}
                    regenerateData={regenerateData}
                    index={index}
                    isContentLocked={
                      socialMediaContentLocked[selectedTitle][index]
                    }
                    content={caption[item]?.caption}
                    editClicked={editIconIsClicked}
                  />
                </div>
              </div>
            </div>
          ))}

          <div className="m-5">
            <SaveCard
              download={true}
              handleSaveButtonClicked={handleSaveButtonClicked}
              handleSaveProjectButtonClicked={handleSaveProjectButtonClicked}
              content={caption}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default SocialMedia;
