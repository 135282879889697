import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { useEffect, useReducer, useRef, useState } from "react";
import "./App.css";
import PersonaIcon from "./components/PersonaIcon";
import CreativeConcept from "./pages/CreativeConcepts";
import Email from "./pages/Email";
import Flyer from "./pages/Flyer";
import Persona from "./pages/Persona";
import Print from "./pages/Print";
import ProductDescription from "./pages/ProductDescription";
import SocialMedia from "./pages/SocialMedia";
//------
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import TemplateViewer from "./components/TemplateViewer";
//------
import {
  SocialMediaImages,
  apiCall,
  deleteDraft,
  deletePoject,
  directStoryRevise,
  directStoryStepGeneration,
  editResponseCall,
  fetchDrafts,
  fetchProject,
  fetchProjectCard,
  flyerDownload,
  flyerPreview,
  foundationApply,
  foundationUndo,
  generateDirectStoryboard,
  imageModelApiCall,
  loraCheckModel,
  mediaLikeApiCall,
  passcodeVerifictation,
  regenerateCall,
  regenerateIndividualStep,
  resumeWork,
  reviseAfterUpdatingSteps,
  reviseSteps,
  savePage,
  saveProject,
  sendRecordPortfolio,
  storyBoardingGenerateImage,
  storyBoardingGenerateImageTailored,
  storyBoardingGenerateSteps,
  storyBoardingRegenerateImage,
  storyBoardingStitch,
  storyBoardingTailoredGenerateSteps,
  storyBoardingUpdateSteps
} from "./utils/helper";
// import StoryBoarding from "./pages/StoryBoarding";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import BackDrop from "./components/BackDrop";
import ConfirmDialog from "./components/ConfirmDialog";
import LogoBackDrop from "./components/LogoBackDrop";
import LogoError from "./components/LogoError";
import Portfolio from "./components/Portfolio";
import SelectImageBackdrop from "./components/SelectImageBackdrop";
import SuccessUpload from "./components/SuccessUpload";
import UserProfile from "./components/userProfile";
import StoryBoardLanding from "./pages/StoryBoardLanding";
import StoryBoardingDirect from "./pages/StoryBoardingDirect";
import StoryBoardingIdeate from "./pages/StoryBoardingIdeate";
import StoryBoardingTailored from "./pages/StoryBoardingTailored";
// import Translation from "./pages/Translation";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import AdIcon from "./components/AdIcon";
import CreativeConceptsIcon from "./components/CreativeConceptsIcon";
import DragDropMediaLikeAds from "./components/DragDropMediaLikeAds";
import EmailIcon from "./components/EmailIcon";
import FileSaved from "./components/FileSaved";
import MaxLimitReachedPopUp from "./components/MaxLimitReachedPopUp";
import MediaLikeAdImage from "./components/MediaLikeAdImage";
import ProductDetails from "./components/ProductDescriptionIcon";
import ProjectConfirmationBox from "./components/ProjectConfirmationBox";
import ProjectRenameCard from "./components/ProjectRenameCard";
import ResumeExceedCard from "./components/RateExceedCard";
import RenameCard from "./components/RenameCard";
import ResumeWork from "./components/ResumeWork";
import SaveIcon from "./components/SaveIcon";
import SaveProjectError from "./components/SaveProjectError";
import SidebarTextfields from "./components/SidebarTextfields";
import SocialMediaIcon from "./components/SocialMediaIcon";
import StoryboardIcon from "./components/StoryboardIcon";
import TranslateIcon from "./components/TranslateIcon";
import SavePage from "./pages/SavePage";
import TranslatePage from "./pages/TranslatePage";
import {
  directTransalation,
  storyBoardTransalation,
} from "./utils/translateHelper";

const App = () => {
  const [loraModel,setLoraModels]=useState([])
  const [timers, setTimers] = useState({});
  const [disabledOptions, setDisabledOptions] = useState(false);
  const [textModel, setTextModel] = useState("claude");
  const [imgModel, setImageModel] = useState('Titan');
  const [mediaAdValues, setMediaAdValues] = useState({});
  const [showMediaLikeLoading, setShowMediaLikeLoading] = useState(false);
  const [showMediaAdImage, setShowMediaAdImage] = useState(false);
  const [showMediaLikeDragDrop, setShowMediaLikeDragDrop] = useState(false);
  const [themeWasApplied, setThemeWasApplied] = useState(false);
  const [horizontal, setHorizontal] = useState(false);
  const [vertical, setVertical] = useState(false);
  const [square, setSquare] = useState(false);
  const [clickedOnSocialOrPrint, setClickedOnSocialOrPrint] = useState(false);
  const [personaWasTranslated, setPersonaWasTranslated] = useState(false);
  const [lastPageOnAd, setLastPageOnAd] = useState("Print");
  // const [savedWork]
  const [lastFlyerOrientation, setLastFlyerOrientation] =
    useState("Horizontal");
  const [selectedTheme, setSelectedTheme] = useState(-1);
  const [prevSelectedTheme, setPrevSelectedTheme] = useState({
    Print: -1,
    SocialMedia: -1,
    Email: -1,
    FlyerHorizontal: -1,
    FlyerVertical: -1,
    FlyerSquare: -1,
    BannerHorizontal: -1,
    BannerVertical: -1,
    StoryboardingTailored: -1,
  });
  const [showRenameCard, setShowRenameCard] = useState(false);
  const [showResumeCard, setShowResumeCard] = useState(false);
  const [showProjectRenameCard, setShowProjectRenameCard] = useState(false)
  const [showProjectConfirmationCard,setShowProjectConfirmationCard] = useState(false)
  const [showProjectError,setShowProjectError] = useState(false)
  const [primaryColor, setPrimaryColor] = useState([
    {
      rgb: { r: 223, g: 18, b: 18, a: 100 },
      hex: "#DF1212",
    },
    {
      rgb: { r: 223, g: 18, b: 18, a: 100 },
      hex: "#DF1212",
    },
    {
      rgb: { r: 223, g: 18, b: 18, a: 100 },
      hex: "#DF1212",
    },
    {
      rgb: { r: 223, g: 18, b: 18, a: 100 },
      hex: "#DF1212",
    },
    {
      rgb: { r: 223, g: 18, b: 18, a: 100 },
      hex: "#DF1212",
    },
  ]);
  const [tabNameForResume, setTabNameForResume] = useState("");
  const [fileNameForResume, setFileNameForResume] = useState("");
  const [tabTypeForResume, setTabTypeForResume] = useState("");
  const [selectedTitle, setSelectedTitle] = useState(0);
  const [maxLimitPopUp, setMaxLimitPopUp] = useState(false);
  const [secondaryColor, setSecondaryColor] = useState([
    {
      rgb: { r: 245, g: 166, b: 35, a: 100 },
      hex: "#F5A623",
    },
    {
      rgb: { r: 245, g: 166, b: 35, a: 100 },
      hex: "#F5A623",
    },
    {
      rgb: { r: 245, g: 166, b: 35, a: 100 },
      hex: "#F5A623",
    },
    {
      rgb: { r: 245, g: 166, b: 35, a: 100 },
      hex: "#F5A623",
    },
    {
      rgb: { r: 245, g: 166, b: 35, a: 100 },
      hex: "#F5A623",
    },
  ]);
  const [imageDimensions, setImageDimensions] = useState("Horizontal");
  const [showFlyerOrientation, setShowFlyerOrientation] = useState(false);
  const [showBannerOrientation, setShowBannerOrientation] = useState(false);
  const [showFlyerDropdown, setShowFlyerDropdown] = useState(false);
  const [selectedButton, setSelectedButton] = useState("natural");
  const [selectedEmotion, setSelectedEmotion] = useState("neutral");
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [translatedLanguage, setTranslatedLanguage] = useState("English");
  const [translatedText, setTranslatedText] = useState("");
  const [showSuccessUpload, setShowSuccessUpload] = useState(
    new Array(5).fill(false)
  );
  const [territoryTitles, setTerritoryTitles] = useState([]);
  const [titles, setTitles] = useState([]);
  const [signals, setSignals] = useState([""]);
  const [showErrorTab, setShowErrorTab] = useState(false);
  const [missingParams, setMissingParams] = useState([]);
  const [tabName, setTabName] = useState("");
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);
  const [previewAccess, setPreviewAccess] = useState(new Array(5).fill(""));
  const [showLogoError, setShowLogoError] = useState(false);
  const [showLogoConfirm, setShowLogoConfirm] = useState(false);
  const [previewButtonClicked, setPreviewButtonClicked] = useState(
    new Array(5).fill(false)
  );
  const [previewImage, setPreviewImage] = useState(new Array(5).fill(["", ""]));
  const [onIntermediatePage, setOnIntermediatePage] = useState(true);
  const [activeTab, setActiveTab] = useState("Persona");
  const [isTabChanged, setIsTabChanged] = useState(false);
  const [isGenerateButtonDisabled, setIsGenerateButtonDisabled] =
    useState(false);
  const [isPersonaEdited, setIsPersonaEdited] = useState(false);
  const [isSocialMediaPersonaEdited, setIsSocialMediaPersonaEdited] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [directStoryBoardPopUp, setDirectStoryBoardPopUp] = useState(false);
  const [successFullDownload, setSuccessFullDownload] = useState(false);
  const [proceedButtonClicked, setProceedButtonClicked] = useState(false);
  const { instance } = useMsal();
  const [stepArr, setStepArr] = useState([]);
  const [stepArrTailored, setStepArrTailored] = useState(new Array(3).fill([]));
  const [magnifiedImage, setMagnifiedImage] = useState("");
  const [showMagnifiedTemplate, setShowMagnifiedTemplate] = useState(false);
  const [showFileSaved, setShowFileSaved] = useState(false);
  const [projectDrafts,setProjectDrafts]=useState([])
  const [translateLanguage, setTranslateLanguage] = useState({
    Persona: ["English", "English", "English"],
    Print: ["English", "English", "English"],
    Flyer: "English",
    FlyerVertical: "English",
    FlyerSquare: "English",
    BannerHorizontal: "English",
    BannerVertical: "English",
    "Social Media": ["English", "English", "English"],
    "Creative Concepts": ["English", "English", "English"],
    Email: ["English", "English", "English"],
    "Product Description": ["English"],
  });
  const [fullPageLoading, setFullPageLoading] = useState(false);
  const [sourceLanguage, setSourceLanguage] = useState({
    Persona: ["English", "English", "English"],
    Print: ["English", "English", "English"],
    Flyer: "English",
    FlyerVertical: "English",
    FlyerSquare: "English",
    BannerHorizontal: "English",
    BannerVertical: "English",
    "Social Media": ["English", "English", "English"],
    "Creative Concepts": ["English", "English", "English"],
    Email: ["English", "English", "English"],
    "Product Description": ["English"],
  });
  const [imageState, setImageState] = useState([
    {
      logoFile: "",
      logoBase64: "",
      uploadedFile: "",
      uploadedBase64: "",
      logoFileName: "",
      uploadedImages: [],
    },
    {
      logoFile: "",
      logoBase64: "",
      uploadedFile: "",
      uploadedBase64: "",
      logoFileName: "",
      uploadedImages: [],
    },
    {
      logoFile: "",
      logoBase64: "",
      uploadedFile: "",
      uploadedBase64: "",
      logoFileName: "",
      uploadedImages: [],
    },
    {
      logoFile: "",
      logoBase64: "",
      uploadedFile: "",
      uploadedBase64: "",
      logoFileName: "",
      uploadedImages: [],
    },
    {
      logoFile: "",
      logoBase64: "",
      uploadedFile: "",
      uploadedBase64: "",
      logoFileName: "",
      uploadedImages: [],
    },
  ]);
  const [showHelperText, setShowHelperText] = useState({
    audience: false,
    product: false,
    objective: false,
    tone: false,
    sellingPoint: false,
  });
  const [savedDrafts, setSavedDrafts] = useState({});
  const [mousePos, setMousePos] = useState({});
  useEffect(() => {
    if (
      errorMessage.message ===
      "Invalid Request, You are at max capacity cannot save more than 10 files."
    )
      setMaxLimitPopUp(true);
  }, [errorMessage.message]);
  const [projectConfirmedTabs, setProjectConfirmedTabs]= useState([])  
  const text_model="claude"
  useEffect(() => {
    const handleMouseClick = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("click", handleMouseClick);

    return () => {
      window.removeEventListener("click", handleMouseClick);
    };
  }, []);

  useEffect(() => {
    if (
      showFlyerDropdown &&
      mousePos.x >= 610 &&
      mousePos.x <= 766 &&
      mousePos.y >= 135 &&
      mousePos.y <= 168
    ) {
      handleClickOnSocialMedia();
    }
    if (
      showFlyerDropdown &&
      mousePos.x >= 610 &&
      mousePos.x <= 766 &&
      mousePos.y >= 97 &&
      mousePos.y <= 131
    ) {
      handleClickOnPrint();
    }
  }, [mousePos]);
  const [uploadedImages, setUploadedImages] = useState(new Array(5).fill([]));
  const [selectedTemplate, setSelectedTemplate] = useState(
    new Array(5).fill(-1)
  );
  const [personaHindi, setPersonaHindi] = useState(null);
  const [chosenImage, setChosenImage] = useState(new Array(5).fill(""));
  const [cred, setCred] = useState(
    localStorage.getItem("cred") ? localStorage.getItem("cred") : ""
  );
  const [sectionData, setSectionData] = useState({
    persona: [],
    print: [],
    flyer: {},
    flyerVertical: {},
    flyerSquare: {},
    bannerHorizontal: {},
    bannerVertical: {},
    creativeConcepts: [],
    email: [],
    socialMedia: [],
    steps: {
      prev: [],
      current: [],
    },
    stepsTailored: [
      { prev: [], current: [] },
      { prev: [], current: [] },
      { prev: [], current: [] },
    ],
    translation: {},
    productDescription: [],
  });
  const [inputText, setInputText] = useState("");
  const [formData, setFormData] = useState({
    demographicData: [],
    product: "",
    objective: "",
    tone: "",
    sellingPoint: "",
    storyBoardTitle: "",
    duration: "",
    story: "",
    settings: [],
  });
  const [formDataError, setFormDataError] = useState({
    demographicData: [],
    product: "",
    objective: "",
    tone: "",
    sellingPoint: "",
    storyBoardTitle: "",
    duration: "",
    settings: "",
  });
  const [checkedGeneratedImages, setCheckedGeneratedImages] = useState([
    new Array(sectionData?.flyer?.Images?.length).fill(0),
    new Array(sectionData?.flyerVertical?.Images?.length).fill(0),
    new Array(sectionData?.flyerSquare?.Images?.length).fill(0),
    new Array(sectionData?.bannerHorizontal?.Images?.length).fill(0),
    new Array(sectionData?.bannerVertical?.Images?.length).fill(0),
  ]);
  const [checkedUploadedImages, setCheckedUploadedImages] = useState([
    new Array(uploadedImages?.length).fill(0),
    new Array(uploadedImages?.length).fill(0),
    new Array(uploadedImages?.length).fill(0),
    new Array(uploadedImages?.length).fill(0),
    new Array(uploadedImages?.length).fill(0),
  ]);

  const [verticalFlyerImageLocked, setVerticalFlyerImageLocked] = useState([
    false,
    false,
  ]);
  const [verticalFlyerContentLocked, setVerticalFlyerContentLocked] =
    useState(false);
  const [horizontalFlyerImageLocked, setHorizontalFlyerImageLocked] = useState([
    false,
    false,
  ]);
  const [horizontalFlyerContentLocked, setHorizontalFlyerContentLocked] =
    useState(false);
  const [squareFlyerImageLocked, setSquareFlyerImageLocked] = useState([
    false,
    false,
  ]);
  const [squareFlyerContentLocked, setSquareFlyerContentLocked] =
    useState(false);
  const [horizontalBannerImageLocked, setHorizontalBannerImageLocked] =
    useState([false, false]);
  const [horizontalBannerContentLocked, setHorizontalBannerContentLocked] =
    useState(false);
  const [verticalBannerImageLocked, setVerticalBannerImageLocked] = useState([
    false,
    false,
  ]);
  const [verticalBannerContentLocked, setVerticalBannerContentLocked] =
    useState(false);
  const [printImageLocked, setPrintImageLocked] = useState(
    Array.from({ length: 3 }, () => Array.from({ length: 2 }).fill(false))
  );
  const [socialMediaImageLocked, setSocialMediaImageLocked] = useState(
    Array.from({ length: 3 }, () =>
      Array.from({ length: 5 }, () => Array.from({ length: 4 }).fill(false))
    )
  );
  const [printContentLocked, setPrintContentLocked] = useState(
    new Array(3).fill(false)
  );
  const [socialMediaContentLocked, setSocialMediaContentLocked] = useState(
    Array.from({ length: 3 }, () => Array.from({ length: 5 }).fill(false))
  );
  const [creativeImageLocked, setCreativeImageLocked] = useState(
    Array.from({ length: 3 }, () =>
      Array.from({ length: 3 }, () => Array.from({ length: 5 }).fill(false))
    )
  );
  const [creativeContentLocked, setCreativeContentLocked] = useState(
    new Array(3).fill(false).map(() => new Array(3).fill(false))
  );
  const [personaContentLocked, setPersonaContentLocked] = useState(
    new Array(3).fill(false)
  );
  const [emailContentLocked, setEmailContentLocked] = useState(
    new Array(3).fill(false)
  );
  const [productDescriptionContentLocked, setProductDescriptionContentLocked] =
    useState(false);
  useEffect(() => {
    setErrorMessage("");
    imageModelApiCall(setErrorMessage,setLoraModels)
  
  }, []);

  useEffect(() => {
    if (document.getElementById("app1"))
      document.getElementById("app1").style.filter = "blur(0)";
    if (document.getElementById("app2"))
      document.getElementById("app2").style.filter = "blur(0)";
  }, [proceedButtonClicked]);
  const [flyerHeadline, setFlyerHeadline] = useState(new Array(5).fill(""));
  const [flyerSubtext, setFlyerSubtext] = useState(new Array(5).fill(""));
  const [isTranslationLoading, setIsTranslationLoading] = useState(false);
  const [isPersonaLoading, setIsPersonaLoading] = useState(false);
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [isFlyerLoading, setIsFlyerLoading] = useState(false);
  const [isFlyerVerticalLoading, setIsFlyerVerticalLoading] = useState(false);
  const [isFlyerSquareLoading, setIsFlyerSquareLoading] = useState(false);
  const [isBannerHorizontalLoading, setIsBannerHorizontalLoading] =
    useState(false);
  const [isBannerVerticalLoading, setIsBannerVerticalLoading] = useState(false);
  const [isCreativeLoading, setIsCreativeLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isProductDescriptionLoading, setIsProductDescriptionLoading] =
    useState(false);
  const [isSocialLoading, setIsSocialLoading] = useState(false);
  const [isStoryBoardLoading, setIsStoryBoardLoading] = useState(false);
  const [isSavePageLoading, setIsSavePageLoading] = useState(false);
  const [generatedImages, setGeneratedImages] = useState(false);
  const [generatedImagesTailored, setGeneratedImagesTailored] = useState(
    new Array(3).fill(false)
  );
  const [userDropdown, setUserDropdown] = useState(false);
  const [personaRequirementChanged, setPersonaRequirementChanged] =
    useState(false);
  const [printRequirementChanged, setPrintRequirementChanged] = useState(false);
  const [emailRequirementChanged, setEmailRequirementChanged] = useState(false);
  const [socialMediaRequirementChanged, setSocialMediaRequirementChanged] =
    useState(false);
  const [creativeRequirementChanged, setCreativeRequirementChanged] =
    useState(false);
  const [flyerRequirementChanged, setFlyerRequirementChanged] = useState(false);
  const [landingVisible, setLandingVisible] = useState(true);
  const [tailoredVisible, setTailoredVisible] = useState(false);
  const [ideateVisible, setIdeateVisible] = useState(false);
  const [directVisible, setDirectVisible] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isStoryGenerated, setIsStoryGenerated] = useState(false);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [mediaAdFile, setMediaAdFile] = useState(null);
  const [mediaAdImage, setMediaAdImage] = useState(null);
  const [showMaxCapacityCard,setShowMaxCapacityCard]= useState(false)
  const [productDescriptionState, setProductDescriptionState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      brand_name: "",
      product_name: "",
      product_description: "",
      collection: "",
      text_format:"",
      character_limit:""
    }
  );
  const dropRef = useRef(null);
  const fileInputRef = useRef(null);
  //TODO: Remove if not needed.
  const isAuthenticated = useIsAuthenticated();
  const useNavigateApp = useNavigate();
  const manageErrors = (fieldsArray = []) => {
    fieldsArray.forEach((key) => {
      if (key !== "demographicData") {
        if (!formData[key].trim().length) {
          setFormDataError((formDataError) => ({
            ...formDataError,
            [key]: "This is required for this tab.",
          }));
        }
      } else {
        const demographicDataError = new Array(
          formData.demographicData.length
        ).fill(null);
        formData.demographicData.forEach((value, index) => {
          if (!value || !value.trim()) {
            demographicDataError[index] = "This is required for this tab.";
          }
        });
        setFormDataError((formDataError) => ({
          ...formDataError,
          [key]: demographicDataError,
        }));
      }
    });
  };

  const formValidation = () => {
    setFormDataError({
      demographicData: [],
      product: "",
      objective: "",
      tone: "",
      sellingPoint: "",
      storyBoardTitle: "",
      duration: "",
    });
    let flag = false;
    switch (activeTab) {
      case "Persona":
        manageErrors(["demographicData"]);
        formData.demographicData.forEach((value) => {
          if (!value || !value.trim()) flag = true;
        });
        return !flag && !!formData.demographicData;
      case "Print":
        manageErrors(["product", "objective", "tone", "sellingPoint"]);
        return (
          sectionData.persona.length &&
          formData.product.trim().length &&
          formData.objective.trim().length &&
          formData.tone.trim().length &&
          formData.sellingPoint.trim().length
        );
      case "Creative Concepts":
        manageErrors(["product", "objective", "tone", "sellingPoint"]);
        return (
          !!sectionData.persona &&
          sectionData.persona.length > 0 &&
          !!formData.product.trim().length &&
          !!formData.objective.trim().length &&
          !!formData.tone.trim().length &&
          !!formData.sellingPoint.trim().length
        );
      case "Flyer":
        manageErrors(["product", "objective", "tone", "sellingPoint"]);
        return (
          !!formData.product.trim().length &&
          !!formData.objective.trim().length &&
          !!formData.tone.trim().length &&
          !!formData.sellingPoint.trim().length
        );

      case "Email":
        manageErrors(["product", "objective", "tone", "sellingPoint"]);
        return (
          !!sectionData.persona &&
          sectionData.persona.length > 0 &&
          !!formData.product.trim().length &&
          !!formData.objective.trim().length &&
          !!formData.tone.trim().length &&
          !!formData.sellingPoint.trim().length
        );

      case "Social Media":
        manageErrors(["product", "objective", "tone", "sellingPoint"]);
        return (
          !!sectionData.persona &&
          sectionData.persona.length > 0 &&
          !!formData.product.trim().length &&
          !!formData.objective.trim().length &&
          !!formData.tone.trim().length &&
          !!formData.sellingPoint.trim().length
        );
      case "Story Boarding":
        manageErrors(["storyBoardTitle", "duration"]);
        return !!formData.duration;
      case "Translation":
      default:
        return false;
    }
  };
  const handleRequirements = () => {
    switch (activeTab) {
      case "Persona":
        setPersonaRequirementChanged(false);
        break;
      case "Print":
        setPrintRequirementChanged(false);
        break;
      case "Flyer":
        setFlyerRequirementChanged(false);
        break;
      case "Creative Concepts":
        setCreativeRequirementChanged(false);
        break;
      case "Email":
        setEmailRequirementChanged(false);
        break;
      case "Social Media":
        setSocialMediaRequirementChanged(false);
        break;
      default:
        console.log("default");
    }
  };
  const handleGeneratedImagesCheckbox = (e, index, base_64, Images) => {
    const checked = [
      ...checkedGeneratedImages[
        showingHorizontal
          ? 0
          : showingVertical
          ? 1
          : showingSquare
          ? 2
          : showingBannerHorizontal
          ? 3
          : 4
      ],
    ];
    if (!e.target.checked) {
      for (let i = 0; i < Images?.length; i++) {
        checked[i] = 0;
      }
      if (showingHorizontal) {
        const index = 0;
        setCheckedGeneratedImages([
          ...checkedGeneratedImages.slice(0, index),
          checked,
          ...checkedGeneratedImages.slice(index + 1),
        ]);
      }
      if (showingVertical) {
        const index = 1;
        setCheckedGeneratedImages([
          ...checkedGeneratedImages.slice(0, index),
          checked,
          ...checkedGeneratedImages.slice(index + 1),
        ]);
      }
      if (showingSquare) {
        const index = 2;
        setCheckedGeneratedImages([
          ...checkedGeneratedImages.slice(0, index),
          checked,
          ...checkedGeneratedImages.slice(index + 1),
        ]);
      }
      if (showingBannerHorizontal) {
        const index = 3;
        setCheckedGeneratedImages([
          ...checkedGeneratedImages.slice(0, index),
          checked,
          ...checkedGeneratedImages.slice(index + 1),
        ]);
      }
      if (showingBannerVertical) {
        const index = 4;
        setCheckedGeneratedImages([
          ...checkedGeneratedImages.slice(0, index),
          checked,
          ...checkedGeneratedImages.slice(index + 1),
        ]);
      }
      if (showingHorizontal) {
        const index = 0;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingVertical) {
        const index = 1;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingSquare) {
        const index = 2;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingBannerHorizontal) {
        const index = 3;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingBannerVertical) {
        const index = 4;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      return;
    }
    checked[index] = 1;
    for (let i = 0; i < Images?.length; i++) {
      if (i !== index) {
        checked[i] = 2;
      }
    }
    if (showingHorizontal) {
      const index = 0;
      setChosenImage([
        ...chosenImage.slice(0, index),
        base_64,
        ...chosenImage.slice(index + 1),
      ]);
    }
    if (showingVertical) {
      const index = 1;
      setChosenImage([
        ...chosenImage.slice(0, index),
        base_64,
        ...chosenImage.slice(index + 1),
      ]);
    }
    if (showingSquare) {
      const index = 2;
      setChosenImage([
        ...chosenImage.slice(0, index),
        base_64,
        ...chosenImage.slice(index + 1),
      ]);
    }
    if (showingBannerHorizontal) {
      const index = 3;
      setChosenImage([
        ...chosenImage.slice(0, index),
        base_64,
        ...chosenImage.slice(index + 1),
      ]);
    }
    if (showingBannerVertical) {
      const index = 4;
      setChosenImage([
        ...chosenImage.slice(0, index),
        base_64,
        ...chosenImage.slice(index + 1),
      ]);
    }
    if (showingHorizontal) {
      const index = 0;
      setCheckedGeneratedImages([
        ...checkedGeneratedImages.slice(0, index),
        checked,
        ...checkedGeneratedImages.slice(index + 1),
      ]);
    }
    if (showingVertical) {
      const index = 1;
      setCheckedGeneratedImages([
        ...checkedGeneratedImages.slice(0, index),
        checked,
        ...checkedGeneratedImages.slice(index + 1),
      ]);
    }
    if (showingSquare) {
      const index = 2;
      setCheckedGeneratedImages([
        ...checkedGeneratedImages.slice(0, index),
        checked,
        ...checkedGeneratedImages.slice(index + 1),
      ]);
    }
    if (showingBannerHorizontal) {
      const index = 3;
      setCheckedGeneratedImages([
        ...checkedGeneratedImages.slice(0, index),
        checked,
        ...checkedGeneratedImages.slice(index + 1),
      ]);
    }
    if (showingBannerVertical) {
      const index = 4;
      setCheckedGeneratedImages([
        ...checkedGeneratedImages.slice(0, index),
        checked,
        ...checkedGeneratedImages.slice(index + 1),
      ]);
    }
  };
  const emptyLockedStates = () => {
    setPrintImageLocked(Array.from({ length: 2 }, () => false));
    setSocialMediaImageLocked(Array.from({ length: 4 }, () => false));
    setPrintContentLocked(false);
    setSocialMediaContentLocked(false);
    setCreativeImageLocked(
      Array.from({ length: 3 }, () => Array.from({ length: 5 }, () => false))
    );
    setCreativeContentLocked(Array.from({ length: 3 }, () => false));
    setPersonaContentLocked(false);
    setVerticalFlyerImageLocked(Array.from({ length: 2 }, () => false));
    setVerticalFlyerContentLocked(false);
  };
  const checkContentLocked = (activeTab) => {
    if (activeTab === "Persona") {
      return !personaContentLocked[selectedTitle];
    }
    if (activeTab === "Email") {
      return !emailContentLocked[selectedTitle];
    }
    return true;
  };
  const handleGenerateButtonClick = (index = null, flagRegenerated = false) => {
    //emptyLockedStates()
    setIsGenerateButtonDisabled(true);
    const isValid = formValidation();
    if (
      (activeTab === "Print" ||
        activeTab === "Story Boarding" ||
        activeTab === "Email" ||
        activeTab === "Social Media" ||
        activeTab === "Creative Concepts") &&
      !isValid &&
      sectionData.persona.length == 0
    ) {
      setErrorMessage({ message: "Persona is required" });
    }
    if (isValid && checkContentLocked(activeTab)) {
      handleRequirements();
      apiCall(
        activeTab,
        formData,
        sectionData,
        flyerHeadline,
        flyerSubtext,
        setFlyerHeadline,
        setFlyerSubtext,
        setIsPersonaLoading,
        setIsPrintLoading,
        isFlyerLoading,
        setIsFlyerLoading,
        isFlyerVerticalLoading,
        setIsFlyerVerticalLoading,
        isFlyerSquareLoading,
        setIsFlyerSquareLoading,
        isBannerHorizontalLoading,
        setIsBannerHorizontalLoading,
        isBannerVerticalLoading,
        setIsBannerVerticalLoading,
        setIsCreativeLoading,
        setIsEmailLoading,
        setIsSocialLoading,
        setSectionData,
        setErrorMessage,
        index,
        translateLanguage,
        imageDimensions,
        showingHorizontal,
        showingVertical,
        showingSquare,
        showingBannerHorizontal,
        showingBannerVertical,
        previewImage,
        setPreviewImage,
        previewAccess,
        setPreviewAccess,
        checkedGeneratedImages,
        setCheckedGeneratedImages,
        personaWasTranslated,
        setTranslateLanguage,
        sourceLanguage,
        printContentLocked,
        printImageLocked,
        socialMediaContentLocked,
        socialMediaImageLocked,
        creativeContentLocked,
        creativeImageLocked,
        verticalFlyerContentLocked,
        verticalFlyerImageLocked,
        horizontalFlyerContentLocked,
        horizontalFlyerImageLocked,
        squareFlyerContentLocked,
        squareFlyerImageLocked,
        horizontalBannerContentLocked,
        horizontalBannerImageLocked,
        verticalBannerContentLocked,
        verticalBannerImageLocked,
        setTitles,
        selectedTitle,
        flagRegenerated,
        setTerritoryTitles,
        selectedTheme,
        textModel,
        imgModel

      );
      if (activeTab === "Persona") {
        setIsPersonaEdited(true);
        setIsSocialMediaPersonaEdited(true);
      }
    }
  };

  const handleFormData = (data) => {
    setIsGenerateButtonDisabled(false);
    setFormData(data);
  };
  const [showingHorizontal, setShowingHorizontal] = useState(false);
  const [showingVertical, setShowingVertical] = useState(false);
  const [showingSquare, setShowingSquare] = useState(false);
  const [showingBannerHorizontal, setShowingBannerHorizontal] = useState(false);
  const [showingBannerVertical, setShowingBannerVertical] = useState(false);

  const getRevisedData = (data, index = 0, onFlyerPage = 0) => {
    const onHorizontal = onFlyerPage === 0;
    const onVertical = onFlyerPage === 1;
    const onSquare = onFlyerPage === 2;
    const onBannerHorizontal = onFlyerPage === 3;
    const onBannerVertical = onFlyerPage === 4;
    if (checkContentLocked(activeTab)) {
      regenerateCall(
        activeTab,
        sectionData,
        setSectionData,
        flyerHeadline,
        flyerSubtext,
        setFlyerHeadline,
        setFlyerSubtext,
        data,
        setIsPersonaLoading,
        setIsPrintLoading,
        isFlyerLoading,
        setIsFlyerLoading,
        isFlyerVerticalLoading,
        setIsFlyerVerticalLoading,
        isFlyerSquareLoading,
        setIsFlyerSquareLoading,
        isBannerHorizontalLoading,
        setIsBannerHorizontalLoading,
        isBannerVerticalLoading,
        setIsBannerVerticalLoading,
        setIsCreativeLoading,
        setIsEmailLoading,
        setIsProductDescriptionLoading,
        setIsSocialLoading,
        setErrorMessage,
        index,
        formData,
        translateLanguage,
        onHorizontal,
        onVertical,
        onSquare,
        onBannerHorizontal,
        onBannerVertical,
        sourceLanguage,
        setTranslateLanguage,
        socialMediaImageLocked,
        socialMediaContentLocked,
        printImageLocked,
        printContentLocked,
        creativeImageLocked,
        creativeContentLocked,
        verticalFlyerContentLocked,
        verticalFlyerImageLocked,
        horizontalFlyerContentLocked,
        horizontalFlyerImageLocked,
        squareFlyerContentLocked,
        squareFlyerImageLocked,
        horizontalBannerContentLocked,
        horizontalBannerImageLocked,
        verticalBannerContentLocked,
        verticalBannerImageLocked,
        selectedTitle,
        textModel,
        imgModel
      );
      if (activeTab === "Persona") {
        setIsPersonaEdited(true);
        setIsSocialMediaPersonaEdited(true);
      }
    }
  };

  const sendEditedData = (data, index = null) => {
    editResponseCall(
      data,
      sectionData,
      setSectionData,
      activeTab,
      isPrintLoading,
      setIsPrintLoading,
      isFlyerLoading,
      setIsFlyerLoading,
      isFlyerVerticalLoading,
      setIsFlyerVerticalLoading,
      isFlyerSquareLoading,
      setIsFlyerSquareLoading,
      isBannerHorizontalLoading,
      setIsBannerHorizontalLoading,
      isBannerVerticalLoading,
      setIsBannerVerticalLoading,
      setIsCreativeLoading,
      formData,
      setErrorMessage,
      index,
      setFlyerHeadline,
      setFlyerSubtext,
      showingHorizontal,
      showingVertical,
      showingSquare,
      showingBannerHorizontal,
      showingBannerVertical,
      printImageLocked,
      socialMediaImageLocked,
      creativeImageLocked,
      verticalFlyerContentLocked,
      verticalFlyerImageLocked,
      horizontalFlyerImageLocked,
      squareFlyerImageLocked,
      horizontalBannerImageLocked,
      verticalBannerImageLocked,
      selectedTitle,
      textModel,
      imgModel
    );
  };

  const generateSteps = () => {
    const isValid = formValidation();
    if (isValid) {
      if (isNaN(Number(formData.duration))) {
        const key = "duration";
        setFormDataError((formDataError) => ({
          ...formDataError,
          [key]: "Duration should be numerical",
        }));
      } else {
        storyBoardingGenerateSteps(
          formData,
          sectionData,
          setSectionData,
          stepArr,
          setStepArr,
          activeTab,
          isStoryBoardLoading,
          setIsStoryBoardLoading,
          setErrorMessage,
          setGeneratedImages,
          textModel
        );
      }
    }
  };
  const generateTailoredSteps = (flag = null) => {
    const isValid = formValidation();
    if (isValid) {
      if (isNaN(Number(formData.duration))) {
        const key = "duration";
        setFormDataError((formDataError) => ({
          ...formDataError,
          [key]: "Duration should be numerical",
        }));
      } else {
        if (translateLanguage?.["Persona"][selectedTitle] !== "English") {
          storyBoardTransalation(
            sectionData?.persona,
            translateLanguage?.["Persona"][selectedTitle],
            "English",
            setSectionData,
            isStoryBoardLoading,
            setIsStoryBoardLoading,
            setErrorMessage,
            "Google Translate",
            "GPT-4",
            sectionData,
            setPersonaHindi
          )
            .then(() => {
              storyBoardingTailoredGenerateSteps(
                formData,
                sectionData,
                setSectionData,
                stepArrTailored,
                setStepArrTailored,
                activeTab,
                isStoryBoardLoading,
                setIsStoryBoardLoading,
                setErrorMessage,
                setGeneratedImagesTailored,
                selectedTitle,
                flag,
                selectedTheme,
                textModel
              );
            })
            .catch((error) => {
              setPersonaHindi(null);
            })
            .finally(() => {
              setPersonaHindi(null);
            });
        } else {
          storyBoardingTailoredGenerateSteps(
            formData,
            sectionData,
            setSectionData,
            stepArrTailored,
            setStepArrTailored,
            activeTab,
            isStoryBoardLoading,
            setIsStoryBoardLoading,
            setErrorMessage,
            setGeneratedImagesTailored,
            selectedTitle,
            flag,
            textModel,
            selectedTheme,
            
          );
        }
      }
    }
  };
  const generateDirectSteps = () => {
    const isValid = formValidation();
    if (isValid) {
      if (isNaN(Number(formData.duration))) {
        const key = "duration";
        setFormDataError((formDataError) => ({
          ...formDataError,
          [key]: "Duration should be numerical",
        }));
      } else {
        directStoryStepGeneration(
          formData,
          sectionData,
          setSectionData,
          stepArr,
          setStepArr,
          activeTab,
          isStoryBoardLoading,
          setIsStoryBoardLoading,
          setErrorMessage,
          setGeneratedImages,
          textModel
        );
      }
    }
  };
  const reviseDirectSteps = (revision_comment) => {
    directStoryRevise(
      revision_comment,
      formData,
      setFormData,
      sectionData,
      setSectionData,
      stepArr,
      setStepArr,
      activeTab,
      isStoryBoardLoading,
      setIsStoryBoardLoading,
      setErrorMessage,
      textModel
    );
  };
  const sendUpdatedSteps = (
    updatedSteps,
    previousSteps,
    generatedImages,
    dataMap = null
  ) => {
    storyBoardingUpdateSteps(
      formData,
      updatedSteps,
      previousSteps,
      generatedImages,
      sectionData,
      setSectionData,
      activeTab,
      isStoryBoardLoading,
      setIsStoryBoardLoading,
      setErrorMessage,
      dataMap,
      tailoredVisible,
      selectedTitle
    );
  };
  const storyRegenerateImages = (
    narration,
    step_no,
    setRegenerateButtonLoader
  ) => {
    storyBoardingRegenerateImage(
      narration,
      step_no,
      setErrorMessage,
      // setIsStoryBoardLoading,
      setRegenerateButtonLoader,
      setSectionData,
      tailoredVisible,
      selectedTitle,
      imgModel
    );
  };
  const storyBoardGenerateImage = () => {
    storyBoardingGenerateImage(
      formData,
      sectionData,
      setSectionData,
      isStoryBoardLoading,
      setIsStoryBoardLoading,
      setErrorMessage,
      setGeneratedImages,
      imgModel
    );
  };
  const storyBoardGenerateImageTailored = () => {
    storyBoardingGenerateImageTailored(
      formData,
      sectionData,
      setSectionData,
      isStoryBoardLoading,
      setIsStoryBoardLoading,
      setErrorMessage,
      setGeneratedImages,
      selectedTitle,
      imgModel
    );
  };

  const sendRecord = (op, purpose) => {
    sendRecordPortfolio(
      op,
      purpose,
      setIsStoryBoardLoading,
      setErrorMessage,
      setProceedButtonClicked,
      setCred
    );
  };

  const onTabClick = (event, tabName) => {
    if (tabName === "Flyer") {
      if (!clickedOnSocialOrPrint) {
        setClickedOnSocialOrPrint(true);
        setActiveTab(lastPageOnAd);
        if (lastPageOnAd === "Flyer") {
          if (lastFlyerOrientation === "Horizontal") {
            setShowingHorizontal(true);
            setShowingSquare(false);
            setShowingVertical(false);
            setShowingBannerHorizontal(false);
            setShowingBannerVertical(false);
          }
          if (lastFlyerOrientation === "Vertical") {
            setShowingHorizontal(false);
            setShowingSquare(false);
            setShowingVertical(true);
            setShowingBannerHorizontal(false);
            setShowingBannerVertical(false);
          }
          if (lastFlyerOrientation === "Square") {
            setShowingHorizontal(false);
            setShowingSquare(true);
            setShowingVertical(false);
            setShowingBannerHorizontal(false);
            setShowingBannerVertical(false);
          }
          if (lastFlyerOrientation === "BannerHorizontal") {
            setShowingHorizontal(false);
            setShowingSquare(false);
            setShowingVertical(false);
            setShowingBannerHorizontal(true);
            setShowingBannerVertical(false);
          }
          if (lastFlyerOrientation === "BannerVertical") {
            setShowingHorizontal(false);
            setShowingSquare(false);
            setShowingVertical(false);
            setShowingBannerHorizontal(false);
            setShowingBannerVertical(true);
          }
        }
      }
      setShowFlyerDropdown(true);
      handleClick1(event);
    } else {
      if (activeTab === "Print" || activeTab === "Flyer")
        setLastPageOnAd(activeTab);
      if (showingHorizontal) {
        setLastFlyerOrientation("Horizontal");
      }
      if (showingVertical) {
        setLastFlyerOrientation("Vertical");
      }
      if (showingSquare) {
        setLastFlyerOrientation("Square");
      }
      if (showingBannerHorizontal) {
        setLastFlyerOrientation("BannerHorizontal");
      }
      if (showingBannerVertical) {
        setLastFlyerOrientation("BannerVertical");
      }
      setShowingHorizontal(false);
      setShowingVertical(false);
      setShowingSquare(false);
      setShowingBannerHorizontal(false);
      setShowingBannerVertical(false);
      setClickedOnSocialOrPrint(false);
      setActiveTab(tabName);
      setShowFlyerDropdown(false);
      setShowFlyerOrientation(false);
    }
    setIsTabChanged(true);
    setErrorMessage("");
  };
  const handleTranslateLanguage = (value, activeTab) => {
    switch (activeTab) {
      case "Persona":
        const newSource = [...sourceLanguage["Persona"]];
        newSource[selectedTitle] = translateLanguage["Persona"][selectedTitle];
        setSourceLanguage((prev) => ({
          ...prev,
          Persona: newSource,
        }));
        const newTrans = [...translateLanguage["Persona"]];
        newTrans[selectedTitle] = value;
        setTranslateLanguage((prev) => ({
          ...prev,
          Persona: newTrans,
        }));
        break;
      case "Product Description":
        const newSourceProductDescription = [
          ...sourceLanguage["Product Description"],
        ];
        newSourceProductDescription[selectedTitle] =
          translateLanguage["Product Description"][selectedTitle];
        setSourceLanguage((prev) => ({
          ...prev,
          "Product Description": newSourceProductDescription,
        }));
        const newTransProductDescription = [
          ...translateLanguage["Product Description"],
        ];
        newTransProductDescription[selectedTitle] = value;
        setTranslateLanguage((prev) => ({
          ...prev,
          "Product Description": newTransProductDescription,
        }));
        break;
      case "Print":
        const newSourcePrint = [...sourceLanguage["Print"]];
        newSourcePrint[selectedTitle] =
          translateLanguage["Print"][selectedTitle];
        setSourceLanguage((prev) => ({
          ...prev,
          Print: newSourcePrint,
        }));
        const newTransPrint = [...translateLanguage["Print"]];
        newTransPrint[selectedTitle] = value;
        setTranslateLanguage((prev) => ({
          ...prev,
          Print: newTransPrint,
        }));
        break;
      case "Flyer":
        if (horizontal) {
          setSourceLanguage((prev) => ({
            ...prev,
            Flyer: translateLanguage.Flyer,
          }));
          setTranslateLanguage((prev) => ({
            ...prev,
            Flyer: value,
          }));
        }
        if (vertical) {
          setSourceLanguage((prev) => ({
            ...prev,
            FlyerVertical: translateLanguage.FlyerVertical,
          }));
          setTranslateLanguage((prev) => ({
            ...prev,
            FlyerVertical: value,
          }));
        }
        if (square) {
          setSourceLanguage((prev) => ({
            ...prev,
            FlyerSquare: translateLanguage.FlyerSquare,
          }));
          setTranslateLanguage((prev) => ({
            ...prev,
            FlyerSquare: value,
          }));
        }
        if (showingBannerHorizontal) {
          setSourceLanguage((prev) => ({
            ...prev,
            BannerHorizontal: translateLanguage.BannerHorizontal,
          }));
          setTranslateLanguage((prev) => ({
            ...prev,
            BannerHorizontal: value,
          }));
        }
        if (showingBannerVertical) {
          setSourceLanguage((prev) => ({
            ...prev,
            BannerVertical: translateLanguage.BannerVertical,
          }));
          setTranslateLanguage((prev) => ({
            ...prev,
            BannerVertical: value,
          }));
        }
        break;
      case "Social Media":
        const newSrcSocial = [...sourceLanguage["Social Media"]];
        newSrcSocial[selectedTitle] =
          translateLanguage["Social Media"][selectedTitle];
        setSourceLanguage((prev) => ({
          ...prev,
          ["Social Media"]: newSrcSocial,
        }));
        const newTransSoc = [...translateLanguage["Social Media"]];
        newTransSoc[selectedTitle] = value;
        setTranslateLanguage((prev) => ({
          ...prev,
          ["Social Media"]: newTransSoc,
        }));
        break;
      case "Creative Concepts":
        const newSrcCrea = [...sourceLanguage["Creative Concepts"]];
        newSrcCrea[selectedTitle] =
          translateLanguage["Creative Concepts"][selectedTitle];
        setSourceLanguage((prev) => ({
          ...prev,
          ["Creative Concepts"]: newSrcCrea,
        }));
        const newTransCrea = [...translateLanguage["Creative Concepts"]];
        newTransCrea[selectedTitle] = value;
        setTranslateLanguage((prev) => ({
          ...prev,
          ["Creative Concepts"]: newTransCrea,
        }));
        break;
      case "Email":
        const newSrcEmail = [...sourceLanguage["Email"]];
        newSrcEmail[selectedTitle] = translateLanguage["Email"][selectedTitle];
        setSourceLanguage((prev) => ({
          ...prev,
          Email: newSrcEmail,
        }));
        const newTranEmail = [...translateLanguage["Email"]];
        newTranEmail[selectedTitle] = value;
        setTranslateLanguage((prev) => ({
          ...prev,
          Email: newTranEmail,
        }));
      default:
        break;
    }
  };

  const userProfileButton = () => {
    setUserDropdown(!userDropdown);
  };

  const logoutHandler = () => {
    localStorage.setItem("cred", "");
    localStorage.setItem("isAdmin", "");
    localStorage.removeItem("valid_user");
    instance.logoutRedirect().catch(console.log);
  };
  const useHandleAdmin = () => {
    useNavigateApp("/admin-console");
  };

  const handleStitch = (reqBody) => {
    storyBoardingStitch(
      sectionData,
      setSectionData,
      isStoryBoardLoading,
      setIsStoryBoardLoading,
      setSuccessFullDownload,
      setErrorMessage,
      formData,
      reqBody
    );
  };

  const validateDuration = (data) => {
    const key = "duration";
    if (isNaN(Number(data))) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        [key]: "Duration should be numerical",
      }));
    } else if (Number(data) > 7) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        [key]: "Maximum 7 mins.",
      }));
    } else {
      setFormDataError((formDataError) => ({
        ...formDataError,
        [key]: "",
      }));
    }
  };
  const makeLandingTrue = () => {
    setLandingVisible(true);
    setIdeateVisible(false);
    setTailoredVisible(false);
    setDirectVisible(false);
    setIsStoryGenerated(false);
  };
  const makeTailoredTrue = () => {
    setLandingVisible(false);
    setIdeateVisible(false);
    setTailoredVisible(true);
    setDirectVisible(false);
  };
  const makeIdeateTrue = () => {
    setLandingVisible(false);
    setIdeateVisible(true);
    setTailoredVisible(false);
    setDirectVisible(false);
  };
  const makeDirectTrue = () => {
    setLandingVisible(false);
    setIdeateVisible(false);
    setTailoredVisible(false);
    setDirectVisible(true);
  };
  const handleConfirmDialogClick = () => {
    const newSectionData = { ...sectionData };
    if (tailoredVisible) {
      newSectionData.stepsTailored[0].prev = [];
      newSectionData.stepsTailored[1].prev = [];
      newSectionData.stepsTailored[2].prev = [];
      newSectionData.stepsTailored[0].current = [];
      newSectionData.stepsTailored[1].current = [];
      newSectionData.stepsTailored[2].current = [];
      setStepArrTailored(new Array(3).fill([]));
    } else {
      newSectionData.steps.prev = [];
      newSectionData.steps.current = [];
    }
    setSectionData(newSectionData);
    setStepArr([]);
    setGeneratedImages(false);
    makeLandingTrue();
    setFormData({
      ...formData,
      storyBoardTitle: "",
      duration: "",
      story: "",
      settings: [],
    });
    setShowDialog(false);
  };

  const regenerateStep = (index, req_body) => {
    regenerateIndividualStep(
      setIsStoryBoardLoading,
      sectionData,
      setSectionData,
      index,
      stepArr,
      setStepArr,
      stepArrTailored,
      setStepArrTailored,
      req_body,
      setErrorMessage,
      tailoredVisible,
      selectedTitle,
      text_model
    );
  };
  const reviseAllSteps = (req_body) => {
    reviseSteps(
      setIsStoryBoardLoading,
      sectionData,
      setSectionData,
      stepArr,
      setStepArr,
      req_body,
      setErrorMessage,
      tailoredVisible,
      selectedTitle,
      setStepArrTailored,
      stepArrTailored,
     
    );
  };
  const reviseAfterUpdating = (
    reviseComment,
    tempUpdatedSteps,
    previousSteps,
    generatedImages,
    dataMap = null
  ) => {
    reviseAfterUpdatingSteps(
      formData,
      tempUpdatedSteps,
      previousSteps,
      generatedImages,
      sectionData,
      setSectionData,
      activeTab,
      isStoryBoardLoading,
      setIsStoryBoardLoading,
      setErrorMessage,
      dataMap,
      reviseComment,
      stepArr,
      setStepArr,
      tailoredVisible,
      selectedTitle,
      setStepArrTailored,
      stepArrTailored
    );
  };
  useState(() => {}, [
    isFlyerLoading,
    isFlyerSquareLoading,
    isFlyerVerticalLoading,
  ]);
  const reviseAllDirectSteps = (req_body) => {
    reviseDirectSteps(
      setIsStoryBoardLoading,
      sectionData,
      setSectionData,
      stepArr,
      setStepArr,
      req_body,
      setErrorMessage
    );
  };
  const directStoryboard = () => {
    generateDirectStoryboard(
      formData,
      sectionData,
      setSectionData,
      setStepArr,
      activeTab,
      isStoryBoardLoading,
      setIsStoryBoardLoading,
      setErrorMessage,
      setGeneratedImages,
      textModel,
      imgModel
    );
  };
  const flyerPreviewCall = (req_body, onFlyerPage = 0) => {
    const tempParams = [];
    Object.keys(req_body).forEach((key) => {
      if (!req_body[key]) {
        tempParams.push(key);
      }
    });
    setMissingParams(tempParams);
    if (tempParams.length) {
      setShowErrorTab(true);
      return;
    }
    flyerPreview(
      isFlyerLoading,
      setIsFlyerLoading,
      isFlyerVerticalLoading,
      setIsFlyerVerticalLoading,
      isFlyerSquareLoading,
      setIsFlyerSquareLoading,
      isBannerHorizontalLoading,
      setIsBannerHorizontalLoading,
      isBannerVerticalLoading,
      setIsBannerVerticalLoading,
      setErrorMessage,
      req_body,
      previewImage,
      setPreviewImage,
      previewButtonClicked,
      setPreviewButtonClicked,
      previewAccess,
      setPreviewAccess,
      onFlyerPage === 0,
      onFlyerPage === 1,
      onFlyerPage === 2,
      onFlyerPage === 3,
      onFlyerPage === 4
    );
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const flyerDownloadCall = (onFlyerPage) => {
    flyerDownload(
      isFlyerLoading,
      setIsFlyerLoading,
      isFlyerVerticalLoading,
      setIsFlyerVerticalLoading,
      isFlyerSquareLoading,
      setIsFlyerSquareLoading,
      isBannerHorizontalLoading,
      setIsBannerHorizontalLoading,
      isBannerVerticalLoading,
      setIsBannerVerticalLoading,
      setErrorMessage,
      previewAccess,
      setDownloadButtonClicked,
      onFlyerPage === 0,
      onFlyerPage === 1,
      onFlyerPage === 2,
      onFlyerPage === 3,
      onFlyerPage === 4
    );
  };
  const saveDraft = (name) => {
    if (activeTab != "Flyer") {
      savePage(
        activeTab,
        setErrorMessage,
        setIsPersonaLoading,
        setIsPrintLoading,
        setIsEmailLoading,
        setIsSocialLoading,
        setIsCreativeLoading,
        setIsFlyerLoading,
        setIsFlyerVerticalLoading,
        setIsFlyerSquareLoading,
        setIsBannerHorizontalLoading,
        setIsBannerVerticalLoading,
        name,
        sectionData,
        formData,
        translateLanguage,
        selectedTemplate,
        primaryColor,
        secondaryColor,
        imageState,
        imageState,
        showingHorizontal,
        showingVertical,
        showingSquare,
        showingBannerHorizontal,
        showingBannerVertical,
        setShowRenameCard,
        setFullPageLoading,
        setShowFileSaved,
        titles,
        setTitles,
        selectedTitle
      );
    } else {
      savePage(
        activeTab,
        setErrorMessage,
        setIsPersonaLoading,
        setIsPrintLoading,
        setIsEmailLoading,
        setIsSocialLoading,
        setIsCreativeLoading,
        setIsFlyerLoading,
        setIsFlyerVerticalLoading,
        setIsFlyerSquareLoading,
        setIsBannerHorizontalLoading,
        setIsBannerVerticalLoading,
        name,
        sectionData,
        formData,
        translateLanguage,
        selectedTemplate[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ],
        primaryColor[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ],
        secondaryColor[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ],
        imageState[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ].logoBase64,
        imageState[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ].logoFileName,
        showingHorizontal,
        showingVertical,
        showingSquare,
        showingBannerHorizontal,
        showingBannerVertical,
        setShowRenameCard,
        setFullPageLoading,
        setShowFileSaved,
        titles,
        setTitles,
        selectedTitle
      );
    }
  };
  const fetchAllDrafts = () => {
    fetchDrafts(
      setErrorMessage,
      setSectionData,
      setSavedDrafts,
      setIsSavePageLoading
    );
  };
  const handleResumeWork = (fileName, tabName, tabType) => {
    resumeWork(
      setClickedOnSocialOrPrint,
      setActiveTab,
      fileName,
      tabName,
      tabType,
      setErrorMessage,
      sectionData,
      setSectionData,
      formData,
      setFormData,
      setIsSavePageLoading,
      selectedTemplate,
      setSelectedTemplate,
      primaryColor,
      setPrimaryColor,
      secondaryColor,
      setSecondaryColor,
      imageState,
      setImageState,
      setTranslateLanguage,
      setShowResumeCard,
      setFullPageLoading,
      setShowingHorizontal,
      setShowingVertical,
      setShowingSquare,
      setShowingBannerHorizontal,
      setShowingBannerVertical,
      titles,
      setTitles,
      selectedTitle,
      signals,
      setSignals,
      setSelectedTitle
    );
  };
  const handleDeleteDraft = (tabName, fileName, tabType = null) => {
    deleteDraft(
      setErrorMessage,
      setIsSavePageLoading,
      fileName,
      tabName,
      tabType,
      setSavedDrafts
    );
  };
  const directTranslate = (item, source, target, activeTab, index = null) => {
    if (activeTab === "Flyer") {
      directTransalation(
        item,
        source,
        target,
        selectedTitle,
        setPersonaWasTranslated,
        setSectionData,
        isFlyerLoading,
        setIsFlyerLoading,
        isFlyerVerticalLoading,
        setIsFlyerVerticalLoading,
        isFlyerSquareLoading,
        setIsFlyerSquareLoading,
        isBannerHorizontalLoading,
        setIsBannerHorizontalLoading,
        isBannerVerticalLoading,
        setIsBannerVerticalLoading,
        setErrorMessage,
        "Google Translate",
        "GPT-4",
        activeTab,
        showingHorizontal,
        showingVertical,
        showingSquare,
        showingBannerHorizontal,
        showingBannerVertical,
        sectionData,
        index
      );
    }
    if (activeTab === "Social Media")
      directTransalation(
        item,
        source,
        target,
        selectedTitle,
        setPersonaWasTranslated,
        setSectionData,
        isSocialLoading,
        setIsSocialLoading,
        isFlyerVerticalLoading,
        setIsFlyerVerticalLoading,
        isFlyerSquareLoading,
        setIsFlyerSquareLoading,
        isBannerHorizontalLoading,
        setIsBannerHorizontalLoading,
        isBannerVerticalLoading,
        setIsBannerVerticalLoading,
        setErrorMessage,
        "Google Translate",
        "GPT-4",
        activeTab,
        showingHorizontal,
        showingVertical,
        showingSquare,
        showingBannerHorizontal,
        showingBannerVertical,
        sectionData,
        index
      );
    if (activeTab === "Email")
      directTransalation(
        item,
        source,
        target,
        selectedTitle,
        setPersonaWasTranslated,
        setSectionData,
        isEmailLoading,
        setIsEmailLoading,
        isFlyerVerticalLoading,
        setIsFlyerVerticalLoading,
        isFlyerSquareLoading,
        setIsFlyerSquareLoading,
        isBannerHorizontalLoading,
        setIsBannerHorizontalLoading,
        isBannerVerticalLoading,
        setIsBannerVerticalLoading,
        setErrorMessage,
        "Google Translate",
        "GPT-4",
        activeTab,
        showingHorizontal,
        showingVertical,
        showingSquare,
        showingBannerHorizontal,
        showingBannerVertical,
        sectionData,
        index
      );
    if (activeTab === "Persona")
      directTransalation(
        item,
        source,
        target,
        selectedTitle,
        setPersonaWasTranslated,
        setSectionData,
        isPersonaLoading,
        setIsPersonaLoading,
        isFlyerVerticalLoading,
        setIsFlyerVerticalLoading,
        isFlyerSquareLoading,
        setIsFlyerSquareLoading,
        isBannerHorizontalLoading,
        setIsBannerHorizontalLoading,
        isBannerVerticalLoading,
        setIsBannerVerticalLoading,
        setErrorMessage,
        "Google Translate",
        "GPT-4",
        activeTab,
        showingHorizontal,
        showingVertical,
        showingSquare,
        showingBannerHorizontal,
        showingBannerVertical,
        sectionData,
        index
      );
    if (activeTab === "Product Description")
      directTransalation(
        item,
        source,
        target,
        selectedTitle,
        setPersonaWasTranslated,
        setSectionData,
        isPersonaLoading,
        setIsPersonaLoading,
        isFlyerVerticalLoading,
        setIsFlyerVerticalLoading,
        isFlyerSquareLoading,
        setIsFlyerSquareLoading,
        isBannerHorizontalLoading,
        setIsBannerHorizontalLoading,
        isBannerVerticalLoading,
        setIsBannerVerticalLoading,
        setErrorMessage,
        "Google Translate",
        "GPT-4",
        activeTab,
        showingHorizontal,
        showingVertical,
        showingSquare,
        showingBannerHorizontal,
        showingBannerVertical,
        sectionData,
        index,
        isProductDescriptionLoading,
        setIsProductDescriptionLoading
      );
    if (activeTab === "Creative Concepts") {
      directTransalation(
        item,
        source,
        target,
        selectedTitle,
        setPersonaWasTranslated,
        setSectionData,
        isCreativeLoading,
        setIsCreativeLoading,
        isFlyerVerticalLoading,
        setIsFlyerVerticalLoading,
        isFlyerSquareLoading,
        setIsFlyerSquareLoading,
        isBannerHorizontalLoading,
        setIsBannerHorizontalLoading,
        isBannerVerticalLoading,
        setIsBannerVerticalLoading,
        setErrorMessage,
        "Google Translate",
        "GPT-4",
        activeTab,
        showingHorizontal,
        showingVertical,
        showingSquare,
        showingBannerHorizontal,
        showingBannerVertical,
        sectionData,
        index
      );
    }
    if (activeTab === "Print")
      directTransalation(
        item,
        source,
        target,
        selectedTitle,
        setPersonaWasTranslated,
        setSectionData,
        isPrintLoading,
        setIsPrintLoading,
        isFlyerVerticalLoading,
        setIsFlyerVerticalLoading,
        isFlyerSquareLoading,
        setIsFlyerSquareLoading,
        isBannerHorizontalLoading,
        setIsBannerHorizontalLoading,
        isBannerVerticalLoading,
        setIsBannerVerticalLoading,
        setErrorMessage,
        "Google Translate",
        "GPT-4",
        activeTab,
        showingHorizontal,
        showingVertical,
        showingSquare,
        showingBannerHorizontal,
        showingBannerVertical,
        sectionData,
        index
      );
  };
  const foundationUndoCall = () => {
    foundationUndo(
      setErrorMessage,
      setIsCreativeLoading,
      setSectionData,
      sectionData,
      setStepArrTailored,
      setGeneratedImagesTailored,
      formData,
      setImageState,
      setSelectedTemplate,
      setPrimaryColor,
      setSecondaryColor
    );
  };
  const foundationApplyCall = () => {
    foundationApply(
      setErrorMessage,
      setIsCreativeLoading,
      formData,
      sectionData,
      selectedTitle,
      imageState,
      selectedTemplate,
      primaryColor,
      secondaryColor
    );
  };
  //-------------------------------------------
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const UI_REFERENCE_FEEDBACK =
    "https://deloittesurvey.deloitte.com/Community/se/3FC11B26270A0A5B";
  const onClickOpenFeedbackHandler = () => {
    window.open(UI_REFERENCE_FEEDBACK, "_blank");
  };

  const [anchorE2, setAnchorE2] = useState(null);
  const [anchorE3, setAnchorE3] = useState(null);
  const [anchorE4, setAnchorE4] = useState(null);
  const open1 = Boolean(anchorE2);
  const open2 = Boolean(anchorE3);
  const open3 = Boolean(anchorE4);
  const handleClick1 = (event) => {
    event.stopPropagation();
    setAnchorE2(event.currentTarget);
  };
  const handleClick2 = (e) => {
    e.stopPropagation();
    setAnchorE3(e.currentTarget);
  };

  const handleClick3 = (e) => {
    e.stopPropagation();
    setAnchorE4(e.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorE2(null);
    setShowFlyerDropdown(false);
  };
  const handleClose2 = () => {
    setAnchorE3(null);
    setShowFlyerOrientation(false);
  };
  const handleClose3 = () => {
    setAnchorE4(null);
    setShowBannerOrientation(false);
  };
  const handleClickOnPrint = () => {
    setActiveTab("Print");
    setShowingHorizontal(false);
    setShowingSquare(false);
    setShowingVertical(false);
    setClickedOnSocialOrPrint(true);
    handleClose1();
  };
  const handleClickOnSocialMedia = () => {
    setActiveTab("Social Media");
    setShowingHorizontal(false);
    setShowingSquare(false);
    setShowingVertical(false);
    setClickedOnSocialOrPrint(true);
    handleClose1();
  };
  const handleOrientationDropdown = (e) => {
    setShowFlyerOrientation(!showFlyerOrientation);
    handleClick2(e);
  };
  const handleBannerOrientationDropdown = (e) => {
    setShowBannerOrientation(!showBannerOrientation);
    handleClick3(e);
  };
  useEffect(() => {}, [activeTab]);
  const handleHorizontal = () => {
    if (showingHorizontal) return;
    setShowingHorizontal(true);
    setShowingVertical(false);
    setShowingSquare(false);
    setShowingBannerVertical(false);
    setShowingBannerHorizontal(false);
    handleClose1();
    handleClose2();
    const newTab = "Flyer";
    setActiveTab(newTab);
    setImageDimensions("Horizontal");
    setIsGenerateButtonDisabled(true);
    setClickedOnSocialOrPrint(false);
  };
  const handleVertical = () => {
    if (showingVertical) return;
    setShowingHorizontal(false);
    setShowingVertical(true);
    setShowingSquare(false);
    setShowingBannerVertical(false);
    setShowingBannerHorizontal(false);
    handleClose1();
    handleClose2();
    setActiveTab("Flyer");
    setImageDimensions("Vertical");
    setIsGenerateButtonDisabled(true);
    setClickedOnSocialOrPrint(false);
  };
  const handleSquare = () => {
    if (showingSquare) return;
    setShowingHorizontal(false);
    setShowingVertical(false);
    setShowingSquare(true);
    setShowingBannerVertical(false);
    setShowingBannerHorizontal(false);
    handleClose1();
    handleClose2();
    setActiveTab("Flyer");
    setImageDimensions("Square");
    setIsGenerateButtonDisabled(true);
    setClickedOnSocialOrPrint(false);
  };

  const handleBannerHorizontal = () => {
    if (showingBannerHorizontal) return;
    setShowingBannerHorizontal(true);
    setShowingBannerVertical(false);
    setShowingHorizontal(false);
    setShowingVertical(false);
    setShowingSquare(false);
    handleClose1();
    handleClose3();
    const newTab = "Flyer";
    setActiveTab(newTab);
    setIsGenerateButtonDisabled(true);
    setClickedOnSocialOrPrint(false);
  };

  const handleBannerVertical = () => {
    if (showingBannerVertical) return;
    setShowingBannerHorizontal(false);
    setShowingBannerVertical(true);
    setShowingHorizontal(false);
    setShowingVertical(false);
    setShowingSquare(false);
    handleClose1();
    handleClose3();
    const newTab = "Flyer";
    setActiveTab(newTab);
    setIsGenerateButtonDisabled(true);
    setClickedOnSocialOrPrint(false);
  };
  const [correctPasscode, setCorrectPasscode] = useState(
    localStorage.getItem("valid_user") === "" ? false : true
  );
  const [verificationMessage, setVerificationMessage] = useState("");
  const [isPasscodeLoading, setIsPasscodeLoading] = useState(false);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[5],
      fontSize: 14,
      width: "7.5rem",
      height: "1.7rem",
      textAlign: "center",
    },
  }));
  const handlePasscodeVerification = (passcode) => {
    passcodeVerifictation(
      setErrorMessage,
      passcode,
      setVerificationMessage,
      setIsPasscodeLoading,
      setCorrectPasscode
    );
  };

  const mediaLikeSuggestionsCall = (image) => {
    if (!image) {
      setErrorMessage("Problem selecting image.");
      return;
    }
    mediaLikeApiCall(
      setShowMediaLikeLoading,
      setErrorMessage,
      image,
      setShowMediaAdImage,
      setShowMediaLikeDragDrop,
      setMediaAdValues
    );
  };

  useEffect(() => {
    if (verificationMessage === "Passkey Matched") {
      localStorage.setItem("valid_user", "true");
    }
  }, [verificationMessage]);

  const handleSaveButtonClicked = () => {
    setShowRenameCard(true);
  };
  const handleSaveProjectButtonClicked = ()=>{
    let validationStatus = projectValidate()
    if(validationStatus){
      setShowProjectRenameCard(true)
    }
    else{
      setShowProjectError(true)
    }
   
  }
  const handleProjectRenameClosed = ()=>{
    setShowProjectRenameCard(false)
  }
  const SocialMediaImageApiCall = (index) => {
    SocialMediaImages(
      setIsSocialLoading,
      setErrorMessage,
      selectedTitle,
      setSectionData,
      sectionData,
      index,
      imgModel
    );
  };

  let flyerLoading = false;
  if (showingHorizontal) {
    flyerLoading = isFlyerLoading;
  } else if (showingVertical) {
    flyerLoading = isFlyerVerticalLoading;
  } else if (showingSquare) {
    flyerLoading = isFlyerSquareLoading;
  } else if (showingBannerHorizontal) {
    flyerLoading = isBannerHorizontalLoading;
  } else if (showingBannerVertical) {
    flyerLoading = isBannerVerticalLoading;
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };
  const showProjectConfirmation = ()=>{
    setShowProjectConfirmationCard(true)
  }
  const projectValidate =()=>{
    const tabs = [
      "persona", "print", "flyer", "flyerVertical",
      "flyerSquare", "bannerHorizontal", "bannerVertical",
      "creativeConcepts", "email", "socialMedia"
  ];
  const hasValue = key => {
      const value = sectionData[key];
      if (Array.isArray(value)) {
          return value.length > 0; 
      } else if (typeof value === 'object' && value !== null) {
          return Object.keys(value).length > 0; 
      }
      return !!value;
  };
  const truthyCount = tabs.reduce((count, key) => count + (hasValue(key) ? 1 : 0), 0);
  return truthyCount >= 2;
  }
  const saveProjectApply =(projectName)=>{
    saveProject(
      sectionData,
      formData,
      projectName,
      errorMessage,
      imageState,
      selectedTemplate,
      primaryColor,
      secondaryColor,
      setIsPersonaLoading,
      setIsCreativeLoading,
      setIsEmailLoading,
      setIsSocialLoading,
      setIsPrintLoading,
      projectConfirmedTabs,
      setShowFileSaved,
      setIsFlyerLoading,
      setIsFlyerVerticalLoading,
      setIsFlyerSquareLoading,
      setIsBannerHorizontalLoading,
      setIsBannerVerticalLoading,
      setShowMaxCapacityCard
    )

  }
  const handleResumeProject = (projectName)=>{
    fetchProject(
      errorMessage,
      sectionData,
      formData,
      setFormData,
      setSectionData,
      setSignals,
      setTitles,
      projectName,
      setIsSavePageLoading,
      setActiveTab,
      selectedTemplate,
      primaryColor,
      secondaryColor,
      imageState,
      setSelectedTemplate,
      setPrimaryColor,
      setSecondaryColor,
      setImageState
    );
  }
  const handleResumeProjectCards = ()=>{
    fetchProjectCard(errorMessage,setProjectDrafts,setIsSavePageLoading)
  }
  const handleDeleteProject =(projectId)=>{
      deletePoject(errorMessage,setProjectDrafts,projectId)
  }
  


  return (
    <div className="flex flex-row h-screen overflow-x-auto">
      {fullPageLoading && <BackDrop type={"fullpage"}></BackDrop>}
      {!proceedButtonClicked && !cred.length && (
        <Portfolio
          proceedButtonClicked={proceedButtonClicked}
          setProceedButtonClicked={setProceedButtonClicked}
          sendRecord={sendRecord}
        />
      )}
      {showMagnifiedTemplate && activeTab === "Flyer" && (
        <TemplateViewer
          path={magnifiedImage}
          setShowMagnifiedTemplate={setShowMagnifiedTemplate}
          tabName={tabName}
          orientation={
            showingHorizontal
              ? "Horizontal"
              : showingVertical
              ? "Vertical"
              : showingSquare
              ? "Square"
              : showingBannerHorizontal
              ? "BannerHorizontal"
              : "BannerVertical"
          }
        />
      )}
      {showMagnifiedTemplate && activeTab === "Print" && (
        <TemplateViewer
          path={magnifiedImage}
          setShowMagnifiedTemplate={setShowMagnifiedTemplate}
          tabName={tabName}
          orientation={"Print"}
        />
      )}
      {showMagnifiedTemplate && activeTab === "Creative Concepts" && (
        <TemplateViewer
          path={magnifiedImage}
          setShowMagnifiedTemplate={setShowMagnifiedTemplate}
          tabName={tabName}
          orientation="CC"
        />
      )}
      {showMagnifiedTemplate && activeTab === "Social Media" && (
        <TemplateViewer
          path={magnifiedImage}
          setShowMagnifiedTemplate={setShowMagnifiedTemplate}
          tabName={tabName}
          orientation="SM"
        />
      )}

      {showErrorTab && activeTab === "Flyer" && (
        <LogoError
          setShowErrorTab={setShowErrorTab}
          missingParams={missingParams}
          setMissingParams={setMissingParams}
        />
      )}
      {showLogoConfirm && activeTab === "Flyer" && (
        <LogoBackDrop setShowLogoConfirm={setShowLogoConfirm} />
      )}
      {showRenameCard && (
        <RenameCard
          setShowRenameCard={setShowRenameCard}
          saveDraft={saveDraft}
          errorMessage={errorMessage}
        />
      )}
      {maxLimitPopUp && (
        <MaxLimitReachedPopUp
          setMaxLimitPopUp={setMaxLimitPopUp}
          setActiveTab={setActiveTab}
        />
      )}
      {showProjectRenameCard && (
        <ProjectRenameCard
          handleProjectRenameClosed={handleProjectRenameClosed}
          showProjectConfirmationCard={showProjectConfirmation}
          sectionData={sectionData}
          setProjectConfirmedTabs={setProjectConfirmedTabs}
          setShowProjectError={setShowProjectError}
        />
      )}
      {showProjectConfirmationCard && (
        <ProjectConfirmationBox
          setShowProjectConfirmationCard={setShowProjectConfirmationCard}
          saveProjectApply={saveProjectApply}
        />
      )}
      {showProjectError && (
        <SaveProjectError setShowProjectError={setShowProjectError} />
      )}
      {showResumeCard && (
        <ResumeWork
          setShowResumeCard={setShowResumeCard}
          handleResumeWork={handleResumeWork}
          tabNameForResume={tabNameForResume}
          fileNameForResume={fileNameForResume}
          tabTypeForResume={tabTypeForResume}
        />
      )}
      {showMaxCapacityCard && (
        <ResumeExceedCard
          setShowMaxCapacityCard={setShowMaxCapacityCard}
          setActiveTab={setActiveTab}
        />
      )}
      {showMediaLikeDragDrop && (
        <DragDropMediaLikeAds
          setShowMediaLikeDragDrop={setShowMediaLikeDragDrop}
          dropRef={dropRef}
          fileInputRef={fileInputRef}
          setFile={setMediaAdFile}
          setImagePreview={setMediaAdImage}
          mediaLikeSuggestionsCall={mediaLikeSuggestionsCall}
          isLoading={showMediaLikeLoading}
          errorMessage={errorMessage}
        />
      )}
      {showMediaAdImage && (
        <MediaLikeAdImage
          image={mediaAdImage}
          setShowMediaAdImage={setShowMediaAdImage}
          mediaAdValues={mediaAdValues}
          setFormData={setFormData}
          formData={formData}
          setSignals={setSignals}
          setShowHelperText={setShowHelperText}
        />
      )}
      {showFileSaved && <FileSaved setShowFileSaved={setShowFileSaved} />}
      {showSuccessUpload.map((item, index) => {
        if (item && activeTab === "Flyer") {
          <SuccessUpload
            showSuccessUpload={showSuccessUpload}
            setShowSuccessUpload={setShowSuccessUpload}
            index={index}
          />;
        }
      })}
      <div
        // id="app2"
        className="h-full overflow-y-auto overflow-x-hidden bg-[#F2F2F2] w-full "
      >
        <div className="top-header w-full flex justify-between sticky top-0 items-center z-30 bg-[#F2F2F2] ">
          <div className="h-[2.5rem] w-[14rem] mt-4 mb-4 z-30 ">
            <img
              className="flex w-full h-full ml-5"
              src={require("./assets/NEW_APP_LOGO.png")}
              alt="deloitte-logo"
            />
          </div>
          <div className="flex items-center justify-center">
            <div
              className="flex items-center justify-center px-2.5 py-2 cursor-pointer"
              onClick={() => {
                setActiveTab("Save");
                setIsSidebarOpen(false);
              }}
            >
              <LightTooltip title="Saved Pages">
                <SaveIcon className="h-6 w-6" />
              </LightTooltip>
            </div>
            <div className="flex items-center justify-center px-2.5 py-2">
              <UserProfile
                onClickOpenFeedbackHandlerForFeedback={
                  onClickOpenFeedbackHandler
                }
                handleAdmin={useHandleAdmin}
                handleLogout={logoutHandler}
                userName={cred}
                name={"Admin"}
                icon={<AdminPanelSettingsIcon />}
              />
            </div>
          </div>
        </div>
        <TabContext value={activeTab}>
          <div className="top-header flex flex-row z-30 sticky top-[4.5rem] w-full   bg-white">
            <div
              className={` bg-[#C5DABC4D] relative flex items-center justify-between ${
                isSidebarOpen ? "p-3 w-full md:w-[21.2rem] " : "w-10"
              } ${
                activeTab === "Save"
                  ? "pointer-events-none cursor-not-allowed "
                  : ""
              }`}
            >
              <p
                className={`font-sans text-md font-normal leading-3 text-left text-black ${
                  !isSidebarOpen && "scale-0"
                }`}
              >
                Please fill in the details
              </p>
              <div
                className={`bg-[#26890D] w-10 h-full absolute flex items-center justify-center -right-0 top-0 ${
                  activeTab === "Save" ? "opacity-50 " : ""
                }`}
                onClick={handleSidebar}
              >
                {isSidebarOpen ? (
                  <ChevronLeftIcon
                    sx={{
                      height: "3rem",
                      width: "3rem",
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <ChevronRightIcon
                    sx={{
                      height: "3rem",
                      width: "3rem",
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </div>
            {showFlyerDropdown && (
              <Menu
                anchorEl={anchorE2}
                open={open1}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={handleClickOnPrint}
                  sx={{
                    color: activeTab === "Print" ? "#26890D" : "black",
                  }}
                >
                  Print
                </MenuItem>

                <MenuItem
                  onClick={handleVertical}
                  sx={{
                    color:
                      activeTab === "Flyer" &&
                      !(showingBannerHorizontal || showingBannerVertical)
                        ? "#26890D"
                        : "black",
                  }}
                >
                  Flyer
                </MenuItem>
                <MenuItem
                  onClick={(e) => handleBannerOrientationDropdown(e)}
                  sx={{
                    color:
                      activeTab === "Flyer" &&
                      (showingBannerHorizontal || showingBannerVertical)
                        ? "#26890D"
                        : "black",
                  }}
                >
                  Banner{" "}
                  <ArrowForwardIosIcon
                    sx={{ marginLeft: "70px", height: "17px", width: "17px" }}
                  />
                </MenuItem>
              </Menu>
            )}
            {showBannerOrientation && (
              <Menu
                anchorEl={anchorE4}
                open={open3}
                onClose={handleClose3}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  sx={{
                    color: showingBannerHorizontal ? "#26890D" : "black",
                  }}
                  onClick={handleBannerHorizontal}
                >
                  Horizontal
                </MenuItem>
                <MenuItem
                  sx={{
                    color: showingBannerVertical ? "#26890D" : "black",
                  }}
                  onClick={handleBannerVertical}
                >
                  Vertical
                </MenuItem>
              </Menu>
            )}

            <div
              className={`flex flex-1 ${
                isSidebarOpen ? "hidden" : "block"
              } md:block  overflow-x-scroll ms-0  bg-white`}
            >
              <TabList
                variant="scrollable"
                scrollButtons
                onChange={onTabClick}
                allowScrollButtonsMobile={true}
                aria-label="lab API tabs example"
                className="header-tabs duration-300 scroll-smooth"
                TabIndicatorProps={{ color: "white", backgroundColor: "white" }}
                sx={{
                  "& .Mui-selected": {
                    borderRadius: "0.8rem",
                    fontWeight: "bold",
                  },
                  "& .MuiTab-root": {
                    fontWeight: "normal",
                  },
                  width: "100%",
                  minHeight: 0,
                  paddingTop: "0",
                  paddingBottom: "0",
                  fontSize: "1rem",
                }}
                textColor="black"
              >
                <Tab
                  label={
                    <div
                      className={`flex flex-row ${
                        activeTab === "Persona" ? "text-[#26890D]" : ""
                      }`}
                      alignItems="center"
                    >
                      <PersonaIcon highlighted={activeTab === "Persona"} />
                      <span
                        className={`tab-text ${
                          activeTab === "Persona"
                            ? "text-[#26890D] font-bold"
                            : "font-normal"
                        }`}
                      >
                        Persona
                      </span>
                    </div>
                  }
                  value="Persona"
                  sx={{
                    whiteSpace: "nowrap",
                    width: "max-content",
                    minWidth: "max-content",
                    fontSize: "1rem",
                  }}
                />
                <Tab
                  label={
                    <div className="flex flex-row" alignItems="center">
                      <CreativeConceptsIcon
                        highlighted={activeTab === "Creative Concepts"}
                      />
                      <span
                        className={`tab-text ${
                          activeTab === "Creative Concepts"
                            ? "text-[#26890D] font-bold"
                            : "font-normal"
                        }`}
                      >
                        Creative Concepts
                      </span>
                    </div>
                  }
                  value="Creative Concepts"
                  sx={{
                    whiteSpace: "nowrap",
                    width: "max-content",
                    fontSize: "1rem",
                    minWidth: "max-content",
                  }}
                />
                <Tab
                  label={
                    <div className="flex flex-row" alignItems="center">
                      <SocialMediaIcon
                        highlighted={activeTab === "Social Media"}
                      />
                      <span
                        className={`tab-text ${
                          activeTab === "Social Media"
                            ? "text-[#26890D] font-bold"
                            : "font-normal"
                        }`}
                      >
                        Social Media
                      </span>
                    </div>
                  }
                  value="Social Media"
                  sx={{
                    whiteSpace: "nowrap",
                    width: "max-content",
                    fontSize: "1rem",
                    minWidth: "max-content",
                  }}
                />
                <Tab
                  label={
                    <div className="flex flex-row items-center">
                      <AdIcon
                        highlighted={
                          activeTab === "Flyer" || activeTab === "Print"
                        }
                      />
                      <span
                        className={`tab-text ${
                          activeTab === "Flyer" || activeTab === "Print"
                            ? "text-[#26890D] font-bold"
                            : "font-normal"
                        }`}
                      >
                        Ad
                      </span>
                      <KeyboardArrowDown
                        sx={{ width: "1.5rem", height: "1.5rem" }}
                      />
                    </div>
                  }
                  value="Flyer"
                  onClick={(event) => {
                    setAnchorE2(event.currentTarget);
                    setShowFlyerDropdown(true);
                    setShowFlyerOrientation(false);
                  }}
                  sx={{
                    position: "relative",
                    minWidth: "max-content",
                    fontSize: "1rem",
                  }}
                />

                <Tab
                  label={
                    <div className="flex flex-row" alignItems="center">
                      <EmailIcon highlighted={activeTab === "Email"} />
                      <span
                        className={`tab-text ${
                          activeTab === "Email"
                            ? "text-[#26890D] font-bold"
                            : "font-normal"
                        }`}
                      >
                        Email
                      </span>
                    </div>
                  }
                  value="Email"
                  sx={{
                    whiteSpace: "nowrap",
                    width: "max-content",
                    fontSize: "1rem",
                    minWidth: "max-content",
                  }}
                />
                <Tab
                  label={
                    <div className="flex flex-row" alignItems="center">
                      <StoryboardIcon
                        highlighted={activeTab === "Story Boarding"}
                      />
                      <span
                        className={`tab-text ${
                          activeTab === "Story Boarding"
                            ? "text-[#26890D] font-bold"
                            : "font-normal"
                        }`}
                      >
                        Storyboard
                      </span>
                    </div>
                  }
                  value="Story Boarding"
                  sx={{
                    whiteSpace: "nowrap",
                    width: "max-content",
                    fontSize: "1rem",
                    minWidth: "max-content",
                  }}
                />
                <Tab
                  label={
                    <div className="flex flex-row" alignItems="center">
                      <ProductDetails
                        highlighted={activeTab === "Product Description"}
                      />
                      <span
                        className={`tab-text ${
                          activeTab === "Product Description"
                            ? "text-[#26890D] font-bold"
                            : "font-normal"
                        }`}
                      >
                        Product Description
                      </span>
                    </div>
                  }
                  value="Product Description"
                  sx={{
                    whiteSpace: "nowrap",
                    width: "max-content",
                    fontSize: "1rem",
                    minWidth: "max-content",
                  }}
                />
                <Tab
                  label={
                    <div className="flex flex-row" alignItems="center">
                      <TranslateIcon highlighted={activeTab === "Translate"} />
                      <span
                        className={`tab-text ${
                          activeTab === "Translate"
                            ? "text-[#26890D] font-bold"
                            : "font-normal"
                        }`}
                      >
                        Translate
                      </span>
                    </div>
                  }
                  value="Translate"
                  sx={{
                    whiteSpace: "nowrap",
                    width: "max-content",
                    fontSize: "1rem",
                    minWidth: "max-content",
                  }}
                />
              </TabList>
            </div>
          </div>
          <TabPanel
            value="Persona"
            sx={{ width: "100%", height: "100%", padding: 0 }}
          >
            <div className="relative w-full h-full">
              {isPersonaLoading && <BackDrop type={"container"}></BackDrop>}
              <div className="flex flex-row w-full">
                <div
                  className={`duration-300  ease-in-out bg-white ${
                    isSidebarOpen
                      ? "w-full md:w-[21.2rem]  overflow-y-auto"
                      : "w-10"
                  }`}
                >
                  <SidebarTextfields
                    isSidebarOpen={isSidebarOpen}
                    handleSidebar={handleSidebar}
                    handleGenerateButtonClick={handleGenerateButtonClick}
                    isButtonDisabled={isGenerateButtonDisabled}
                    formDataError={formDataError}
                    formData={formData}
                    handleFormData={handleFormData}
                    activeTab={activeTab}
                    setPersonaRequirementChanged={setPersonaRequirementChanged}
                    setPrintRequirementChanged={setPrintRequirementChanged}
                    setEmailRequirementChanged={setEmailRequirementChanged}
                    setSocialMediaRequirementChanged={
                      setSocialMediaRequirementChanged
                    }
                    setCreativeRequirementChanged={
                      setCreativeRequirementChanged
                    }
                    setFlyerRequirementChanged={setFlyerRequirementChanged}
                    tailoredVisible={tailoredVisible}
                    signals={signals}
                    setSignals={setSignals}
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                    setShowMediaLikeDragDrop={setShowMediaLikeDragDrop}
                    showHelperText={showHelperText}
                    textModel={textModel}
                    setTextModel={setTextModel}
                    setIsGenerateButtonDisabled={setIsGenerateButtonDisabled}
                    imgModel={imgModel}
                    setImageModel={setImageModel}
                    loraModel={loraModel}
                    timers={timers}
                    setTimers={setTimers}
                    disabledOptions={disabledOptions}
                    setDisabledOptions={setDisabledOptions}
                    loraCheckModel={loraCheckModel}
                    setErrorMessage={setErrorMessage}
                  />
                </div>
                <Persona
                  revisedData={getRevisedData}
                  regenerateData={handleGenerateButtonClick}
                  handleGenerateButtonClick={handleGenerateButtonClick}
                  setSectionData={setSectionData}
                  sectionData={sectionData}
                  isTabChanged={isTabChanged}
                  formValidation={formValidation}
                  activeTab={activeTab}
                  setIsPersonaEdited={setIsPersonaEdited}
                  setIsSocialMediaPersonaEdited={setIsSocialMediaPersonaEdited}
                  personaRequirementChanged={personaRequirementChanged}
                  setPersonaRequirementChanged={setPersonaRequirementChanged}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  isLoading={isPersonaLoading}
                  directTranslate={directTranslate}
                  setInputText={setInputText}
                  translateLanguage={translateLanguage}
                  sourceLanguage={sourceLanguage}
                  setTranslateLanguage={setTranslateLanguage}
                  setSourceLanguage={setSourceLanguage}
                  handleTranslateLanguage={handleTranslateLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  personaWasTranslated={personaWasTranslated}
                  setPersonaWasTranslated={setPersonaWasTranslated}
                  personaContentLocked={personaContentLocked}
                  setPersonaContentLocked={setPersonaContentLocked}
                  handleSaveButtonClicked={handleSaveButtonClicked}
                  titles={titles}
                  setSelectedTitle={setSelectedTitle}
                  selectedTitle={selectedTitle}
                  territoryTitles={territoryTitles}
                  selectedTheme={selectedTheme}
                  setSelectedTheme={setSelectedTheme}
                  handleSaveProjectButtonClicked={
                    handleSaveProjectButtonClicked
                  }
                  isSidebarOpen={isSidebarOpen}
                  saveProjectApply={saveProjectApply}
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel
            value="Print"
            sx={{ width: "100%", height: "100%", padding: 0 }}
          >
            <div
              className={`relative w-full ${
                sectionData?.print[0]?.["Magazine Ad"] === undefined
                  ? "h-full"
                  : ""
              }`}
            >
              {isPrintLoading && <BackDrop type={"container"}></BackDrop>}
              <div className="flex flex-row w-full">
                <div
                  className={`duration-300  ease-in-out bg-white ${
                    isSidebarOpen
                      ? "w-full md:w-[21.2rem]  overflow-y-auto"
                      : "w-10"
                  }`}
                >
                  <SidebarTextfields
                    isSidebarOpen={isSidebarOpen}
                    handleSidebar={handleSidebar}
                    handleGenerateButtonClick={handleGenerateButtonClick}
                    isButtonDisabled={isGenerateButtonDisabled}
                    formDataError={formDataError}
                    formData={formData}
                    handleFormData={handleFormData}
                    activeTab={activeTab}
                    setPersonaRequirementChanged={setPersonaRequirementChanged}
                    setPrintRequirementChanged={setPrintRequirementChanged}
                    setEmailRequirementChanged={setEmailRequirementChanged}
                    setSocialMediaRequirementChanged={
                      setSocialMediaRequirementChanged
                    }
                    setCreativeRequirementChanged={
                      setCreativeRequirementChanged
                    }
                    setFlyerRequirementChanged={setFlyerRequirementChanged}
                    tailoredVisible={tailoredVisible}
                    signals={signals}
                    setSignals={setSignals}
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                    setShowMediaLikeDragDrop={setShowMediaLikeDragDrop}
                    showHelperText={showHelperText}
                    textModel={textModel}
                    setTextModel={setTextModel}
                    setIsGenerateButtonDisabled={setIsGenerateButtonDisabled}
                    imgModel={imgModel}
                    setImageModel={setImageModel}
                    loraModel={loraModel}
                    timers={timers}
                    setTimers={setTimers}
                    disabledOptions={disabledOptions}
                    setDisabledOptions={setDisabledOptions}
                    loraCheckModel={loraCheckModel}
                  />
                </div>
                <Print
                  Images={sectionData?.print?.[selectedTitle]?.Images}
                  MagazineAd={
                    sectionData?.print?.[selectedTitle]?.["Magazine Ad"]
                  }
                  handleGenerateButtonClick={handleGenerateButtonClick}
                  revisedData={getRevisedData}
                  regenerateData={handleGenerateButtonClick}
                  setSectionData={setSectionData}
                  sectionData={sectionData}
                  editedData={sendEditedData}
                  activeTab={activeTab}
                  printRequirementChanged={printRequirementChanged}
                  setPrintRequirementChanged={setPrintRequirementChanged}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  isLoading={isPrintLoading}
                  magnifiedImage={magnifiedImage}
                  setMagnifiedImage={setMagnifiedImage}
                  setShowMagnifiedTemplate={setShowMagnifiedTemplate}
                  setTabName={setTabName}
                  directTranslate={directTranslate}
                  setInputText={setInputText}
                  translateLanguage={translateLanguage}
                  sourceLanguage={sourceLanguage}
                  setTranslateLanguage={setTranslateLanguage}
                  setSourceLanguage={setSourceLanguage}
                  handleTranslateLanguage={handleTranslateLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  setPrintLocked={setPrintImageLocked}
                  printLocked={printImageLocked}
                  setPrintContentLocked={setPrintContentLocked}
                  printContentLocked={printContentLocked}
                  handleSaveButtonClicked={handleSaveButtonClicked}
                  titles={titles}
                  setSelectedTitle={setSelectedTitle}
                  selectedTitle={selectedTitle}
                  territoryTitles={territoryTitles}
                  selectedTheme={selectedTheme}
                  setSelectedTheme={setSelectedTheme}
                  themeWasApplied={themeWasApplied}
                  prevSelectedTheme={prevSelectedTheme}
                  setPrevSelectedTheme={setPrevSelectedTheme}
                  isSidebarOpen={isSidebarOpen}
                  handleSaveProjectButtonClicked={
                    handleSaveProjectButtonClicked
                  }
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value="Flyer"
            sx={{
              width: "100%",
              padding: 0,
            }}
          >
            <div className="relative w-full">
              {showingHorizontal && isFlyerLoading && (
                <BackDrop type={"container"} />
              )}
              {showingVertical && isFlyerVerticalLoading && (
                <BackDrop type={"container"} />
              )}
              {showingSquare && isFlyerSquareLoading && (
                <BackDrop type={"container"} />
              )}
              {showingBannerHorizontal && isBannerHorizontalLoading && (
                <BackDrop type={"container"} />
              )}
              {showingBannerVertical && isBannerVerticalLoading && (
                <BackDrop type={"container"} />
              )}
              <div className="flex flex-row w-full ">
                <div
                  className={`duration-300  ease-in-out bg-white ${
                    isSidebarOpen
                      ? "w-full md:w-[21.2rem]  overflow-y-auto"
                      : "w-10"
                  }`}
                >
                  <SidebarTextfields
                    isSidebarOpen={isSidebarOpen}
                    handleSidebar={handleSidebar}
                    handleGenerateButtonClick={handleGenerateButtonClick}
                    isButtonDisabled={isGenerateButtonDisabled}
                    formDataError={formDataError}
                    formData={formData}
                    handleFormData={handleFormData}
                    activeTab={activeTab}
                    setPersonaRequirementChanged={setPersonaRequirementChanged}
                    setPrintRequirementChanged={setPrintRequirementChanged}
                    setEmailRequirementChanged={setEmailRequirementChanged}
                    setSocialMediaRequirementChanged={
                      setSocialMediaRequirementChanged
                    }
                    setCreativeRequirementChanged={
                      setCreativeRequirementChanged
                    }
                    setFlyerRequirementChanged={setFlyerRequirementChanged}
                    tailoredVisible={tailoredVisible}
                    signals={signals}
                    setSignals={setSignals}
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                    setShowMediaLikeDragDrop={setShowMediaLikeDragDrop}
                    showHelperText={showHelperText}
                    textModel={textModel}
                    setTextModel={setTextModel}
                    setIsGenerateButtonDisabled={setIsGenerateButtonDisabled}
                    imgModel={imgModel}
                    setImageModel={setImageModel}
                    loraModel={loraModel}
                    timers={timers}
                    setTimers={setTimers}
                    disabledOptions={disabledOptions}
                    setDisabledOptions={setDisabledOptions}
                    loraCheckModel={loraCheckModel}
                  />
                </div>
                <Flyer
                  Images={
                    showingHorizontal
                      ? sectionData?.flyer?.Images
                      : showingVertical
                      ? sectionData?.flyerVertical?.Images
                      : showingSquare
                      ? sectionData?.flyerSquare?.Images
                      : showingBannerHorizontal
                      ? sectionData?.bannerHorizontal?.Images
                      : sectionData?.bannerVertical?.Images
                  }
                  MagazineAd={
                    showingHorizontal
                      ? sectionData?.flyer?.Flyer_Translated
                      : showingVertical
                      ? sectionData?.flyerVertical?.Flyer_Translated
                      : showingSquare
                      ? sectionData?.flyerSquare?.Flyer_Translated
                      : showingBannerHorizontal
                      ? sectionData?.bannerHorizontal?.Flyer_Translated
                      : sectionData?.bannerVertical?.Flyer_Translated
                  }
                  handleGenerateButtonClick={handleGenerateButtonClick}
                  revisedData={getRevisedData}
                  regenerateData={handleGenerateButtonClick}
                  setSectionData={setSectionData}
                  sectionData={sectionData}
                  flyerHeadline={flyerHeadline}
                  flyerSubtext={flyerSubtext}
                  editedData={sendEditedData}
                  activeTab={activeTab}
                  flyerRequirementChanged={flyerRequirementChanged}
                  setPrintRequirementChanged={setPrintRequirementChanged}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  isLoading={flyerLoading}
                  magnifiedImage={magnifiedImage}
                  setMagnifiedImage={setMagnifiedImage}
                  setShowMagnifiedTemplate={setShowMagnifiedTemplate}
                  flyerPreview={flyerPreviewCall}
                  previewImage={previewImage}
                  setPreviewImage={setPreviewImage}
                  setPreviewButtonClicked={setPreviewButtonClicked}
                  setShowLogoConfirm={setShowLogoConfirm}
                  setTabName={setTabName}
                  setMissingParams={setMissingParams}
                  setShowErrorTab={setShowErrorTab}
                  setShowSuccessUpload={setShowSuccessUpload}
                  imageState={imageState}
                  setImageState={setImageState}
                  uploadedImages={uploadedImages}
                  setUploadedImages={setUploadedImages}
                  selectedTemplate={selectedTemplate}
                  setSelectedTemplate={setSelectedTemplate}
                  chosenImage={chosenImage}
                  setChosenImage={setChosenImage}
                  checkedGeneratedImages={checkedGeneratedImages}
                  setCheckedGeneratedImages={setCheckedGeneratedImages}
                  checkedUploadedImages={checkedUploadedImages}
                  setCheckedUploadedImages={setCheckedUploadedImages}
                  previewButtonClicked={previewButtonClicked}
                  flyerDownloadCall={flyerDownloadCall}
                  setDownloadButtonClicked={setDownloadButtonClicked}
                  primaryColor={primaryColor}
                  setPrimaryColor={setPrimaryColor}
                  secondaryColor={secondaryColor}
                  setSecondaryColor={setSecondaryColor}
                  setFlyerSubtext={setFlyerSubtext}
                  setFlyerHeadline={setFlyerHeadline}
                  directTranslate={directTranslate}
                  setInputText={setInputText}
                  translateLanguage={translateLanguage}
                  sourceLanguage={sourceLanguage}
                  setTranslateLanguage={setTranslateLanguage}
                  setSourceLanguage={setSourceLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  isFlyerLoading={isFlyerLoading}
                  isFlyerVerticalLoading={isFlyerVerticalLoading}
                  isFlyerSquareLoading={isFlyerSquareLoading}
                  isBannerHorizontalLoading={isBannerHorizontalLoading}
                  isBannerVerticalLoading={isBannerVerticalLoading}
                  showingHorizontal={showingHorizontal}
                  showingVertical={showingVertical}
                  showingSquare={showingSquare}
                  showingBannerHorizontal={showingBannerHorizontal}
                  showingBannerVertical={showingBannerVertical}
                  imageDimensions={imageDimensions}
                  setImageDimensions={setImageDimensions}
                  showSuccessUpload={showSuccessUpload}
                  horizontal={horizontal}
                  vertical={vertical}
                  square={square}
                  setVerticalFlyerImageLocked={setVerticalFlyerImageLocked}
                  verticalFlyerImageLocked={verticalFlyerImageLocked}
                  setVerticalFlyerContentLocked={setVerticalFlyerContentLocked}
                  verticalFlyerContentLocked={verticalFlyerContentLocked}
                  setHorizontalFlyerImageLocked={setHorizontalFlyerImageLocked}
                  horizontalFlyerImageLocked={horizontalFlyerImageLocked}
                  setHorizontalFlyerContentLocked={
                    setHorizontalFlyerContentLocked
                  }
                  horizontalFlyerContentLocked={horizontalFlyerContentLocked}
                  setSquareFlyerContentLocked={setSquareFlyerContentLocked}
                  squareFlyerContentLocked={squareFlyerContentLocked}
                  setSquareFlyerImageLocked={setSquareFlyerImageLocked}
                  squareFlyerImageLocked={squareFlyerImageLocked}
                  setHorizontalBannerContentLocked={
                    setHorizontalBannerContentLocked
                  }
                  horizontalBannerContentLocked={horizontalBannerContentLocked}
                  setHorizontalBannerImageLocked={
                    setHorizontalBannerImageLocked
                  }
                  horizontalBannerImageLocked={horizontalBannerImageLocked}
                  setVerticalBannerContentLocked={
                    setVerticalBannerContentLocked
                  }
                  verticalBannerContentLocked={verticalBannerContentLocked}
                  setVerticalBannerImageLocked={setVerticalBannerImageLocked}
                  verticalBannerImageLocked={verticalBannerImageLocked}
                  handleSaveButtonClicked={handleSaveButtonClicked}
                  handleGeneratedImagesCheckbox={handleGeneratedImagesCheckbox}
                  selectedTitle={selectedTitle}
                  territoryTitles={territoryTitles}
                  selectedTheme={selectedTheme}
                  setSelectedTheme={setSelectedTheme}
                  themeWasApplied={themeWasApplied}
                  prevSelectedTheme={prevSelectedTheme}
                  setPrevSelectedTheme={setPrevSelectedTheme}
                  isSidebarOpen={isSidebarOpen}
                  handleSaveProjectButtonClicked={
                    handleSaveProjectButtonClicked
                  }
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value="Creative Concepts"
            sx={{ width: "100%", padding: 0, height: "100%" }}
          >
            <div
              className={`relative w-full ${
                sectionData?.creativeConcepts?.["Concepts and Images"] ===
                undefined
                  ? "h-full"
                  : ""
              }`}
            >
              {isCreativeLoading && <BackDrop type={"container"}></BackDrop>}
              <div className="flex flex-row w-full">
                <div
                  className={`duration-300  ease-in-out bg-white ${
                    isSidebarOpen
                      ? "w-full md:w-[21.2rem]  overflow-y-auto"
                      : "w-10"
                  }`}
                >
                  <SidebarTextfields
                    isSidebarOpen={isSidebarOpen}
                    handleSidebar={handleSidebar}
                    handleGenerateButtonClick={handleGenerateButtonClick}
                    isButtonDisabled={isGenerateButtonDisabled}
                    formDataError={formDataError}
                    formData={formData}
                    handleFormData={handleFormData}
                    activeTab={activeTab}
                    setPersonaRequirementChanged={setPersonaRequirementChanged}
                    setPrintRequirementChanged={setPrintRequirementChanged}
                    setEmailRequirementChanged={setEmailRequirementChanged}
                    setSocialMediaRequirementChanged={
                      setSocialMediaRequirementChanged
                    }
                    setCreativeRequirementChanged={
                      setCreativeRequirementChanged
                    }
                    setFlyerRequirementChanged={setFlyerRequirementChanged}
                    tailoredVisible={tailoredVisible}
                    signals={signals}
                    setSignals={setSignals}
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                    setShowMediaLikeDragDrop={setShowMediaLikeDragDrop}
                    showHelperText={showHelperText}
                    textModel={textModel}
                    setTextModel={setTextModel}
                    setIsGenerateButtonDisabled={setIsGenerateButtonDisabled}
                    imgModel={imgModel}
                    setImageModel={setImageModel}
                    loraModel={loraModel}
                    timers={timers}
                    setTimers={setTimers}
                    disabledOptions={disabledOptions}
                    setDisabledOptions={setDisabledOptions}
                    loraCheckModel={loraCheckModel}
                  />
                </div>
                <CreativeConcept
                  creativeConcepts={
                    sectionData?.creativeConcepts?.["Concepts and Images"]?.[
                      selectedTitle
                    ]
                  }
                  handleGenerateButtonClick={handleGenerateButtonClick}
                  revisedData={getRevisedData}
                  regenerateData={handleGenerateButtonClick}
                  sectionData={sectionData}
                  setSectionData={setSectionData}
                  activeTab={activeTab}
                  editedData={sendEditedData}
                  creativeRequirementChanged={creativeRequirementChanged}
                  setCreativeRequirementChanged={setCreativeRequirementChanged}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  isLoading={isCreativeLoading}
                  translateLanguage={translateLanguage}
                  handleTranslateLanguage={handleTranslateLanguage}
                  setInputText={setInputText}
                  sourceLanguage={sourceLanguage}
                  directTranslate={directTranslate}
                  setSourceLanguage={setSourceLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  magnifiedImage={magnifiedImage}
                  setMagnifiedImage={setMagnifiedImage}
                  setShowMagnifiedTemplate={setShowMagnifiedTemplate}
                  setTabName={setTabName}
                  setCreativeLocked={setCreativeImageLocked}
                  creativeLocked={creativeImageLocked}
                  creativeContentLocked={creativeContentLocked}
                  setCreativeContentLocked={setCreativeContentLocked}
                  handleSaveButtonClicked={handleSaveButtonClicked}
                  titles={titles}
                  setSelectedTitle={setSelectedTitle}
                  selectedTitle={selectedTitle}
                  foundationUndo={foundationUndoCall}
                  foundationApply={foundationApplyCall}
                  setSelectedTheme={setSelectedTheme}
                  setThemeWasApplied={setThemeWasApplied}
                  themeWasApplied={themeWasApplied}
                  setPrevSelectedTheme={setPrevSelectedTheme}
                  isSidebarOpen={isSidebarOpen}
                  handleSaveProjectButtonClicked={
                    handleSaveProjectButtonClicked
                  }
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value="Product Description"
            sx={{ width: "100%", padding: 0, height: "100%" }}
          >
            <div
              className={`relative w-full ${
                !sectionData?.productDescription.length ? "h-full" : ""
              }`}
            >
              {isProductDescriptionLoading && (
                <BackDrop type={"container"}></BackDrop>
              )}
              <div className="flex flex-row w-full h-full ">
                <div
                  className={`duration-300  ease-in-out bg-white ${
                    isSidebarOpen
                      ? "w-full md:w-[21.2rem]  overflow-y-auto"
                      : "w-10"
                  }`}
                >
                  <SidebarTextfields
                    isSidebarOpen={isSidebarOpen}
                    handleSidebar={handleSidebar}
                    handleGenerateButtonClick={handleGenerateButtonClick}
                    isButtonDisabled={isGenerateButtonDisabled}
                    formDataError={formDataError}
                    formData={formData}
                    handleFormData={handleFormData}
                    activeTab={activeTab}
                    setPersonaRequirementChanged={setPersonaRequirementChanged}
                    setPrintRequirementChanged={setPrintRequirementChanged}
                    setEmailRequirementChanged={setEmailRequirementChanged}
                    setSocialMediaRequirementChanged={
                      setSocialMediaRequirementChanged
                    }
                    setCreativeRequirementChanged={
                      setCreativeRequirementChanged
                    }
                    setFlyerRequirementChanged={setFlyerRequirementChanged}
                    tailoredVisible={tailoredVisible}
                    signals={signals}
                    setSignals={setSignals}
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                    setShowMediaLikeDragDrop={setShowMediaLikeDragDrop}
                    showHelperText={showHelperText}
                    textModel={textModel}
                    setIsGenerateButtonDisabled={setIsGenerateButtonDisabled}
                    imgModel={imgModel}
                    setImageModel={setImageModel}
                    loraModel={loraModel}
                    timers={timers}
                    setTimers={setTimers}
                    disabledOptions={disabledOptions}
                    setDisabledOptions={setDisabledOptions}
                    loraCheckModel={loraCheckModel}
                  />
                </div>
                <ProductDescription
                  handleGenerateButtonClick={handleGenerateButtonClick}
                  revisedData={getRevisedData}
                  regenerateData={handleGenerateButtonClick}
                  email={sectionData?.email}
                  sectionData={sectionData}
                  setSectionData={setSectionData}
                  activeTab={activeTab}
                  setIsPersonaEdited={setIsPersonaEdited}
                  isPersonaEdited={isPersonaEdited}
                  emailRequirementChanged={emailRequirementChanged}
                  setEmailRequirementChanged={setEmailRequirementChanged}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  isLoading={isEmailLoading}
                  translateLanguage={translateLanguage}
                  handleTranslateLanguage={handleTranslateLanguage}
                  setInputText={setInputText}
                  sourceLanguage={sourceLanguage}
                  directTranslate={directTranslate}
                  setSourceLanguage={setSourceLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  emailContentLocked={emailContentLocked}
                  setEmailContentLocked={setEmailContentLocked}
                  handleSaveButtonClicked={handleSaveButtonClicked}
                  titles={titles}
                  setSelectedTitle={setSelectedTitle}
                  selectedTitle={selectedTitle}
                  setIsProductDescriptionLoading={
                    setIsProductDescriptionLoading
                  }
                  setProductDescriptionContentLocked={
                    setProductDescriptionContentLocked
                  }
                  productDescriptionContentLocked={
                    productDescriptionContentLocked
                  }
                  file={file}
                  setFile={setFile}
                  imagePreview={imagePreview}
                  setImagePreview={setImagePreview}
                  dropRef={dropRef}
                  fileInputRef={fileInputRef}
                  productDescriptionState={productDescriptionState}
                  setProductDescriptionState={setProductDescriptionState}
                  isSidebarOpen={isSidebarOpen}
                  textModel={textModel}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value="Email"
            sx={{
              width: "100%",
              padding: 0,
              height: `${sectionData?.email.length ? "auto" : "100%"}`,
            }}
          >
            <div
              className={`relative w-full h-full ${
                sectionData?.email.length ? "" : "h-full"
              }`}
            >
              {isEmailLoading && <BackDrop type={"container"}></BackDrop>}
              <div className="flex flex-row w-full">
                <div
                  className={`duration-300  ease-in-out bg-white ${
                    isSidebarOpen
                      ? "w-full md:w-[21.2rem]  overflow-y-auto"
                      : "w-10"
                  }`}
                >
                  <SidebarTextfields
                    isSidebarOpen={isSidebarOpen}
                    handleSidebar={handleSidebar}
                    handleGenerateButtonClick={handleGenerateButtonClick}
                    isButtonDisabled={isGenerateButtonDisabled}
                    formDataError={formDataError}
                    formData={formData}
                    handleFormData={handleFormData}
                    activeTab={activeTab}
                    setPersonaRequirementChanged={setPersonaRequirementChanged}
                    setPrintRequirementChanged={setPrintRequirementChanged}
                    setEmailRequirementChanged={setEmailRequirementChanged}
                    setSocialMediaRequirementChanged={
                      setSocialMediaRequirementChanged
                    }
                    setCreativeRequirementChanged={
                      setCreativeRequirementChanged
                    }
                    setFlyerRequirementChanged={setFlyerRequirementChanged}
                    tailoredVisible={tailoredVisible}
                    signals={signals}
                    setSignals={setSignals}
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                    setShowMediaLikeDragDrop={setShowMediaLikeDragDrop}
                    showHelperText={showHelperText}
                    textModel={textModel}
                    setTextModel={setTextModel}
                    setIsGenerateButtonDisabled={setIsGenerateButtonDisabled}
                    imgModel={imgModel}
                    setImageModel={setImageModel}
                    loraModel={loraModel}
                    timers={timers}
                    setTimers={setTimers}
                    disabledOptions={disabledOptions}
                    setDisabledOptions={setDisabledOptions}
                    loraCheckModel={loraCheckModel}
                  />
                </div>
                <Email
                  handleGenerateButtonClick={handleGenerateButtonClick}
                  revisedData={getRevisedData}
                  regenerateData={handleGenerateButtonClick}
                  email={sectionData?.email}
                  sectionData={sectionData}
                  setSectionData={setSectionData}
                  activeTab={activeTab}
                  setIsPersonaEdited={setIsPersonaEdited}
                  isPersonaEdited={isPersonaEdited}
                  emailRequirementChanged={emailRequirementChanged}
                  setEmailRequirementChanged={setEmailRequirementChanged}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  isLoading={isEmailLoading}
                  translateLanguage={translateLanguage}
                  handleTranslateLanguage={handleTranslateLanguage}
                  setInputText={setInputText}
                  sourceLanguage={sourceLanguage}
                  directTranslate={directTranslate}
                  setSourceLanguage={setSourceLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  emailContentLocked={emailContentLocked}
                  setEmailContentLocked={setEmailContentLocked}
                  handleSaveButtonClicked={handleSaveButtonClicked}
                  titles={titles}
                  setSelectedTitle={setSelectedTitle}
                  selectedTitle={selectedTitle}
                  territoryTitles={territoryTitles}
                  selectedTheme={selectedTheme}
                  setSelectedTheme={setSelectedTheme}
                  themeWasApplied={themeWasApplied}
                  prevSelectedTheme={prevSelectedTheme}
                  setPrevSelectedTheme={setPrevSelectedTheme}
                  isSidebarOpen={isSidebarOpen}
                  handleSaveProjectButtonClicked={
                    handleSaveProjectButtonClicked
                  }
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel
            value="Social Media"
            sx={{ width: "100%", padding: 0, height: "100%" }}
          >
            <div
              className={`relative w-full ${
                sectionData?.socialMedia?.[selectedTitle]?.["caption"] ===
                undefined
                  ? "h-full"
                  : ""
              }`}
            >
              {isSocialLoading && <BackDrop type={"container"}></BackDrop>}
              <div className="flex flex-row w-full ">
                <div
                  className={`duration-300  ease-in-out bg-white ${
                    isSidebarOpen
                      ? "w-full md:w-[21.2rem]  overflow-y-auto"
                      : "w-10"
                  }`}
                >
                  <SidebarTextfields
                    isSidebarOpen={isSidebarOpen}
                    handleSidebar={handleSidebar}
                    handleGenerateButtonClick={handleGenerateButtonClick}
                    isButtonDisabled={isGenerateButtonDisabled}
                    formDataError={formDataError}
                    formData={formData}
                    handleFormData={handleFormData}
                    activeTab={activeTab}
                    setPersonaRequirementChanged={setPersonaRequirementChanged}
                    setPrintRequirementChanged={setPrintRequirementChanged}
                    setEmailRequirementChanged={setEmailRequirementChanged}
                    setSocialMediaRequirementChanged={
                      setSocialMediaRequirementChanged
                    }
                    setCreativeRequirementChanged={
                      setCreativeRequirementChanged
                    }
                    setFlyerRequirementChanged={setFlyerRequirementChanged}
                    tailoredVisible={tailoredVisible}
                    signals={signals}
                    setSignals={setSignals}
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                    setShowMediaLikeDragDrop={setShowMediaLikeDragDrop}
                    showHelperText={showHelperText}
                    textModel={textModel}
                    setTextModel={setTextModel}
                    setIsGenerateButtonDisabled={setIsGenerateButtonDisabled}
                    imgModel={imgModel}
                    setImageModel={setImageModel}
                    loraModel={loraModel}
                    timers={timers}
                    setTimers={setTimers}
                    disabledOptions={disabledOptions}
                    setDisabledOptions={setDisabledOptions}
                    loraCheckModel={loraCheckModel}
                  />
                </div>
                <SocialMedia
                  caption={sectionData?.socialMedia[selectedTitle]?.["caption"]}
                  handleGenerateButtonClick={handleGenerateButtonClick}
                  revisedData={getRevisedData}
                  regenerateData={handleGenerateButtonClick}
                  sectionData={sectionData}
                  setSectionData={setSectionData}
                  activeTab={activeTab}
                  isSocialMediaPersonaEdited={isSocialMediaPersonaEdited}
                  setIsSocialMediaPersonaEdited={setIsSocialMediaPersonaEdited}
                  socialMediaRequirementChanged={socialMediaRequirementChanged}
                  setSocialMediaRequirementChanged={
                    setSocialMediaRequirementChanged
                  }
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  isLoading={isSocialLoading}
                  magnifiedImage={magnifiedImage}
                  setMagnifiedImage={setMagnifiedImage}
                  setShowMagnifiedTemplate={setShowMagnifiedTemplate}
                  setTabName={setTabName}
                  translateLanguage={translateLanguage}
                  handleTranslateLanguage={handleTranslateLanguage}
                  setInputText={setInputText}
                  sourceLanguage={sourceLanguage}
                  directTranslate={directTranslate}
                  setSourceLanguage={setSourceLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  setSocialMediaLocked={setSocialMediaImageLocked}
                  socialMediaLocked={socialMediaImageLocked}
                  socialMediaContentLocked={socialMediaContentLocked}
                  setSocialMediaContentLocked={setSocialMediaContentLocked}
                  handleSaveButtonClicked={handleSaveButtonClicked}
                  titles={titles}
                  setSelectedTitle={setSelectedTitle}
                  selectedTitle={selectedTitle}
                  socialMediaImagesCall={SocialMediaImageApiCall}
                  territoryTitles={territoryTitles}
                  selectedTheme={selectedTheme}
                  setSelectedTheme={setSelectedTheme}
                  themeWasApplied={themeWasApplied}
                  prevSelectedTheme={prevSelectedTheme}
                  setPrevSelectedTheme={setPrevSelectedTheme}
                  isSidebarOpen={isSidebarOpen}
                  handleSaveProjectButtonClicked={
                    handleSaveProjectButtonClicked
                  }
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value="Story Boarding"
            sx={{ width: "100%", padding: 0, height: "100%" }}
          >
            {errorMessage && directStoryBoardPopUp && (
              <SelectImageBackdrop
                message={"Generate Persona before generating steps."}
                imageSrc={require("./assets/notVerified.png")}
                directStoryBoardPopUp={directStoryBoardPopUp}
                setDirectStoryBoardPopUp={setDirectStoryBoardPopUp}
                setSuccessFullDownload={setSuccessFullDownload}
                successFullDownload={successFullDownload}
              />
            )}
            <div
              className={`relative w-full 
              ${
                generatedImages || generatedImagesTailored[selectedTitle]
                  ? sectionData.stepsTailored[selectedTitle].prev[0]?.IMAGE ||
                    sectionData.steps.prev[0]?.IMAGE
                    ? ""
                    : "h-full"
                  : sectionData.stepsTailored[selectedTitle].prev.length ||
                    sectionData.steps.prev?.length
                  ? ""
                  : "h-full"
              }
              `}
            >
              {isStoryBoardLoading && <BackDrop type={"container"}></BackDrop>}
              <div className="flex flex-row w-full">
                <div
                  className={`duration-300  ease-in-out bg-white ${
                    isSidebarOpen
                      ? "w-full md:w-[21.2rem]  overflow-y-auto"
                      : "w-10"
                  }`}
                >
                  <SidebarTextfields
                    isSidebarOpen={isSidebarOpen}
                    handleSidebar={closeSidebar}
                    handleGenerateButtonClick={handleGenerateButtonClick}
                    isButtonDisabled={isGenerateButtonDisabled}
                    formDataError={formDataError}
                    formData={formData}
                    handleFormData={handleFormData}
                    activeTab={activeTab}
                    setPersonaRequirementChanged={setPersonaRequirementChanged}
                    setPrintRequirementChanged={setPrintRequirementChanged}
                    setEmailRequirementChanged={setEmailRequirementChanged}
                    setSocialMediaRequirementChanged={
                      setSocialMediaRequirementChanged
                    }
                    setCreativeRequirementChanged={
                      setCreativeRequirementChanged
                    }
                    setFlyerRequirementChanged={setFlyerRequirementChanged}
                    tailoredVisible={tailoredVisible}
                    signals={signals}
                    setSignals={setSignals}
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                    setShowMediaLikeDragDrop={setShowMediaLikeDragDrop}
                    showHelperText={showHelperText}
                    textModel={textModel}
                    setTextModel={setTextModel}
                    setIsGenerateButtonDisabled={setIsGenerateButtonDisabled}
                    imgModel={imgModel}
                    setImageModel={setImageModel}
                    loraModel={loraModel}
                    timers={timers}
                    setTimers={setTimers}
                    disabledOptions={disabledOptions}
                    setDisabledOptions={setDisabledOptions}
                    loraCheckModel={loraCheckModel}
                  />
                </div>
                {showDialog && (
                  <ConfirmDialog
                    setShowDialog={setShowDialog}
                    handleConfirmDialogClick={handleConfirmDialogClick}
                    setStepArrTailored={setStepArrTailored}
                    tailoredVisible={tailoredVisible}
                  />
                )}
                {successFullDownload && (
                  <SelectImageBackdrop
                    message={"Your File is successfully downloaded."}
                    imageSrc={require("./assets/verified.png")}
                    directStoryBoardPopUp={directStoryBoardPopUp}
                    setDirectStoryBoardPopUp={setDirectStoryBoardPopUp}
                    setSuccessFullDownload={setSuccessFullDownload}
                    successFullDownload={successFullDownload}
                  />
                )}
                {landingVisible && (
                  <StoryBoardLanding
                    makeDirectTrue={makeDirectTrue}
                    makeLandingTrue={makeLandingTrue}
                    makeIdeateTrue={makeIdeateTrue}
                    makeTailoredTrue={makeTailoredTrue}
                    isSidebarOpen={isSidebarOpen}
                  />
                )}
                {tailoredVisible && (
                  <>
                    <StoryBoardingTailored
                      stepArr={stepArrTailored}
                      setStepArr={setStepArrTailored}
                      handleGenerateButtonClick={handleGenerateButtonClick}
                      formData={formData}
                      setFormData={setFormData}
                      setFormDataError={setFormDataError}
                      sectionData={sectionData}
                      setSectionData={setSectionData}
                      activeTab={activeTab}
                      updateSteps={sendUpdatedSteps}
                      generateSteps={generateTailoredSteps}
                      generateImage={storyBoardGenerateImageTailored}
                      regenerateImage={storyRegenerateImages}
                      handleStitch={handleStitch}
                      formDataError={formDataError}
                      generatedImagesTailored={generatedImagesTailored}
                      setGeneratedImagesTailored={setGeneratedImagesTailored}
                      validateDuration={validateDuration}
                      errorMessage={errorMessage}
                      setErrorMessage={setErrorMessage}
                      makeLandingTrue={makeLandingTrue}
                      setShowDialog={setShowDialog}
                      regenerateStep={regenerateStep}
                      reviseAllSteps={reviseAllSteps}
                      reviseAfterUpdating={reviseAfterUpdating}
                      tailoredVisible={tailoredVisible}
                      directVisible={directVisible}
                      ideateVisible={ideateVisible}
                      directStoryBoardPopUp={directStoryBoardPopUp}
                      setDirectStoryBoardPopUp={setDirectStoryBoardPopUp}
                      setSuccessFullDownload={setSuccessFullDownload}
                      successFullDownload={successFullDownload}
                      setIsStoryGenerated={setIsStoryGenerated}
                      titles={titles}
                      selectedTitle={selectedTitle}
                      setSelectedTitle={setSelectedTitle}
                      territoryTitles={territoryTitles}
                      selectedTheme={selectedTheme}
                      setSelectedTheme={setSelectedTheme}
                      themeWasApplied={themeWasApplied}
                      prevSelectedTheme={prevSelectedTheme}
                      setPrevSelectedTheme={setPrevSelectedTheme}
                      isStoryBoardLoading={isStoryBoardLoading}
                      isSidebarOpen={isSidebarOpen}
                      handleSidebar={handleSidebar}
                      textModel={textModel}
                    />
                  </>
                )}
                {ideateVisible && (
                  <StoryBoardingIdeate
                    stepArr={stepArr}
                    setStepArr={setStepArr}
                    handleGenerateButtonClick={handleGenerateButtonClick}
                    formData={formData}
                    setFormData={setFormData}
                    setFormDataError={setFormDataError}
                    sectionData={sectionData}
                    setSectionData={setSectionData}
                    activeTab={activeTab}
                    updateSteps={sendUpdatedSteps}
                    generateSteps={generateSteps}
                    generateImage={storyBoardGenerateImage}
                    regenerateImage={storyRegenerateImages}
                    handleStitch={handleStitch}
                    formDataError={formDataError}
                    generatedImages={generatedImages}
                    setGeneratedImages={setGeneratedImages}
                    validateDuration={validateDuration}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    makeLandingTrue={makeLandingTrue}
                    setShowDialog={setShowDialog}
                    regenerateStep={regenerateStep}
                    reviseAllSteps={reviseAllSteps}
                    reviseAfterUpdating={reviseAfterUpdating}
                    tailoredVisible={tailoredVisible}
                    directVisible={directVisible}
                    ideateVisible={ideateVisible}
                    setIsStoryGenerated={setIsStoryGenerated}
                    isSidebarOpen={isSidebarOpen}
                    textModel={textModel}
                  />
                )}
                {directVisible && (
                  <StoryBoardingDirect
                    stepArr={stepArr}
                    setStepArr={setStepArr}
                    handleGenerateButtonClick={handleGenerateButtonClick}
                    formData={formData}
                    setFormData={setFormData}
                    setFormDataError={setFormDataError}
                    sectionData={sectionData}
                    setSectionData={setSectionData}
                    activeTab={activeTab}
                    updateSteps={sendUpdatedSteps}
                    generateSteps={generateSteps}
                    generateImage={storyBoardGenerateImage}
                    regenerateImage={storyRegenerateImages}
                    handleStitch={handleStitch}
                    formDataError={formDataError}
                    generatedImages={generatedImages}
                    setGeneratedImages={setGeneratedImages}
                    validateDuration={validateDuration}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    makeLandingTrue={makeLandingTrue}
                    setShowDialog={setShowDialog}
                    generateDirectSteps={generateDirectSteps}
                    reviseDirectSteps={reviseDirectSteps}
                    regenerateStep={regenerateStep}
                    generateDirectStoryboard={directStoryboard}
                    tailoredVisible={tailoredVisible}
                    directVisible={directVisible}
                    ideateVisible={ideateVisible}
                    setIsStoryGenerated={setIsStoryGenerated}
                    isSidebarOpen={isSidebarOpen}
                    textModel={textModel}
                  />
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value="Translate"
            sx={{ width: "100%", padding: 0, height: "100%" }}
          >
            <div className="relative w-full h-full">
              {isTranslationLoading && <BackDrop type={"container"}></BackDrop>}
              <div className="flex flex-row w-full ">
                <div
                  className={`duration-300  ease-in-out bg-white ${
                    isSidebarOpen
                      ? "w-full md:w-[21.2rem]  overflow-y-auto"
                      : "w-10"
                  }`}
                >
                  <SidebarTextfields
                    isSidebarOpen={isSidebarOpen}
                    handleSidebar={handleSidebar}
                    handleGenerateButtonClick={handleGenerateButtonClick}
                    isButtonDisabled={isGenerateButtonDisabled}
                    formDataError={formDataError}
                    formData={formData}
                    handleFormData={handleFormData}
                    activeTab={activeTab}
                    setPersonaRequirementChanged={setPersonaRequirementChanged}
                    setPrintRequirementChanged={setPrintRequirementChanged}
                    setEmailRequirementChanged={setEmailRequirementChanged}
                    setSocialMediaRequirementChanged={
                      setSocialMediaRequirementChanged
                    }
                    setCreativeRequirementChanged={
                      setCreativeRequirementChanged
                    }
                    setFlyerRequirementChanged={setFlyerRequirementChanged}
                    tailoredVisible={tailoredVisible}
                    signals={signals}
                    setSignals={setSignals}
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                    setShowMediaLikeDragDrop={setShowMediaLikeDragDrop}
                    showHelperText={showHelperText}
                    setIsGenerateButtonDisabled={setIsGenerateButtonDisabled}
                    imgModel={imgModel}
                    setImageModel={setImageModel}
                    loraModel={loraModel}
                    timers={timers}
                    setTimers={setTimers}
                    disabledOptions={disabledOptions}
                    setDisabledOptions={setDisabledOptions}
                    loraCheckModel={loraCheckModel}
                  />
                </div>
                <TranslatePage
                  inputText={inputText}
                  setInputText={setInputText}
                  sectionData={sectionData}
                  setSectionData={setSectionData}
                  setIsTranslationLoading={setIsTranslationLoading}
                  setErrorMessage={setErrorMessage}
                  setSelectedLanguage={setSelectedLanguage}
                  selectedLanguage={selectedLanguage}
                  setTranslatedLanguage={setTranslatedLanguage}
                  translatedLanguage={translatedLanguage}
                  setTranslatedText={setTranslatedText}
                  translatedText={translatedText}
                  selectedButton={selectedButton}
                  setSelectedButton={setSelectedButton}
                  selectedEmotion={selectedEmotion}
                  setSelectedEmotion={setSelectedEmotion}
                  isSidebarOpen={isSidebarOpen}
                />
              </div>
            </div>
          </TabPanel>
          {isSavePageLoading && <BackDrop type={"container"}></BackDrop>}

          <TabPanel
            value="Save"
            sx={{ width: "100%", padding: 0, height: "100%" }}
          >
            <div className="relative w-full">
              <SavePage
                fetchAllDrafts={fetchAllDrafts}
                savedDrafts={savedDrafts}
                handleResumeWork={handleResumeWork}
                handleDeleteDraft={handleDeleteDraft}
                setShowResumeCard={setShowResumeCard}
                tabNameForResume={tabNameForResume}
                setTabNameForResume={setTabNameForResume}
                tabTypeForResume={tabTypeForResume}
                setTabTypeForResume={setTabTypeForResume}
                fileNameForResume={fileNameForResume}
                setFileNameForResume={setFileNameForResume}
                handleResumeProjectCards={handleResumeProjectCards}
                projectDrafts={projectDrafts}
                handleResumeProject={handleResumeProject}
                handleDeleteProject={handleDeleteProject}
              />
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};
export default App;
