import axios from "axios";
import { loginRequest } from "../authConfigFile/authConfig";
import { msal } from "..";
import {
  DIRECT_TRANSLATION_URL,
  DIRECT_TRANSLATION_POLLING_URL,
  STYLIZE_TRANSLATION_POLLING_URL,
  STYLIZE_TRANSLATION_URL,
  DETECT_LANGUAGE,
} from "./constants";
const errorMessages = {
  429: "Limit Exceeded. Please try after sometime!",
  401: "Session Expired. Please login again!",
};

export const getIdToken = async (setErrorMessage) => {
  try {
    const { idToken } = await msal.acquireTokenSilent(loginRequest);
    return `Bearer ${idToken}`;
  } catch (error) {
    setErrorMessage(error);
  }
};

export const detectLanguage = (
  item,
  setErrorMessage,
  setIsTranslationLoading,
  setSelectedLanguage
) => {
  const payload = { input: item };
  setIsTranslationLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(DETECT_LANGUAGE, payload, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: idToken,
          },
        })
        .then((res) => {
          setSelectedLanguage(res.data["language"]);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsTranslationLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

//for translate tab
export const translate = (
  item,
  source,
  target,
  selectedButton,
  selectedEmotion,
  setTranslatedText,
  setIsLoading,
  translationValue,
  localisationValue,
  setErrorMessage,
  setPrompt,
  setTranslationChunks
) => {
  const payload = {
    input: item,
    source_language: source,
    target_language: target,
    tone: selectedButton,
    emotion: selectedEmotion,
    translation_model: translationValue,
    localization_model: localisationValue,
    prompt: `Your job is to localize the translation so it sounds more ${selectedButton} and ${selectedEmotion} only adjust as needed.`,
  };
  setIsLoading(true);
  let url = "";
  if (item.length > 3000) {
    url = STYLIZE_TRANSLATION_POLLING_URL;
    payload["content"] = item;
  } else {
    url = STYLIZE_TRANSLATION_URL;
  }
  const promptText = prompt;
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(url, payload, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: idToken,
          },
        })
        .then((res) => {
          if (item.length <= 3000) {
            setTranslatedText(res.data["translation"]);
            setPrompt(res.data["prompt"]);
            setIsLoading(false);
          } else {
            const uuidArray = res.data["message"][0].map(
              (item) => item["UUID"]
            );
            setTranslationChunks({
              id: uuidArray,
              initialTime: res.data["message"][1]["TIME"],
            });
            setPrompt(
              `Your job is to localize the translation so it sounds more ${selectedButton} and ${selectedEmotion} only adjust as needed.`
            );
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
      setIsLoading(false);
    });
};
export const directTransalation = (
  item,
  source,
  target,
  selectedTitle,
  setPersonaWasTranslated,
  setSectionData,
  isLoading,
  setIsLoading,
  isFlyerVerticalLoading,
  setIsFlyerVerticalLoading,
  isFlyerSquareLoading,
  setIsFlyerSquareLoading,
  isBannerHorizontalLoading,
  setIsBannerHorizontalLoading,
  isBannerVerticalLoading,
  setIsBannerVerticalLoading,
  setErrorMessage,
  translationModel,
  localisationModel,
  activeTab,
  horizontal,
  vertical,
  square,
  bannerHorizontal,
  bannerVertical,
  sectionData = null,
  index = null,
  isProductDescriptionLoading,
  setIsProductDescriptionLoading
) => {
  if (activeTab === "Product Description") {
    setIsProductDescriptionLoading(true);
  }

  let payload = {
    source_language: source,
    target_language: target,
    input: item,
    translation_model: translationModel,
    localization_model: localisationModel,
  };
  // if (source === target) return;
  if (activeTab === "Flyer") {
    if (horizontal) {
      setIsLoading(true);
    }
    if (vertical) {
      setIsFlyerVerticalLoading(true);
    }
    if (square) {
      setIsFlyerSquareLoading(true);
    }
    if (bannerHorizontal) {
      setIsBannerHorizontalLoading(true);
    }
    if (bannerVertical) {
      setIsBannerVerticalLoading(true);
    }
  } else {
    setIsLoading(true);
  }
  let url = DIRECT_TRANSLATION_URL;
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(url, payload, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: idToken,
          },
        })
        .then((res) => {
          // eslint-disable-next-line default-case
          switch (activeTab) {
            case "Flyer":
              if (horizontal) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyer: {
                    ...sectionData.flyer,
                    Flyer_Translated: res.data["translation"],
                  },
                }));
              }
              if (vertical) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyerVertical: {
                    ...sectionData.flyerVertical,
                    Flyer_Translated: res.data["translation"],
                  },
                }));
              }
              if (square) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyerSquare: {
                    ...sectionData.flyerSquare,
                    Flyer_Translated: res.data["translation"],
                  },
                }));
              }
              if (bannerHorizontal) {
                setSectionData((previousState) => ({
                  ...previousState,
                  bannerHorizontal: {
                    ...sectionData.bannerHorizontal,
                    Flyer_Translated: res.data["translation"],
                  },
                }));
              }
              if (bannerVertical) {
                setSectionData((previousState) => ({
                  ...previousState,
                  bannerVertical: {
                    ...sectionData.bannerVertical,
                    Flyer_Translated: res.data["translation"],
                  },
                }));
              }
              break;
            case "Social Media":
              setSectionData((prevState) => {
                const sectionDataCopy = { ...prevState };
                sectionDataCopy.socialMedia[selectedTitle]["caption"] =
                  res.data["translation"];
                return sectionDataCopy;
              });
              setIsLoading(false);
              break;
            case "Creative Concepts":
              setSectionData((previousState) => {
                let sectionDataCopy = { ...previousState };
                if (
                  sectionDataCopy.creativeConcepts?.["Concepts and Images"]?.[
                    selectedTitle
                  ][index]
                )
                  sectionDataCopy.creativeConcepts["Concepts and Images"][
                    selectedTitle
                  ][index].ConceptTerritory = res.data["translation"];
                return sectionDataCopy;
              });
              setIsLoading(false);
              break;
            case "Email":
              setSectionData((prevState) => {
                const sectionDataCopy = { ...prevState };
                sectionDataCopy.email[selectedTitle] = res.data["translation"];
                return sectionDataCopy;
              });
              setIsLoading(false);
              break;
            case "Product Description":
              setSectionData((prevState) => {
                const sectionDataCopy = { ...prevState };
                sectionDataCopy.productDescription = res.data["translation"];
                return sectionDataCopy;
              });
              setIsProductDescriptionLoading(false);
              break;
            case "Persona":
              setSectionData((prevState) => {
                const sectionDataCopy = { ...prevState };
                sectionDataCopy.persona[selectedTitle] =
                  res.data["translation"];
                return sectionDataCopy;
              });
              setPersonaWasTranslated(true);
              setIsLoading(false);

              break;
            case "Print":
              setSectionData((prevState) => {
                const sectionDataCopy = { ...prevState };
                sectionDataCopy.print[selectedTitle]["Magazine Ad"] =
                  res.data["translation"];
                return sectionDataCopy;
              });
              setIsLoading(false);
              break;

            default:
              break;
          }
        })
        .catch((error) => {
          console.log(error);
          if (activeTab === "Product Description") {
            setIsProductDescriptionLoading(false);
          }
        })
        .finally(() => {
          if (activeTab === "Flyer") {
            if (horizontal) {
              setIsLoading(false);
            }
            if (vertical) {
              setIsFlyerVerticalLoading(false);
            }
            if (square) {
              setIsFlyerSquareLoading(false);
            }
            if (bannerHorizontal) {
              setIsBannerHorizontalLoading(false);
            }
            if (bannerVertical) {
              setIsBannerVerticalLoading(false);
            }
          } else {
            setIsLoading(false);
            if (activeTab === "Product Description") {
              setIsProductDescriptionLoading(false);
            }
          }
        });
    })
    .catch((error) => {
      if (activeTab === "Flyer") {
        if (horizontal) {
          setIsLoading(false);
        }
        if (vertical) {
          setIsFlyerVerticalLoading(false);
        }
        if (square) {
          setIsFlyerSquareLoading(false);
        }
        if (bannerHorizontal) {
          setIsBannerHorizontalLoading(false);
        }
        if (bannerVertical) {
          setIsBannerVerticalLoading(false);
        }
      } else setIsLoading(false);
      setErrorMessage(error);
    });
};

export const translationDirect = (
  item,
  source,
  target,
  setSectionData,
  setTranslatedText,
  setIsLoading,
  setErrorMessage,
  translationModel,
  localisationModel,
  translationChunks,
  setTranslationChunks
) => {
  let payload = {
    source_language: source,
    target_language: target,
    input: item,
    translation_model: translationModel,
    localization_model: localisationModel,
  };

  setIsLoading(true);
  let url = "";
  if (item.length > 3000) {
    url = DIRECT_TRANSLATION_POLLING_URL;
    payload["content"] = item;
  } else {
    url = DIRECT_TRANSLATION_URL;
  }
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(url, payload, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: idToken,
          },
        })
        .then((res) => {
          // setTranslatedText(res.data["translation"]);
          if (item.length <= 3000) {
            setTranslatedText(res.data["translation"]);
            setIsLoading(false);
          } else {
            const uuidArray = res.data["message"][0].map(
              (item) => item["UUID"]
            );
            setTranslationChunks({
              id: uuidArray,
              initialTime: res.data["message"][1]["TIME"],
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const edit = (
  translatedText,
  prompt,
  setTranslatedText,
  setIsLoading,
  setErrorMessage,
  translationModel,
  localisationModel,
  item,
  selectedButton,
  selectedEmotion,
  source,
  target,
  setPrompt,
  setTranslationChunks
) => {
  let payload = {
    translation: translatedText,
    prompt: prompt,
  };
  setIsLoading(true);
  let url =
    "https://localizationandtranslation.deloitte.com/api/translation/edit";
  if (item.length > 3000) {
    payload.content = item;
    payload.prompt = `Your job is to localize the translation so it sounds more ${selectedButton} and ${selectedEmotion} only adjust as needed.`;
    payload.source_language = source;
    payload.target_language = target;
    payload.localization_model = localisationModel;
    payload.translation_model = translationModel;
    url = STYLIZE_TRANSLATION_POLLING_URL;
  }
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(url, payload, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: idToken,
          },
        })
        .then((res) => {
          if (item.length <= 300) {
            setTranslatedText(res.data["translation"]);
            setPrompt(prompt);
            setIsLoading(false);
          } else {
            const uuidArray = res.data["message"][0].map(
              (item) => item["UUID"]
            );
            setTranslationChunks({
              id: uuidArray,
              initialTime: res.data["message"][1]["TIME"],
            });
            setPrompt(
              `Your job is to localize the translation so it sounds more ${selectedButton} and ${selectedEmotion} only adjust as needed.`
            );
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const lamdaEndpoint = (
  translationChunks,
  isTranslationRunning,
  intervalRef,
  setTranslatedText,
  setIsLoading
) => {
  const payload = {
    body: {
      uuids: translationChunks.id,
    },
  };
  axios
    .post(
      "https://pzk3829tnk.execute-api.us-east-1.amazonaws.com/deployment/polling_translation",
      payload,
      {
        headers: {
          "Content-type": "application/json, charset=utf-8",
        },
      }
    )
    .then((res) => {
      isTranslationRunning.current = res.data;
      if (res.data.statusCode !== 200) {
        clearInterval(intervalRef.current);
        return Promise.reject("not found");
      }
      let body = JSON.parse(res.data.body);
      if (body["success"]) {
        setTranslatedText(body["data"].join());
        clearInterval(intervalRef.current);
        setIsLoading(false);
      }
    })
    .catch((error) => {
      setIsLoading(false);
    });
};

export const storyBoardTransalation = (
  item,
  source,
  target,
  setSectionData,
  isLoading,
  setIsLoading,
  setErrorMessage,
  translationModel,
  localisationModel,
  sectionData,
  setPersonaHindi
) => {
  let payload = {
    source_language: source,
    target_language: target,
    input: item,
    translation_model: translationModel,
    localization_model: localisationModel,
  };
  setIsLoading(true);
  let url = DIRECT_TRANSLATION_URL;

  return getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(url, payload, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: idToken,
          },
        })
        .then((res) => {
          setPersonaHindi(res.data["translation"]);
          //return Promise.resolve(res.data['translation'])
          //setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};
