import { useMsal } from "@azure/msal-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ProfileContent from "../authConfigFile/profileContent";
import BackDrop from "../components/BackDrop";
import RequestDenied from "../components/RequestDenied";
import RequestForm from "../components/RequestForm";
import RequestSent from "../components/RequestSent";
import { CHECKING_USER_STATUS } from "../utils/constants";
import { getIdToken } from "../utils/translateHelper";

const HandleUser = () => {
  const { accounts } = useMsal();

  const [errorMessage, setErrorMessage] = useState("");
  const [userState, setUserState] = useState("form");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [project, setProject] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    renderComponent();
  }, [userState]);

  useEffect(() => {
    const email = accounts[0].username;
    const name = email.split("@")[0];
    localStorage.setItem("cred", name);
  }, []);

  useEffect(() => {
    if (accounts.length > 0) {
      const userDetail = accounts[0].username;
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(
              CHECKING_USER_STATUS,
              {},
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: idToken,
                },
              }
            )
            .then((response) => {
              setUserState(response.data.user_status);
              setProject(response.data.project ?? "");
              setDataUpdated(true);
              setIsAdmin(response.data.is_admin)
              localStorage.setItem("isAdmin", JSON.stringify(response.data.is_admin))
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    //}
  }, [accounts]);

  const handleFormSubmit = () => {
    setUserState("Pending");
  };
  const handleRejectRedirect = () => {
    setUserState("form");
  };
  const updateProject = (value) => {
    setProject(value);
  };

  const renderComponent = () => {
    if (!dataUpdated) {
      return <BackDrop type={"fullpage"} />;
    } else {
      switch (userState) {
        case "Pending":
          if (isAdmin)
            return <ProfileContent />;
          else return <RequestSent project={project} />;
        case "Approved":
          if (isAdmin)
            return <ProfileContent />;
          else return <ProfileContent />;
        case "No Access":
          if (isAdmin)
            return <ProfileContent />;
          else
            return (
              <RequestDenied
                setUserState={setUserState}
                handleRejectRedirect={handleRejectRedirect}
              />
            );
        default:
          if (isAdmin)
            return <ProfileContent />;
          else
            return (
              <RequestForm
                setUserState={setUserState}
                handleFormSubmit={handleFormSubmit}
                updateProject={updateProject}
              />
            );
      }
    }
  };

  return renderComponent();
};

export default HandleUser;