import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import SelectImageBackdrop from "./SelectImageBackdrop";
import LogoError from "./LogoError";
import ReviewMode from "./ReviewMode";
import EditMode from "./EditMode";
import ImageView from "./ImageView";
import MenuConceptTheme from "./MenuConceptTheme";
import "./TopInputTailored.css";

const TopInputTailored = ({
  sectionData,
  formData,
  errorMessage,
  setErrorMessage,
  setFormData,
  formDataError,
  setGeneratedImagesGlobal,
  setGeneratedImages,
  generateSteps,
  validateDuration,
  handleBlur,
  handleDuration,
  isTextareaFilled,
  handleSettings,
  directStoryBoardPopUp,
  setDirectStoryBoardPopUp,
  setShowDialog,
  makeLandingTrue,
  setStepArr,
  steps,
  setShowEditingMode,
  handleGenerateImagesButton,
  regenerateImage,
  handleReviseButton,
  handleAddStepEach,
  addEachClicked,
  editEachClicked,
  handleChangeInNewStep,
  handleSaveAfterAddButton,
  handleEditChangeEach,
  handleEditEachButton,
  handleSaveEachButton,
  handleRefreshEachButton,
  handleDeleteEachButton,
  showEditingMode,
  generatedImagesGlobal,
  tailoredVisible,
  directVisible,
  ideateVisible,
  handleStitch,
  setIsStoryGenerated,
  selectedTitle,
  setGeneratedImagesTailored,
  territoryTitles,
  selectedTheme,
  setSelectedTheme,
  selectedValue,
  setSelectedValue,
  setIsHovered,
  handleMouseMove,
  setSelectedTerritory,
  themeWasApplied,
  setRanEffect,
  ranEffect,
  isSidebarOpen,
  handleSidebar,
}) => {
  const [settings, setSettings] = useState("");
  const handleHomeButtonClick = () => {
    if (sectionData?.stepsTailored[selectedTitle]?.prev?.length)
      setShowDialog(true);
    else {
      setStepArr([]);
      makeLandingTrue();
      setFormData({
        ...formData,
        storyBoardTitle: "",
        duration: "",
        story: "",
        settings: [],
      });
    }
  };

  const [isSubmit, setIsSubmit] = useState(false);
  return (
    <div className={`flex justify-center`}>
      <div
        className={`w-full h-full bg-white rounded-lg p-5 flex flex-col justify-wrap justify-between  items-start m-5  relative${
          sectionData.stepsTailored?.[selectedTitle]?.prev?.[0] && " mt-0"
        } `}
      >
        <div>
          {" "}
          <MenuConceptTheme
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            setIsHovered={setIsHovered}
            handleMouseMove={handleMouseMove}
            territoryTitles={territoryTitles[selectedTitle]}
            selectedTheme={selectedTheme}
            setSelectedTheme={setSelectedTheme}
            setSelectedTerritory={setSelectedTerritory}
            themeWasApplied={themeWasApplied}
            setRanEffect={setRanEffect}
            ranEffect={ranEffect}
          />
        </div>
        <div className="w-full h-full flex justify-center items-center md:justify-between md:items-start justify-wrap flex-col md:flex-row">
          <div className=" w-full md:w-1/4 md:mr-4">
            <p className="duration">Duration in mins</p>
            <TextField
              inputProps={{
                sx: {
                  height: "15px",
                  margin: "0px",
                },
                inputMode: "decimal",
                pattern: "^[0-9]*\\.?[0-9]{0,2}$",
                min: "1",
                step: "any",
              }}
              color="tertiary"
              className="textScene w-full m-0 md:ml-4 p-0 md:pr-4"
              defaultValue=""
              InputLabelProps={{
                sx: {
                  color: "#FFF",
                },
              }}
              focused
              value={formData?.duration}
              onChange={(e) => {
                const value = e.target.value;
                if (!value || value.match(/^[0-9]*\.?[0-9]{0,2}$/)) {
                  handleDuration(e);
                }
              }}
              error={!!formDataError.duration}
              helperText={formDataError.duration}
              type="number"
            />
          </div>
          <div className="w-full md:w-8/12 md:mr-4">
            <p className="scene">Set the scene (Optional)</p>
            <TextField
              inputProps={{
                sx: {
                  height: "15px",
                },
              }}
              fullWidth
              className="font-semibold"
              defaultValue=""
              color="tertiary"
              focused
              value={settings}
              onChange={(e) => {
                setSettings(e.target.value);
              }}
            />
          </div>

          <div className="w-full md:w-auto flex items-center justify-center md:justify-end mt-6">
            <Button
              className="submit"
              variant="contained"
              color="secondary"
              onClick={() => {
                if (!sectionData.persona.length) {
                  setDirectStoryBoardPopUp(true);
                  setErrorMessage({
                    ...errorMessage,
                    message: "Generate Persona before generating steps.",
                  });

                  return;
                }
                if (tailoredVisible) {
                  setGeneratedImagesTailored((prev) => {
                    const newww = [...prev];
                    newww[selectedTitle] = false;
                    return newww;
                  });
                }
                handleSettings(settings);
                generateSteps();
                setIsSubmit(true);
                setIsStoryGenerated(false);
              }}
              disabled={!formData?.duration || formData?.duration == 0}
            >
              SUBMIT
            </Button>
          </div>
          <div className=" flex items-end justify-end mt-4">
            <Button
              sx={{
                padding: "0.75rem 0.8125rem",
                fontWeight: 600,
                border: "1px solid #26890D",
                marginTop: "0.5rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
              onClick={() => {
                handleHomeButtonClick();
              }}
            >
              <img
                src={require("../assets/Vector.jpg")}
                alt="home-icon"
                className="h-[1.4375rem] w-[1.4375rem]"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopInputTailored;
