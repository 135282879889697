import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import React from "react";
import HandleUser from "./pages/HandleUser";
import Login from "./pages/Login";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary"
import Fallback from "./components/ErrorBoundary/Fallback";


const Main = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ErrorBoundary fallback={Fallback}>
          <HandleUser/>
        </ErrorBoundary>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
};
export default Main;
