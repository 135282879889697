import React from "react";

const UserIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="-0.00390625" width="32" height="32" rx="6" fill="#50AE32" />
      <path
        d="M22.4998 22.9456C22.4998 21.1615 21.7801 19.5002 20.4729 18.2679C19.3745 17.2323 17.9902 16.6238 16.5069 16.5108C18.5553 16.2727 20.1504 14.5402 20.1504 12.4437C20.1504 10.1852 18.2995 8.34766 16.0245 8.34766C13.7496 8.34766 11.8987 10.1852 11.8987 12.4437C11.8987 14.5341 13.4848 16.2628 15.5246 16.5086C12.1314 16.7492 9.49219 19.5792 9.49219 23.0081V23.651H22.5L22.4998 22.9456ZM12.93 12.4437C12.93 10.7498 14.3181 9.37169 16.0243 9.37169C17.7305 9.37169 19.1187 10.7498 19.1187 12.4437C19.1187 14.1375 17.7305 15.5157 16.0243 15.5157C14.3181 15.5157 12.93 14.1375 12.93 12.4437ZM10.5361 22.6268C10.7236 19.8746 12.9271 17.6654 15.7064 17.523C17.2221 17.4454 18.6626 17.9734 19.7622 19.0103C20.7841 19.9735 21.3794 21.2479 21.459 22.6268H10.5361Z"
        fill="white"
        stroke="white"
           stroke-width="0.5"
      />
    </svg>
  );
};

export default UserIcon;
