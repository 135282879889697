import React from "react";
import { Link } from "react-router-dom";
import NotFound from "../../assets/NotFound.png"

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center h-screen justify-center p-4 ">
  <img src={NotFound} alt="Not Found" className="max-w-full h-1/2 mb-8" />
  <h1 className="text-5xl font-bold text-gray-800 mb-4">Page Not Found</h1>
  <p className="text-lg text-gray-600 mb-8">
    The page you were looking for could not be found. Please navigate back
    to the home page.
  </p>
  <button className="px-6 py-3 bg-[#26890D] text-white rounded-md hover:bg-green-700 transition duration-300">
    <Link className="hover:no-underline" to="/">Go Back</Link>
  </button>
</div>
  );
};

export default NotFoundPage;
