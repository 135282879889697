import { Button } from "@mui/material";
import React from "react";
const StoryBoardLanding = ({
  makeDirectTrue,
  makeLandingTrue,
  makeIdeateTrue,
  makeTailoredTrue,
  isSidebarOpen
}) => {
  return (
    <div className={`h-screen w-full flex flex-col justify-start items-start flex-1 bg-white m-4   rounded-lg ${isSidebarOpen?"hidden" :"flex"} md:flex`}>
      <p className="mx-4 mt-6 mb-2.5 text-left text-sm font-bold">Choose Your Path</p>
      <div className="w-full gap-4">
        <div className="grid grid-cols-1 lg:grid-cols-3  mx-2 ">
          <div className="p-2 border border-gray-300 m-1 flex flex-col rounded-lg h-full max-w-sm mx-auto">
            <div className="flex-1 p-4">
              <img
                className="h-auto w-full rounded"
                src={require("../assets/storyboard3.png")}
                alt="tailor"
              />
            </div>
            <div className="flex-2 p-4 font-sans text-sm">
              <span className="font-bold">Tailored : </span> Create targeted
              content that matches the business requirements on the left side of
              the page.
            </div>
            <div className="flex p-4 items-end justify-center flex-1">
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  makeTailoredTrue();
                }}
                sx={{
                  height: "2.75rem",
                  fontFamily: "Open Sans",
                  fontSize: "0.813rem",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "1rem",
                  textTransform: "uppercase",
                  borderRadius: "0.375rem",
                }}
              >
                use persona and business requirements
              </Button>
            </div>
          </div>
          <div className="p-2 border border-gray-300 m-1 flex flex-col rounded-lg h-full max-w-sm mx-auto">
            <div className="flex-1 p-4">
              <img
                className="h-auto w-full rounded"
                src={require("../assets/storyboard1.png")}
                alt="tailor"
              />
            </div>
            <div className="flex-2 p-4 font-sans text-sm">
              <span className="font-bold">Ideate : </span> Start with an idea of
              what you want to see, and tailor it with the aid of AI.
            </div>
            <div className="flex items-end justify-center flex-1 p-4">
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  makeIdeateTrue();
                }}
                sx={{
                  height: "2.75rem",
                  fontFamily: "Open Sans",
                  fontSize: "0.813rem",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "1rem",
                  textTransform: "uppercase",
                  borderRadius: "0.375rem",
                }}
              >
                start from idea
              </Button>
            </div>
          </div>
          <div className="p-2 border border-gray-300 m-1 flex flex-col rounded-lg h-full max-w-sm mx-auto">
            <div className="flex-1 p-4">
              <img
                className="h-auto w-full rounded"
                src={require("../assets/storyboard2.png")}
                alt="tailor"
              />
            </div>
            <div className="flex-2 font-sans text-sm p-4">
              <span className="font-bold">Direct : </span> Start with human
              written content to create fast storyboards. If you have a script
              this is a great place to start.
            </div>
            <div className="flex items-end justify-center flex-1 p-4">
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  makeDirectTrue();
                }}
                sx={{
                  height: "2.75rem",
                  fontFamily: "Open Sans",
                  fontSize: "0.813rem",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "1rem",
                  textTransform: "uppercase",
                  borderRadius: "0.375rem",
                }}
              >
                use your own script
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryBoardLanding;
