import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Tooltip } from "@mui/material";
import { React, useEffect, useState } from "react";
import { characterLimitConstant, textFormatConstant } from "../utils/constants";
import "./CardProductDescription.css";

const CardProductDescription = (props) => {
  const { brand_name, product_name, product_description,collection } =
    props.productDescriptionState;
  const [showEditArea, setShowEditArea] = useState(false);
  const [responseValue, setResponseValue] = useState("");
  const [textFormat, setTextFormat] = useState("")
  const [characterLimit, setCharacterLimit] = useState("")
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    setResponseValue(props.text);
  }, [props.text]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "15%",
      },
    },
  };


  const handleEditChange = (e) => {
      setResponseValue(e.target.value);    
  };

  const handleIconClick = (e) => {
    if(!props.productDescriptionContentLocked){
      setShowEditArea(!showEditArea);
    }
    
  };

  const handleContentLocked = () => {
    props.setProductDescriptionContentLocked(
      !props.productDescriptionContentLocked
    );
  };

  const inputField = (type, size, number=false) => (
    <TextField
      fullWidth
      color="tertiary"
      defaultValue=""
      focused
      multiline
      value={type === "custom_limit" && props.productDescriptionState[type] !== "Custom Limit" ? props.productDescriptionState[type] : null }
      onChange={(e) => {
        let value = e.target.value;
        props.setProductDescriptionState({
          [type]: value,
        });
        if(number){
          if (value === '' || (Number(value) > 0 && Number(value) <= 3000 && /^\d*$/.test(value))) {
            setError(false);
            setHelperText('');
          } else {
            setError(true);
            setHelperText('Please enter a valid number between 0 and 3000.');
          }
        }
      }}
      InputProps={{
        sx: {
          borderRadius: "0.5rem",
        },
      }}
      inputProps={{
        sx: {
          height: size,
          overflow: "auto",
        },
        min: 1,
        max: 3000
      }}
      type={number ? "number" : "text"}
      error={number && error}
      helperText={number && helperText}
    />
  );

  return (
    <div
  className={`bg-white pl-4 pr-1 ${
    props.text.length && "border-2 border-gray-200 rounded-lg"
  } w-full relative`}
>
  {props.text.length ? (
    <div className="flex flex-col  sm:flex-row h-72 sm:h-full my-1">
      {showEditArea ? (
        <TextField
          variant="standard"
          sx={{
            width: "100%", 
            minHeight: "14.375rem",
            maxHeight: "26.875rem",
            overflowY: "auto", 
            "& fieldset": { border: "none" },
            marginTop: "0.625rem",
          }}
          fullWidth 
          multiline
          defaultValue={responseValue}
          onChange={handleEditChange}
          InputProps={{
            disableUnderline: true,
            sx: {
              color: "#000",
              fontFamily: "Open Sans, sans-serif",
              fontSize: "0.875rem",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "1.875rem",
            },
          }}
          inputRef={(input) => input && input.focus()}
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
        />
      ) : (
        <div className="w-full overflow-auto whitespace-pre-wrap h-full leading-9 cardProductDescription">
          {responseValue}
        </div>
      )}

      <div className="sm:pl-4 flex flex-row sm:flex-col justify-around sm:justify-end items-center">
        {!showEditArea ? (
          <div>
            <Button variant="text" onClick={handleIconClick}>
              <img
                src={require("../assets/edit-icon.png")}
                className="editiconProductDescription"
                alt="edit icon"
              />
            </Button>
          </div>
        ) : (
          <Button variant="text" onClick={handleIconClick}>
            <img
              src={require("../assets/saveIcon.png")}
              alt="Save Icon"
              className="saveIconProductDescription"
            />
          </Button>
        )}

        <div>
          <Button onClick={handleContentLocked}>
          <img
            src={require(`../assets/${
              props.productDescriptionContentLocked
                ? "content-lock"
                : "content-unlock"
            }.jpg`)}
            alt="locked-Icon"
            className="lockIconProductDescription mt-4 sm:mt-0 mb-[1.06rem]"
          />
        </Button>
          </div>

        <div>
          <Tooltip title="Send To Translate Tab">
            <Button
              onClick={() => {
                props.setInputText(props.text);
                props.setSelectedLanguage(
                  props.translateLanguage?.[props.activeTab][
                    props.selectedTitle
                  ]
                );
              }}
            >
              <img
                src={require("../assets/send-to-translate.png")}
                className=" text-[#26890d] mt-3 sm:mt-[0.63rem] mb-[0.625rem]"
                alt="Send to translate tab"
              />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col h-full w-full items-center md:items-end">
      <div className="w-full flex flex-col items-center md:items-start justify-between mb-[2rem]">
        <div className="w-full flex flex-col gap-4">
          <p>What is the brand name?</p>
          {inputField("brand_name", 25)}
        </div>
        <div className="w-full flex flex-col gap-4 pt-5">
          <p>What is the product name?</p>
          {inputField("product_name", 20)}
        </div>
        <div className="w-full flex flex-col gap-4 pt-5">
          <p>Provide relevant product details here: (Features and Benefits)</p>
          {inputField("product_description", 50)}
        </div>
        <div className="w-full flex flex-col gap-4 pt-5">
          <p>If this product is part of a collection or product line,describe the collection: <span class="bg-gray-200 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:text-grey-300">Optional</span></p>
          {inputField("collection", 25)}
        </div>
        <div className="w-full flex flex-col gap-4 pt-5">
          <div className="flex space-x-24">
          <div className="flex-col">
            <p className="pb-2">Text Format: <span class="bg-gray-200 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:text-grey-300">Optional</span></p>
            <FormControl sx={{ mb: 2, width: "100%", height: 44 }}>
              <InputLabel>Text Format</InputLabel>
              <Select
                value={textFormat}
                onChange={(e)=>{
                  setTextFormat(e.target.value)
                  props.setProductDescriptionState({
                    "text_format": e.target.value,
                  });
                }}
                input={<OutlinedInput label={textFormat} />}
                MenuProps={MenuProps}
                variant="outlined"
                focused
                color="tertiary"
                sx={{width:"100%",height:"55px", borderRadius: "0.5rem"}}
              >
                  {textFormatConstant && textFormatConstant.map((format, index) => (
                    <MenuItem key={index} value={format}>
                      {format}
                    </MenuItem>
                  ))}
                
              </Select>
            </FormControl>
          </div>

          <p className="flex-col">
          <p className="pb-2">Character Limit: <span class="bg-gray-200 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:text-grey-300">Optional</span></p>
            <FormControl sx={{ mb: 2, width: "100%", height: 44 }}>
              <InputLabel>Character Limit</InputLabel>
              <Select
                
                value={characterLimit}
                onChange={(e)=>{
                  setCharacterLimit(e.target.value)
                  props.setProductDescriptionState({
                    "character_limit": e.target.value,
                  });
                }}
                input={<OutlinedInput label={textFormat} />}
                MenuProps={MenuProps}
                variant="outlined"
                focused
                color="tertiary"
                sx={{width:"100%",height:"55px", borderRadius: "0.5rem"}}
                >
                  {characterLimitConstant && Object.keys(characterLimitConstant).map((key, index) => (
                    <MenuItem key={index} value={key}>
                      {characterLimitConstant[key]}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </p>
          {characterLimit == "Custom Limit" && <p className="flex-col">
          <p className="pb-2">Custom Limit: <span class="bg-gray-200 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:text-grey-300">Optional</span></p>
            {inputField("character_limit", 25, true)}
          </p>
          }
          </div>
        </div>
      </div>
      <Button
        className="submit-button-productDescription"
        variant="contained"
        color="secondary"
        disabled={
          brand_name === "" ||
          product_name === "" ||
          product_description === "" ||
          error
        }
        onClick={() => {
          props.GeneratePromtProductDescription();
        }}
      >
        SUBMIT
      </Button>
    </div>
  )}
</div>
  );
};
export default CardProductDescription;
