import React from "react";

const CreativeConceptsIcon = ({ highlighted }) => {
  return (
    <img
      className="h-[1.1rem] w-[1.1rem]"
      src={
        highlighted
          ? require("../assets/Group-3.png")
          : require("../assets/Group-2.png")
      }
    />
  );
};

export default CreativeConceptsIcon;
