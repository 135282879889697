import React from "react";
import ErrorFaceImage from "./ErrorFaceImage";
import { Button, IconButton, Typography } from "@mui/material";

const MaxLimitReachedPopUp = ({ setMaxLimitPopUp, setActiveTab }) => {
  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-10 flex items-center justify-center">
      <div className=" bg-white flex items-center justify-center text-black rounded-2xl w-[41.75rem] h-[28.563rem] relative">
        <IconButton
          onClick={() => setMaxLimitPopUp(false)}
          sx={{ top: 0, right: 0, width: "max-content", position: "absolute" }}
        >
          <img src={require("../assets/close-icon.png")} />
        </IconButton>
        <div className="bg-[#F1F9ED] m-7 flex flex-col justify-center items-center h-[90%] w-full my-10 gap-4">
          <ErrorFaceImage />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "32px",
              lineHeight: "1.5rem",
              color: "#E85E5E",
            }}
          >
            SORRY
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "1.5rem",
              color: "#000000",
            }}
          >
            You are at max capacity can not save more than 10 files.
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "1.5rem",
              color: "#E85E5E",
            }}
          >
            Please delete some existing files to save new ones.
          </Typography>
          <div className="flex flex-row justify-between gap-5">
            <Button
              variant="outlined"
              sx={{
                color: "#26890D",
                borderRadius: "6px",
                height: "2.75rem",
                width: "7.5rem",
              }}
              onClick={() => setMaxLimitPopUp(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "white",
                borderRadius: "6px",
                height: "2.75rem",
                width: "max-content",
                textOverflow: "ellipsis",
                wordWrap: "break-word",
                backgroundColor: "#26890D",
              }}
              onClick={async () => {
                await setMaxLimitPopUp(false);
                setActiveTab("Save");
              }}
            >
              Redirect to Saved Pages
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaxLimitReachedPopUp;
