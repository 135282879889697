import { Button } from "@mui/material";
import React from "react";
import { useRef, useEffect } from "react";

const GenerativeFillUpload = ({
  file,
  setFile,
  imagePreview,
  setImagePreview,
  setCurrentTab,
}) => {
  const dropRef = useRef(null);
  const fileInputRef = useRef(null);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    let div = dropRef.current;

    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      let files = e.dataTransfer.files;
      if (files && files.length > 0) {
        const file = files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    div?.addEventListener("dragenter", handleDragEnter);
    div?.addEventListener("dragover", handleDragOver);
    div?.addEventListener("dragleave", handleDragLeave);
    div?.addEventListener("drop", handleDrop);

    return () => {
      div?.removeEventListener("dragenter", handleDragEnter);
      div?.removeEventListener("dragover", handleDragOver);
      div?.removeEventListener("dragleave", handleDragLeave);
      div?.removeEventListener("drop", handleDrop);
    };
  }, []);
  return (
    <div className="flex flex-col h-full space-y-8 items-between w-full">
      <div className="flex flex-row justify-center items-start h-full w-full pt-12">
        <div className="border-dashed border-2 h-[85%] w-[70%] rounded-lg border-gray-300 flex flex-col items-center justify-between pl-4 pr-4">
          <div className="flex flex-col h-full w-full space-y-8">
            <div className="flex flex-row  w-full pt-8 h-[70%]">
              <div className="flex flex-col  justify-start items-center w-[20%] space-x-2">
                <img
                  src={require("../assets/HelperStage1.png")}
                  alt="HelperStage"
                />
                <span>Upload your Image</span>
              </div>
              <div className="flex flex-col justify-start items-center w-[20%] text-wrap">
                <img
                  src={require("../assets/HelperStage2.png")}
                  alt="HelperStage2"
                />
                <span className="text-center">Remove Existing Background</span>
              </div>
              <div className="flex flex-col  justify-start items-center w-[20%] text-wrap">
                <img
                  src={require("../assets/HelperStage3.png")}
                  alt="HelperStage3"
                />
                <span className="text-center">
                  Generating five Options of Background
                </span>
              </div>
              <div className="flex flex-col  justify-start items-center w-[20%] text-wrap">
                <img
                  src={require("../assets/HelperStage4.png")}
                  alt="HelperStage4"
                />
                <span className="text-center">
                  Use Prompt for Different Background
                </span>
              </div>

              <div className="flex flex-col  justify-start items-center w-[20%]text-wrap">
                <img
                  src={require("../assets/HelperStage4.png")}
                  alt="HelperStage4"
                />
                <span className="text-center">
                  Download your file as PSD/ZIP
                </span>
              </div>
            </div>
            <div className="flex flex-row justify-center pb-8">
              <img
                src={require("../assets/HelperProgress.png")}
                alt="progress-bar"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between items-center w-full h-[40%] ml-5 mr-5 mt-10 ">
        {file ? (
          <div className="flex flex-row h-full w-[52%] space-x-4 justify-center items-center ">
            <Button sx={{ height: "20%", width: "8%", cursor: "default" }}>
              {" "}
              <img
                src={require("../assets/disabledArrowLeft.png")}
                alt="disabledArrowLeft"
              />
            </Button>
            <div className="w-[80%] h-full">
              <img
                src={imagePreview}
                alt="preview"
                className="h-full w-full object-contain rounded-xl"
              />
            </div>
            <Button
              onClick={() => setCurrentTab("Steps")}
              sx={{ height: "20%", width: "8%" }}
            >
              {" "}
              <img src={require("../assets/ArrowRight.png")} alt="ArrowRight" />
            </Button>
          </div>
        ) : (
          <div
            ref={dropRef}
            className="flex flex-col items-center w-full h-full"
          >
            <div className="m-4 mt-5">
              <img src={require("../assets/Inbox.jpg")} alt="Inbox" />
            </div>
            <div className="m-2 pb-10">
              <div>
                Drag and Drop Image here or{" "}
                <input
                  id="file-upload"
                  accept=".jpg, .png, .jpeg, .webp"
                  ref={fileInputRef}
                  type="file"
                  onChange={onFileChange}
                  hidden
                  className="cursor-pointer text-blue-500"
                />
                <label
                  htmlFor="file-upload"
                  className="cursor-pointer text-blue-500"
                >
                  Browse Files
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default GenerativeFillUpload;
