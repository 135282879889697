import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import Main from "./Main";
import { msalConfig } from "./authConfigFile/authConfig";
import "./index.css";
import configureStore from "./redux/Store/configureStore";

const theme = createTheme({
  palette: {
    primary: {
      main: "#86BC25",
    },
    secondary: {
      main: "#26890D",
    },
    tertiary: {
      main: "#dadada",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
});

export const msal = new PublicClientApplication(msalConfig);
if (!msal.getActiveAccount()) {
  if (msal.getAllAccounts().length > 0) {
    msal.setActiveAccount(msal.getAllAccounts()[0]);
  }
}

msal.addEventCallback((e) => {
  if (e.eventType === EventType.LOGIN_SUCCESS) {
    if (e.payload) {
      try {
        const { account } = e.payload;
        msal.setActiveAccount(account);
        //token expiration
        if (account.idTokenClaims && account.idTokenClaims.exp) {
          const nowInSeconds = Math.round(new Date().getTime() / 1000);
          const expirationTime = account.idTokenClaims.exp;
          const timeRemaining = expirationTime - nowInSeconds;

          if (timeRemaining <= 0 || timeRemaining < 300) {
            msal.logoutRedirect().catch(console.log);
          }
        }
      } catch (_e) {}
    }
  }
});
const root = ReactDOM.createRoot(document.getElementById("root"));
const store = configureStore();
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MsalProvider instance={msal}>
          <Main />
        </MsalProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </Provider>
);
