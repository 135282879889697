import React, { useEffect } from "react";
import Error from "../components/Error";
import Ideate from "../components/Ideate";

const StoryBoardingIdeate = ({
  stepArr,
  setStepArr,
  handleGenerateButtonClick,
  formData,
  setFormData,
  setFormDataError,
  sectionData,
  setSectionData,
  activeTab,
  updateSteps,
  generateSteps,
  regenerateImage,
  generateImage,
  handleStitch,
  formDataError,
  generatedImages,
  setGeneratedImages,
  validateDuration,
  errorMessage,
  setErrorMessage,
  makeLandingTrue,
  setShowDialog,
  regenerateStep,
  reviseAllSteps,
  reviseAfterUpdating,
  tailoredVisible,
  directVisible,
  ideateVisible,
  setIsStoryGenerated,
  isSidebarOpen,
  textModel
}) => {
  useEffect(() => {
    if (!stepArr) handleGenerateButtonClick();
  }, []);
  const handleSavingChange = (newStepArr) => {
    setStepArr(newStepArr);
  };
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);
  return (
    
    <div className={`flex w-full h-full flex-1 ${isSidebarOpen ? "hidden":"block"} sm:block`}>
      <div className="flex justify-center w-full m-5">
        <div className="w-full h-full">
          {errorMessage && <Error errorMessage={errorMessage.message} />}
          <Ideate
            steps={stepArr}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            setFormDataError={setFormDataError}
            formData={formData}
            setFormData={setFormData}
            sectionData={sectionData}
            setSectionData={setSectionData}
            handleSavingChange={handleSavingChange}
            updateSteps={updateSteps}
            generateSteps={generateSteps}
            regenerateImage={regenerateImage}
            generateImage={generateImage}
            handleStitch={handleStitch}
            formDataError={formDataError}
            generatedImagesGlobal={generatedImages}
            setGeneratedImagesGlobal={setGeneratedImages}
            validateDuration={validateDuration}
            makeLandingTrue={makeLandingTrue}
            setShowDialog={setShowDialog}
            regenerateStep={regenerateStep}
            reviseAllSteps={reviseAllSteps}
            reviseAfterUpdating={reviseAfterUpdating}
            tailoredVisible={tailoredVisible}
            directVisible={directVisible}
            ideateVisible={ideateVisible}
            setIsStoryGenerated={setIsStoryGenerated}
            textModel={textModel}
          />
        </div>
      </div>
    </div>
  );
};

export default StoryBoardingIdeate;
