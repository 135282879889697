import React from "react";
import { useEffect,useState } from "react";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { parse, format ,parseISO} from 'date-fns';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { getModelData } from "../utils/helper";

const ModelTable = ({ setShowModel }) => {
  const [modelData,setModelData]= useState([])

  useEffect(()=>{
    getModelData(setModelData)

  },[])
   
  return (
    <div className="absolute inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center">
      <div className=" bg-white flex items-center justify-center text-black rounded-2xl w-[61.75rem] h-[28.563rem] relative">
        <Button
          sx={{ position: "absolute", top: 5, right: 0 }}
          onClick={() => {
            setShowModel(false);
          }}
        >
          <img src={require("../assets/close-icon.png")} />
        </Button>
        <div className="h-full w-full flex flex-col">
          <div className="w-full bg-[#86BC25] flex h-[10%] items-center pl-4  rounded-tl-2xl rounded-tr-2xl">
            <Typography
              variant="subtitle1"
              sx={{ color: "white", fontWeight: "bold" }}
            >
              {" "}
              Models Trained
            </Typography>
          </div>
          <div className="h-[90%] w-full p-12 overflow-y-auto">
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow  sx={{ backgroundColor: '#EFF1E5' }}>
            <TableCell align="center">Model Name</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {modelData.map((row) => (
            <TableRow
              key={row.name}

            >
              <TableCell align="center" >{row.model_name}</TableCell>
              <TableCell align="center">{row.date.split(" ")[0]}</TableCell>
              <TableCell align="center" sx={{color:"#26890D"}}>{row.status}</TableCell>
              <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {row.status == "PENDING" && <img src={require("../assets/delete.png") } alt="action"/>}
                {row.status == "SUCCESS" && <img src={require("../assets/delete.png") } alt="action"/>}
                {row.status == "FAILED" && <img src={require("../assets/reload-model.png")} alt="reload"/>}
                
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

          </div>

        </div>
      </div>
    </div>
  );
};

export default ModelTable;
