import React from "react";
import { Typography } from "@mui/material";
const SelectImageBackdrop = ({
  message,
  setDirectStoryBoardPopUp,
  successFullDownload,
  setSuccessFullDownload,
  imageSrc,
}) => {
  const clickhandler = () => {
    setDirectStoryBoardPopUp(false);
    setSuccessFullDownload(false);
  };
  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-auto">
      <div className="h-[240px] w-[540px] bg-white text-black rounded-2xl">
        <p
          className="flex justify-end mr-3 text-xl cursor-pointer"
          onClick={() => clickhandler()}
        >
          X
        </p>
        <Typography
          display="block"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={imageSrc}
            alt="error"
            
            className="text-[#26890D] text-[80px] w-20 h-20"
          />
          <div className="p-4">{message}</div>
        </Typography>
      </div>
    </div>
  );
};

export default SelectImageBackdrop;
