import React from "react";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
const LogoError = ({ setShowErrorTab, missingParams, setMissingParams }) => {
  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="h-[240px] w-[540px] bg-white flex items-center justify-center text-black rounded-2xl">
        <Typography
          display="block"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../assets/notVerified.png")}
            className="w-20 h-20 text-[80px] text-[#26890D]"
            alt="not-authenticated"
          />
          <div className="p-4">
            Oops! You did not select your{" "}
            {missingParams.map((each, index) => (
              <>{`${each}${
                index === missingParams.length - 1 ? "." : ", "
              } `}</>
            ))}
          </div>
          <Button
            onClick={() => {
              setMissingParams([]);
              setShowErrorTab(false);
            }}
            sx={{  bottom: 170, left: {xs:170, sm:200, md:250} }}
          >
            {/* <CloseIcon style={{ color: "black" }} />
            
            */}
            <img src={require("../assets/close-icon.png")} />
          </Button>
        </Typography>
      </div>
    </div>
  );
};

export default LogoError;
