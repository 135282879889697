import { Typography } from "@mui/material";
import React from "react";
import { Button } from "@mui/material";

const ResumeExceedCard = ({setShowMaxCapacityCard,setActiveTab}) => {
  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white flex items-center justify-center text-black rounded-2xl  w-[43rem] h-[25rem] relative">
        <div className="bg-[#F1F9ED] h-[85%] w-[90%] flex flex-col rounded-xl p-12 items-center space-y-4">
          <img src={require("../assets/notVerified.png")} className="h-20 w-20"/>
          <Typography variant="h4" sx={{fontWeight:"bold",color:"#E85E5E"}}>SORRY</Typography>
          <div className="space-y-2 flex flex-col justify-center items-center">
          <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>
            You are at max capacity can not save more than 10 files.
          </Typography>
          <span className="text-[#E85E5E]">Please delete some existing files to save new ones</span>
          <div className="flex justify-center space-x-2">
            <Button variant="contained" color="secondary" sx={{
        backgroundColor: 'white',
        color:"#26890D",
        border:"1px solid #26890D",
        '&:hover': {
            backgroundColor: 'white',
            border: '1px solid #26890D',
          },
        
      }} onClick={()=>setShowMaxCapacityCard(false)}>
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={()=>{ setShowMaxCapacityCard(false) ;setActiveTab("Save")}}>
              Save Page
            </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResumeExceedCard;
