import React, { useState } from "react";
import {
  FormControl,
  TextField,
  Button,
  Drawer,
  Box,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "../assets/delete-icon.svg";
import "./SideBarContainer.css";
import MultipleSignals from "./MultipleSignals";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import FloatingActionButton from "./FloatingActionButton";

const SidebarTextfields = ({
  isSidebarOpen,
  handleSidebar,
  handleGenerateButtonClick,
  isButtonDisabled,
  formDataError,
  formData,
  handleFormData,
  activeTab,
  setPersonaRequirementChanged,
  setPrintRequirementChanged,
  setEmailRequirementChanged,
  setSocialMediaRequirementChanged,
  setCreativeRequirementChanged,
  setFlyerRequirementChanged,
  tailoredVisible,
  signals,
  setSignals,
  selectedTitle,
  setSelectedTitle,
  setShowMediaLikeDragDrop,
  showHelperText,
  textModel,
  setTextModel,
  setIsGenerateButtonDisabled,
  imgModel,
  setImageModel,
  loraModel,
  timers,
  setTimers,
  disabledOptions,
  setDisabledOptions,
  loraCheckModel,
  setErrorMessage
}) => {
  const [fields, setFields] = useState({
    Persona: {
      Signals: true,
      Business: false,
      Story: false,
    },
    Print: {
      Signals: false,
      Business: true,
      Story: false,
    },
    Flyer: {
      Signals: false,
      Business: true,
      Story: false,
    },
    "Creative Concepts": {
      Signals: false,
      Business: true,
      Story: false,
    },
    Email: {
      Signals: false,
      Business: true,
      Story: false,
    },
    "Social Media": {
      Signals: false,
      Business: true,
      Story: false,
    },
    "Story Boarding": {
      Signals: false,
      Business: false,
      Story: true,
    },
  });
  const openSignals = () => {
    setIsSignalClicked((prevClicked) => !prevClicked);
    if (!isSignalClicked) {
      setBottomLeftRadius("0px");
      setBottomRightRadius("0px");
    } else {
      setBottomLeftRadius("0.75rem");
      setBottomRightRadius("0.75rem");
    }
  };
  const handleChangeForSignals = () => {
    setFields({
      ...fields,
      [activeTab]: {
        ...fields[activeTab],
        Signals: !fields[activeTab]?.Signals,
      },
    });
  };
  const openBusiness = () => {
    setIsBusinessClicked((prevClicked) => !prevClicked);
    if (isBusinessClicked) {
      setBottomLeftRadiusBusiness("0px");
      setBottomRightRadiusBusiness("0px");
    } else {
      setBottomLeftRadiusBusiness("0.75rem");
      setBottomRightRadiusBusiness("0.75rem");
    }
  };
  const handleChangeForBusinessRequirements = () => {
    setFields({
      ...fields,
      [activeTab]: {
        ...fields[activeTab],
        Business: !fields[activeTab]?.Business,
      },
    });
  };
  const handleChangeForStoryBoarding = () => {
    setFields({
      ...fields,
      [activeTab]: {
        ...fields[activeTab],
        Story: !fields[activeTab].Story,
      },
    });
  };
  const [signalDataChanged, setSignalDataChanged] = useState(false);
  const [businessDataChanged, setBusinessDataChanged] = useState(false);

  const handleRequirementChanges = () => {
    if (signalDataChanged) {
      setPersonaRequirementChanged(true);
      setEmailRequirementChanged(true);
      setFlyerRequirementChanged(true);
      setSocialMediaRequirementChanged(true);
      setSignalDataChanged(false);
    }
    if (businessDataChanged) {
      setPrintRequirementChanged(true);
      setFlyerRequirementChanged(true);
      setEmailRequirementChanged(true);
      setSocialMediaRequirementChanged(true);
      setCreativeRequirementChanged(true);
      setBusinessDataChanged(false);
    }
  };

  const [bottomLeftRadius, setBottomLeftRadius] = useState("0.75rem");
  const [bottomRightRadius, setBottomRightRadius] = useState("0.75rem");
  const [isSignalClicked, setIsSignalClicked] = useState(false);
  const [isBusinessClicked, setIsBusinessClicked] = useState(false);
  const [bottomLeftRadiusBusiness, setBottomLeftRadiusBusiness] =
    useState("0.75rem");
  const [bottomRightRadiusBusiness, setBottomRightRadiusBusiness] =
    useState("0.75rem");

  const drawerWidth = "25rem";

  // -- logic for multiple signals

  const handleAddField = () => {
    setSignals([...signals, ""]);
  };

  const handleSignalChange = (index, signal) => {
    const updatedSignals = signals.map((sig, sigIndex) =>
      sigIndex === index ? signal : sig
    );
    setSignals(updatedSignals);

    return updatedSignals;
  };

  const handleDeleteSignalButton = (index) => {
    const updatedSignals = setSignals(
      signals.filter((signal, sigIndex) => sigIndex !== index)
    );
    handleFormData({
      ...formData,
      demographicData: updatedSignals,
    });
  };

  return (
    <div className="flex flex-col items-center h-screen">
       <div
        className={`  ${!isSidebarOpen && "scale-0"} ${
          (activeTab === "Story Boarding" && !tailoredVisible) ||
          ["Product Description", "Translate"].includes(activeTab)
            ? "opacity-50 pointer-events-none"
            : ""
        } flex flex-col justify-around gap-2`}
      >
        {/* Media Like Ad  */}

        <div className="flex mx-2 mt-4  justify-between items-center px-2 py-3  mb-2">
          <div
            className=" py-2 px-4  border border-[#26890D] rounded-md flex justify-between items-center space-x-6 cursor-pointer"
            onClick={() => setShowMediaLikeDragDrop(true)}
          >
            <p className="font-semibold text-[#26890D]">LOOK-ALIKE AD</p>

            <UploadFileOutlinedIcon className="w-10 h-10 text-[#26890D]" />
          </div>

          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "white",
                  color: "#000",
                  border: "0.5px solid gray",
                  maxWidth: "350px",
                  // fontWeight:"400",
                  fontFamily: "Open Sans",
                },
              },
            }}
            title={
              <p className="text-sm text-black font-normal font-sans">
                Have a great existing advertisement for your product/service?
                Simply upload the reference ad picture and the 'Look-alike Ad'
                function will automatically generate the audience signals and
                business requirements needed for your campaign.
              </p>
            }
            arrow
          >
            <InfoOutlinedIcon className="w-10 h-10  text-[#787878] hover:text-[#26890D] cursor-pointer" />
          </Tooltip>
        </div>

        <hr className="border border-[#D7D7D7] " />

        <Accordion
          sx={{
            padding: "0.5rem 0",
            flexGrow: 1,
            overflowY: "auto",
            boxShadow: "none",
          }}
          expanded={fields[activeTab]?.["Signals"]}
          // expanded={fields[activeTab ==="Storyboard"] ? tailoredVisible :fields[activeTab]?.["Signals"] }
          onChange={() => {
            openSignals();
            handleChangeForSignals();
          }}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "#FFF",

              borderTopLeftRadius: "0.75rem",
              borderTopRightRadius: "0.75rem",
              borderBottomLeftRadius: isSignalClicked ? "0.75rem" : "0rem",
              borderBottomRightRadius: isSignalClicked ? "0.75rem" : "0rem",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>SIGNALS</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#FFF",

              borderBottomLeftRadius: bottomLeftRadius,
              borderBottomRightRadius: bottomRightRadius,
            }}
          >
            <MultipleSignals
              signals={signals}
              setSignals={setSignals}
              formData={formData}
              formDataError={formDataError}
              handleFormData={handleFormData}
              setSignalDataChanged={setSignalDataChanged}
              showHelperText={showHelperText}
            />
          </AccordionDetails>
        </Accordion>

        <hr className="border border-[#D7D7D7] " />
        <Accordion
          sx={{
            padding: "0.5rem 0",
            flexGrow: 1,
            overflowY: "auto",
            boxShadow: "none",
            backgroundColor: "#fff",
          }}
          expanded={fields[activeTab]?.["Business"]}
          onChange={() => {
            openBusiness();
            handleChangeForBusinessRequirements();
          }}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "#FFF",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              BUSINESS REQUIREMENTS
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#FFF",
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <TextField
                label="Product / Service"
                multiline
                color="tertiary"
                rows={2}
                defaultValue=""
                fullWidth
                InputLabelProps={{
                  style: {
                    color: "#828282",
                  },
                }}
                sx={{
                  marginTop: 1,

                  "& .MuiFormLabel-root": {
                    fontWeight: 600,
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "currentColor",
                    opacity: 1,
                  },
                  "& .MuiOutlinedInput-root": {
                    overflow: "hidden",
                  },
                  "& .MuiOutlinedInput-input": {
                    overflow: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  },
                }}
                focused
                placeholder="Enter your product or service details here to provide a concise and informative description. Include as much or as little detail as you like. "
                value={formData.product ? formData.product : ""}
                onChange={(e) => {
                  setBusinessDataChanged(true);
                  handleFormData({ ...formData, product: e.target.value });
                }}
                error={
                  !formData.product.trim().length && !!formDataError.product
                }
                helperText={
                  !formData.product.trim().length ? formDataError.product : ""
                }
              />
              {showHelperText["product"] && formData.product.length > 0 && (
                <FormHelperText sx={{ textAlign: "right" }}>
                  Extracted From An Uploaded Ad
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                label="Objective"
                color="tertiary"
                multiline
                rows={5}
                defaultValue=""
                InputLabelProps={{
                  style: {
                    color: "#828282",
                  },
                }}
                fullWidth
                sx={{
                  marginTop: 3,

                  "& .MuiFormLabel-root": {
                    fontWeight: 600,
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "currentColor",
                    opacity: 1,
                  },
                  "& .MuiOutlinedInput-root": {
                    overflow: "hidden",
                  },
                  "& .MuiOutlinedInput-input": {
                    overflow: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  },
                }}
                focused
                placeholder="Clearly define your objective, specifying the goal or purpose and outlining desired outcomes and milestones. Be sure to include the main idea you want communicated to the potential customers, whether it's about product sales, building trust, or promoting specific events."
                value={formData.objective}
                onChange={(e) => {
                  setBusinessDataChanged(true);
                  handleFormData({
                    ...formData,
                    objective: e.target.value,
                  });
                }}
                error={
                  !formData.objective.trim().length && !!formDataError.objective
                }
                helperText={
                  !formData.objective.trim().length
                    ? formDataError.objective
                    : ""
                }
              />
              {showHelperText["objective"] && formData.objective.length > 0 && (
                <FormHelperText sx={{ textAlign: "right" }}>
                  Extracted From An Uploaded Ad
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                label="Tone/Mood Desired"
                multiline
                color="tertiary"
                rows={2}
                defaultValue=""
                InputLabelProps={{
                  style: {
                    color: "#828282",
                  },
                }}
                fullWidth
                sx={{
                  marginTop: 3,

                  "& .MuiFormLabel-root": {
                    fontWeight: 600,
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "currentColor",
                    opacity: 1,
                  },
                  "& .MuiOutlinedInput-root": {
                    overflow: "hidden",
                  },
                  "& .MuiOutlinedInput-input": {
                    overflow: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  },
                }}
                focused
                placeholder="Describe the desired tone or mood for your content. Whether it's upbeat, luxury, kid friendly, simple, or professional, provide a brief overview to set the right atmosphere and convey the intended emotion. "
                value={formData.tone}
                onChange={(e) => {
                  setBusinessDataChanged(true);
                  handleFormData({ ...formData, tone: e.target.value });
                }}
                error={!formData.tone.trim().length && !!formDataError.tone}
                helperText={
                  !formData.tone.trim().length ? formDataError.tone : ""
                }
              />
              {showHelperText["tone"] && formData.tone.length > 0 && (
                <FormHelperText sx={{ textAlign: "right" }}>
                  Extracted From An Uploaded Ad
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                label="Key Selling Points"
                multiline
                color="tertiary"
                rows={3}
                defaultValue=""
                InputLabelProps={{
                  style: {
                    color: "#828282",
                  },
                }}
                fullWidth
                sx={{
                  marginTop: 3,
                  marginBottom: 10,

                  "& .MuiFormLabel-root": {
                    fontWeight: 600,
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "currentColor",
                    opacity: 1,
                  },
                  "& .MuiOutlinedInput-root": {
                    overflow: "hidden",
                  },
                  "& .MuiOutlinedInput-input": {
                    overflow: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  },
                }}
                focused
                placeholder="Highlight the key selling points that sets your product or service apart. Clearly articulate the unique feature or benefit that makes it stand out, attracting attention and driving the value proposition. "
                value={formData.sellingPoint}
                onChange={(e) => {
                  setBusinessDataChanged(true);
                  handleFormData({
                    ...formData,
                    sellingPoint: e.target.value,
                  });
                }}
                error={
                  !formData.sellingPoint.trim().length &&
                  !!formDataError.sellingPoint
                }
                helperText={
                  !formData.sellingPoint.trim().length
                    ? formDataError.sellingPoint
                    : ""
                }
              />
              {showHelperText["sellingPoint"] &&
                formData.sellingPoint.length > 0 && (
                  <FormHelperText sx={{ textAlign: "right" }}>
                    Extracted From An Uploaded Ad
                  </FormHelperText>
                )}
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <hr className="border border-[#D7D7D7] " />
        
        <div className="flex flex-row p-6 w-full md:w-[21.2rem] mt-2  flex justify-center fixed bottom-0 bg-white z-10">
        <FloatingActionButton textModel={textModel} setTextModel={setTextModel} setIsGenerateButtonDisabled={setIsGenerateButtonDisabled} imgModel={imgModel} setImageModel={setImageModel}  loraModel={loraModel} timers={timers} setTimers={setTimers} disabledOptions={disabledOptions} setDisabledOptions={setDisabledOptions} loraCheckModel={loraCheckModel} setErrorMessage={setErrorMessage}/>
          <Button
            variant="contained"
            focused
            color="secondary"
            sx={{
              fontWeight: 600,
              width: { xs: "90%", md: "18.75rem" },

              "&.Mui-disabled": {
                opacity: 1,
                backgroundColor: "lightgray",
              },
            }}
            disabled={
              (activeTab === "Story Boarding" && !tailoredVisible) ||
              ["Product Description", "Translate"].includes(activeTab) ||
              isButtonDisabled
            }
            onClick={() => {
              handleRequirementChanges();
              handleGenerateButtonClick();
              setSelectedTitle(
                selectedTitle < signals.length ? selectedTitle : 0
              );
            }}
          >
            Generate
          </Button>
        </div>
      </div>

      {!isSidebarOpen && (
        <div className="h-full fixed bg-white">
          <p
            className="text-lg  rotate-180 px-1.5 py-2 "
            style={{ writingMode: "vertical-lr" }}
          >
            Signals and Business Requirements
          </p>
        </div>
      )}
    </div>
  );
};

export default SidebarTextfields;
