import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
const styles = {
  input1: {
    height: 50,
  },
  input2: {
    height: 200,
    fontSize: "3em",
  },
};
const TopInputIdeate = ({
  formData,
  setFormData,
  formDataError,
  setGeneratedImagesGlobal,
  setGeneratedImages,
  generateSteps,
  validateDuration,
  handleBlur,
  handleDuration,
  handleTitle,
  isDurationFilled,
  isTextareaFilled,
  sectionData,
  setShowDialog,
  makeLandingTrue,
  setStepArr,
  setIsStoryGenerated,
}) => {
  const handleHomeButtonClick = () => {
    if (sectionData?.steps?.prev.length) setShowDialog(true);
    else {
      setStepArr([]);
      setGeneratedImages(false);
      makeLandingTrue();
      setFormData({
        ...formData,
        storyBoardTitle: "",
        duration: "",
        story: "",
        settings: [],
      });
    }
  };
  const [isSubmit, setIsSubmit] = useState(false);

  return (
    <div className="flex justify-center">
      <div
        className={`w-full h-full bg-white rounded-lg p-5  flex-col md:flex-row  relative justify-wrap justify-between flex items-center md:items-start m-5`}
      >
        <div className="w-full md:w-8/12 md:mr-4">
          <p className="ml-4 text-black font-normal text-xs mr-4 leading-4 mb-2">
            Storyboard Title
          </p>
          <TextField
            inputProps={{
              sx: {
                height: "15px",
              },
            }}
            defaultValue=""
            color="tertiary"
            value={formData?.storyBoardTitle}
            focused
            sx={{
              fontWeight: 600,
              width: "100%",
              marginLeft: { sm: 0, md: "1rem" },
              paddingRight: { sm: 0, md: "1rem" },
            }}
            onChange={(e) => handleTitle(e)}
            // error={!!formDataError.storyBoardTitle}
            // helperText={formDataError.storyBoardTitle}
          />
        </div>

        <div className="w-full md:w-1/4 md:mr-4">
          <p className="text-black font-normal text-xs leading-[14px] mb-2">
            Duration (in mins)
          </p>
          <TextField
            inputProps={{
              sx: {
                height: 15,
              },
              inputMode: "decimal",
              pattern: "^[0-9]*\\.?[0-9]{0,2}$",
              min: "1",
              step: "any",
            }}
            type="number"
            //className="w-full md:w-1/2 lg:w-full"
            fullWidth
            defaultValue=""
            color="tertiary"
            focused
            sx={{
              fontWeight: 600,
            }}
            value={formData?.duration}
            onChange={(e) => {
              const value = e.target.value;
              if (!value || value.match(/^[0-9]*\.?[0-9]{0,2}$/)) {
                handleDuration(e);
              }
            }}
            error={!!formDataError.duration}
            helperText={formDataError.duration}
            onBlur={handleBlur}
          />
        </div>
        <div className="w-full md:w-auto flex items-center justify-center md:justify-end mt-6">
          <Button
            sx={{
              padding: "0.9375rem 1.75rem",
              fontWeight: 600,
              height: "46px",
              borderRadius: "6px",
            }}
            variant="contained"
            color="secondary"
            disabled={
              !formData.duration ||
              !formData.storyBoardTitle ||
              formData?.duration == 0
            }
            onClick={() => {
              setGeneratedImages(false);
              setGeneratedImagesGlobal(false);
              generateSteps();
              setIsSubmit(true);
              setIsStoryGenerated(false);
            }}
          >
            SUBMIT
          </Button>
        </div>
        <div className=" flex items-center justify-center">
          <Button
            onClick={handleHomeButtonClick}
            sx={{
              padding: "10px 10px",
              fontWeight: 600,
              border: "1px solid #468529",
              marginTop: "24px",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          >
            <img
              src={require("../assets/Vector.jpg")}
              className="h-6 w-6"
              alt="home-icon"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TopInputIdeate;
