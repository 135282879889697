import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Radio from "@mui/material/Radio";
import { useState } from "react";
import CreativeHoverCard from "./CreativeHoverCard";

const MenuConceptTheme = ({
  selectedValue,
  setSelectedValue,
  setIsHovered,
  handleMouseMove,
  territoryTitles,
  selectedTheme,
  setSelectedTheme,
  setSelectedTerritory,
  setRanEffect,
  themeWasApplied,
  ranEffect,
  setPrevSelectedTheme,
  flyerIndex,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (index) => {
    if (typeof index != "object") {
      if (selectedTheme != index) {
        setSelectedTheme(index);
        if (typeof ranEffect != "boolean" && ranEffect.length === 5) {
          setRanEffect([...ranEffect.slice(0, flyerIndex), true, ...ranEffect.slice(flyerIndex + 1)]);
        } else setRanEffect(true);
      }
    }
    setSelectedValue(index);
    setSelectedTerritory(-1);
    setAnchorEl(null);
  };
  const handleRadioChange = (event) => {
    setSelectedTheme(event.target.value);
  };
  return (
    <>
      <div className="flex justify-center items-center">
        <Button
          aria-controls={open ? "business-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded whitespace-nowrap"
          variant="outlined"
          sx={{
            marginBottom: "1.25rem",
            color: "black",
            borderColor: "#26890D",
            paddingRight: 1,
          }}
        >
          Concept Theme
          <ArrowDropDownIcon />
        </Button>
        <Menu
          id="business-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => handleClose(-1)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onMouseMove={handleMouseMove}
                sx={{ fontWeight: selectedTheme === -1 ? 600 : 400 }}
          >
            <Radio
              checked={selectedTheme === -1}
              // onChange={handleChange}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            Business Requirements (Default)
          </MenuItem>
          {themeWasApplied &&
            territoryTitles?.map((title, index) => (
              <MenuItem
                onClick={() => handleClose(index)}
                onMouseEnter={() => {
                  setSelectedTerritory(index);
                  setIsHovered(true);
                }}
                onMouseLeave={() => {
                  setSelectedTerritory(-1);
                  setIsHovered(false);
                }}
                onMouseMove={handleMouseMove}
                sx={{ fontWeight: selectedTheme === index ? 600 : 400 }}
              >
                <Radio
                  checked={selectedTheme === index}
                  // onChange={handleChange}
                  value={index}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                  onChange={handleRadioChange}
                />
                {title}
              </MenuItem>
            ))}
        </Menu>
      </div>
    </>
  );
};

export default MenuConceptTheme;
