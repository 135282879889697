import { Typography } from "@mui/material"
import React from "react"
import { useState,useEffect } from "react"
import {IconButton} from "@mui/material"
import { Divider } from "@mui/material";
import { format, parseISO } from 'date-fns';
const SaveProjectCard = ({tab,data,signal})=>{
    
    const timestamp = data?.[signal]?.["timestamp"];
    const parsedDate = parseISO(timestamp);
    const formattedDate = format(parsedDate, 'dd MMM, yyyy, hh:mmaaa');
   
    const [tabData,setTabData] = useState(null)
    const [imageData,setImageData]=useState(null)
    const [nameOfTab,setNameOfTab]=useState(null)
    const handleContent=()=>{
      if(tab=="persona"){
        setTabData(data[signal]["persona"])
      }
      if(tab=="email"){
        setTabData(data[signal]["email"])
      }
      if(tab=="magazine_ad"){
        setTabData(data[signal]["ad"])
        setImageData(data[signal]["image"])
      }
      if(tab=="social_media"){
        setTabData(data[signal]["instagram"]["caption"])
        setImageData(data[signal]["instagram"]["images_link"])
      }
      if(tab=="creative_concept")
      {
        setTabData(data[signal]["creative_concept1"])
        setImageData(data[signal]["images_link1"])
      }
      if(tab=="flyer"){
        setTabData(data[signal]["ad"])
        setImageData(data[signal]["image"])
      }
      if(tab=="banner"){
        setTabData(data[signal]['ad'])
        setImageData(data[signal]["image"])
      }
    }
    const handleTabName = ()=>{
      if(tab=="persona")setNameOfTab("Persona")
      if(tab=="magazine_ad")setNameOfTab("Ad / Print")
      if(tab=="email")setNameOfTab("Email")
      if(tab=="social_media")setNameOfTab("Social Media")
      if(tab=="creative_concept")setNameOfTab("Creative Concepts")
      if(tab=="flyer")setNameOfTab("Flyer")
      if(tab=="banner"){
        if(data[signal]?.["banner_type"]=="Vertical")
          setNameOfTab("Banner / Verticall")
        else{
          setNameOfTab("Banner / Horizontal")
        }
      }
    }
    useEffect(()=>{
      handleContent()
      handleTabName()
    },[data,signal])
    return(
        <div className="h-[18rem] w-full border-2 border-gray-200 rounded-xl flex flex-col ">
            <div className="w-full h-[10%] flex justify-between items-center px-2 py-4">
                <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>{nameOfTab}</Typography>
                
            </div>
            <Divider
        sx={{
          bgcolor: "#86BC25",
          marginTop: "0.2rem",
          marginBottom: "0.2rem",
          width: "95%",
          marginLeft: "0.47rem",
        }}
      />
        {imageData && (
        <div className="content-img ml-2 mb-2">
          <img id="image" src={`data:image/png;base64,${imageData}`} alt="image-data"/>
        </div>
      )}
      <div className="content-text overflow-y-auto p-2">{tabData}</div>
      <Divider
        sx={{
          bgcolor: "#86BC25",
          marginTop: "0.2rem",
          marginBottom: "0.2rem",
          width: "95%",
          marginLeft: "0.47rem",
        }}
      />
      <div className="px-2 pb-1">{formattedDate}</div>

        </div>
    )
}

export default SaveProjectCard