import {
  DEMOGRAPHIC_ACTION,
  PRODUCT_ACTION,
  OBJECTIVE_ACTION,
  TONE_ACTION,
  SELLING_POINT_ACTION,
  SECTION_DETAILS,
} from "../Actions/type";
const initialState = {
  /* age:null,
    householdSize:null,
    income:null,
    location:null,
    tenure:null,
    purchaseFrequecy:null,
    topProduct:null,
    shoppingMode:null */
  demographic: null,
  persona: null,
  product: null,
  objective: null,
  tone: null,
  sellingPoint: null,
};

export const demographicReducer = (state = initialState, action) => {
  switch (action.type) {
    case SECTION_DETAILS:
      return {
        ...state,
        demographic: action.data.demographicData,
        product: action.data.product,
        objective: action.data.objective,
        tone: action.data.tone,
        sellingPoint: action.data.sellingPoint,
      };
    case DEMOGRAPHIC_ACTION:
      return {
        ...state,
        demographic: action.data,
      };
    case PRODUCT_ACTION:
      return {
        ...state,
        product: action.data,
      };
    case OBJECTIVE_ACTION:
      return {
        ...state,
        objective: action.data,
      };
    case TONE_ACTION:
      return {
        ...state,
        tone: action.data,
      };
    case SELLING_POINT_ACTION:
      return {
        ...state,
        sellingPoint: action.data,
      };
    default:
      return state;
  }
};
