import React from "react";
import { useState } from "react";
import { Button, Typography, TextField } from "@mui/material";

const ProjectConfirmationBox = ({ setShowProjectConfirmationCard ,saveProjectApply}) => {
  const [projectName, setProjectName] = useState("");
  const handleTextChange = (e) => {
    setProjectName(e.target.value);
  };
  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className=" bg-white flex items-center justify-center text-black rounded-2xl w-[41.75rem] h-[28.563rem] relative">
        <Button
          sx={{ position: "absolute", top: 5, right: 0 }}
          onClick={() => {
            setShowProjectConfirmationCard(false);
          }}
        >
          <img src={require("../assets/close-icon.png")} />
        </Button>
        <div className="bg-[#F1F9ED] h-[80%] w-[80%] flex flex-col rounded-xl p-12">
          <Typography variant="h6">
            Please enter Name for this Project
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ paddingBottom: 6, paddingTop: 1 }}
          >
            You can find this work on saved tab
          </Typography>
          <TextField
            sx={{
              color: "#000",
              background: "#FFF",
              border: "1px solid var(--CTA, #26890D)",
            }}
            onChange={handleTextChange}
          ></TextField>
          <div className="flex justify-end space-x-4 pt-4">
            <Button
              variant="outlined"
              sx={{
                background: "#FFF",
                color: "var(--CTA, #26890D)",
                border: "1px solid var(--CTA, #26890D)",
              }}
              onClick={()=> setShowProjectConfirmationCard(false)}
            >
              CANCEL
            </Button>
            <Button variant="contained" color="secondary" onClick={()=>{
              saveProjectApply(projectName);
              setShowProjectConfirmationCard(false);
              }}>
              OK
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectConfirmationBox;
