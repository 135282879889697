import { Button, Typography } from "@mui/material";
import React from "react";

const TemplateDownloader = ({
  previewButtonClicked,
  setPreviewButtonClicked,
  path,
  setDownloadButtonClicked,
  flyerDownloadCall,
  showingHorizontal,
  showingVertical,
  showingSquare,
  showingBannerHorizontal,
  showingBannerVertical,
}) => {
  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div
        className="bg-white flex flex-col items-center justify-between text-black rounded-2xl"
        style={{
          backgroundColor: "#F1F1F1",
          position: "relative",
          width: showingHorizontal
            ? "530px"
            : showingVertical
            ? "340px"
            : showingSquare
            ? "420px"
            : showingBannerHorizontal
            ? "600px"
            : "200px",
          height: showingHorizontal
            ? "455px"
            : showingVertical
            ? "540px"
            : showingSquare
            ? "480px"
            : showingBannerHorizontal
            ? "150px"
            : "720px",
        }}
      >
        <div
          className={`relative ${
            showingBannerHorizontal ? "mt-0" : "mt-5"
          } h-[595px]`}
        >
          <Typography
            display="block"
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={`data:image/png;base64,${path}`}
              className="w-[90%] h-[90%] m-1 mt-4"
            />
          </Typography>
          <Button
            onClick={() => {
              if (showingHorizontal) {
                setPreviewButtonClicked([
                  false,
                  ...previewButtonClicked.slice(1),
                ]);
              }
              if (showingVertical) {
                setPreviewButtonClicked([
                  previewButtonClicked[0],
                  false,
                  ...previewButtonClicked.slice(2),
                ]);
              }
              if (showingSquare) {
                setPreviewButtonClicked([
                  previewButtonClicked[0],
                  previewButtonClicked[1],
                  false,
                  ...previewButtonClicked.slice(3),
                ]);
              }
              if (showingBannerHorizontal) {
                const index = 3;
                setPreviewButtonClicked([
                  ...previewButtonClicked.slice(0, index),
                  false,
                  ...previewButtonClicked.slice(index + 1),
                ]);
              }
              if (showingBannerVertical) {
                const index = 4;
                setPreviewButtonClicked([
                  ...previewButtonClicked.slice(0, index),
                  false,
                  ...previewButtonClicked.slice(index + 1),
                ]);
              }
            }}
            sx={{
              bottom: showingHorizontal
                ? 380
                : showingVertical
                ? 465
                : showingSquare
                ? 420
                : showingBannerHorizontal
                ? 95
                : 620,
              left: showingHorizontal
                ? 480
                : showingVertical
                ? 290
                : showingSquare
                ? 370
                : showingBannerHorizontal
                ? 550
                : 138,
            }}
          >
            <img src={require("../assets/close-icon.png")} />
          </Button>
        </div>
        <div
          className={`flex ${
            showingBannerVertical
              ? " flex-col justify-center items-center mb-5 w-[200px] ml-1"
              : " flex-row absolute bottom-3 right-3 mt-[30px]"
          }`}
        >
          <p className="ml-5 mr-5 text-[#848484] text-[10px]">
            Collect your printable file here.
          </p>
          <Button
            variant="contained"
            color="secondary"
            sx={
              showingBannerVertical
                ? {
                    marginTop: "0px",
                    width: "153px",
                  }
                : { marginTop: "0px" }
            }
            onClick={() =>
              flyerDownloadCall(
                showingHorizontal
                  ? 0
                  : showingVertical
                  ? 1
                  : showingSquare
                  ? 2
                  : showingBannerHorizontal
                  ? 3
                  : 4
              )
            }
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TemplateDownloader;
