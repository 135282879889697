import { useMsal } from "@azure/msal-react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import { Button, TextField, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { msal } from "..";
import { loginRequest } from "../authConfigFile/authConfig";
import UserProfile from "../components/userProfile";
import { DateTimePickerTabs } from "@mui/x-date-pickers/DateTimePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  ADMIN_FILTER_URL,
  DOWNLOAD_RECORDS,
  STATUS_CALL,
  USER_REQUEST_STATUS,
  USER_TIMELINE,
  operations,
  INFINITE_STATUS,
} from "../utils/constants";
import { handleDisplayfilter } from "../utils/helper";
import "./AdminPage.css";
const useStyles = makeStyles({
  approved: {
    color: "#86BC25",
  },
  denied: {
    color: "#e85e5e",
  },
  neutral: {
    color: "#acacac",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#26890D",
    color: theme.palette.common.white,
    fontSize: 18,
    padding: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(loginData) {
  const formattedTimestamp = new Date(loginData.timestamp).toLocaleString();
  return {
    id: loginData.id,
    username: loginData.email,
    Offering_portfolio: loginData.offering_portfolio,
    purpose: loginData.purpose,
    name: loginData.name,
    login_sessions: loginData.No_of_Loggin_sessions,
    client: loginData.client,
    profile: loginData.profile,
    timestamp: formattedTimestamp,
    user_status: loginData.user_status,
    project: loginData.project,
    end_date: loginData.end_date,
    start_date: loginData.start_date,
    case_description: loginData.case_description,
    client_or_pursuit: loginData.client_or_pursuit,
    api_count: loginData.api_call_count,
    is_infinite_access: loginData.is_infinite_access,
  };
}

const AdminPage = () => {
  const [selectedValue, setSelectedValue] = useState("option");
  const [loginData, setLoginData] = useState([]);
  const [infiniteDate, setInfiniteDate] = useState({});
  const [filter, setFilter] = useState("");
  const testingButtonClicked = false;
  const clientButtonClicked = false;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const { instance } = useMsal();

  const cred = localStorage.getItem("cred") ? localStorage.getItem("cred") : "";
  let yesterday = moment().add(1, "days");
  const useNavigateApp = useNavigate();
  const [startDate, setStartDate] = useState({});
  const [startDownloadDate, setStartDownloadDate] = useState(null);
  const [endDownloadDate, setEndDownloadDate] = useState(null);
  const getIdToken = async (setErrorMessage) => {
    try {
      const { idToken } = await msal.acquireTokenSilent(loginRequest);
      return `Bearer ${idToken}`;
    } catch (error) {
      setErrorMessage(error);
    }
  };
  const [handlePortfolioSelect, setHandlePortfolioSelect] = useState("");
  const [displayTable, setDisplayTable] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = ADMIN_FILTER_URL;
        const requestBody = {
          duration: 7,
          archival: !displayTable,
        };

        const idToken = await getIdToken();
        const response = await axios.post(apiUrl, JSON.stringify(requestBody), {
          headers: { Authorization: idToken },
        });
        setLoginData(response.data["user_records"]);
        const statusFromResponse = {};
        const startDatesFromResponse = {};
        const infiniteStatus = {};
        response.data["user_records"].forEach((row) => {
          startDatesFromResponse[
            `${row.email}_${
              row.offering_portfolio ? row.offering_portfolio : "a"
            }_${row.project ? row.project : "p"}`
          ] = row.end_date ? moment(row.end_date) : null;
          statusFromResponse[
            `${row.email}_${
              row.offering_portfolio ? row.offering_portfolio : "a"
            }_${row.project ? row.project : "p"}`
          ] = row.status_call;
          infiniteStatus[
            `${row.email}_${
              row.offering_portfolio ? row.offering_portfolio : "a"
            }_${row.project ? row.project : "p"}`
          ] = row.is_infinite_access;
        });

        setSelected(statusFromResponse);
        setStartDate(startDatesFromResponse);
        setInfiniteDate(infiniteStatus);
      } catch (error) {
        console.error("API call error", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    setHandlePortfolioSelect(e.target.value);
  };

  const handleEndDateChange = (
    newDate,
    rowId,
    offering_portfolio,
    project,
    userEmail
  ) => {
    setStartDate((prevStartDates) => ({ ...prevStartDates, [rowId]: newDate }));
    const momentDate = moment(newDate).format("YYYY-MM-DD");
    getIdToken()
      .then((idToken) => {
        axios
          .post(
            USER_TIMELINE,
            {
              end_date: momentDate,
              access_status: "approved",
              offering_portfolio: offering_portfolio,
              project: project,
              email: userEmail,
            },
            {
              headers: { Authorization: idToken },
            }
          )
          .then((res) => {
            setStartDate((prevStartDates) => ({
              ...prevStartDates,
              [rowId]: newDate,
            }));
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSort = (column) => {
    const sortedData = [...loginData].sort((a, b) => {
      const valueA =
        column === "login_sessions"
          ? Number(a[column])
          : a[column].toLowerCase();
      const valueB =
        column === "login_sessions"
          ? Number(b[column])
          : b[column].toLowerCase();

      if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
      if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setLoginData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);

    let duration = 0;

    if (value === "option1") {
      duration = 30;
    } else if (value === "option2") {
      duration = 120;
    } else if (value === "option3") {
      duration = 365;
    } else {
      duration = 7;
    }
    const apiUrl = ADMIN_FILTER_URL;
    const requestBody = {
      duration: duration,
      archival: !displayTable,
    };
    getIdToken().then((idToken) => {
      axios
        .post(apiUrl, JSON.stringify(requestBody), {
          headers: { Authorization: idToken },
        })
        .then((response) => {
          setLoginData(response.data["user_records"]);
          const startDatesFromResponse = {};
          const statusFromResponse = {};
          const infiniteStatus = {};
          response.data["user_records"].forEach((row) => {
            startDatesFromResponse[
              `${row.email}_${
                row.offering_portfolio ? row.offering_portfolio : "a"
              }_${row.project ? row.project : "p"}`
            ] = row.end_date ? moment(row.end_date) : null;
            statusFromResponse[
              `${row.email}_${
                row.offering_portfolio ? row.offering_portfolio : "a"
              }_${row.project ? row.project : "p"}`
            ] = row.status_call;
            infiniteStatus[
              `${row.email}_${
                row.offering_portfolio ? row.offering_portfolio : "a"
              }_${row.project ? row.project : "p"}`
            ] = row.is_infinite_access;
          });
          setSelected(statusFromResponse);
          setStartDate(startDatesFromResponse);
          setInfiniteDate(infiniteStatus);
        })
        .catch((error) => {
          console.error("API call error", error);
        });
    });
  };
  const downloadFile = () => {
    let duration = 0;
    const value = selectedValue;
    if (value === "option1") {
      duration = 30;
    } else if (value === "option2") {
      duration = 120;
    } else if (value === "option3") {
      duration = 365;
    } else {
      duration = 7;
    }
    const requestBody = {
      duration: duration,
      archival: !displayTable,
    };
    getIdToken()
      .then((idToken) => {
        axios
          .post(DOWNLOAD_RECORDS, requestBody, {
            headers: { Authorization: idToken },
            responseType: "blob",
          })
          .then((res) => {
            const blob = new Blob([res.data], { type: "application/zip" });
            const blobUrl = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = blobUrl;
            anchor.target = "_blank";
            anchor.click();

            window.setTimeout(() => {
              window.URL.revokeObjectURL(blobUrl);
              anchor.remove();
            }, 0);
          })
          .catch((error) => {
            console.log("error");
          });
      })
      .catch((error) => {
        console.log("error");
      });
  };
  const handleBackgroundClick = () => {
    //setFilter("");
    //setIsFilterActive(false);
  };

  const filteredRows = loginData.map(createData).filter((row) => {
    if (testingButtonClicked) {
      const purposeMatch = String(row.purpose).toLowerCase().includes(filter);
      return purposeMatch;
    } else if (clientButtonClicked) {
      return row.purpose !== "testing";
    } else {
      const name = String(row.name)
        .toLowerCase()
        .includes(filter.toLowerCase());
      const portfolio = String(row.Offering_portfolio)
        .toLowerCase()
        .includes(handlePortfolioSelect.toLowerCase());
      const email = String(row.username)
        .toLowerCase()
        .includes(filter.toLowerCase());
      const project = String(row.project)
        .toLowerCase()
        .includes(filter.toLowerCase());
      // Add checks for start date and end date
      const startDateMatch =
        !startDownloadDate ||
        moment(row.start_date, "YYYY-MM-DD").isSameOrAfter(
          startDownloadDate,
          "day"
        );
      const endDateMatch =
        !endDownloadDate ||
        moment(row.end_date, "YYYY-MM-DD").isSameOrBefore(
          endDownloadDate,
          "day"
        );

      return (
        (name || email || project) &&
        startDateMatch &&
        endDateMatch &&
        portfolio
      );
    }
  });
  const useHandleAdmin = () => {
    useNavigateApp("/");
  };
  const logoutHandler = () => {
    localStorage.setItem("cred", "");
    localStorage.setItem("isAdmin", "");
    instance.logoutRedirect().catch(console.log);
  };

  const classes = useStyles();

  const handleApproveDeny = (
    userEmail,
    offering_portfolio,
    project,
    status
  ) => {
    getIdToken()
      .then((idToken) => {
        axios
          .post(
            USER_REQUEST_STATUS,
            {
              email: userEmail,
              access_status: status,
              offering_portfolio: offering_portfolio,
              project: project,
            },
            {
              headers: { Authorization: idToken },
            }
          )
          .then((response) => {
            if (status === "approved") {
              setLoginData((prevState) => {
                let newLoginData = prevState.map((item) => {
                  if (
                    item.email === userEmail &&
                    item.offering_portfolio === offering_portfolio &&
                    item.project == project
                  ) {
                    return { ...item, user_status: "Approved" };
                  }
                  return item;
                });
                return newLoginData;
              });
            } else {
              setLoginData((prevState) => {
                let newLoginData = prevState.map((item) => {
                  if (
                    item.email === userEmail &&
                    item.offering_portfolio === offering_portfolio &&
                    item.project == project
                  ) {
                    return { ...item, user_status: "No Access" };
                  }
                  return item;
                });
                return newLoginData;
              });
            }

            // setIsApproved(true);  // perhaps distinction by row is needed here
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const clearAllInputs = () => {
    setHandlePortfolioSelect("");
    setStartDownloadDate(null);
    setEndDownloadDate(null);
  };
  const [color, setColor] = useState({
    "No Outreach [Default]": "#000000",
    "Email Sent": "#00A2B8",
    "Scheduled Demo": "#EF8100",
    "Demo Completed": "#26890D",
    "Working Team": "#2166EB",
    "Awaiting Feedback": "#B9B200",
    "Request Closed": "#D30000",
  });
  const [selected, setSelected] = useState({});
  const updateStatus = (
    userEmail,
    offering_portfolio,
    project,
    status,
    rowId
  ) => {
    setSelected((prevStatus) => ({ ...prevStatus, [rowId]: status }));

    getIdToken()
      .then((idToken) => {
        axios
          .post(
            STATUS_CALL,
            {
              email: userEmail,
              offering_portfolio: offering_portfolio,
              project: project,
              status_call: status,
            },
            {
              headers: { Authorization: idToken },
            }
          )
          .then((response) => {
            setSelected((prevStatus) => ({ ...prevStatus, [rowId]: status }));
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
      <>
        <StyledTableRow {...otherProps}>
          {children}
          {displayTable && (
            <StyledTableCell padding="checkbox">
              <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </StyledTableCell>
          )}
        </StyledTableRow>
        {isExpanded && <StyledTableRow>{expandComponent}</StyledTableRow>}
      </>
    );
  };
  const displayText = displayTable
    ? `Current Requests${
        filteredRows.length > 0 ? `(${filteredRows.length})` : ""
      }`
    : "Current Requests";
  const displayTextArchived = !displayTable
    ? `Archived${filteredRows.length > 0 ? `(${filteredRows.length})` : ""}`
    : "Archived";

  const toggleDateToInfinity = (key, offering_portfolio, project, username) => {
    const current_status = !infiniteDate[key];
    getIdToken()
      .then((idToken) => {
        axios
          .post(
            INFINITE_STATUS,
            {
              status: !infiniteDate[key],
              offering_portfolio: offering_portfolio,
              project: project,
              email: username,
            },
            {
              headers: { Authorization: idToken },
            }
          )
          .then((res) => {
            setInfiniteDate((prev) => ({ ...prev, [key]: !prev[key] }));
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    const current_end_date =
      startDate[
        `${username}_${offering_portfolio ? offering_portfolio : "a"}_${
          project ? project : "p"
        }`
      ];
    const today = moment();

    if (!current_status && current_end_date.isBefore(today)) {
      handleEndDateChange(today, key, offering_portfolio, project, username);
    }
  };
  function CustomTabs({
    rowId,
    tab_offering_portfolio,
    tab_project,
    tab_username,
  }) {
    return (
      <React.Fragment>
        <div className="pl-8">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() =>
                    toggleDateToInfinity(
                      rowId,
                      tab_offering_portfolio,
                      tab_project,
                      tab_username
                    )
                  }
                  checked={infiniteDate[rowId]}
                />
              }
              label="Set Date As Infinite"
            />
          </FormGroup>
        </div>
      </React.Fragment>
    );
  }
  return (
    <div
      onMouseDown={handleBackgroundClick}
      className="h-screen text-ellipsis whitespace-nowrap overflow-y-scroll"
    >
      {/* <div className="flex items-center justify-between "></div> */}

      <div className="w-full overflow-x-scroll">
        <div className="flex flex-row ml-2 w-full">
          <div className="w-[19%]">
            <img
              src={require("../assets/creativeEdgeNew.png")}
              alt="deloitte-logo"
              className="w-[18.875rem] h-[3.1875rem] mt-[3.12rem] mb-8 "
            />
          </div>
          <div className="flex flex-row items-end justify-end m-6 p-5 mt-0 ml-0  h-[8.375rem] w-[82%] rounded-lg bg-[#F7F7F7]">
            <div className="flex flex-col w-2/4 items-end">
              <div className="w-2/4">
                <div className="text-gray-500 text-xs font-normal">Search</div>
                <TextField
                  type="text"
                  placeholder="Type here.."
                  value={filter}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setFilter(e.target.value)}
                  className="border-2 border-gray-300 ml-2 p-2 rounded "
                  fullWidth
                />
              </div>
            </div>

            <div className="flex flex-row items-end justify-end w-2/4">
              <div className="mr-2">
                <UserProfile
                  handleAdmin={useHandleAdmin}
                  handleLogout={logoutHandler}
                  userName={cred}
                  name={"Home"}
                  icon={<HomeIcon sx={{ marginLeft: "20px" }} />}
                  page="admin"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border border-sm bg-white mx-2 overflow-y-scroll">
        <div className="flex w-full">
          <div className="datefield justify-between mx-4 mt-2 mb-3 items-center">
            <div classaName="flex flex-row item-center">
              <Button
                variant="text"
                onClick={() => {
                  setDisplayTable(true);
                  handleDisplayfilter(
                    "current",
                    setLoginData,
                    setStartDate,
                    selectedValue
                  );
                }}
                sx={{ color: displayTable ? "#86BC25" : "#acacac" }}
              >
                {displayText}
              </Button>
              <span>|</span>
              <Button
                variant="text"
                onClick={() => {
                  setDisplayTable(false);
                  handleDisplayfilter(
                    "archive",
                    setLoginData,
                    setStartDate,
                    selectedValue
                  );
                }}
                sx={{ color: !displayTable ? "#86BC25" : "#acacac" }}
              >
                {displayTextArchived}
              </Button>
            </div>
            <div className="select-fields items-center space-x-4">
              <div className="flex flex-col mr-2 ml-2 mb-4 justify-center">
                <div className="text-gray-500 text-xs font-normal">
                  Select Period
                </div>
                <Select
                  id="dropdown"
                  sx={{ height: "2.5rem", width: "9rem" }}
                  value={selectedValue}
                  onChange={handleDropdownChange}
                >
                  <MenuItem value="option">Last 7 days</MenuItem>
                  <MenuItem value="option1">Last 30 days</MenuItem>
                  <MenuItem value="option2">Last Quarter</MenuItem>
                  <MenuItem value="option3">Last Year</MenuItem>
                </Select>
              </div>
              <div className="flex mb-4 justify-center">
                <FormControl sx={{ minWidth: 200 }}>
                  <div className="text-gray-500 text-xs font-normal">
                    Offering Portfolio
                  </div>
                  <Select
                    value={handlePortfolioSelect}
                    onChange={handleChange}
                    sx={{
                      height: "2.5rem",
                      width: "15.5rem",
                    }}
                  >
                    {operations.map((name) => {
                      return (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="mr-2 ml-2 mb-4 justify-center">
                <div className="date-picker-name text-gray-500 text-xs font-normal">
                  Select Period
                </div>
                <DatePicker
                  inputFormat="MM/dd/yyyy"
                  value={startDownloadDate}
                  onChange={(newDate) => setStartDownloadDate(newDate)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div className="mr-2 mb-4 justify-center">
                <div className="date-picker-name  text-gray-500 text-xs font-normal">
                  End Period
                </div>
                <DatePicker
                  inputFormat="MM/dd/yyyy"
                  value={endDownloadDate}
                  onChange={(newDate) => setEndDownloadDate(newDate)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div className="mr-4 pt-4 mb-4">
                <Button sx={{ textTransform: "none" }} onClick={clearAllInputs}>
                  <CancelOutlinedIcon sx={{ marginRight: "0.25rem" }} />
                  Clear
                </Button>
              </div>
            </div>
            <div className="mr-2">
              <Button
                variant="contained"
                color="secondary"
                onClick={downloadFile}
                sx={{ padding: "0.6875rem 0.75rem", height: "40px" }}
              >
                <img
                  className="h-4 w-[1.1rem]"
                  src={require("../assets/download-white-bg.png")}
                />
                <div className="pl-2">Download</div>
              </Button>
            </div>
          </div>
        </div>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            padding: "1.5rem",
            paddingTop: "0",
          }}
        >
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    align="center"
                    onClick={() => handleSort("name")}
                    sx={{
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    Name
                    {sortColumn === "name" &&
                      (sortOrder === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      ))}
                    {sortColumn !== "name" && (
                      <ArrowUpwardIcon sx={{ opacity: 0.5 }} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    Email ID
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    Offering Portfolio
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    Project Name
                  </StyledTableCell>
                  {displayTable && (
                    <>
                      <StyledTableCell
                        align="center"
                        sx={{
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        Client or Pursuit
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        Start Date
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        End Date
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell
                    align="center"
                    sx={{
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    Login Count
                  </StyledTableCell>
                  {displayTable && (
                    <>
                      <StyledTableCell
                        align="center"
                        sx={{
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        Access
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        Status
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          position: "sticky",
                          top: 0,
                        }}
                      ></StyledTableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredRows.length > 0 ? (
                  filteredRows.map((row, index) => (
                    <ExpandableTableRow
                      key={index}
                      expandComponent={
                        <TableCell colSpan="11">
                         
                            <div className="flex flex-row w-full  space-x-8 bg-gray-100 p-4">
                              <div className="flex flex-col justify-center space-y-2 w-1/2">
                                <p className="text-sm text-black  font-normal">
                                  The specific use case and description of the
                                  engagement.
                                </p>
                                <div className="h-24  w-full border border-[#F2F2F2] whitespace-break-spaces rounded-lg bg-white p-2">
                                  {row.case_description}
                                </div>
                              </div>
                              <div className="p-2 w-1/2 mt-6 flex flex-col space-y-2 px-4 bg-[#fff]  border border-[#F2F2F2] whitespace-break-spaces rounded-lg">
                                <div className="flex flex-row w-full gap-6">
                                  <div className="w-1/3 flex flex-row justify-between">
                                    <p>Print & static content</p>
                                    <strong>
                                      {row.api_count.hasOwnProperty(
                                        "PRINT_AND_STATIC_DESIGN"
                                      )
                                        ? row.api_count[
                                            "PRINT_AND_STATIC_DESIGN"
                                          ]
                                        : 0}
                                    </strong>
                                  </div>
                                  <div className="w-1/3 flex flex-row justify-between">
                                    <p>Creative Concepts </p>
                                    <strong>
                                      {row.api_count.hasOwnProperty(
                                        "CREATIVE_CONCEPT"
                                      )
                                        ? row.api_count["CREATIVE_CONCEPT"]
                                        : 0}
                                    </strong>
                                  </div>
                                  <div className="w-1/3 flex flex-row justify-between">
                                    <p>E-mail</p>
                                    <strong>
                                      {row.api_count.hasOwnProperty("EMAIL")
                                        ? row.api_count["EMAIL"]
                                        : 0}
                                    </strong>
                                  </div>
                                </div>
                                <div className="flex flex-row gap-6">
                                  <div className="w-1/3 flex flex-row justify-between">
                                    <p>Social Media content </p>
                                    <strong>
                                      {row.api_count.hasOwnProperty(
                                        "SOCIAL_MEDIA"
                                      )
                                        ? row.api_count["SOCIAL_MEDIA"]
                                        : 0}
                                    </strong>
                                  </div>
                                  <div className="w-1/3 flex flex-row justify-between">
                                    <p>Persona</p>
                                    <strong>
                                      {row.api_count.hasOwnProperty("PERSONA")
                                        ? row.api_count["PERSONA"]
                                        : 0}
                                    </strong>
                                  </div>
                                  <div className="w-1/3 flex flex-row justify-between">
                                    <p>Storyboard</p>
                                    <strong>
                                      {row.api_count.hasOwnProperty(
                                        "STORYBOARD"
                                      )
                                        ? row.api_count["STORYBOARD"]
                                        : 0}
                                    </strong>
                                  </div>
                                </div>
                                <div className="flex flex-row w-full gap-2">
                                  <div className="w-1/3 flex flex-row justify-between ">
                                    <p>Product Description </p>
                                    <strong className="mx-4">
                                      {row.api_count.hasOwnProperty(
                                        "PRODUCT_DESCRIPTION"
                                      )
                                        ? row.api_count["PRODUCT_DESCRIPTION"]
                                        : 0}
                                    </strong>
                                  </div>
                                  <div className="w-1/3 flex flex-row justify-between">
                                    <p>Look-ALike AD</p>
                                    <strong className="mx-4"> 
                                      {row.api_count.hasOwnProperty("MEDIA_LOOK_ALIKE")
                                        ? row.api_count["MEDIA_LOOK_ALIKE"]
                                        : 0}
                                    </strong>
                                  </div>
                               
                                </div>
                              </div>
                            </div>
                          
                        </TableCell>
                      }
                    >
                      <StyledTableCell align="center">
                        <a href={`${row.profile}`} className="popup-link">
                          {row.name}
                          <div className="popup">{row.profile}</div>
                        </a>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.username}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.Offering_portfolio}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ width: "12rem", whiteSpace: "break-spaces" }}
                      >
                        {row.project}
                      </StyledTableCell>
                      {displayTable && (
                        <>
                          <StyledTableCell align="center">
                            {row.client_or_pursuit}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.start_date}
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            sx={{ width: "10rem" }}
                          >
                            <DatePicker
                              label={
                                !infiniteDate[
                                  `${row.username}_${
                                    row.Offering_portfolio
                                      ? row.Offering_portfolio
                                      : "a"
                                  }_${row.project ? row.project : "p"}`
                                ]
                                  ? ""
                                  : "Date set as Infinite"
                              }
                              slots={{ tabs: CustomTabs }}
                              slotProps={{
                                tabs: {
                                  rowId: `${row.username}_${
                                    row.Offering_portfolio
                                      ? row.Offering_portfolio
                                      : "a"
                                  }_${row.project ? row.project : "p"}`,
                                  tab_offering_portfolio:
                                    row.Offering_portfolio,
                                  tab_project: row.project,
                                  tab_username: row.username,
                                },
                              }}
                              inputFormat="MM/dd/yyyy"
                              disableFuture={
                                infiniteDate[
                                  `${row.username}_${
                                    row.Offering_portfolio
                                      ? row.Offering_portfolio
                                      : "a"
                                  }_${row.project ? row.project : "p"}`
                                ]
                              }
                              disablePast={
                                infiniteDate[
                                  `${row.username}_${
                                    row.Offering_portfolio
                                      ? row.Offering_portfolio
                                      : "a"
                                  }_${row.project ? row.project : "p"}`
                                ]
                              }
                              minDate={
                                infiniteDate[
                                  `${row.username}_${
                                    row.Offering_portfolio
                                      ? row.Offering_portfolio
                                      : "a"
                                  }_${row.project ? row.project : "p"}`
                                ]
                                  ? yesterday
                                  : null
                              }
                              value={
                                !infiniteDate[
                                  `${row.username}_${
                                    row.Offering_portfolio
                                      ? row.Offering_portfolio
                                      : "a"
                                  }_${row.project ? row.project : "p"}`
                                ]
                                  ? startDate[
                                      `${row.username}_${
                                        row.Offering_portfolio
                                          ? row.Offering_portfolio
                                          : "a"
                                      }_${row.project ? row.project : "p"}`
                                    ]
                                  : null
                              }
                              onChange={(newDate) =>
                                handleEndDateChange(
                                  newDate,
                                  `${row.username}_${
                                    row.Offering_portfolio
                                      ? row.Offering_portfolio
                                      : "a"
                                  }_${row.project ? row.project : "p"}`,
                                  row.Offering_portfolio,
                                  row.project,
                                  row.username
                                )
                              }
                              renderInput={(params) => {
                                return <TextField {...params} fullWidth />;
                              }}
                            />
                          </StyledTableCell>
                        </>
                      )}
                      <StyledTableCell align="center">
                        {row.login_sessions}
                      </StyledTableCell>
                      {displayTable && (
                        <>
                          <StyledTableCell align="center">
                            <Button
                              onClick={() =>
                                handleApproveDeny(
                                  row.username,
                                  row.Offering_portfolio,
                                  row.project,
                                  "approved"
                                )
                              }
                              className={
                                row.user_status != "Approved"
                                  ? classes.approved
                                  : classes.neutral
                              }
                            >
                              <CheckCircleIcon
                                sx={{
                                  color:
                                    row.user_status == "Approved"
                                      ? "#86BC25"
                                      : "#acacac",
                                }}
                              />
                            </Button>

                            <Button
                              onClick={() =>
                                handleApproveDeny(
                                  row.username,
                                  row.Offering_portfolio,
                                  row.project,
                                  "denied"
                                )
                              }
                              className={
                                row.user_status == "No Access"
                                  ? classes.denied
                                  : classes.neutral
                              }
                            >
                              <CancelIcon
                                sx={{
                                  color:
                                    row.user_status == "No Access"
                                      ? "#e85e5e"
                                      : "#acacac",
                                }}
                              />
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Select
                              sx={{
                                height: "3.5rem",
                                minHeight: "3.5rem",
                                width: "14rem",
                              }}
                              value={
                                selected[
                                  `${row.username}_${
                                    row.Offering_portfolio
                                      ? row.Offering_portfolio
                                      : "a"
                                  }_${row.project ? row.project : "p"}`
                                ]
                              }
                              onChange={(e) =>
                                updateStatus(
                                  row.username,
                                  row.Offering_portfolio,
                                  row.project,
                                  e.target.value,
                                  `${row.username}_${
                                    row.Offering_portfolio
                                      ? row.Offering_portfolio
                                      : "a"
                                  }_${row.project ? row.project : "p"}`
                                )
                              }
                              style={{
                                color:
                                  color[
                                    selected[
                                      `${row.username}_${
                                        row.Offering_portfolio
                                          ? row.Offering_portfolio
                                          : "a"
                                      }_${row.project ? row.project : "p"}`
                                    ]
                                  ],
                              }}
                            >
                              <MenuItem
                                value="No Outreach [Default]"
                                sx={{ color: "#000000" }}
                              >
                                No Outreach [Default]
                              </MenuItem>
                              <MenuItem
                                value="Email Sent"
                                sx={{ color: "#00A2B8" }}
                              >
                                Email Sent
                              </MenuItem>
                              <MenuItem
                                value="Scheduled Demo"
                                sx={{ color: "#EF8100" }}
                              >
                                Scheduled Demo
                              </MenuItem>
                              <MenuItem
                                value="Demo Completed"
                                sx={{ color: "#26890D" }}
                              >
                                Demo Completed
                              </MenuItem>
                              <MenuItem
                                value="Working Team"
                                sx={{ color: "#2166EB" }}
                              >
                                Working Team
                              </MenuItem>
                              <MenuItem
                                value="Awaiting Feedback"
                                sx={{ color: "#B9B200" }}
                              >
                                Awaiting Feedback
                              </MenuItem>
                              <MenuItem
                                value="Request Closed"
                                sx={{ color: "#D30000" }}
                              >
                                Request Closed
                              </MenuItem>
                            </Select>
                          </StyledTableCell>
                        </>
                      )}
                    </ExpandableTableRow>
                  ))
                ) : (
                  <TableCell colspan={10}>
                    <div className="flex flex-col justify-center items-center">
                      <img src={require("../assets/illustration.png")} />
                      <Typography
                        sx={{ fontSize: "2.25rem", color: "#86BC25" }}
                      >
                        No Data Found
                      </Typography>
                      <Typography
                        sx={{ fontSize: "0.9rem", fontWeight: "bold" }}
                      >
                        There is no data to show right now!
                      </Typography>
                    </div>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default AdminPage;
