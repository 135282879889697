import { React, useState } from "react";
import { useEffect } from "react";
import Revise from "../components/Revise";
import Card from "../components/Card";
import Error from "../components/Error";
import Feedback from "../components/Feedback";
import TranslateDropdown from "../components/TranslateDropdown";
import { Alert, Button } from "@mui/material";
import PageviewIcon from "@mui/icons-material/Pageview";
import BackDrop from "../components/BackDrop";
import SaveCard from "../components/SaveCard";
import AiGeneratedContent from "../components/AiGeneratedContent";
import SignalTitles from "../components/SignalTitles";
import ThemeButtons from "../components/ThemeButtons";

const CreativeConcept = ({
  creativeConcepts,
  handleGenerateButtonClick,
  revisedData,
  regenerateData,
  sectionData,
  setSectionData,
  activeTab,
  editedData,
  creativeRequirementChanged,
  errorMessage,
  setErrorMessage,
  isLoading,
  translateLanguage,
  handleTranslateLanguage,
  setInputText,
  sourceLanguage,
  directTranslate,
  setSourceLanguage,
  setSelectedLanguage,
  magnifiedImage,
  setMagnifiedImage,
  setShowMagnifiedTemplate,
  setTabName,
  setIsCreativeLoading,
  setCreativeLocked,
  creativeLocked,
  creativeContentLocked,
  setCreativeContentLocked,
  handleSaveButtonClicked,
  titles,
  setSelectedTitle,
  selectedTitle,
  foundationUndo,
  foundationApply,
  setSelectedTheme,
  setThemeWasApplied,
  themeWasApplied,
  setPrevSelectedTheme,
  isSidebarOpen,
  handleSaveProjectButtonClicked
}) => {
  const [editIconIsClicked, setEditIconIsClicked] = useState(false);
  useEffect(() => {
    //setLockImages( creativeLocked.map(arr => arr.map(val => val ? "lock" : "unlock")))
    if ((!creativeConcepts || creativeRequirementChanged) && !isLoading) {
      handleGenerateButtonClick();
    }
  }, []);

  useEffect(() => {
    if (
      sourceLanguage[activeTab][selectedTitle] !==
      translateLanguage[activeTab][selectedTitle]
    ) {
      for (let i = 0; i < 3; i++) {
        directTranslate(
          sectionData.creativeConcepts?.["Concepts and Images"][selectedTitle][
            i
          ]["ConceptTerritory"],
          sourceLanguage[activeTab][selectedTitle],
          translateLanguage[activeTab][selectedTitle],
          activeTab,
          i
        );
      }
      const newSrc = [...sourceLanguage[activeTab]];
      newSrc[selectedTitle] = translateLanguage[activeTab][selectedTitle];
      setSourceLanguage((prev) => ({
        ...prev,
        [activeTab]: newSrc,
      }));
    }
  }, [translateLanguage[activeTab][selectedTitle]]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleSavingResponseValue = (data, index) => {
    let newObj = { ...sectionData };
    newObj.creativeConcepts["Concepts and Images"][selectedTitle][index][
      "ConceptTerritory"
    ] = data;
    setSectionData(newObj);
  };

  const handleMagnify = (tabName, index, path) => {
    setTabName(tabName);
    setMagnifiedImage(path);
    setShowMagnifiedTemplate(true);
  };
  const handleLocked = (index, key) => {
    setCreativeLocked((previousState) => {
      let lockState = previousState;
      lockState[selectedTitle][index][key] =
        !lockState[selectedTitle][index][key];
      return lockState;
    });
  };
  return (
    <div
      className={`flex-1 w-full ${isSidebarOpen ? "hidden" : "block"} md:block`}
    >
      {!creativeConcepts && (
        <div className="flex flex-col">
          <div className="flex">
            <div className="p-10 m-5 w-full bg-white rounded-xl">
              {errorMessage && (
                <Alert severity="error" sx={{ marginTop: 3, marginBottom: 3 }}>
                  {errorMessage.message}
                </Alert>
              )}

              <Card text="" />
              <div className="w-full pt-7">
                <Revise
                  revisedData={revisedData}
                  regenerateData={regenerateData}
                  selectedTitle={selectedTitle}
                />
              </div>
            </div>
          </div>
          <div className="m-5">
            <SaveCard
              download={true}
              handleSaveButtonClicked={handleSaveButtonClicked}
            />
          </div>
        </div>
      )}
      {creativeConcepts && (
        <TranslateDropdown
          translateLanguage={translateLanguage}
          handleTranslateLanguage={handleTranslateLanguage}
          activeTab={activeTab}
          selectedTitle={selectedTitle}
        />
      )}

      {creativeConcepts && titles.length > 1 && (
        <>
          <SignalTitles
            titles={titles}
            selectedTitle={selectedTitle}
            setSelectedTitle={setSelectedTitle}
            activeTab={"Creative Concepts"}
          />
        </>
      )}
      {creativeConcepts?.map((item, index) => (
        <div className="flex flex-col">
          <div key={index} className="flex">
            <div
              key={index}
              className={`w-full pt-4 px-4 sm:px-10 pb-10 mx-5 my-5 bg-white ${
                titles?.length > 1
                  ? "mt-0 rounded-t-none rounded-b-lg"
                  : "rounded-lg"
              }`}
            >
              {errorMessage && <Error errorMessage={errorMessage.message} />}
              <ThemeButtons
                foundationApplyCall={foundationApply}
                foundationUndoCall={foundationUndo}
                index={index}
                setSelectedTheme={setSelectedTheme}
                setThemeWasApplied={setThemeWasApplied}
                themeWasApplied={themeWasApplied}
                setPrevSelectedTheme={setPrevSelectedTheme}
                errorMessage={errorMessage}
              />
              <Card
                key={index}
                text={item["ConceptTerritory"]}
                editedData={editedData}
                handleSavingResponseValue={handleSavingResponseValue}
                index={index}
                activeTab={activeTab}
                setInputText={setInputText}
                sectionData={sectionData}
                content={
                  sectionData.creativeConcepts["Concepts and Images"][
                    selectedTitle
                  ][index]["ConceptTerritory"]
                } //doubtful
                setSelectedLanguage={setSelectedLanguage}
                translateLanguage={translateLanguage}
                isLoading={isLoading}
                contentLocked={creativeContentLocked}
                setContentLocked={setCreativeContentLocked}
                selectedTitle={selectedTitle}
                setEditIconIsClicked={setEditIconIsClicked}
              />
              {/* <AiGeneratedContent /> */}
              <div className="mt-10">
                <div className="flex flex-nowrap justify-around overflow-x-auto w-full  mt-5">
                  {item["Images"]?.map((img, key) => (
                    <div className="flex-shrink-0 justify-center px-2 mr-6">
                      <img
                        className="h-[13.125rem] w-[13.125rem] rounded-lg"
                        key={key}
                        src={`data:image/png;base64,${img}`}
                        alt="fs"
                      />
                      <Button
                        sx={{
                          position: "relative",
                          zindex: 1,
                          bottom: 35,
                          left: 180,
                          padding: 0,
                          color: "grey",
                          transform: "translateX(-30%)",
                        }}
                        onClick={() => handleLocked(index, key)}
                      >
                        <img
                          src={require(`../assets/${
                            creativeLocked[selectedTitle][index][key]
                              ? "lock"
                              : "unlock"
                          }.png`)}
                          alt="locked-Icon"
                        />
                      </Button>
                      {/* <Button
                        style={{
                          position: "relative",
                          zindex: 1,
                          bottom: 35,
                          left: 140,
                          padding: 0,
                          color: "grey",
                        }}
                        onClick={() => handleLocked(index, key)}
                      >
                        <img
                          src={require(`../assets/${
                            creativeLocked[index][key] ? "lock" : "unlock"
                          }.png`)}
                          alt="locked-Icon"
                        />
                      </Button> */}
                      {/* <Button
                        style={{
                          position: "relative",
                          zindex: 1,
                          bottom: 35,
                          left: 140,
                          padding: 0,
                          color: "grey",
                        }}
                        onClick={() => handleLocked(index, key)}
                      >
                        <img
                          src={require(`../assets/${lockImages[index][key]}.png`)}
                          alt="locked-Icon"
                        />
                      </Button> */}
                      <Button
                        sx={{
                          position: "relative",
                          zIndex: 2,
                          bottom: 202,
                          left: -12,
                          padding: 0,
                          color: "grey",
                          transform: "translateX(-95%)",
                        }}
                        onClick={() => handleMagnify("generated", key, img)}
                      >
                        <img
                          src={require("../assets/magnify.png")}
                          alt="magnify-Icon"
                        />
                      </Button>
                    </div>
                  ))}
                </div>
                <AiGeneratedContent />
                <div className="mt-6">
                  <Revise
                    revisedData={revisedData}
                    regenerateData={regenerateData}
                    index={index}
                    selectedTitle={selectedTitle}
                    isContentLocked={
                      creativeContentLocked[selectedTitle][index]
                    }
                    content={item["ConceptTerritory"]}
                    editClicked={editIconIsClicked}
                  />
                </div>
              </div>
            </div>
          </div>
          {index === 2 && (
            <div className="m-5">
              <SaveCard
                download={true}
                handleSaveButtonClicked={handleSaveButtonClicked}
                handleSaveProjectButtonClicked={handleSaveProjectButtonClicked}
                content={creativeConcepts}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default CreativeConcept;
