import React from "react";
import { TextField, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import "./EditMode.css";
const EditMode = ({
  steps,
  handleAddStepEach,
  addEachClicked,
  editEachClicked,
  handleChangeInNewStep,
  handleSaveAfterAddButton,
  handleEditChangeEach,
  handleEditEachButton,
  handleSaveEachButton,
  handleRefreshEachButton,
  handleDeleteEachButton,
  sectionData,
  setShowEditingMode,
}) => {
  return (
    <>
      <div className="bg-white p-5 m-5 mt-[-20px] rounded-lg">
  {steps?.map((step, index) => (
    <div key={index}>
      <div className="flex justify-start">
        <Button className="flex justify-start" onClick={() => handleAddStepEach(index)}>
          <div className="p-2 flex justify-center items-center rounded-md mr-2.5">
            <img className="flex" src={require("../assets/addstep1.png")} alt="Add Step" />
          </div>
          Add Step
        </Button>
      </div>
      {addEachClicked[index] && (
        <div className="flex flex-col sm:flex-row w-full justify-between">
          <TextField
            className="md:w-4/5 w-full"
            multiline
            defaultValue=""
            placeholder="Add new step here."
            onChange={(e) => handleChangeInNewStep(e, index)}
          />
          <Button
            onClick={() => handleSaveAfterAddButton(index)}
            className="flex justify-center items-center rounded-md border border-green-600 bg-white p-2 mr-5 mb-5 md:mb-0"
          >
            <SaveIcon />
          </Button>
        </div>
      )}
      <div className="flex flex-col md:flex-row w-full justify-between">
        {!editEachClicked[index] ? (
          <div className="ml-2.5 flex justify-center items-center text-sm font-normal">
            {step}
          </div>
        ) : (
          <TextField
            className="md:w-4/5 w-full"
            multiline
            defaultValue={step}
            onChange={(e) => handleEditChangeEach(e, index)}
          />
        )}
        <div className="flex items-start ml-5 space-x-4">
          {!editEachClicked[index] ? (
            <>
              <Button onClick={() => handleEditEachButton(index)} className="flex justify-center items-center rounded-md border border-green-600 bg-white p-2">
                <img src={require("../assets/edit-icon.png")} alt="Edit" className="mr-1.5 w-5.5 h-5.5" />
              </Button>
              <Button onClick={() => handleRefreshEachButton(index)} className="flex justify-center items-center rounded-md border border-green-600 bg-white p-2">
                <img src={require("../assets/reload-icon.png")} alt="reload" className="mr-1.5 w-5 h-5" />
              </Button>
              <Button onClick={() => handleDeleteEachButton(index)} className="flex justify-center items-center rounded-md border border-green-600 bg-white p-2">
                <img src={require("../assets/delete-icon.png")} alt="delete" className="mr-1.5 w-5 h-5" />
              </Button>
            </>
          ) : (
            <div className="flex items-center space-x-4">
              <Button onClick={() => handleSaveEachButton(index)} className="flex justify-center items-center rounded-md border border-green-600 bg-green-600 p-2">
                <img src={require("../assets/save-icon.png")} alt="Edit" className="mr-1.5 w-5.5 h-5.5" />
              </Button>
              <Button onClick={() => handleRefreshEachButton(index)} className="flex justify-center items-center rounded-md border border-green-600 bg-white p-2">
                <img src={require("../assets/reload-icon.png")} alt="reload" className="mr-1.5 w-5.5 h-5.5" />
              </Button>
              <Button onClick={() => handleDeleteEachButton(index)} className="flex justify-center items-center rounded-md border border-green-600 bg-white p-2">
                <img src={require("../assets/delete-icon.png")} alt="delete" className="mr-1.5 w-5.5 h-5.5" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  ))}
  {steps.length > 0 && (
    <div className="flex justify-start">
      <Button className="flex justify-start" onClick={() => handleAddStepEach(steps.length)}>
        <div className="p-2 flex justify-center items-center rounded-md mr-2.5">
          <img className="flex" src={require("../assets/addstep1.png")} alt="Add Step" />
        </div>
        Add Step
      </Button>
    </div>
  )}
  {addEachClicked[steps.length] && (
    <div className="flex flex-col md:flex-row w-full">
      <TextField
        className="md:w-4/5 w-full"
        multiline
        defaultValue=""
        placeholder="Add new step here."
        onChange={(e) => handleChangeInNewStep(e, steps.length)}
      />
      <Button className="flex justify-center items-center mt-2 md:mt-0 md:ml-5" onClick={() => handleSaveAfterAddButton(steps.length)}>
        <SaveIcon />
      </Button>
    </div>
  )}
  <div className="flex justify-end">
    <Button
      className="mr-4 font-semibold rounded-md py-3 px-5"
      variant="contained"
      color="secondary"
      onClick={() => setShowEditingMode(false)}
    >
      Stop Editing
    </Button>
  </div>
</div>
    </>
  );
};

export default EditMode;
