/* eslint-disable global-require */
import { React, useEffect, useState, useRef, useDebugValue } from "react";
import Revise from "../components/Revise";
import Card from "../components/Card";
import TranslateIcon from "@mui/icons-material/Translate";
import Error from "../components/Error";
import {
  Alert,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import Feedback from "../components/Feedback";
import FlyerDownloader from "../components/FlyerDownloader";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import LogoBackDrop from "../components/LogoBackDrop";
import SelectImageBackdrop from "../components/SelectImageBackdrop";
import TemplateViewer from "../components/TemplateViewer";
import TemplateDownloader from "../components/TemplateDownloader";
import { SketchPicker } from "react-color";
import PageviewIcon from "@mui/icons-material/Pageview";
import SaveIcon from "@mui/icons-material/Save";
import Popover from "@mui/material/Popover";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Translate from "../components/Translate";
import { directTransalation, translate } from "../utils/translateHelper";
import SelectInput from "@mui/material/Select/SelectInput";
import SaveCard from "../components/SaveCard";
import AiGeneratedContent from "../components/AiGeneratedContent";
import MenuConceptTheme from "../components/MenuConceptTheme";
import CreativeHoverCard from "../components/CreativeHoverCard";
const Flyer = ({
  Images,
  MagazineAd,
  handleGenerateButtonClick,
  revisedData,
  regenerateData,
  setSectionData,
  sectionData,
  flyerHeadline,
  flyerSubtext,
  editedData,
  activeTab,
  flyerRequirementChanged,
  errorMessage,
  setErrorMessage,
  isLoading,
  magnifiedImage,
  setMagnifiedImage,
  setShowMagnifiedTemplate,
  flyerPreview,
  previewImage,
  setPreviewImage,
  setPreviewButtonClicked,
  setShowLogoConfirm,
  setTabName,
  setMissingParams,
  setShowErrorTab,
  setShowSuccessUpload,
  imageState,
  setImageState,
  uploadedImages,
  setUploadedImages,
  selectedTemplate,
  setSelectedTemplate,
  chosenImage,
  setChosenImage,
  checkedGeneratedImages,
  setCheckedGeneratedImages,
  checkedUploadedImages,
  setCheckedUploadedImages,
  previewButtonClicked,
  flyerDownloadCall,
  setDownloadButtonClicked,
  primaryColor,
  setPrimaryColor,
  secondaryColor,
  setSecondaryColor,
  setFlyerHeadline,
  setFlyerSubtext,
  directTranslate,
  setInputText,
  translateLanguage,
  sourceLanguage,
  setTranslateLanguage,
  setSourceLanguage,
  setSelectedLanguage,
  isFlyerLoading,
  isFlyerVerticalLoading,
  isFlyerSquareLoading,
  isBannerHorizontalLoading,
  isBannerVerticalLoading,
  showingHorizontal,
  showingVertical,
  showingSquare,
  showingBannerHorizontal,
  showingBannerVertical,
  imageDimensions,
  setImageDimensions,
  showSuccessUpload,
  horizontal,
  vertical,
  square,
  setVerticalFlyerImageLocked,
  verticalFlyerImageLocked,
  setVerticalFlyerContentLocked,
  verticalFlyerContentLocked,
  setHorizontalFlyerImageLocked,
  horizontalFlyerImageLocked,
  setHorizontalFlyerContentLocked,
  horizontalFlyerContentLocked,
  setSquareFlyerContentLocked,
  squareFlyerContentLocked,
  setSquareFlyerImageLocked,
  squareFlyerImageLocked,
  setHorizontalBannerContentLocked,
  horizontalBannerContentLocked,
  setHorizontalBannerImageLocked,
  horizontalBannerImageLocked,
  setVerticalBannerContentLocked,
  verticalBannerContentLocked,
  setVerticalBannerImageLocked,
  verticalBannerImageLocked,
  handleSaveButtonClicked,
  handleGeneratedImagesCheckbox,
  selectedTitle,
  territoryTitles,
  selectedTheme,
  setSelectedTheme,
  themeWasApplied,
  setPrevSelectedTheme,
  prevSelectedTheme,
  isSidebarOpen,
  handleSaveProjectButtonClicked,
}) => {
  //const [logoFileName, setLogoFileName] = useState("");
  const [selectedValue, setSelectedValue] = useState(-1);
  const [selectedTerritory, setSelectedTerritory] = useState(-1);
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    setMousePosition({
      x: event.clientX,
      y: event.clientY,
    });
  };
  const [ranEffect, setRanEffect] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [generatedClicked, setGeneratedClicked] = useState(true);
  const [showPrimaryColorPicker, setShowPrimaryColorPicker] = useState(false);
  const [showSecondaryColorPicker, setShowSecondaryColorPicker] =
    useState(false);

  const [onUploadImageEventStorage, setOnUploadImageEventStorage] = useState();

  const colorPickerRef = useRef(null);
  const colorSecondaryPickRef = useRef(null);
  const [uploadTab, setUploadTab] = useState(false);
  const [editedAnything, setEditedAnything] = useState(false);
  const [revisedInfo, setRevisedInfo] = useState("");
  const [responseValue, setResponseValue] = useState(MagazineAd);
  const [lockImages, setLockImages] = useState(["unlock", "unlock"]);
  const [lockHorizontalImages, setLockHorizontalImages] = useState([
    "unlock",
    "unlock",
  ]);
  const [lockSquareImages, setLockSquareImages] = useState([
    "unlock",
    "unlock",
  ]);
  const [lockBannerHorizontalImages, setLockBannerHorizontalImages] = useState([
    "unlock",
    "unlock",
  ]);
  const [lockBannerVerticalImages, setLockBannerVerticalImages] = useState([
    "unlock",
    "unlock",
  ]);
  let flyerTypeIndex = 0;
  if (showingHorizontal) flyerTypeIndex = 0;
  else if (showingVertical) flyerTypeIndex = 1;
  else if (showingSquare) flyerTypeIndex = 2;
  else if (showingBannerHorizontal) flyerTypeIndex = 3;
  else flyerTypeIndex = 4;

  let flyerType = "FlyerHorizontal";
  if (showingHorizontal) flyerType = "FlyerHorizontal";
  else if (showingVertical) flyerType = "FlyerVertical";
  else if (showingSquare) flyerType = "FlyerSquare";
  else if (showingBannerHorizontal) flyerType = "BannerHorizontal";
  else flyerType = "BannerVertical";
  useEffect(() => {
    if (
      (!MagazineAd ||
        ranEffect[flyerTypeIndex] ||
        (themeWasApplied && prevSelectedTheme[flyerType] !== selectedTheme)) &&
      !isLoading
    ) {
      const newObj = { ...prevSelectedTheme };
      newObj[flyerType] = selectedTheme;
      setPrevSelectedTheme(newObj);
      setRanEffect([
        ...ranEffect.slice(0, flyerTypeIndex),
        false,
        ...ranEffect.slice(flyerTypeIndex + 1),
      ]);
      handleGenerateButtonClick();
    }
  }, [
    selectedTheme,
    showingHorizontal,
    showingBannerHorizontal,
    showingVertical,
    showingSquare,
    showingBannerVertical,
  ]);
  useEffect(() => {
    setResponseValue(MagazineAd);
  }, [MagazineAd]);
  const handleLocked = (index) => {
    if (showingVertical) {
      setLockImages((previousState) => {
        let lockedImages = previousState;
        lockedImages[index] == "lock"
          ? (lockedImages[index] = "unlock")
          : (lockedImages[index] = "lock");
        return lockedImages;
      });
      setVerticalFlyerImageLocked((previousState) => {
        let lockedState = previousState;
        lockedState[index] = !lockedState[index];
        return lockedState;
      });
    } else if (showingHorizontal) {
      setLockHorizontalImages((previousState) => {
        let lockedImages = previousState;
        lockedImages[index] == "lock"
          ? (lockedImages[index] = "unlock")
          : (lockedImages[index] = "lock");
        return lockedImages;
      });
      setHorizontalFlyerImageLocked((previousState) => {
        let lockedState = previousState;
        lockedState[index] = !lockedState[index];
        return lockedState;
      });
    } else if (showingSquare) {
      setLockSquareImages((previousState) => {
        let lockedImages = previousState;
        lockedImages[index] == "lock"
          ? (lockedImages[index] = "unlock")
          : (lockedImages[index] = "lock");
        return lockedImages;
      });
      setSquareFlyerImageLocked((previousState) => {
        let lockedState = previousState;
        lockedState[index] = !lockedState[index];
        return lockedState;
      });
    } else if (showingBannerHorizontal) {
      setLockBannerHorizontalImages((previousState) => {
        let lockedImages = previousState;
        lockedImages[index] == "lock"
          ? (lockedImages[index] = "unlock")
          : (lockedImages[index] = "lock");
        return lockedImages;
      });
      setHorizontalBannerImageLocked((previousState) => {
        let lockedState = previousState;
        lockedState[index] = !lockedState[index];
        return lockedState;
      });
    } else {
      setLockBannerVerticalImages((previousState) => {
        let lockedImages = previousState;
        lockedImages[index] == "lock"
          ? (lockedImages[index] = "unlock")
          : (lockedImages[index] = "lock");
        return lockedImages;
      });
      setVerticalBannerImageLocked((previousState) => {
        let lockedState = previousState;
        lockedState[index] = !lockedState[index];
        return lockedState;
      });
    }
  };

  useEffect(() => {
    if (showingHorizontal) {
      const newPreview = previewImage.map((item, index) => {
        if (index === 0) return "";
        else return item;
      });
      setPreviewImage(newPreview);
    }
    if (showingVertical) {
      const newPreview = previewImage.map((item, index) => {
        if (index === 1) return "";
        else return item;
      });
      setPreviewImage(newPreview);
    }
    if (showingSquare) {
      const newPreview = previewImage.map((item, index) => {
        if (index === 2) return "";
        else return item;
      });
      setPreviewImage(newPreview);
    }
    if (showingBannerHorizontal) {
      const newPreview = previewImage.map((item, index) => {
        if (index === 3) return "";
        else return item;
      });
      setPreviewImage(newPreview);
    }
    if (showingBannerVertical) {
      const newPreview = previewImage.map((item, index) => {
        if (index === 4) return "";
        else return item;
      });
      setPreviewImage(newPreview);
    }
  }, [
    imageState[
      showingHorizontal
        ? 0
        : showingVertical
        ? 1
        : showingSquare
        ? 2
        : showingBannerHorizontal
        ? 3
        : 4
    ].logoFile,
    imageState[
      showingHorizontal
        ? 0
        : showingVertical
        ? 1
        : showingSquare
        ? 2
        : showingBannerHorizontal
        ? 3
        : 4
    ].uploadedFile,
    primaryColor[
      showingHorizontal
        ? 0
        : showingVertical
        ? 1
        : showingSquare
        ? 2
        : showingBannerHorizontal
        ? 3
        : 4
    ].rgb,
    secondaryColor[
      showingHorizontal
        ? 0
        : showingVertical
        ? 1
        : showingSquare
        ? 2
        : showingBannerHorizontal
        ? 3
        : 4
    ].rgb,
    chosenImage[
      showingHorizontal
        ? 0
        : showingVertical
        ? 1
        : showingSquare
        ? 2
        : showingBannerHorizontal
        ? 3
        : 4
    ],
    checkedGeneratedImages[
      showingHorizontal
        ? 0
        : showingVertical
        ? 1
        : showingSquare
        ? 2
        : showingBannerHorizontal
        ? 3
        : 4
    ],
    selectedTemplate[
      showingHorizontal
        ? 0
        : showingVertical
        ? 1
        : showingSquare
        ? 2
        : showingBannerHorizontal
        ? 3
        : 4
    ],
    generatedClicked,
  ]);

  useEffect(() => {
    if (showingHorizontal && sourceLanguage.Flyer !== translateLanguage.Flyer) {
      directTranslate(
        sectionData.flyer?.["Flyer_Translated"],
        sourceLanguage.Flyer,
        translateLanguage.Flyer,
        activeTab
      );
      setSourceLanguage((prev) => ({
        ...prev,
        Flyer: translateLanguage.Flyer,
      }));
    }
  }, [translateLanguage.Flyer]);

  useEffect(() => {
    if (
      showingVertical &&
      sourceLanguage.FlyerVertical !== translateLanguage.FlyerVertical
    ) {
      directTranslate(
        sectionData.flyerVertical?.["Flyer_Translated"],
        sourceLanguage.FlyerVertical,
        translateLanguage.FlyerVertical,
        activeTab
      );
      setSourceLanguage((prev) => ({
        ...prev,
        FlyerVertical: translateLanguage.FlyerVertical,
      }));
    }
  }, [translateLanguage.FlyerVertical]);

  useEffect(() => {
    if (
      showingSquare &&
      sourceLanguage.FlyerSquare !== translateLanguage.FlyerSquare
    ) {
      directTranslate(
        sectionData.flyerSquare?.["Flyer_Translated"],
        sourceLanguage.FlyerSquare,
        translateLanguage.FlyerSquare,
        activeTab
      );
      setSourceLanguage((prev) => ({
        ...prev,
        FlyerSquare: translateLanguage.FlyerSquare,
      }));
    }
  }, [translateLanguage.FlyerSquare]);

  useEffect(() => {
    if (
      showingBannerHorizontal &&
      sourceLanguage.BannerHorizontal !== translateLanguage.BannerHorizontal
    ) {
      directTranslate(
        sectionData.bannerHorizontal?.["Flyer_Translated"],
        sourceLanguage.BannerHorizontal,
        translateLanguage.BannerHorizontal,
        activeTab
      );
      setSourceLanguage((prev) => ({
        ...prev,
        BannerHorizontal: translateLanguage.BannerHorizontal,
      }));
    }
  }, [translateLanguage.BannerHorizontal]);

  useEffect(() => {
    if (
      showingBannerVertical &&
      sourceLanguage.BannerVertical !== translateLanguage.BannerVertical
    ) {
      directTranslate(
        sectionData.bannerVertical?.["Flyer_Translated"],
        sourceLanguage.BannerVertical,
        translateLanguage.BannerVertical,
        activeTab
      );
      setSourceLanguage((prev) => ({
        ...prev,
        BannerVertical: translateLanguage.BannerVertical,
      }));
    }
  }, [translateLanguage.BannerVertical]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const imagePathsHorizontal = [
    require("../assets/temp1.png"),
    require("../assets/temp2.png"),
    require("../assets/temp3.png"),
    require("../assets/temp4.png"),
    require("../assets/temp5.png"),
    require("../assets/temp6.png"),
  ];
  const imagePathsVertical = [
    require("../assets/Flyer-V-08.png"),
    require("../assets/01.png"),
    require("../assets/02.png"),
    require("../assets/04.png"),
    require("../assets/05.png"),
    require("../assets/06.png"),
  ];
  const imagePathsSquare = [
    require("../assets/01s.png"),
    require("../assets/02s.png"),
    require("../assets/03s.png"),
    require("../assets/04s.png"),
    require("../assets/05s.png"),
    require("../assets/06s.png"),
  ];
  const imagePathsBannerHorizontal = [
    require(`../assets/banhor1.png`),
    require("../assets/BH_2.png"),
    require("../assets/banhor3.png"),
    require("../assets/banhor4.png"),
    require("../assets/banhor5.png"),
    require("../assets/banhor6.png"),
  ];
  const imagePathsBannerVertical = [
    require(`../assets/V_B_01.jpg`),
    require("../assets/BV_2.png"),
    require("../assets/V_B_03.jpg"),
    require("../assets/V_B_04.jpg"),
    require("../assets/V_B_05.jpg"),
    require("../assets/V_B_06.jpg"),
  ];
  const handlePrimaryButton = () => {
    setShowPrimaryColorPicker((prev) => !prev);
  };
  const handleSecondaryButton = () => {
    setShowSecondaryColorPicker((prev) => !prev);
  };
  const handlePrimaryColorChange = (color) => {
    if (showingHorizontal) {
      const index = 0;
      setPrimaryColor([
        ...primaryColor.slice(0, index),
        {
          rgb: color.rgb,
          hex: color.hex,
        },
        ...primaryColor.slice(index + 1),
      ]);
    } else if (showingVertical) {
      const index = 1;
      setPrimaryColor([
        ...primaryColor.slice(0, index),
        {
          rgb: color.rgb,
          hex: color.hex,
        },
        ...primaryColor.slice(index + 1),
      ]);
    } else if (showingSquare) {
      const index = 2;
      setPrimaryColor([
        ...primaryColor.slice(0, index),
        {
          rgb: color.rgb,
          hex: color.hex,
        },
        ...primaryColor.slice(index + 1),
      ]);
    } else if (showingBannerHorizontal) {
      const index = 3;
      setPrimaryColor([
        ...primaryColor.slice(0, index),
        {
          rgb: color.rgb,
          hex: color.hex,
        },
        ...primaryColor.slice(index + 1),
      ]);
    } else if (showingBannerVertical) {
      const index = 4;
      setPrimaryColor([
        ...primaryColor.slice(0, index),
        {
          rgb: color.rgb,
          hex: color.hex,
        },
        ...primaryColor.slice(index + 1),
      ]);
    }
  };
  const handleTranslateLanguage = (value) => {
    if (showingHorizontal) {
      setSourceLanguage((prev) => ({
        ...prev,
        Flyer: translateLanguage.Flyer,
      }));
      setTranslateLanguage((prev) => ({
        ...prev,
        Flyer: value,
      }));
    }
    if (showingVertical) {
      setSourceLanguage((prev) => ({
        ...prev,
        FlyerVertical: translateLanguage.FlyerVertical,
      }));
      setTranslateLanguage((prev) => ({
        ...prev,
        FlyerVertical: value,
      }));
    }
    if (showingSquare) {
      setSourceLanguage((prev) => ({
        ...prev,
        FlyerSquare: translateLanguage.FlyerSquare,
      }));
      setTranslateLanguage((prev) => ({
        ...prev,
        FlyerSquare: value,
      }));
    }
    if (showingBannerHorizontal) {
      setSourceLanguage((prev) => ({
        ...prev,
        BannerHorizontal: translateLanguage.BannerHorizontal,
      }));
      setTranslateLanguage((prev) => ({
        ...prev,
        BannerHorizontal: value,
      }));
    }
    if (showingBannerVertical) {
      setSourceLanguage((prev) => ({
        ...prev,
        BannerVertical: translateLanguage.BannerVertical,
      }));
      setTranslateLanguage((prev) => ({
        ...prev,
        BannerVertical: value,
      }));
    }
  };

  const handleSecondaryColorChange = (color) => {
    if (showingHorizontal) {
      const index = 0;
      setSecondaryColor([
        ...secondaryColor.slice(0, index),
        {
          rgb: color.rgb,
          hex: color.hex,
        },
        ...secondaryColor.slice(index + 1),
      ]);
    } else if (showingVertical) {
      const index = 1;
      setSecondaryColor([
        ...secondaryColor.slice(0, index),
        {
          rgb: color.rgb,
          hex: color.hex,
        },
        ...secondaryColor.slice(index + 1),
      ]);
    } else if (showingSquare) {
      const index = 2;
      setSecondaryColor([
        ...secondaryColor.slice(0, index),
        {
          rgb: color.rgb,
          hex: color.hex,
        },
        ...secondaryColor.slice(index + 1),
      ]);
    } else if (showingBannerHorizontal) {
      const index = 3;
      setSecondaryColor([
        ...secondaryColor.slice(0, index),
        {
          rgb: color.rgb,
          hex: color.hex,
        },
        ...secondaryColor.slice(index + 1),
      ]);
    } else if (showingBannerVertical) {
      const index = 4;
      setSecondaryColor([
        ...secondaryColor.slice(0, index),
        {
          rgb: color.rgb,
          hex: color.hex,
        },
        ...secondaryColor.slice(index + 1),
      ]);
    }
  };
  const handleUploadButtonClick = () => {
    setUploadTab(true);
  };
  const handleGenerateImagesButtonClick = () => {
    setUploadTab(false);
  };
  const handleMagnify = (tabName, index, path) => {
    setTabName(tabName);
    setMagnifiedImage(path);
    setShowMagnifiedTemplate(true);
  };
  const handleGeneratedClick = () => {
    setGeneratedClicked(true);
  };

  const handleUploadImageClick = () => {
    setGeneratedClicked(false);
  };

  const [tempContent, setTempContent] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const handleSavingResponseValue = (data) => {
    let newObj = { ...sectionData };
    if (showingHorizontal) {
      newObj.flyer["Flyer_Translated"] = data;
    }
    if (showingVertical) {
      newObj.flyerVertical["Flyer_Translated"] = data;
    }
    if (showingSquare) {
      newObj.flyerSquare["Flyer_Translated"] = data;
    }
    if (showingBannerHorizontal) {
      newObj.bannerHorizontal["Flyer_Translated"] = data;
    }
    if (showingBannerVertical) {
      newObj.bannerVertical["Flyer_Translated"] = data;
    }
    setSectionData(newObj);
  };
  const [headlineSubtextUpdatedInEdit, setHeadlineSubtextUpdatedInEdit] =
    useState(false);
  const handleEditClick = () => {
    if (showingVertical) {
      if (
        verticalFlyerContentLocked !== undefined &&
        verticalFlyerContentLocked
      ) {
        return;
      }
    } else if (showingHorizontal) {
      if (
        horizontalFlyerContentLocked !== undefined &&
        horizontalFlyerContentLocked
      ) {
        return;
      }
    } else if (showingSquare) {
      if (squareFlyerContentLocked !== undefined && squareFlyerContentLocked) {
        return;
      }
    } else if (showingBannerHorizontal) {
      if (
        horizontalBannerContentLocked !== undefined &&
        horizontalBannerContentLocked
      ) {
        return;
      }
    } else {
      if (
        verticalBannerContentLocked !== undefined &&
        verticalBannerContentLocked
      ) {
        return;
      }
    }
    if (isEditing) {
      if (showingHorizontal) {
        const index = 0;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingVertical) {
        const index = 1;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingSquare) {
        const index = 2;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingBannerHorizontal) {
        const index = 3;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingBannerVertical) {
        const index = 4;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      const checked = [
        ...checkedGeneratedImages[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ],
      ];
      for (let i = 0; i < Images?.length; i++) {
        checked[i] = 0;
      }
      if (showingHorizontal) {
        const index = 0;
        setCheckedGeneratedImages([
          ...checkedGeneratedImages.slice(0, index),
          checked,
          ...checkedGeneratedImages.slice(index + 1),
        ]);
      }
      if (showingVertical) {
        const index = 1;
        setCheckedGeneratedImages([
          ...checkedGeneratedImages.slice(0, index),
          checked,
          ...checkedGeneratedImages.slice(index + 1),
        ]);
      }
      if (showingSquare) {
        const index = 2;
        setCheckedGeneratedImages([
          ...checkedGeneratedImages.slice(0, index),
          checked,
          ...checkedGeneratedImages.slice(index + 1),
        ]);
      }
      if (showingBannerHorizontal) {
        const index = 3;
        setCheckedGeneratedImages([
          ...checkedGeneratedImages.slice(0, index),
          checked,
          ...checkedGeneratedImages.slice(index + 1),
        ]);
      }
      if (showingBannerVertical) {
        const index = 4;
        setCheckedGeneratedImages([
          ...checkedGeneratedImages.slice(0, index),
          checked,
          ...checkedGeneratedImages.slice(index + 1),
        ]);
      }
      if (showingHorizontal) {
        const index = 0;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingVertical) {
        const index = 1;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingSquare) {
        const index = 2;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingBannerHorizontal) {
        const index = 3;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (showingBannerVertical) {
        const index = 4;
        setChosenImage([
          ...chosenImage.slice(0, index),
          "",
          ...chosenImage.slice(index + 1),
        ]);
      }
      if (responseValue !== MagazineAd) {
        handleSavingResponseValue(responseValue);
        // setOriginalContent(editedContent);
        let response = responseValue;
        if (showingHorizontal)
          response = response + sectionData.flyer["Visual"];
        if (showingVertical)
          response = response + sectionData.flyerVertical["Visual"];
        if (showingSquare)
          response = response + sectionData.flyerSquare["Visual"];
        if (showingBannerHorizontal)
          response = response + sectionData.bannerHorizontal["Visual"];
        if (showingBannerVertical)
          response = response + sectionData.bannerVertical["Visual"];
        editedData(response);
        if (showingHorizontal) {
          const index = 0;
          setChosenImage([
            ...chosenImage.slice(0, index),
            "",
            ...chosenImage.slice(index + 1),
          ]);
        }
        if (showingVertical) {
          const index = 1;
          setChosenImage([
            ...chosenImage.slice(0, index),
            "",
            ...chosenImage.slice(index + 1),
          ]);
        }
        if (showingSquare) {
          const index = 2;
          setChosenImage([
            ...chosenImage.slice(0, index),
            "",
            ...chosenImage.slice(index + 1),
          ]);
        }
        if (showingBannerHorizontal) {
          const index = 3;
          setChosenImage([
            ...chosenImage.slice(0, index),
            "",
            ...chosenImage.slice(index + 1),
          ]);
        }
        if (showingBannerVertical) {
          const index = 4;
          setChosenImage([
            ...chosenImage.slice(0, index),
            "",
            ...chosenImage.slice(index + 1),
          ]);
        }
        const checked = [
          ...checkedGeneratedImages[
            showingHorizontal
              ? 0
              : showingVertical
              ? 1
              : showingSquare
              ? 2
              : showingBannerHorizontal
              ? 3
              : 4
          ],
        ];
        for (let i = 0; i < Images?.length; i++) {
          checked[i] = 0;
        }
        if (showingHorizontal) {
          const index = 0;
          setCheckedGeneratedImages([
            ...checkedGeneratedImages.slice(0, index),
            checked,
            ...checkedGeneratedImages.slice(index + 1),
          ]);
        }
        if (showingVertical) {
          const index = 1;
          setCheckedGeneratedImages([
            ...checkedGeneratedImages.slice(0, index),
            checked,
            ...checkedGeneratedImages.slice(index + 1),
          ]);
        }
        if (showingSquare) {
          const index = 2;
          setCheckedGeneratedImages([
            ...checkedGeneratedImages.slice(0, index),
            checked,
            ...checkedGeneratedImages.slice(index + 1),
          ]);
        }
        if (showingBannerHorizontal) {
          const index = 3;
          setCheckedGeneratedImages([
            ...checkedGeneratedImages.slice(0, index),
            checked,
            ...checkedGeneratedImages.slice(index + 1),
          ]);
        }
        if (showingBannerVertical) {
          const index = 4;
          setCheckedGeneratedImages([
            ...checkedGeneratedImages.slice(0, index),
            checked,
            ...checkedGeneratedImages.slice(index + 1),
          ]);
        }
        if (showingHorizontal) {
          const index = 0;
          setChosenImage([
            ...chosenImage.slice(0, index),
            "",
            ...chosenImage.slice(index + 1),
          ]);
        }
        if (showingVertical) {
          const index = 1;
          setChosenImage([
            ...chosenImage.slice(0, index),
            "",
            ...chosenImage.slice(index + 1),
          ]);
        }
        if (showingSquare) {
          const index = 2;
          setChosenImage([
            ...chosenImage.slice(0, index),
            "",
            ...chosenImage.slice(index + 1),
          ]);
        }
        if (showingBannerHorizontal) {
          const index = 3;
          setChosenImage([
            ...chosenImage.slice(0, index),
            "",
            ...chosenImage.slice(index + 1),
          ]);
        }
        if (showingBannerVertical) {
          const index = 4;
          setChosenImage([
            ...chosenImage.slice(0, index),
            "",
            ...chosenImage.slice(index + 1),
          ]);
        }
        const myString = responseValue;
        const headlineRegex = /Headline:\s*([^\n]*)\n/;
        const subtextRegex = /Subtext:\s*([^]*)\n/;
        const headlineMatch = headlineRegex.exec(myString);
        const subtextMatch = subtextRegex.exec(myString);
        const headlineValue = headlineMatch && headlineMatch[1];
        const subtextValue = subtextMatch && subtextMatch[1];
        if (showingHorizontal) {
          const newValues = flyerHeadline.map((item, index) => {
            if (index === 0) return headlineValue;
            else return item;
          });
          setFlyerHeadline(newValues);
        }
        if (showingVertical) {
          const newValues = flyerHeadline.map((item, index) => {
            if (index === 1) return headlineValue;
            else return item;
          });
          setFlyerHeadline(newValues);
        }
        if (showingSquare) {
          const newValues = flyerHeadline.map((item, index) => {
            if (index === 2) return headlineValue;
            else return item;
          });
          setFlyerHeadline(newValues);
        }
        if (showingBannerHorizontal) {
          const newValues = flyerHeadline.map((item, index) => {
            if (index === 3) return headlineValue;
            else return item;
          });
          setFlyerHeadline(newValues);
        }
        if (showingBannerVertical) {
          const newValues = flyerHeadline.map((item, index) => {
            if (index === 4) return headlineValue;
            else return item;
          });
          setFlyerHeadline(newValues);
        }
        if (showingHorizontal) {
          const newValues = flyerSubtext.map((item, index) => {
            if (index === 0) return subtextValue;
            else return item;
          });
          setFlyerSubtext(newValues);
        }
        if (showingVertical) {
          const newValues = flyerSubtext.map((item, index) => {
            if (index === 1) return subtextValue;
            else return item;
          });
          setFlyerSubtext(newValues);
        }
        if (showingSquare) {
          const newValues = flyerSubtext.map((item, index) => {
            if (index === 2) return subtextValue;
            else return item;
          });
          setFlyerSubtext(newValues);
        }
        if (showingBannerHorizontal) {
          const newValues = flyerSubtext.map((item, index) => {
            if (index === 3) return subtextValue;
            else return item;
          });
          setFlyerSubtext(newValues);
        }
        if (showingBannerVertical) {
          const newValues = flyerSubtext.map((item, index) => {
            if (index === 4) return subtextValue;
            else return item;
          });
          setFlyerSubtext(newValues);
        }
        setHeadlineSubtextUpdatedInEdit(true);
      }
      setIsEditing(false);
    } else {
      setTempContent(responseValue);
      setIsEditing(true);
      return;
    }
  };

  useEffect(() => {
    if (headlineSubtextUpdatedInEdit) {
      setHeadlineSubtextUpdatedInEdit(false);
      return;
    }
    setHeadlineSubtextUpdatedInEdit(false);
    const myString = showingHorizontal
      ? sectionData.flyer?.Flyer
      : showingVertical
      ? sectionData.flyerVertical?.Flyer
      : showingSquare
      ? sectionData.flyerSquare?.Flyer
      : showingBannerHorizontal
      ? sectionData.bannerHorizontal?.Flyer
      : sectionData.bannerVertical?.Flyer;
    const headlineRegex = /Headline:\s*([^\n]*)\n/;
    const subtextRegex = /Subtext:\s*([^]*)\n/;
    const headlineMatch = headlineRegex.exec(myString);
    const subtextMatch = subtextRegex.exec(myString);
    const headlineValue = headlineMatch && headlineMatch[1];
    const subtextValue = subtextMatch && subtextMatch[1];
    if (showingHorizontal) {
      const newValues = flyerHeadline.map((item, index) => {
        if (index === 0) return headlineValue;
        else return item;
      });
      setFlyerHeadline(newValues);
    }
    if (showingVertical) {
      const newValues = flyerHeadline.map((item, index) => {
        if (index === 1) return headlineValue;
        else return item;
      });
      setFlyerHeadline(newValues);
    }
    if (showingSquare) {
      const newValues = flyerHeadline.map((item, index) => {
        if (index === 2) return headlineValue;
        else return item;
      });
      setFlyerHeadline(newValues);
    }
    if (showingBannerHorizontal) {
      const newValues = flyerHeadline.map((item, index) => {
        if (index === 3) return headlineValue;
        else return item;
      });
      setFlyerHeadline(newValues);
    }
    if (showingBannerVertical) {
      const newValues = flyerHeadline.map((item, index) => {
        if (index === 4) return headlineValue;
        else return item;
      });
      setFlyerHeadline(newValues);
    }
    if (showingHorizontal) {
      const newValues = flyerSubtext.map((item, index) => {
        if (index === 0) return subtextValue;
        else return item;
      });
      setFlyerSubtext(newValues);
    }
    if (showingVertical) {
      const newValues = flyerSubtext.map((item, index) => {
        if (index === 1) return subtextValue;
        else return item;
      });
      setFlyerSubtext(newValues);
    }
    if (showingSquare) {
      const newValues = flyerSubtext.map((item, index) => {
        if (index === 2) return subtextValue;
        else return item;
      });
      setFlyerSubtext(newValues);
    }
    if (showingBannerHorizontal) {
      const newValues = flyerSubtext.map((item, index) => {
        if (index === 3) return subtextValue;
        else return item;
      });
      setFlyerSubtext(newValues);
    }
    if (showingBannerVertical) {
      const newValues = flyerSubtext.map((item, index) => {
        if (index === 4) return subtextValue;
        else return item;
      });
      setFlyerSubtext(newValues);
    }
  }, [
    flyerHeadline[
      showingHorizontal
        ? 0
        : showingVertical
        ? 1
        : showingSquare
        ? 2
        : showingBannerHorizontal
        ? 3
        : 4
    ],
    flyerSubtext[
      showingHorizontal
        ? 0
        : showingVertical
        ? 1
        : showingSquare
        ? 2
        : showingBannerHorizontal
        ? 3
        : 4
    ],
  ]);
  const handleEditChange = (e) => {
    const editValue = e.target.value;
    setResponseValue(editValue);
  };

  /*  const [uploadedImages, setUploadedImages] = useState([]);
  const [checkedUploadedImages, setCheckedUploadedImages] = useState(
    new Array(uploadedImages?.length).fill(0)
  ); */
  const handleImageClick = (index1) => {
    if (showingHorizontal) {
      const index = 0;
      setSelectedTemplate([
        ...selectedTemplate.slice(0, index),
        index1 ===
        selectedTemplate[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ]
          ? -1
          : index1,
        ...selectedTemplate.slice(index + 1),
      ]);
    }
    if (showingVertical) {
      const index = 1;
      setSelectedTemplate([
        ...selectedTemplate.slice(0, index),
        index1 ===
        selectedTemplate[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ]
          ? -1
          : index1,
        ...selectedTemplate.slice(index + 1),
      ]);
    }
    if (showingSquare) {
      const index = 2;
      setSelectedTemplate([
        ...selectedTemplate.slice(0, index),
        index1 ===
        selectedTemplate[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ]
          ? -1
          : index1,
        ...selectedTemplate.slice(index + 1),
      ]);
    }
    if (showingBannerHorizontal) {
      const index = 3;
      setSelectedTemplate([
        ...selectedTemplate.slice(0, index),
        index1 ===
        selectedTemplate[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ]
          ? -1
          : index1,
        ...selectedTemplate.slice(index + 1),
      ]);
    }
    if (showingBannerVertical) {
      const index = 4;
      setSelectedTemplate([
        ...selectedTemplate.slice(0, index),
        index1 ===
        selectedTemplate[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ]
          ? -1
          : index1,
        ...selectedTemplate.slice(index + 1),
      ]);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleLogoInputChange = (e) => {
    let { logoFile } =
      imageState[
        showingHorizontal
          ? 0
          : showingVertical
          ? 1
          : showingSquare
          ? 2
          : showingBannerHorizontal
          ? 3
          : 4
      ];
    //setLogoFileName(e.target.files[0]["name"]);
    logoFile = e.target.files[0];
    getBase64(logoFile)
      .then((result) => {
        logoFile["base64"] = result;
        if (showingHorizontal) {
          const index = 0;
          setImageState([
            ...imageState.slice(0, index),
            {
              ...imageState[0],
              logoBase64: result,
              logoFile: logoFile,
              logoFileName: logoFile["name"],
            },
            ...imageState.slice(index + 1),
          ]);
        }
        if (showingVertical) {
          const index = 1;
          setImageState([
            ...imageState.slice(0, index),
            {
              ...imageState[1],
              logoBase64: result,
              logoFile: logoFile,
              logoFileName: logoFile["name"],
            },
            ...imageState.slice(index + 1),
          ]);
        }
        if (showingSquare) {
          const index = 2;
          setImageState([
            ...imageState.slice(0, index),
            {
              ...imageState[2],
              logoBase64: result,
              logoFile: logoFile,
              logoFileName: logoFile["name"],
            },
            ...imageState.slice(index + 1),
          ]);
        }
        if (showingBannerHorizontal) {
          const index = 3;
          setImageState([
            ...imageState.slice(0, index),
            {
              ...imageState[3],
              logoBase64: result,
              logoFile: logoFile,
              logoFileName: logoFile["name"],
            },
            ...imageState.slice(index + 1),
          ]);
        }
        if (showingBannerVertical) {
          const index = 4;
          setImageState([
            ...imageState.slice(0, index),
            {
              ...imageState[4],
              logoBase64: result,
              logoFile: logoFile,
              logoFileName: logoFile["name"],
            },
            ...imageState.slice(index + 1),
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /* setImageState({
      ...imageState,
      logoFile: e.target.files[0],
    }); */
    setShowLogoConfirm(true);
    e.target.value = null;
  };

  const handleUploadedImageConversion = (e, index1, todo) => {
    if (todo === "reset") {
      if (showingHorizontal) {
        const index = 0;
        setImageState([
          ...imageState.slice(0, index),
          {
            ...imageState[0],
            uploadedBase64: "",
            uploadedFile: "",
          },
          ...imageState.slice(index + 1),
        ]);
      }
      if (showingVertical) {
        const index = 1;
        setImageState([
          ...imageState.slice(0, index),
          {
            ...imageState[1],
            uploadedBase64: "",
            uploadedFile: "",
          },
          ...imageState.slice(index + 1),
        ]);
      }
      if (showingSquare) {
        const index = 2;
        setImageState([
          ...imageState.slice(0, index),
          {
            ...imageState[2],
            uploadedBase64: "",
            uploadedFile: "",
          },
          ...imageState.slice(index + 1),
        ]);
      }
      if (showingBannerHorizontal) {
        const index = 3;
        setImageState([
          ...imageState.slice(0, index),
          {
            ...imageState[3],
            uploadedBase64: "",
            uploadedFile: "",
          },
          ...imageState.slice(index + 1),
        ]);
      }
      if (showingBannerVertical) {
        const index = 4;
        setImageState([
          ...imageState.slice(0, index),
          {
            ...imageState[4],
            uploadedBase64: "",
            uploadedFile: "",
          },
          ...imageState.slice(index + 1),
        ]);
      }
      return;
    }

    if (!e) return;

    let { uploadedFile } =
      imageState[
        showingHorizontal
          ? 0
          : showingVertical
          ? 1
          : showingSquare
          ? 2
          : showingBannerHorizontal
          ? 3
          : 4
      ];

    uploadedFile = e.target?.files?.[index1];

    getBase64(uploadedFile)
      .then((result) => {
        uploadedFile["base64"] = result;
        if (showingHorizontal) {
          const index = 0;
          setImageState([
            ...imageState.slice(0, index),
            {
              ...imageState[0],
              uploadedBase64: result,
              uploadedFile: e.target?.files?.[index1],
            },
            ...imageState.slice(index + 1),
          ]);
        }
        if (showingVertical) {
          const index = 1;
          setImageState([
            ...imageState.slice(0, index),
            {
              ...imageState[1],
              uploadedBase64: result,
              uploadedFile: e.target?.files?.[index1],
            },
            ...imageState.slice(index + 1),
          ]);
        }
        if (showingSquare) {
          const index = 2;
          setImageState([
            ...imageState.slice(0, index),
            {
              ...imageState[2],
              uploadedBase64: result,
              uploadedFile: e.target?.files?.[index1],
            },
            ...imageState.slice(index + 1),
          ]);
        }
        if (showingBannerHorizontal) {
          const index = 3;
          setImageState([
            ...imageState.slice(0, index),
            {
              ...imageState[3],
              uploadedBase64: result,
              uploadedFile: e.target?.files?.[index1],
            },
            ...imageState.slice(index + 1),
          ]);
        }
        if (showingBannerVertical) {
          const index = 4;
          setImageState([
            ...imageState.slice(0, index),
            {
              ...imageState[4],
              uploadedBase64: result,
              uploadedFile: e.target?.files?.[index1],
            },
            ...imageState.slice(index + 1),
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /* setImageState({
      ...imageState,
      
    }); */
  };

  const checkboxStyles = {
    backgroundColor: "white",
    border: "2px solid green",
  };

  const tickMarkStyles = {
    color: "green",
  };
  const handleOutsidePrimaryClick = (event) => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target)
    ) {
      setShowPrimaryColorPicker(false);
    }
  };
  const handleOutsideSecondaryClick = (event) => {
    if (
      colorSecondaryPickRef.current &&
      !colorSecondaryPickRef.current.contains(event.target)
    ) {
      setShowSecondaryColorPicker(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsidePrimaryClick);
    document.addEventListener("click", handleOutsideSecondaryClick);
    return () => {
      document.removeEventListener("click", handleOutsidePrimaryClick);
      document.removeEventListener("click", handleOutsideSecondaryClick);
    };
  }, []);

  //const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleImagesUpload = (e) => {
    setOnUploadImageEventStorage(e);
    const files = e.target.files;
    const uploaded = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();

      reader.onload = (event) => {
        uploaded.push(event.target.result);
        if (uploaded.length === files.length) {
          if (showingHorizontal) {
            const index = 0;
            setUploadedImages([
              ...uploadedImages.slice(0, index),
              uploaded,
              ...uploadedImages.slice(index + 1),
            ]);
          }
          if (showingVertical) {
            const index = 1;
            setUploadedImages([
              ...uploadedImages.slice(0, index),
              uploaded,
              ...uploadedImages.slice(index + 1),
            ]);
          }
          if (showingSquare) {
            const index = 2;
            setUploadedImages([
              ...uploadedImages.slice(0, index),
              uploaded,
              ...uploadedImages.slice(index + 1),
            ]);
          }
          if (showingBannerHorizontal) {
            const index = 3;
            setUploadedImages([
              ...uploadedImages.slice(0, index),
              uploaded,
              ...uploadedImages.slice(index + 1),
            ]);
          }
          if (showingBannerVertical) {
            const index = 4;
            setUploadedImages([
              ...uploadedImages.slice(0, index),
              uploaded,
              ...uploadedImages.slice(index + 1),
            ]);
          }
        }
      };

      reader.readAsDataURL(files[i]);
    }
    if (showingHorizontal) {
      const index = 0;
      setShowSuccessUpload([
        ...showSuccessUpload.slice(0, index),
        true,
        ...showSuccessUpload.slice(index + 1),
      ]);
    } else if (showingVertical) {
      const index = 1;
      setShowSuccessUpload([
        ...showSuccessUpload.slice(0, index),
        true,
        ...showSuccessUpload.slice(index + 1),
      ]);
    } else if (showingSquare) {
      const index = 2;
      setShowSuccessUpload([
        ...showSuccessUpload.slice(0, index),
        true,
        ...showSuccessUpload.slice(index + 1),
      ]);
    } else if (showingBannerHorizontal) {
      const index = 3;
      setShowSuccessUpload([
        ...showSuccessUpload.slice(0, index),
        true,
        ...showSuccessUpload.slice(index + 1),
      ]);
    } else if (showingBannerVertical) {
      const index = 4;
      setShowSuccessUpload([
        ...showSuccessUpload.slice(0, index),
        true,
        ...showSuccessUpload.slice(index + 1),
      ]);
    }
  };
  const handleImageSelect = (index, e, e2) => {
    const checked = [
      ...checkedUploadedImages[
        showingHorizontal
          ? 0
          : showingVertical
          ? 1
          : showingSquare
          ? 2
          : showingBannerHorizontal
          ? 3
          : 4
      ],
    ];
    if (!e2.target.checked) {
      for (
        let i = 0;
        i <
        uploadedImages[
          showingHorizontal
            ? 0
            : showingVertical
            ? 1
            : showingSquare
            ? 2
            : showingBannerHorizontal
            ? 3
            : 4
        ]?.length;
        i++
      ) {
        checked[i] = 0;
      }
      if (showingHorizontal) {
        const index = 0;
        setCheckedUploadedImages([
          ...checkedUploadedImages.slice(0, index),
          checked,
          ...checkedUploadedImages.slice(index + 1),
        ]);
      }
      if (showingVertical) {
        const index = 1;
        setCheckedUploadedImages([
          ...checkedUploadedImages.slice(0, index),
          checked,
          ...checkedUploadedImages.slice(index + 1),
        ]);
      }
      if (showingSquare) {
        const index = 2;
        setCheckedUploadedImages([
          ...checkedUploadedImages.slice(0, index),
          checked,
          ...checkedUploadedImages.slice(index + 1),
        ]);
      }
      if (showingBannerHorizontal) {
        const index = 3;
        setCheckedUploadedImages([
          ...checkedUploadedImages.slice(0, index),
          checked,
          ...checkedUploadedImages.slice(index + 1),
        ]);
      }
      if (showingBannerVertical) {
        const index = 4;
        setCheckedUploadedImages([
          ...checkedUploadedImages.slice(0, index),
          checked,
          ...checkedUploadedImages.slice(index + 1),
        ]);
      }
      handleUploadedImageConversion(e, index, "reset");
      return;
    }
    checked[index] = 1;
    for (
      let i = 0;
      i <
      uploadedImages[
        showingHorizontal
          ? 0
          : showingVertical
          ? 1
          : showingSquare
          ? 2
          : showingBannerHorizontal
          ? 3
          : 4
      ]?.length;
      i++
    ) {
      if (i !== index) {
        checked[i] = 2;
      }
    }

    if (showingHorizontal) {
      const index = 0;
      setCheckedUploadedImages([
        ...checkedUploadedImages.slice(0, index),
        checked,
        ...checkedUploadedImages.slice(index + 1),
      ]);
    }
    if (showingVertical) {
      const index = 1;
      setCheckedUploadedImages([
        ...checkedUploadedImages.slice(0, index),
        checked,
        ...checkedUploadedImages.slice(index + 1),
      ]);
    }
    if (showingSquare) {
      const index = 2;
      setCheckedUploadedImages([
        ...checkedUploadedImages.slice(0, index),
        checked,
        ...checkedUploadedImages.slice(index + 1),
      ]);
    }
    if (showingBannerHorizontal) {
      const index = 3;
      setCheckedUploadedImages([
        ...checkedUploadedImages.slice(0, index),
        checked,
        ...checkedUploadedImages.slice(index + 1),
      ]);
    }
    if (showingBannerVertical) {
      const index = 4;
      setCheckedUploadedImages([
        ...checkedUploadedImages.slice(0, index),
        checked,
        ...checkedUploadedImages.slice(index + 1),
      ]);
    }
    if (e) handleUploadedImageConversion(e, index);
  };
  useEffect(() => {
    const previmage =
      previewImage[
        showingHorizontal
          ? 0
          : showingVertical
          ? 1
          : showingSquare
          ? 2
          : showingBannerHorizontal
          ? 3
          : 4
      ];
    if (showingHorizontal) {
      const index = 0;
      setPreviewImage([
        ...previewImage.slice(0, index),
        previmage,
        ...previewImage.slice(index + 1),
      ]);
    }
    if (showingVertical) {
      const index = 1;
      setPreviewImage([
        ...previewImage.slice(0, index),
        previmage,
        ...previewImage.slice(index + 1),
      ]);
    }
    if (showingSquare) {
      const index = 2;
      setPreviewImage([
        ...previewImage.slice(0, index),
        previmage,
        ...previewImage.slice(index + 1),
      ]);
    }
    if (showingBannerHorizontal) {
      const index = 3;
      setPreviewImage([
        ...previewImage.slice(0, index),
        previmage,
        ...previewImage.slice(index + 1),
      ]);
    }
    if (showingBannerVertical) {
      const index = 4;
      setPreviewImage([
        ...previewImage.slice(0, index),
        previmage,
        ...previewImage.slice(index + 1),
      ]);
    }
  }, [
    uploadedImages[
      showingHorizontal
        ? 0
        : showingVertical
        ? 1
        : showingSquare
        ? 2
        : showingBannerHorizontal
        ? 3
        : 4
    ],
    Images,
  ]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const allLanguages = [
    "English",
    "Arabic",
    "Bengali",
    "Chinese",
    "French",
    "German",
    "Hindi",
    "Indonesian",
    "Italian",
    "Japanese",
    "Korean",
    "Latvian",
    "Portuguese",
    "Russian",
    "Spanish",
    "Tamil",
    "Urdu",
    "Vietnamese",
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      sx: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleContentLocked = () => {
    if (showingVertical) {
      setVerticalFlyerContentLocked((prevState) => {
        return !prevState;
      });
    } else if (showingHorizontal) {
      setHorizontalFlyerContentLocked((prevState) => {
        return !prevState;
      });
    } else if (showingSquare) {
      setSquareFlyerContentLocked((prevState) => {
        return !prevState;
      });
    } else if (showingBannerHorizontal) {
      setHorizontalBannerContentLocked((prevState) => {
        return !prevState;
      });
    } else {
      setVerticalBannerContentLocked((prevState) => {
        return !prevState;
      });
    }
  };

  const primaryColorIndex = showingHorizontal
    ? 0
    : showingVertical
    ? 1
    : showingSquare
    ? 2
    : showingBannerHorizontal
    ? 3
    : 4;

  const primaryColorIndexBgColor = primaryColor[primaryColorIndex].hex;

  return (
    <div
      className={`page w-full h-full bg-[#F2F2F2] flex-1 ${
        isSidebarOpen ? "hidden" : "block"
      } sm:block`}
    >
      {isHovered && selectedTerritory !== -1 && (
        <CreativeHoverCard
          position={mousePosition}
          territoryTitle={territoryTitles[selectedTitle]?.[selectedTerritory]}
          content={
            sectionData?.creativeConcepts?.["Concepts and Images"]?.[
              selectedTitle
            ]?.[selectedTerritory]?.["ConceptTerritory"]
          }
          images={
            sectionData?.creativeConcepts?.["Concepts and Images"]?.[
              selectedTitle
            ]?.[selectedTerritory]?.["Images"]
          }
        />
      )}
      {previewButtonClicked[
        showingHorizontal
          ? 0
          : showingVertical
          ? 1
          : showingSquare
          ? 2
          : showingBannerHorizontal
          ? 3
          : 4
      ] &&
        activeTab === "Flyer" && (
          <TemplateDownloader
            previewButtonClicked={previewButtonClicked}
            path={
              previewImage[
                showingHorizontal
                  ? 0
                  : showingVertical
                  ? 1
                  : showingSquare
                  ? 2
                  : showingBannerHorizontal
                  ? 3
                  : 4
              ]
            }
            setPreviewButtonClicked={setPreviewButtonClicked}
            setDownloadButtonClicked={setDownloadButtonClicked}
            flyerDownloadCall={flyerDownloadCall}
            showingHorizontal={showingHorizontal}
            showingVertical={showingVertical}
            showingSquare={showingSquare}
            showingBannerHorizontal={showingBannerHorizontal}
            showingBannerVertical={showingBannerVertical}
          />
        )}
      <div className="flex justify-center bg-[#F2F2F2]">
        <div className="w-full m-4">
          {errorMessage && <Error errorMessage={errorMessage.message} />}
          <div className="bg-white p-4 rounded-lg flex flex-col justify-between h-max sm:h-[31.3rem]">
            <div className="flex flex-col justify-center sm:flex-row sm:justify-between w-full">
              <div className="flex flex-col w-6/12">
                <div className="flex justify-start items-start">
                  <MenuConceptTheme
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    setIsHovered={setIsHovered}
                    handleMouseMove={handleMouseMove}
                    territoryTitles={territoryTitles[selectedTitle]}
                    selectedTheme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                    setSelectedTerritory={setSelectedTerritory}
                    setRanEffect={setRanEffect}
                    themeWasApplied={themeWasApplied}
                    ranEffect={ranEffect}
                    setPrevSelectedTheme={setPrevSelectedTheme}
                    flyerIndex={flyerTypeIndex}
                  />
                </div>
                <div className="flex flex-row mb-4 mt-2 w-full h-4 mr-px">
                  <Button
                    onClick={handleGeneratedClick}
                    variant="text"
                    sx={{
                      color: generatedClicked ? "#26890D" : "#848484",
                      letterSpacing: "0.3px",
                    }}
                  >
                    Generated Images
                  </Button>
                  <Divider
                    orientation="vertical"
                    sx={{
                      bgcolor: "black",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <Button
                    onClick={handleUploadImageClick}
                    variant="text"
                    sx={{ color: !generatedClicked ? "#26890D" : "#848484" }}
                  >
                    Upload Image
                  </Button>
                </div>
                <div>
                  {/* Display the upload image input when Upload Image is clicked */}
                  {!generatedClicked &&
                    !uploadedImages[
                      showingHorizontal
                        ? 0
                        : showingVertical
                        ? 1
                        : showingSquare
                        ? 2
                        : showingBannerHorizontal
                        ? 3
                        : 4
                    ].length > 0 && (
                      <div className="flex justify-center items-center h-[225px] w-[475px]">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImagesUpload(e)}
                          className="hidden"
                          id="upload-button"
                          multiple
                        />
                        <label htmlFor="upload-button">
                          <Button
                            component="span"
                            variant="contained"
                            color="secondary"
                          >
                            Browse File
                          </Button>
                        </label>
                      </div>
                    )}

                  {!generatedClicked &&
                    uploadedImages[
                      showingHorizontal
                        ? 0
                        : showingVertical
                        ? 1
                        : showingSquare
                        ? 2
                        : showingBannerHorizontal
                        ? 3
                        : 4
                    ].length > 0 && (
                      <>
                        <div className="overflow-x-auto whitespace-nowrap mr-[30px]">
                          {uploadedImages[
                            showingHorizontal
                              ? 0
                              : showingVertical
                              ? 1
                              : showingSquare
                              ? 2
                              : showingBannerHorizontal
                              ? 3
                              : 4
                          ].map((image, index) => (
                            <div
                              key={index}
                              className="relative inline-block w-56 mr-2.5 overflow-y-auto max-h-56 overflow-x-hidden flyer_scrollbarwidth_thin"
                            >
                              <img
                                src={image}
                                alt={`Uploaded ${index}`}
                                className="w-[225px] h-[255px]"
                                // onClick={() => handleImageSelect(index, e)}
                              />
                              <input
                                type="checkbox"
                                disabled={
                                  checkedUploadedImages[
                                    showingHorizontal
                                      ? 0
                                      : showingVertical
                                      ? 1
                                      : showingSquare
                                      ? 2
                                      : showingBannerHorizontal
                                      ? 3
                                      : 4
                                  ][index] === 2
                                }
                                onChange={(e) =>
                                  handleImageSelect(
                                    index,
                                    onUploadImageEventStorage,
                                    e
                                  )
                                }
                                checked={
                                  checkedUploadedImages[
                                    showingHorizontal
                                      ? 0
                                      : showingVertical
                                      ? 1
                                      : showingSquare
                                      ? 2
                                      : showingBannerHorizontal
                                      ? 3
                                      : 4
                                  ][index] === 1
                                }
                                className="mt-1.5  absolute top-[5px] left-[199px]"
                              />
                              {/* <Button
                                style={{
                                  position: "relative",
                                  zindex: 1,
                                  bottom: 35,
                                  left: 140,
                                  padding: 0,
                                  color: "grey",
                                }}
                                onClick={() => handleLocked(index)}
                              >
                                <img
                                  src={require(`../assets/${lockImages[index]}.png`)}
                                  alt="locked-Icon"
                                />
                              </Button> */}
                              <Button
                                sx={{
                                  position: "absolute",
                                  bottom: 8,
                                  left: 190,
                                  padding: 0,
                                  color: "#676767",
                                }}
                                onClick={() =>
                                  handleMagnify("uploaded", index, image)
                                }
                              >
                                <img src={require("../assets/magnify.png")} />
                              </Button>
                            </div>
                          ))}
                        </div>
                        <div className="flex  items-left mt-4">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImagesUpload}
                            id="upload-button"
                            multiple // Allow multiple file selection
                            className="hidden"
                          />
                          <label htmlFor="upload-button">
                            <Button
                              component="span"
                              variant="contained"
                              color="secondary"
                              className="text-white"
                            >
                              Browse Files
                            </Button>
                          </label>
                        </div>
                      </>
                    )}
                </div>

                {/* Conditionally render the images section based on generatedClicked state */}
                {generatedClicked && (
                  <div
                    className={
                      showingBannerHorizontal
                        ? "flex flex-col h-fit"
                        : "flex flex-row w-fit"
                    }
                  >
                    {Images?.map((item, key) => (
                      <div
                        className={`relative flex content-start justify-items-start ${
                          showingBannerHorizontal
                            ? "py-1 w-60"
                            : "px-2 w-[180px]"
                        }`}
                        key={key}
                      >
                        <img
                          className="h-full w-full"
                          src={`data:image/png;base64,${item}`}
                          alt="fs"
                        />
                        <input
                          type="checkbox"
                          className={`absolute top-2 ${
                            showingBannerHorizontal
                              ? "left-[210px]"
                              : "left-[150px]"
                          }`}
                          src={require("../assets/checkbox.png")}
                          checked={
                            checkedGeneratedImages[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ][key] === 1
                          }
                          onChange={(e) => {
                            handleGeneratedImagesCheckbox(e, key, item, Images);
                          }}
                          disabled={
                            checkedGeneratedImages[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ][key] === 2
                          }
                        ></input>
                        <Button
                          sx={{
                            position: "absolute",
                            bottom: 8,
                            left: showingBannerHorizontal ? 183 : 123,
                            padding: 0,
                            color: "#676767",
                          }}
                          onClick={() => handleLocked(key)}
                        >
                          <img
                            src={require(`../assets/${
                              showingVertical
                                ? verticalFlyerImageLocked[key]
                                  ? "lock"
                                  : "unlock"
                                : showingHorizontal
                                ? horizontalFlyerImageLocked[key]
                                  ? "lock"
                                  : "unlock"
                                : showingSquare
                                ? squareFlyerImageLocked[key]
                                  ? "lock"
                                  : "unlock"
                                : showingBannerHorizontal
                                ? horizontalBannerImageLocked[key]
                                  ? "lock"
                                  : "unlock"
                                : verticalBannerImageLocked[key]
                                ? "lock"
                                : "unlock"
                            }.png`)}
                            alt="locked-Icon"
                          />
                        </Button>
                        <Button
                          sx={{
                            position: "absolute",
                            top: 10,
                            left: 15,
                            padding: 0,
                            color: "#676767",
                            transform: "translateX(-30%)",
                          }}
                          onClick={() => handleMagnify("generated", key, item)}
                        >
                          <img
                            src={require("../assets/magnify.png")}
                            alt="magnify icon"
                          />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex flex-col w-full sm:ml-6 sm:mr-2">
                {MagazineAd && (
                  <div className="flex flex-row justify-center sm:justify-end w-full mt-4 sm:mt-0 h-[37px] mb-1">
                    <div className="h-4 mt-[1px]">Translate</div>
                    <FormControl
                      sx={{
                        width: "140px",
                        marginBottom: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      <Select
                        value={
                          showingHorizontal
                            ? translateLanguage.Flyer
                            : showingVertical
                            ? translateLanguage.FlyerVertical
                            : showingSquare
                            ? translateLanguage.FlyerSquare
                            : showingBannerHorizontal
                            ? translateLanguage.BannerHorizontal
                            : translateLanguage.BannerVertical
                        }
                        onChange={(e) =>
                          handleTranslateLanguage(e.target.value)
                        }
                        MenuProps={MenuProps}
                        sx={{ width: "100%", height: "100%" }}
                      >
                        {allLanguages.map((language) => (
                          <MenuItem value={language}>{language}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                <div className="flex flex-row h-full overflow-y-auto border border-[#DFDFDF] rounded-lg relative w-full">
                  <div className="overflow-hidden w-full relative h-[190px]">
                    {isEditing ? (
                      <>
                        <textarea
                          defaultValue={MagazineAd}
                          onChange={handleEditChange}
                          className="mt-4 mr-10 ml-[15px] mb-[15px] whitespace-break-spaces overflow-y-auto max-h-[150px] w-4/5 border-none outline-none resize-none h-full"
                        />
                        <button onClick={handleEditClick}>
                          <img
                            src={require("../assets/saveIcon.png")}
                            alt="Save Icon"
                            className="absolute bottom-20 right-0 mr-5 text-[#26890D]"
                          />
                        </button>
                        {MagazineAd && (
                          <Button
                            variant="text"
                            onClick={handleContentLocked}
                            sx={{
                              position: "absolute",
                              bottom: "0",
                              right: "0",
                              marginBottom: "40px",
                            }}
                          >
                            <img
                              src={require(`../assets/${
                                showingVertical
                                  ? verticalFlyerContentLocked
                                    ? "content-lock"
                                    : "content-unlock"
                                  : showingHorizontal
                                  ? horizontalFlyerContentLocked
                                    ? "content-lock"
                                    : "content-unlock"
                                  : showingSquare
                                  ? squareFlyerContentLocked
                                    ? "content-lock"
                                    : "content-unlock"
                                  : showingBannerVertical
                                  ? horizontalBannerContentLocked
                                    ? "content-lock"
                                    : "content-unlock"
                                  : verticalBannerContentLocked
                                  ? "content-lock"
                                  : "content-unlock"
                              }.jpg`)}
                              alt="locked-Icon"
                              className="relative mb-[1px] mr-[13px]"
                            />
                          </Button>
                        )}
                        <Tooltip
                          title="Send To Translate Tab"
                          sx={{
                            position: "absolute",
                            bottom: "0",
                            right: "0",
                            marginBottom: "5px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setInputText(
                                showingHorizontal
                                  ? sectionData.flyer?.["Flyer_Translated"]
                                  : showingVertical
                                  ? sectionData.flyerVertical?.[
                                      "Flyer_Translated"
                                    ]
                                  : showingSquare
                                  ? sectionData.flyerSquare?.[
                                      "Flyer_Translated"
                                    ]
                                  : showingBannerHorizontal
                                  ? sectionData.bannerHorizontal?.[
                                      "Flyer_Translated"
                                    ]
                                  : sectionData.bannerVertical?.[
                                      "Flyer_Translated"
                                    ]
                              );
                              setSelectedLanguage(
                                showingHorizontal
                                  ? translateLanguage.Flyer
                                  : showingVertical
                                  ? translateLanguage.FlyerVertical
                                  : showingSquare
                                  ? translateLanguage.FlyerSquare
                                  : showingBannerHorizontal
                                  ? translateLanguage.BannerHorizontal
                                  : translateLanguage.BannerVertical
                              );
                            }}
                          >
                            <img
                              src={require("../assets/send-to-translate.png")}
                              className="text-[#26890D]"
                              alt="Send to translate tab"
                            />
                          </Button>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        {(showingHorizontal && !isFlyerLoading) ||
                        (showingVertical && !isFlyerVerticalLoading) ||
                        (showingSquare && !isFlyerSquareLoading) ||
                        (showingBannerHorizontal &&
                          !isBannerHorizontalLoading) ||
                        (showingBannerVertical && !isBannerVerticalLoading) ? (
                          <p className="overflow-y-auto mt-[15px] mr-[60px] ml-[15px] mb-[10px] whitespace-break-spaces max-h-[110px]">
                            {responseValue}
                          </p>
                        ) : null}
                        {MagazineAd && (
                          <button onClick={handleEditClick}>
                            <img
                              src={require("../assets/edit-icon.png")}
                              className="absolute bottom-20 right-0 mr-5 text-[#26890D]"
                              alt="edit icon"
                            />
                          </button>
                        )}
                        {MagazineAd && (
                          <Button
                            variant="text"
                            onClick={handleContentLocked}
                            sx={{
                              position: "absolute",
                              bottom: "0",
                              right: "0",
                              marginBottom: "40px",
                            }}
                          >
                            <img
                              src={require(`../assets/${
                                showingVertical
                                  ? verticalFlyerContentLocked
                                    ? "content-lock"
                                    : "content-unlock"
                                  : showingHorizontal
                                  ? horizontalFlyerContentLocked
                                    ? "content-lock"
                                    : "content-unlock"
                                  : showingSquare
                                  ? squareFlyerContentLocked
                                    ? "content-lock"
                                    : "content-unlock"
                                  : showingBannerVertical
                                  ? verticalBannerContentLocked
                                    ? "content-lock"
                                    : "content-unlock"
                                  : horizontalBannerContentLocked
                                  ? "content-lock"
                                  : "content-unlock"
                              }.jpg`)}
                              alt="locked-Icon"
                              className="relative mb-[1px] "
                            />
                          </Button>
                        )}
                        {MagazineAd && (
                          <Tooltip
                            title="Send To Translate Tab"
                            sx={{
                              position: "absolute",
                              bottom: "0",
                              right: "0",
                              marginBottom: "5px",
                            }}
                          >
                            <Button
                              onClick={() => {
                                setInputText(
                                  showingHorizontal
                                    ? sectionData.flyer?.["Flyer_Translated"]
                                    : showingVertical
                                    ? sectionData.flyerVertical?.[
                                        "Flyer_Translated"
                                      ]
                                    : showingSquare
                                    ? sectionData.flyerSquare?.[
                                        "Flyer_Translated"
                                      ]
                                    : showingBannerHorizontal
                                    ? sectionData.bannerHorizontal?.[
                                        "Flyer_Translated"
                                      ]
                                    : sectionData.bannerVertical?.[
                                        "Flyer_Translated"
                                      ]
                                );
                                setSelectedLanguage(
                                  showingHorizontal
                                    ? translateLanguage.Flyer
                                    : showingVertical
                                    ? translateLanguage.FlyerVertical
                                    : showingSquare
                                    ? translateLanguage.FlyerSquare
                                    : showingBannerHorizontal
                                    ? translateLanguage.BannerHorizontal
                                    : translateLanguage.BannerVertical
                                );
                              }}
                            >
                              <img
                                src={require("../assets/send-to-translate.png")}
                                className="text-[#26890D]"
                                alt="send to translate"
                              />
                            </Button>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {MagazineAd && <AiGeneratedContent />}
                <div className="flex align-text-center mt-5">
                  <span className="text-black text-xs font-normal leading-4">
                    Ask for revisions
                  </span>
                </div>
                <div className="mt-2 flex flex-col sm:flex-row items-center justify-center w-full">
                  <TextField
                    label=""
                    fullWidth
                    value={revisedInfo}
                    onChange={(e) => setRevisedInfo(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      marginLeft: "30px",
                      width: "30%",
                      height: "100%",
                      marginTop: { xs: "10px", sm: "8px", md: 0 },
                    }}
                    onClick={() => {
                      revisedData(
                        revisedInfo,
                        0,
                        showingHorizontal
                          ? 0
                          : showingVertical
                          ? 1
                          : showingSquare
                          ? 2
                          : showingBannerHorizontal
                          ? 3
                          : 4
                      );
                      setRevisedInfo("");
                    }}
                  >
                    <img
                      src={require("../assets/revise-icon.png")}
                      className="mr-3"
                    />{" "}
                    Revise
                  </Button>
                </div>
                {/* <div>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      marginTop: "22px",
                      marginBottom: "20px",
                      color: "white",
                      bgcolor: "#468529",
                    }}
                    onClick={regenerateData}
                  >
                    <AutorenewIcon sx={{ marginRight: "5px" }} />
                    REGENERATE
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="self-start align-text-top">
              <p className="ml-2 mt-[15px] text-[#646859] text-[10px]">
                If you click the regenerate button, you will see updated content
                and generated images.
              </p>
            </div>
            <div className="mb-[19px]">
              <Button
                variant="contained"
                color="secondary"
                onClick={regenerateData}
                sx={{
                  width: "99%",
                  height: "44px",
                  marginLeft: "0.4%",
                  marginTop: "10px",
                  // color: "white",
                  // bgcolor: "#468529",
                }}
              >
                {" "}
                <img
                  src={require("../assets/Regenerate-Icon.png")}
                  className="mr-3"
                />
                REGENERATE
              </Button>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg flex sm:flex-row flex-col mt-5 mb-5 w-full">
            <div className="flex flex-col w-full sm:w-1/2">
              <div className="h-4 text-[#000]">PREPARE YOUR PRINTABLE FILE</div>
              <div className="flex flex-col h-max md:h-[7.5rem] mt-6 rounded-lg bg-gray-100 p-1.5 sm:p-0">
                <p className="mt-4 mb-2.5 sm:ml-9 text-base font-thin text-center sm:text-left">
                  Select Your Brand Color
                </p>
                <div className="flex flex-col md:flex-row w-[22.4rem] h-max gap-4  sm:ml-9">
                  <div
                    className="flex flex-row content-center justify-around items-center align-items-center w-[15.7rem] h-[3.2rem] rounded-lg relative bg-white"
                    ref={colorPickerRef}
                  >
                    <p className="text-sm mt-0">Primary Color</p>
                    {showPrimaryColorPicker && (
                      <div className="absolute bottom-[50px]">
                        <SketchPicker
                          color={
                            primaryColor[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ].hex
                          }
                          onChangeComplete={handlePrimaryColorChange}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        backgroundColor:
                          primaryColor[
                            showingHorizontal
                              ? 0
                              : showingVertical
                              ? 1
                              : showingSquare
                              ? 2
                              : showingBannerHorizontal
                              ? 3
                              : 4
                          ].hex,
                      }}
                      className={`w-[30px] h-[30px] rounded-[5px] ml-[10px]`}
                      onClick={handlePrimaryButton}
                    ></div>
                  </div>
                  <div
                    className="flex flex-row content-center justify-around rounded-lg items-center align-items-center w-[16rem] h-[50px] bg-white relative"
                    ref={colorSecondaryPickRef}
                  >
                    {showSecondaryColorPicker && (
                      <div className="absolute bottom-[50px] left-[30px]">
                        <SketchPicker
                          color={
                            secondaryColor[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ].hex
                          }
                          onChangeComplete={handleSecondaryColorChange}
                        />
                      </div>
                    )}
                    <p className="text-sm mt-0 ml-1">Secondary Color</p>
                    <div
                      style={{
                        backgroundColor:
                          secondaryColor[
                            showingHorizontal
                              ? 0
                              : showingVertical
                              ? 1
                              : showingSquare
                              ? 2
                              : showingBannerHorizontal
                              ? 3
                              : 4
                          ].hex,
                      }}
                      className={`w-[30px] h-[30px] rounded-md mr-1 bg-[${
                        secondaryColor[
                          showingHorizontal
                            ? 0
                            : showingVertical
                            ? 1
                            : showingSquare
                            ? 2
                            : showingBannerHorizontal
                            ? 3
                            : 4
                        ].hex
                      }]`}
                      onClick={handleSecondaryButton}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full ">
                <div className="flex flex-col w-full h-max sm:h-[7.5rem] bg-[#F6F6F6] mt-9 rounded-[10px]">
                  <div className="flex flex-col my-4 sm:my-0 sm:flex-row w-full gap-x-14">
                    <div className="flex flex-col justify-center items-center relative w-full">
                      <p className="font-thin">Upload Your Logo Here</p>
                      <div className="flex justify-center items-center h-5">
                        <p className="text-xs">
                          {
                            imageState[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ].logoFileName
                          }
                        </p>
                      </div>
                      <Button
                        component="label"
                        variant="contained"
                        color="secondary"
                        sx={{
                          width: "150px",
                          height: "30px",
                          fontSize: "10px",
                          // backgroundColor: "#468529",
                          // color: "#FFF",
                        }}
                      >
                        Browse files
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleLogoInputChange(e)}
                          className="w-px h-px"
                        />
                      </Button>
                    </div>
                    <div className="flex items-center justify-center bg-white w-full h-[90px] mt-4 rounded-2xl text-xs font-thin sm:mr-[10%]">
                      <p className="w-full mt-[10px] p-1 sm:p-2.5 text-center">
                        “ Please upload your high-resolution logo and receive
                        the finest print-ready file.”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:pl-5 relative w-full sm:w-1/2">
              {/* <div className="flex flex-row"> */}
              <div className="w-full h-4 text-black ">SELECT AD TEMPLATE</div>

              {/* <div>
                  <Button
                    aria-describedby={id}
                    onClick={handleClick}
                    style={{ color: "black" }}
                  >
                    Flyer
                    <ArrowDropDownIcon />
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      showingVertical: "bottom",
                      showingHorizontal: "left",
                    }}
                  >
                    <MenuItem>Vertical Banner</MenuItem>
                    <MenuItem>Horizontal Banner</MenuItem>
                  </Popover>
                </div> */}
              {/* </div> */}

              <div className="flex justify-center items-center w-full h-[17.2rem] bg-[#F6F6F6] mt-6 rounded-[10px]">
                <div
                  className={`grid w-full ${
                    showingBannerHorizontal || showingBannerVertical
                      ? "grid-cols-2 md:grid-cols-3 "
                      : "grid-cols-3"
                  }  gap-4 m-2 place-items-center`}
                >
                  {showingHorizontal &&
                    imagePathsHorizontal.map((path, index) => (
                      <div className="relative w-[150px]">
                        <img
                          src={path}
                          alt="loading"
                          className={`h-full w-full rounded-lg  ${
                            selectedTemplate[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ][index]
                              ? "border-3 border-green-500"
                              : "border border-black"
                          }`}
                          onClick={
                            () => handleImageClick(index)
                            // setSelectedTemplate([
                            //   ...selectedTemplate.slice(0, index),
                            //   !selectedTemplate[index],
                            //   ...selectedTemplate.slice(index + 1),
                            // ])
                          }
                        />
                        {selectedTemplate[
                          showingHorizontal
                            ? 0
                            : showingVertical
                            ? 1
                            : showingSquare
                            ? 2
                            : showingBannerHorizontal
                            ? 3
                            : 4
                        ] === index ? (
                          <input
                            type="checkbox"
                            className="absolute bottom-2.5 h-5 w-[25px] left-[120px]"
                            checked={true}
                            onChange={() => handleImageClick(index)}
                          />
                        ) : (
                          <Button
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 100,
                              color: "#676767",
                            }}
                            onClick={() =>
                              handleMagnify("uploaded", index, path)
                            }
                          >
                            <img
                              src={require("../assets/magnify.png")}
                              alt="magnify-Icon"
                            />
                          </Button>
                        )}
                      </div>
                    ))}
                  {showingVertical &&
                    imagePathsVertical.map((path, index) => (
                      <div className="flex justify-center items-center w-[5.6rem] relative ml-1 mr-1">
                        <img
                          src={path}
                          alt="loading"
                          className={`h-[90%] w-[90%] rounded-lg ${
                            selectedTemplate[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ][index]
                              ? "border-3 border-green-500"
                              : "border border-black"
                          }`}
                          onClick={
                            () => handleImageClick(index)
                            // setSelectedTemplate([
                            //   ...selectedTemplate.slice(0, index),
                            //   !selectedTemplate[index],
                            //   ...selectedTemplate.slice(index + 1),
                            // ])
                          }
                        />
                        {selectedTemplate[
                          showingHorizontal
                            ? 0
                            : showingVertical
                            ? 1
                            : showingSquare
                            ? 2
                            : showingBannerHorizontal
                            ? 3
                            : 4
                        ] === index ? (
                          <input
                            type="checkbox"
                            className="absolute bottom-2 w-4 h-4 left-16"
                            checked={true}
                            onChange={() => handleImageClick(index)}
                          />
                        ) : (
                          <Button
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 40,
                              color: "#676767",
                            }}
                            onClick={() =>
                              handleMagnify("uploaded", index, path)
                            }
                          >
                            <img
                              src={require("../assets/magnify.png")}
                              alt="magnify-Icon"
                            />
                          </Button>
                        )}
                      </div>
                    ))}
                  {showingSquare &&
                    imagePathsSquare.map((path, index) => (
                      <div className="flex justify-center items-center w-[150px] relative ml-[10px]">
                        <img
                          src={path}
                          alt="loading"
                          className={`h-4/5 w-4/5 rounded-lg ${
                            selectedTemplate[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ][index]
                              ? "border-3 border-green-500"
                              : "border border-black"
                          }`}
                          onClick={() => handleImageClick(index)}
                        />
                        {selectedTemplate[
                          showingHorizontal
                            ? 0
                            : showingVertical
                            ? 1
                            : showingSquare
                            ? 2
                            : showingBannerHorizontal
                            ? 3
                            : 4
                        ] === index ? (
                          <input
                            type="checkbox"
                            className={`absolute bottom-2.5 ${
                              showingSquare ? "left-28" : "left-[90px]"
                            } w-4 h-4`}
                            checked={true}
                            onChange={() => handleImageClick(index)}
                          />
                        ) : (
                          <Button
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: showingSquare ? 85 : 65,
                              color: "#676767",
                            }}
                            onClick={() =>
                              handleMagnify("uploaded", index, path)
                            }
                          >
                            <img
                              src={require("../assets/magnify.png")}
                              alt="magnify-Icon"
                            />
                          </Button>
                        )}
                      </div>
                    ))}
                  {showingBannerHorizontal &&
                    imagePathsBannerHorizontal.map((path, index) => (
                      <div className="flex justify-center items-center w-48 h-[120px] relative rounded-lg bg-gray-200 ">
                        <img
                          src={path}
                          alt="loading"
                          className={`h-5 w-4/5 border ${
                            selectedTemplate[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ][index]
                              ? "border-green-500 border-3"
                              : "border-black border"
                          }`}
                          onClick={
                            () => handleImageClick(index)
                            // setSelectedTemplate([
                            //   ...selectedTemplate.slice(0, index),
                            //   !selectedTemplate[index],
                            //   ...selectedTemplate.slice(index + 1),
                            // ])
                          }
                        />
                        {selectedTemplate[
                          showingHorizontal
                            ? 0
                            : showingVertical
                            ? 1
                            : showingSquare
                            ? 2
                            : showingBannerHorizontal
                            ? 3
                            : 4
                        ] === index ? (
                          <input
                            type="checkbox"
                            className="absolute bottom-5 left-[85px] w-[14%] h-[14%]"
                            checked={true}
                            onChange={() => handleImageClick(index)}
                          />
                        ) : (
                          <Button
                            sx={{
                              position: "absolute",
                              bottom: 10,
                              left: 65,
                              color: "#676767",
                            }}
                            onClick={() =>
                              handleMagnify("uploaded", index, path)
                            }
                          >
                            <img
                              src={require("../assets/magnify.png")}
                              alt="magnify-Icon"
                            />
                          </Button>
                        )}
                      </div>
                    ))}
                  {showingBannerVertical &&
                    imagePathsBannerVertical.map((path, index) => (
                      <div className="flex justify-center items-center w-48 h-[120px] relative bg-gray-200 rounded-lg">
                        <img
                          src={path}
                          alt="loading"
                          className={`h-[80%] w-5 ${
                            selectedTemplate[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ][index]
                              ? "border-3 border-green-500"
                              : "border border-black"
                          }`}
                          onClick={() => handleImageClick(index)}
                        />
                        {selectedTemplate[
                          showingHorizontal
                            ? 0
                            : showingVertical
                            ? 1
                            : showingSquare
                            ? 2
                            : showingBannerHorizontal
                            ? 3
                            : 4
                        ] === index ? (
                          <input
                            type="checkbox"
                            className="absolute bottom-12 left-[120px] w-[14%] h-[14%]"
                            checked={true}
                            onChange={() => handleImageClick(index)}
                          />
                        ) : (
                          <Button
                            sx={{
                              position: "absolute",
                              bottom: 40,
                              left: 100,
                              color: "#676767",
                            }}
                            onClick={() =>
                              handleMagnify("uploaded", index, path)
                            }
                          >
                            <img
                              src={require("../assets/magnify.png")}
                              alt="magnify-Icon"
                            />
                          </Button>
                        )}
                      </div>
                    ))}
                </div>
              </div>
              <div
                className={` ${
                  showingBannerHorizontal || showingBannerVertical
                    ? "mt-16 sm:mt-4"
                    : "mt-4"
                } flex justify-end`}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    // backgroundColor: "#468529",
                    width: "200px",
                    //color: "#FFF",
                  }}
                  onClick={() => {
                    if (
                      previewImage[
                        showingHorizontal
                          ? 0
                          : showingVertical
                          ? 1
                          : showingSquare
                          ? 2
                          : showingBannerHorizontal
                          ? 3
                          : 4
                      ].length
                    ) {
                      if (showingHorizontal) {
                        setPreviewButtonClicked([
                          true,
                          ...previewButtonClicked.slice(1),
                        ]);
                      }
                      if (showingVertical) {
                        setPreviewButtonClicked([
                          previewButtonClicked[0],
                          true,
                          ...previewButtonClicked.slice(2),
                        ]);
                      }
                      if (showingSquare) {
                        setPreviewButtonClicked([
                          previewButtonClicked[0],
                          previewButtonClicked[1],
                          true,
                          ...previewButtonClicked.slice(3),
                        ]);
                      }
                      if (showingBannerHorizontal) {
                        const index = 3;
                        setPreviewButtonClicked([
                          ...previewButtonClicked.slice(0, index),
                          true,
                          ...previewButtonClicked.slice(index + 1),
                        ]);
                      }
                      if (showingBannerVertical) {
                        const index = 4;
                        setPreviewButtonClicked([
                          ...previewButtonClicked.slice(0, index),
                          true,
                          ...previewButtonClicked.slice(index + 1),
                        ]);
                      }
                    } else {
                      flyerPreview(
                        {
                          headline:
                            flyerHeadline[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ],
                          subtext:
                            flyerSubtext[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ],
                          // image: chosenImage,
                          image: generatedClicked
                            ? chosenImage[
                                showingHorizontal
                                  ? 0
                                  : showingVertical
                                  ? 1
                                  : showingSquare
                                  ? 2
                                  : showingBannerHorizontal
                                  ? 3
                                  : 4
                              ]
                            : !imageState[
                                showingHorizontal
                                  ? 0
                                  : showingVertical
                                  ? 1
                                  : showingSquare
                                  ? 2
                                  : showingBannerHorizontal
                                  ? 3
                                  : 4
                              ].uploadedBase64
                            ? ""
                            : imageState[
                                showingHorizontal
                                  ? 0
                                  : showingVertical
                                  ? 1
                                  : showingSquare
                                  ? 2
                                  : showingBannerHorizontal
                                  ? 3
                                  : 4
                              ].uploadedBase64.substring(
                                imageState[
                                  showingHorizontal
                                    ? 0
                                    : showingVertical
                                    ? 1
                                    : 2
                                ].uploadedBase64.indexOf(",") + 1
                              ),
                          logo: imageState[
                            showingHorizontal
                              ? 0
                              : showingVertical
                              ? 1
                              : showingSquare
                              ? 2
                              : showingBannerHorizontal
                              ? 3
                              : 4
                          ].logoBase64.substring(
                            imageState[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ].logoBase64.indexOf(",") + 1
                          ),
                          primarycolour: {
                            r: primaryColor[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ].rgb["r"],
                            g: primaryColor[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ].rgb["g"],
                            b: primaryColor[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ].rgb["b"],
                          },
                          secondarycolour: {
                            r: secondaryColor[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ].rgb["r"],
                            g: secondaryColor[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ].rgb["g"],
                            b: secondaryColor[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ].rgb["b"],
                          },
                          template_id:
                            selectedTemplate[
                              showingHorizontal
                                ? 0
                                : showingVertical
                                ? 1
                                : showingSquare
                                ? 2
                                : showingBannerHorizontal
                                ? 3
                                : 4
                            ] === -1
                              ? ""
                              : (
                                  selectedTemplate[
                                    showingHorizontal
                                      ? 0
                                      : showingVertical
                                      ? 1
                                      : showingSquare
                                      ? 2
                                      : showingBannerHorizontal
                                      ? 3
                                      : 4
                                  ] + 1
                                ).toString(),
                        },
                        showingHorizontal
                          ? 0
                          : showingVertical
                          ? 1
                          : showingSquare
                          ? 2
                          : showingBannerHorizontal
                          ? 3
                          : 4
                      );
                    }
                  }}
                  disabled={MagazineAd === undefined}
                >
                  Preview
                </Button>
              </div>
            </div>
          </div>
          <div>
            <SaveCard
              download={false}
              handleSaveButtonClicked={handleSaveButtonClicked}
              handleSaveProjectButtonClicked={handleSaveProjectButtonClicked}
              content={MagazineAd}
            />
          </div>
          {/* <Translate /> */}
        </div>
      </div>
    </div>
  );
};
export default Flyer;
