import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import "./SavedWorkCard.css";

const SavedWorkCard = ({
  key,
  tabName,
  tabKey,
  draft,
  handleResumeWork,
  handleDeleteDraft,
  tabNameForResume,
  setTabNameForResume,
  tabTypeForResume,
  setTabTypeForResume,
  fileNameForResume,
  setFileNameForResume,
  setShowResumeCard,
}) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateAndTime = new Date(draft["timestamp"]);
  let timeStamp = "";
  let hour = dateAndTime.getHours();
  let meridian = "";
  let minute = dateAndTime.getMinutes();
  if (hour >= 12) {
    meridian = "pm";
    hour = hour % 12 === 0 ? 12 : hour % 12;
  } else {
    meridian = "am";
    hour = hour === 0 ? 12 : hour;
  }
  if (minute < 10) {
    minute = "0" + minute.toString();
  } else {
    minute = minute.toString();
  }
  timeStamp =
    dateAndTime.getDate() +
    " " +
    months[dateAndTime.getMonth()] +
    ", " +
    hour.toString() +
    ":" +
    minute +
    meridian;

  const getContent = (draft, key) => {
    if (key === "persona") {
      return draft["persona"];
    }
    if (key === "email") {
      return draft["email"];
    }
    if (key === "magazine_ad") {
      return draft["ad"];
    }
    if (key === "social_media") {
      return draft["caption"];
    }
    if (key === "creative_concept") {
      return draft["creative_concept"];
    }
    return draft["ad"];
  };
  const content = useMemo(() => getContent(draft, tabKey), [draft, tabKey]);
  return (
    <div className="card flex flex-col">
      <div className="flex justify-between w-full mr-1 pt-2 pl-2 pr-2">
        <div
          className="overflow-auto w-36 whitespace-nowrap"
          
        >
          <Typography>{draft["name"]}</Typography>
        </div>
        <div>
          <Typography
            sx={{
              // marginLeft: "4rem",
              marginTop: "0.40rem",
              fontFamily: "Open Sans",
              fontSize: "0.75rem",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "0.875rem",
              color: "#676767",
            }}
          >
            {timeStamp}
          </Typography>
        </div>
      </div>
      <Divider
        sx={{
          bgcolor: "#86BC25",
          marginTop: "0.4rem",
          marginBottom: "0.4rem",
          width: "95%",
          marginLeft: "0.47rem",
        }}
      />
      {draft["image"] && (
        <div className="content-img ml-2 mb-2">
          <img id="image" src={`data:image/png;base64,${draft["image"]}`} />
        </div>
      )}
      <div className="content-text overflow-y-auto p-2">{content}</div>
      <Divider
        sx={{
          bgcolor: "#86BC25",
          marginTop: "0.4rem",
          marginBottom: "0.4rem",
          width: "95%",
          marginLeft: "0.47rem",
        }}
      />
      <div className="deletes-resume flex w-full items-center justify-between ml-2 mr-2 mb-2">
        <IconButton
          sx={{ paddingLeft: 0, paddingRight: 0 }}
          onClick={() => {
            if (tabKey === "flyer_horizontal")
              handleDeleteDraft("flyer", draft["name"], "Horizontal");
            else if (tabKey === "flyer_vertical")
              handleDeleteDraft("flyer", draft["name"], "Vertical");
            else if (tabKey === "flyer_square")
              handleDeleteDraft("flyer", draft["name"], "Square");
            else if (tabKey === "banner_horizontal")
              handleDeleteDraft("banner", draft["name"], "Horizontal");
            else if (tabKey === "banner_vertical")
              handleDeleteDraft("banner", draft["name"], "Vertical");
            else handleDeleteDraft(tabKey, draft["name"]);
          }}
        >
          <img src={require("../assets/delete-icon.png")}></img>
        </IconButton>
        <Button
          sx={{
            color: "white",
            bgcolor: "#26890d",
            padding: "0.375rem 0.625rem 0.375rem 0.625rem",
            marginRight: "1.1rem",
          }}
          variant="contained"
          onClick={() => {
            setShowResumeCard(true);
            if (tabKey === "flyer_horizontal") {
              setTabNameForResume("flyer");
              setFileNameForResume(draft["name"]);
              setTabTypeForResume("Horizontal");
            } else if (tabKey === "flyer_vertical") {
              setTabNameForResume("flyer");
              setFileNameForResume(draft["name"]);
              setTabTypeForResume("Vertical");
            } else if (tabKey === "flyer_square") {
              setTabNameForResume("flyer");
              setFileNameForResume(draft["name"]);
              setTabTypeForResume("Square");
            } else if (tabKey === "banner_horizontal") {
              setTabNameForResume("banner");
              setFileNameForResume(draft["name"]);
              setTabTypeForResume("Horizontal");
            } else if (tabKey === "banner_vertical") {
              setTabNameForResume("banner");
              setFileNameForResume(draft["name"]);
              setTabTypeForResume("Vertical");
            } else {
              setTabNameForResume(tabKey);
              setFileNameForResume(draft["name"]);
            }
          }}
        >
          Resume Work
        </Button>
      </div>
    </div>
  );
};

export default SavedWorkCard;
