import React, { useState } from "react";
import "./SavedTabsPanel.css";

const SavedTabsPanel = ({ tabName, tabKey, handleTabClicked, selectedTab}) => {
  const [clicked, setClicked] = useState(false);
  return (
    <div
      onClick={() => {
        handleTabClicked(tabKey)
      }}
      className={`box flex justify-start items-center ${
        selectedTab === tabKey
          ? "text-white bg-[#26890D]"
          : "text-[#26890D] bg-white"
      }`}
    >
      {tabName}
    </div>
  );
};

export default SavedTabsPanel;
