import { React } from "react";
import { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { Button, TextField, Tooltip } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";

const Card = (props) => {
  const [responseValue, setResponseValue] = useState("");
  const [showEditArea, setShowEditArea] = useState([false, false, false]);
  const [editIconVisibility, setEditIconVisibility] = useState([
    true,
    true,
    true,
  ]);
  useEffect(() => setResponseValue(props.text), [props.text]);

  const renderPrintText = (text) => {
    if (text) {
      const lines = text.split("\n");
      lines.pop();
      const result = [];
      lines.forEach((line) => {
        let [key, ...desc] = line.split(":");
        desc = desc.join(":");
        result.push(<strong>{key} : </strong>);
        result.push(desc + "\n");
      });
      return result;
    } else return text;
  };
  const handleIconClick = () => {
    if (props.index !== undefined) {
      if (
        props.contentLocked[props.selectedTitle] !== undefined &&
        props.contentLocked[props.selectedTitle][props.index]
      ) {
        return;
      }
    } else {
      if (
        props.contentLocked[props.selectedTitle] !== undefined &&
        props.contentLocked[props.selectedTitle]
      ) {
        return;
      }
    }
    if (props.setEditIconIsClicked !== undefined) {
      props.setEditIconIsClicked((prev) => !prev);
    }
    setEditIconVisibility((prevState) => {
      const newPrevState = prevState;
      newPrevState[props.selectedTitle] = !prevState[props.selectedTitle];
      return newPrevState;
    });
    if (showEditArea[props.selectedTitle]) {
      props.index !== undefined
        ? props.handleSavingResponseValue(responseValue, props.index)
        : props.handleSavingResponseValue(responseValue);
      if (
        props.activeTab &&
        (props.activeTab === "Print" ||
          (props.activeTab === "Creative Concepts" &&
            props.activeTab !== "Social Media")) &&
        responseValue !== props.text
      ) {
        props.index !== undefined && props.activeTab !== "Social Media"
          ? props.editedData(responseValue, props.index)
          : props.editedData(responseValue);
      }
      setShowEditArea((prevState) => {
        const newPrevState = prevState;
        newPrevState[props.selectedTitle] = false;
        return newPrevState;
      });
    } else {
      setShowEditArea((prevState) => {
        const newPrevState = prevState;
        newPrevState[props.selectedTitle] = true;
        return newPrevState;
      });
    }
  };
  const handleEditChange = (e) => {
    setResponseValue(e.target.value);
  };
  const handleContentLocked = () => {
    props.index !== undefined
      ? props.setContentLocked((prevState) => {
          let contentLocked = prevState;
          contentLocked[props.selectedTitle][props.index] =
            !prevState[props.selectedTitle][props.index];
          return contentLocked;
        })
      : props.setContentLocked((prevState) => {
          const newPrevState = prevState;
          newPrevState[props.selectedTitle] = !prevState[props.selectedTitle];
          return newPrevState;
        });
  };

  return (
    <div className="h-100 bg-white pl-4 pr-1 pb-4 border-2 border-gray-200 rounded-lg w-full relative">
      <div className="flex flex-col sm:flex-row space-x-4">
        {showEditArea[props.selectedTitle] ? (
          <TextField
            variant="standard"
            sx={{
              width: "100%",
              minHeight: "14.375rem",
              maxHeight: "26.875rem",
              overflow: "auto",
              "& fieldset": { border: "none" },
              marginTop: "0.625rem",
            }}
            fullwidth
            multiline
            defaultValue={props.text}
            onChange={handleEditChange}
            InputProps={{
              disableUnderline: true,
              sx: {
                color: "#000",
                fontFamily: "Open Sans, sans-serif",
                fontSize: "0.875rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "1.875rem",
              },
            }}
            inputRef={(input) => input && input.focus()}
            onFocus={(e) =>
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              )
            }
          />
        ) : (
          <div className="w-full overflow-auto whitespace-normal text-black text-sm font-normal leading-[30px] min-h-[230px] max-h-[430px]">
            {!props.isLoading && props.activeTab === "Print"
              ? props.text && (
                  <p className="whitespace-break-spaces">
                    {responseValue
                      ? renderPrintText(responseValue)
                      : renderPrintText(props.text)}
                  </p>
                )
              : props.text && (
                  <p className="whitespace-break-spaces">
                    {responseValue
                      ? props.activeTab != "Social Media" &&
                        props.index !== undefined
                        ? `Concept Territory ${props.index + 1}\n` +
                          responseValue
                        : responseValue
                      : props.activeTab != "Social Media" &&
                        props.index !== undefined
                      ? `Concept Territory ${props.index + 1}\n` + props.text
                      : props.text}
                  </p>
                )}
          </div>
        )}
        <div className="sm:pl-4 flex flex-row justify-between sm:flex-col sm:justify-end items-center">
          {editIconVisibility[props.selectedTitle] && props.text ? (
            <div>
              <Button variant="text" onClick={handleIconClick}>
                <img
                  src={require("../assets/edit-icon.png")}
                  className=" mb-4 sm:mb-[1.063rem]  mt-2 sm:mt-4"
                  alt="edit icon"
                />
              </Button>
            </div>
          ) : (
            props.text && (
              <Button variant="text" onClick={handleIconClick}>
                <img
                  src={require("../assets/saveIcon.png")}
                  alt="Save Icon"
                  className="mb-4"
                />
              </Button>
            )
          )}
          {props.text &&
            props.contentLocked[props.selectedTitle] !== undefined && (
              <Button
                onClick={handleContentLocked}
                disabled={!editIconVisibility[props.selectedTitle]}
              >
                {!editIconVisibility[props.selectedTitle] ? (
                  <img
                    src={require(`../assets/disabledLock.png`)}
                    alt="disabled-lock"
                    className="mb-4 text-gray-500 h-[1.5rem] w-[1.5rem]"
                  />
                ) : (
                  <img
                    src={require(`../assets/${
                      props.index !== undefined
                        ? props.contentLocked[props.selectedTitle][props.index]
                          ? "content-lock"
                          : "content-unlock"
                        : props.contentLocked[props.selectedTitle]
                        ? "content-lock"
                        : "content-unlock"
                    }.jpg`)}
                    alt="locked-Icon"
                    className="mb-4 mt-2 text-gray-500"
                  />
                )}
              </Button>
            )}
          {props.text && (
            <div className="mr-4 sm:mr-0">
              <Tooltip title="Send To Translate Tab">
                <Button
                  onClick={() => {
                    props.setInputText(props.text);
                    props.setSelectedLanguage(
                      props.translateLanguage?.[props.activeTab][
                        props.selectedTitle
                      ]
                    );
                  }}
                  disabled={!editIconVisibility[props.selectedTitle]}
                >
                  {!editIconVisibility[props.selectedTitle] ? (
                    <img
                      src={require("../assets/disabledTranslate.png")}
                      className=" mb-[10px] mt-px h-[1.5rem] w-[1.5rem]"
                      alt="disabled-send-to-translate"
                    />
                  ) : (
                    <img
                      src={require("../assets/send-to-translate.png")}
                      className=" mb-[10px] mt-px"
                      alt="Send to translate tab"
                    />
                  )}
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
