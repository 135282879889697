import React, { useState, useEffect, useRef } from "react";
import "./FileTracker.css";
import downloadIcon from "../assets/download-icon.png";
import reload from "../assets/Group.png";
import { getIdToken } from "../utils/translateHelper";
import { Button } from "@mui/material";
import Badge from "@mui/material/Badge";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import downloadSingleLanguageIcon from "../assets/singleLanguageDownloadIcon.png";
import {
  URL,
  DOWNLOAD_ALL_FILES,
  DOWNLOAD_SINGLE_FILE,
  DELETE_FILE,
} from "../utils/constants";
import CustomModal from "./Shared/CustomModal";
import WarningIcon from "@mui/icons-material/Warning";

const FileTracker = ({
  UploadedFiles,
  filesData,
  isClicked,
  fileCount,
  setFileCount,
}) => {
  const [formDisplay, setFormDisplay] = useState(false);
  const [expandedFiles, setExpandedFiles] = useState({});
  const [isColumnLayout, setColumnLayout] = useState(false);
  const chatContainerRef = useRef(null);

  const toggleLanguages = (index) => {
    setColumnLayout(!isColumnLayout);
    setExpandedFiles((prevExpandedFiles) => ({
      ...prevExpandedFiles,
      [index]: !prevExpandedFiles[index],
    }));
  };

  const handleButtonClick = () => {
    setFormDisplay(!formDisplay);
  };

  const handleCloseClick = () => {
    setFormDisplay(false);
    setFileCount(0);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("overlay")) {
      setFormDisplay(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        formDisplay &&
        chatContainerRef.current &&
        !chatContainerRef.current.contains(event.target)
      ) {
        setFormDisplay(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [formDisplay]);

  async function downloadFile(
    folderName,
    fileName,
    isTranslation,
    sourceLanguage
  ) {
    const idToken = await getIdToken();
    const postData = {
      folder_name: folderName,
      is_translation: isTranslation,
      source_language: sourceLanguage,
    };
    try {
      const response = await fetch(URL + DOWNLOAD_ALL_FILES, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error(`Download failed with status ${response.status}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file:", error.message);
    }
  }

  const DeleteList = async (taskId) => {
    const idToken = await getIdToken();

    const postData = {
      task_id: taskId,
    };
    try {
      await axios.post(URL + DELETE_FILE, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
      });
      UploadedFiles();
    } catch (error) {
      console.error("Error downloading file:", error.message);
    }
  };

  const languageFileDownload = async (language, fileName, file_name) => {
    const idToken = await getIdToken();
    const postData = {
      language: language,
      folder_name: fileName,
    };
    try {
      const response = await fetch(URL + DOWNLOAD_SINGLE_FILE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error(`Download failed with status ${response.status}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${file_name}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file:", error.message);
    }
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <>
      <div className="chatButton">
        <div className="flex align-end justify-end mr-5 mt-20">
          <Badge badgeContent={fileCount} color="error">
            <Button
              sx={{ width: "115px", padding: "10px 16px" }}
              variant="contained"
              color="secondary"
              onClick={handleButtonClick}
            >
              Track File
            </Button>
          </Badge>
        </div>
        {formDisplay && (
          <div className="overlay" onClick={handleOverlayClick} />
        )}
        <div
          className={` ${
            formDisplay ? "chat-container open" : "chat-container close"
          }`}
        >
          <div className="text-white font-bold py-2 px-4 chat-heading ">
            <h1 className="w-full">Track Files</h1>

            <div className="flex items-center w-full space-x-8">
              <div
                className={`reload-icon  ${isClicked ? "glow" : ""}`}
                onClick={UploadedFiles}
              >
                <img
                  src={reload}
                  alt="reload"
                  className="w-[27.5px] h-[22px] ml-[17px] flex items-center  cursor-pointer"
                />
              </div>

              <div onClick={() => setIsDeleteModalOpen(true)}>
                <img
                  className="DeleteAllList-Icon w-6 ml-[17px] cursor-pointer"
                  src={require("../assets/DeleteAllList-Icon.png")}
                  alt="Delete-Icon"
                />
              </div>

            <Button className="cancel-chat-button" onClick={handleCloseClick}>
              <CancelIcon sx={{ color: "white" }} />
            </Button>
          </div>
        </div>
        <div className="file-container">
          {filesData?.map((fileData, index) => (
            <div key={index}>
              {fileData?.status === "FAILED" ? (
                <div
                  key={index}
                  
                  className="file-list-failed m-4"
                >
                  <div
                    onClick={() => DeleteList(fileData.taskId)}
                    className="flex flex-row-reverse -mt-2.5 mb-1.5 cursor-pointer"
                  >
                    <img
                      className="Delete-single-icon"
                      src={require("../assets/delete-icon-failedList.png")}
                      alt="Delete-Icon"
                    />
                  </div>
                  <div className="file-data">
                    <div className="flex items-center">
                      <img
                        className="file-icon"
                        src={require("../assets/file-icon.png")}
                        alt="File Icon"
                      />
                      <label>
                        <p className="file-name-failed">{fileData.fileName}</p>
                      </label>
                    </div>
                  </div>
                  <div>
                    <p className="failed-status-text">Proccessed Failed</p>
                  </div>
                </div>
              ) : (
                <div
                  key={index}
                
                  className={`m-4 ${
                    fileData.progress >= 100
                      ? "file-list-completedProgress"
                      : "file-list"
                  }`}
                >
                  <div
                    onClick={() => DeleteList(fileData.taskId)}
                    
                    className="flex flex-row-reverse mt-[-10px] mb-[5px] cursor-pointer"
                  >
                    {fileData.progress >= 100 ? (
                      <img
                        className="Delete-single-icon"
                        src={require("../assets/Delete-Icon-Vector.png")}
                        alt="Delete-Icon"
                      />
                    ) : (
                      <img
                        className="Delete-single-icon-Pending"
                        src={require("../assets/Vector-delete-icon-Pending.png")}
                        alt="Delete-Icon"
                      />
                    )}
                  </div>
                  <div className="file-data">
                    <div className="flex items-center">
                      <img
                        className="file-icon"
                        src={require("../assets/file-icon.png")}
                        alt="File Icon"
                      />
                      <label>
                        <p className="file-name">{fileData.createdAt}</p>
                      </label>
                    </div>
                  </div>
                  <div className="button-container">
                    {fileData.progress >= 100 && (
                      <button
                        className="download-File-Button"
                        onClick={() =>
                          downloadFile(
                            fileData.originalFileName,
                            fileData.fileName
                          )
                        }
                      >
                        <img src={downloadIcon} alt="Download" />
                      </button>
                    )}
                  </div>
                  <div>
                    {fileData.progress >= 100 ? (
                      <div>
                        <p className="file-uploaded-successfully">
                          {fileData.totalFiles} files Processed.
                        </p>
                        <div className="flex flex-wrap" >
                          {fileData?.targetLanguage
                            ?.slice(0, expandedFiles[index] ? undefined : 3)
                            .map((language, languageIndex) => (
                              <div
                                key={languageIndex}
                                
                                className="flex flex-row"
                              >
                                <Button
                                  onClick={() =>
                                    languageFileDownload(
                                      language,
                                      fileData.originalFileName,
                                      fileData.fileName
                                    )
                                  }
                                  
                                  className="rounded-[100px] border border-[var(--Band,#86BC25)] bg-white py-1 px-2.5 text-[#6D6D6D]  text-sm font-normal leading-[15px]"
                                >
                                  {language}
                                  <img
                                    src={downloadSingleLanguageIcon}
                                    alt="Download"
                                    
                                    className="w-[15px] ml-[5px]"
                                  />
                                </Button>
                              </div>
                            ))}
                          {fileData?.targetLanguage?.length > 3 && (
                            <Button
                              onClick={() => toggleLanguages(index)}
                              sx={{
                                borderRadius: "100px",
                                border: "0.5px solid var(--Band, #86BC25)",
                                background: " #FFF",
                                padding: "4px 10px",
                                gap: "4px",
                                color: "#6D6D6D",
                                fontFamily: "Open Sans",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "15px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {expandedFiles[index]
                                ? "-"
                                : `+${
                                    fileData?.targetLanguage?.length - 3
                                  } more`}
                            </Button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {fileData.totalFiles > 1 ? (
                          <p className="file-uploaded">
                            {fileData.totalFiles} files Processing...
                          </p>
                        ) : (
                          <p className="file-uploaded">
                            Your file is Processing...
                          </p>
                        )}
                        <div className="progressbar-container">
                          <progress
                            className="progressbar"
                            value={fileData.progress + 1}
                            max="100"
                          ></progress>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default FileTracker;
