import { Button, TextField, Typography } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { Clear, Add, Handshake } from "@mui/icons-material";
import React from "react";
import ModelTable from "../components/ModelTable";
import BackDrop from "../components/BackDrop";
import { startTraining,checkTrainingStatus,getModelData,getModelPresignedUrls } from "../utils/helper";
const ModelTraining = () => {
  const [productName, setProductName] = useState("");
  const [images, setImages] = useState([]);
  const dropRef = useRef(null);
  const fileInputRef = useRef(null);
  const[files,setFiles]=useState([])
  const [modelPage, setModelPage] = useState("main");
  const [showModel, setShowModel] = useState(false)
  const [isModelLoading,setIsModelLoading]=useState(false)
  const [currentStatus,setCurrentStatus]=useState(0)
  const onFileChange = async(e) => {
    setFiles([...e.target.files]);
    const filesArray = Array.from(e.target.files);
    const base64Files = await Promise.all(filesArray.map(fileToBase64));

    setImages((prevImages) => [...prevImages, ...base64Files]);
  };
  const fetchStatus = () => {
    const folderId = localStorage.getItem("folder_id");
    const model = localStorage.getItem("model");
    checkTrainingStatus(folderId, model,setCurrentStatus);
  };
  useEffect(()=>{
   
    fetchStatus()
    const intervalId = setInterval(fetchStatus, 5 * 60 * 1000);
    return () => clearInterval(intervalId);

  },[])
  useEffect(()=>{
    if(modelPage=="successStarted" && currentStatus=="FAILED"){
      setModelPage("successFailed")
    }
    if(modelPage=="successStarted" && currentStatus=="SUCCESS"){
       setModelPage("main")
    }

  },[currentStatus])
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const addFile = (e) => {
    setFiles([...images, e.target.files[0]]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImages([...images,reader.result]);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    let div = dropRef.current;

    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      let files = e.dataTransfer.files;
      if (files && files.length > 0) {
        const file = files[0];
        //setFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          //setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
  });
  const handleSubmit =()=>{
      getModelPresignedUrls(files,images,productName,setModelPage,setIsModelLoading)
  }
  return (
    <div className="flex flex-col w-full h-screen">
      {
        isModelLoading &&  <BackDrop type={"fullpage"}></BackDrop>
      }
      <div className="h-[10%] bg-white flex items-center justify-between px-8">
        <img
          src={require("../assets/NEW_APP_LOGO.png")}
          alt="header"
          className="w-[15%]"
        />
        <img src={require("../assets/Vector.jpg")}/>
      </div>
      <div className="w-full h-[90%] flex flex-col bg-[#F2F2F2] ">
        <div className="flex justify-end pr-24 pt-4">
          <Button variant="contained" color="secondary" sx={{background:"white" ,color:"#86BC25",borderColor:"#86BC25",borderWidth: '2px',  borderStyle: 'solid', boxShadow: 'none'}} 
          onClick={()=>{setShowModel(true)}}
          >
            Model History
          </Button>
        </div>
        <div className="justify-center items-center flex flex-col w-full h-[85%]">
          <div className="h-[90%] w-[90%] rounded-2xl flex flex-col border">
            <div className="w-full bg-[#86BC25] flex h-[10%] items-center pl-4 rounded-tl-2xl rounded-tr-2xl">
              <Typography
                variant="subtitle1"
                sx={{ color: "white", fontWeight: "bold" }}
              >
                {" "}
                Train Custom Model
              </Typography>
            </div>
            {modelPage == "main" && (
              <div className="h-[90%] w-full bg-white flex flex-col px-24 py-16 space-y-4 rounded-bl-2xl rounded-br-2xl">
                <div>What is the name of the product you are training on?</div>
                <TextField onChange={(e) => setProductName(e.target.value)} />
                {files.length < 1 ? (
                  <div className="border border-[#BEBEBE] flex flex-col h-[24rem] w-full items-center  justify-center space-y-2 ">
                    <img src={require("../assets/Inbox.jpg")} alt="Inbox" />
                    <div>
                      Drag and Drop Image here or{" "}
                      <input
                        id="file-upload"
                        accept=".jpg, .png, .jpeg, .webp"
                        ref={fileInputRef}
                        type="file"
                        onChange={onFileChange}
                        hidden
                        multiple
                        className="cursor-pointer text-green-500"
                      />
                      <label
                        htmlFor="file-upload"
                        className="cursor-pointer text-[#458A25]"
                      >
                        Browse Files
                      </label>
                    </div>
                    <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                      In order to train the model you need 20-25 images
                    </Typography>
                  </div>
                ) : (
                  <div className="border border-[#BEBEBE] flex flex-col h-[17rem] py-4 pl-4 pr-12">
                    <div className=" h-[14rem] w-full grid grid-cols-5 gap-x-4 gap-y-2 ">
                      {files.map((item) => {
                        return (
                          <div
                            className="h-[2rem] w-[16rem] border border-gray rounded-xl text-sm flex px-2"
                            key={item.name}
                          >
                            <div className="w-[90%] whitespace-nowrap text-ellipsis overflow-hidden">
                              {item.name}
                            </div>
                            <div>
                              <Clear onClick={() => {}} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="flex justify-end h-[3rem] text-[#26890D]">
                      <input
                        id="file-upload"
                        accept=".jpg, .png, .jpeg, .webp"
                        ref={fileInputRef}
                        onChange={addFile}
                        type="file"
                        hidden
                        className="cursor-pointer text-green-500"
                      />
                      <label
                        htmlFor="file-upload"
                        className="cursor-pointer text-[#26890D]"
                      >
                        <Add /> Add
                      </label>
                    </div>
                  </div>
                )}
                <div className="flex justify-end">
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={images.length < 1 || !productName}
                    onClick={() => setModelPage("confirm")}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
            {modelPage == "confirm" && (
              <div className="h-[90%] w-full bg-white flex flex-col p-28 space-y-4  rounded-bl-2xl rounded-br-2xl">
                <div>Model Name</div>
                <div className="bg-[#D0D0CE] h-[3rem] w-full border  px-4 flex items-center">
                  {productName || "custom"}
                </div>
                <div className="border border-[#BEBEBE] flex flex-col h-[20rem] w-full items-center  justify-center space-y-2 ">
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {images.length} images uploaded
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                    Your model will be available after training completes
                  </Typography>
                </div>
                <div className="flex flex-row justify-end">
                  <Button variant="contained" color="secondary" onClick={()=>{handleSubmit()}}>Start Training</Button>
                </div>
              </div>
            )}
            {
              modelPage == "successStarted" && (
                <div className="h-[90%] w-full bg-white flex flex-col items-center justify-center space-y-4  rounded-bl-2xl rounded-br-2xl">
                  <img src={require("../assets/training-like.gif")} alt="like" className="h-[25%] w-[15%]"/>
                  <Typography variant="h4" sx={{fontWeight:"bold"}}>SUCCESSFULLY STARTED</Typography>
                  <Typography varaint="subtitle1" sx={{fontSize:14}}>Your model training is successfully started</Typography>
                </div>
              )
            }
            {
              modelPage =="successFailed" && (
                <div className="h-[90%] w-full bg-white flex flex-col items-center justify-center space-y-4  rounded-bl-2xl rounded-br-2xl">
                   <img
                    src={require("../assets/notVerified.png")}
                    className="w-20 h-20 text-[80px] text-[#26890D]"
                    alt="save project error"
                  />
                  <Typography variant="h4" sx={{fontWeight:"bold"}}>SORRY</Typography>
                  <Typography>We ran into some issue while training your model</Typography>
                  <Button variant="contained" color="secondary" onClick={()=>setModelPage("main")}>Start Your Training Again</Button>

                </div>
              )
            }
            {
              showModel && (
                <ModelTable setShowModel={setShowModel} />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelTraining;
