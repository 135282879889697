import { pdfjs } from "react-pdf";
export const URL = "https://creativedge-aws.deloitte.com";
export const DIRECT_TRANSLATION_URL = `${URL}/api/translation/direct-translation`;
export const DIRECT_TRANSLATION_POLLING_URL = `${URL}/api/translation/translate-polling`;
export const STYLIZE_TRANSLATION_URL = `${URL}/api/translation/translate`;
export const STYLIZE_TRANSLATION_POLLING_URL = `${URL}/api/translation/stylize-polling`;
export const operations = [
  "Core Business Operations",
  "Customer and Marketing",
  "Enterprise Performance",
  "Human Capital",
  "M&A and Restructuring",
  "Strategy and Analytics",
];

export const characterLimitConstant = {
  "100": "Short (100 characters)",
  "250": "Medium (250 characters)",
  "500": "Long (500 characters)",
  "1000": "Very Long (1000 characters)",
  "Custom Limit": "Custom Limit"
};

export const textFormatConstant = [
  "Short Bullet List",
  "Long Bullet List",
  "Short Paragraph",
  "Long Paragraph",
]

export const PDF_WORKER_SRC = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export const ADMIN_FILTER_URL = `${URL}/auth-service/filter-records`;
export const USER_TIMELINE = `${URL}/auth-service/user-timeline`;
export const USER_REQUEST_STATUS = `${URL}/auth-service/user-request-status`;
export const CHECKING_USER_STATUS = `${URL}/auth-service/checking-user-status`;
export const USER_REQUEST_ACCESS = `${URL}/auth-service/user-request-access`;
export const DOWNLOAD_RECORDS = `${URL}/auth-service/download-records`;
export const DIRECT_TRANSLATION = `${URL}/api/translation/direct-translation`;
export const MAGAZINE_AD = `${URL}/ad-copy/magazine-ad`;
export const PERSONA = `${URL}/ad-copy/persona`;
export const FLYER = `${URL}/ad-copy/flyer`;
export const CREATIVE_IDEATION = `${URL}/creative-ideation/concept`;
export const EMAIL = `${URL}/ad-copy/email`;
export const SOCIAL_MEDIA = `${URL}/ad-copy/social-media-ad`;
export const REVISION = `${URL}/ad-copy/revision`;
export const MAGAZINE_AD_REVISION = `${URL}/ad-copy/magazine-ad/revision`;
export const FLYER_REVISION = `${URL}/ad-copy/flyer/revision`;
export const CREATIVE_IDEATION_REVISION = `${URL}/creative-ideation/revision`;
export const EMAIL_REVISION = `${URL}/ad-copy/email/revision`;
export const SOCIAL_MEDIA_REVISION = `${URL}/ad-copy/social-media-ad/revision`;
export const MAGAZINE_AD_EDIT = `${URL}/ad-copy/magazine-ad/edit`;
export const FLYER_EDIT = `${URL}/ad-copy/flyer/edit`;
export const CREATIVE_IDEATION_EDIT = `${URL}/creative-ideation/edit`;
export const STORY_REGENERATE_IMAGE = `${URL}/storyboarding/regenerate-image`;
export const STORY_UPDATE_STEP = `${URL}/storyboarding/update-step`;
export const STORY_GENERATE_STEP = `${URL}/storyboarding/generate-steps`;
export const STORY_TAILORED_GENERATE_STEP = `${URL}/storyboarding/tailored/generate-steps`;
export const STORY_GENERATE_IMAGE = `${URL}/storyboarding/generate-image`;
export const STORY_GENERATE_PDF = `${URL}/storyboarding/generate-pdf-report`;
export const STORY_REGENERATE_STEP = `${URL}/storyboarding/regenerate-step`;
export const STORY_REVISE_STEP = `${URL}/storyboarding/revise-steps`;
export const STORY_UPDATE_STEP_NO_IMAGE = `${URL}/storyboarding/update-step-no-image`;
export const STORY_DIRECT_VIEW_REVISION = `${URL}/storyboarding/direct-view/revision`;
export const STORY_DIRECT_VIEW_GENERATE_STEP = `${URL}/storyboarding/direct-view/generate-steps`;
export const STORY_DIRECT_VIEW_STORY_REVISION = `${URL}/storyboarding/direct-view/story-revision`;
export const AUTH = `${URL}/auth-service/auth`;
export const FLYER_PREVIEW = `${URL}/ad-copy/flyer/preview`;
export const FLYER_VERTICAL_PREVIEW = `${URL}/ad-copy/flyer-vertical/preview`;
export const FLYER_SQUARE_PREVIEW = `${URL}/ad-copy/flyer-square/preview`;
export const BANNER_HORIZONTAL_PREVIEW = `${URL}/ad-copy/banner-horizontal/preview`;
export const BANNER_VERTICAL_PREVIEW = `${URL}/ad-copy/banner-vertical/preview`;
export const DOWNLOAD_FLYER = `${URL}/ad-copy/download-flyer`;
export const VALIDATE_PASSKEY = `${URL}/auth-service/validate-passkey`;
export const PAGE_SAVE = `${URL}/page/save`;
export const PAGE_FETCH_ALL = `${URL}/page/fetch-all`;
export const PAGE_FETCH_CARD = `${URL}/page/fetch-card`;
export const DETECT_LANGUAGE = `${URL}/api/translation/detect-language`;
export const STATUS_CALL = `${URL}/auth-service/user-status-call`;
export const INFINITE_STATUS = `${URL}/auth-service/user-timeline-infinite`;
export const DIRECT_TRANSLATION_MULTIPLEFILEUPLOAD =
  "/api/translation/batch-translate";
export const TRANSLATE_AUDIO = "/api/translation/transcribe-audiofile";
export const POLLING_STATUS = "/api/translation/batch-translate-polling-status";
export const STYLIZE_TRANSLATION = "/api/translation/stylize-documents";
export const DOWNLOAD_SINGLE_FILE =
  "/api/translation/particular-language-file-download";
export const DOWNLOAD_ALL_FILES =
  "/api/translation/all-files-for-all-language-download";
export const DELETE_FILE = "/api/translation/delete-tasks";
export const FOUNDATION_UNDO = `${URL}/creative-ideation/undo`;
export const FOUNDATION_APPLY = `${URL}/creative-ideation/apply`;
export const GENERATE_BACKGROUND = `${URL}/generative-fill/generate-background`;
export const FILL_BACKGROUND = `${URL}/generative-fill/fill-background`;
export const DOWNLOAD_GENERATIVE_FILL = `${URL}/generative-fill/download-fill-background`;
export const SOCIAL_MEDIA_IMAGE = `${URL}/ad-copy/social-media-ad/image`;
export const GENERATE_PROMT_PRODUCT_DESCRIPTION = `${URL}/generative-fill/generate-description `;
export const REVISE_PROMT_PRODUCT_DESCRIPTION = `${URL}/generative-fill/generate-description/revision `;
export const MEDIA_LIKE_API = `${URL}/generative-fill/media-alike-ad`
export const SAVE_PROJECT_API  = `${URL}/page/save-project`
export const FETCH_ALL_CARD_PROJECT = `${URL}/page/fetch-all-card-project`
export const FETCH_ALL_PROJECT = `${URL}/page/fetch-all-project`
export const FLYER_DIMENSION = "1408x768";
export const BANNER_HORIZONTAL_DIMENSION = "768x1408"
export const BANNER_VERTICAL_DIMENSION = "1408x768"
export const START_TRAINING_URL = 'https://4gpeaeio4h.execute-api.us-east-1.amazonaws.com/sd-training/ops/start-training'
export const CHECK_MODEL_STATUS = 'https://4gpeaeio4h.execute-api.us-east-1.amazonaws.com/sd-training/ops/check-status'
export const CHECK_MODEL_DATA = 'https://4gpeaeio4h.execute-api.us-east-1.amazonaws.com/sd-training/ops/get-user-data'
export const MODEL_S3_URL = "https://4gpeaeio4h.execute-api.us-east-1.amazonaws.com/sd-training/ops/generate-s3-url"
export const CHECK_MODEL= "https://4gpeaeio4h.execute-api.us-east-1.amazonaws.com/sd-training/ops/check-model"



export const DELETE_PROJECT = `${URL}/page/delete-project`
