import React from "react";
import { Link } from "react-router-dom";
import Unauthorized from "../../assets/Unauthorized.png"

const UnAuthorized = () => {
  return (
    <div className="flex flex-col items-center h-screen justify-center p-4 ">
  <img src={Unauthorized} alt="Unauthorized" className="max-w-full h-1/2 mb-8" />
  <h1 className="text-6xl font-semibold  text-[#26890D] mb-4">401 Unauthorized</h1>
  <p className="text-xl text-black font-bold mb-8">
   You are not authorized to access this page
  </p>
  <button className="px-6 py-3 bg-[#26890D] text-white rounded-md hover:bg-green-700 transition duration-300">
    <Link className="hover:no-underline" to="/">Go Back</Link>
  </button>
</div>
  );
};

export default UnAuthorized;
