import React, { useRef } from "react";
import ReactPlayer from "react-player";
import myVideo from "../../assets/creativedge.mp4";

const Video = () => {
  const playerRef = useRef(null);
  const playing = false;
  return (
    <div className="flex justify-center items-center w-full h-[90%]">
      <ReactPlayer
        url={myVideo}
        playing={playing}
        controls
        ref={playerRef}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default Video;
