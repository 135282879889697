import React, { useState, useEffect } from "react";
import TopInputIdeate from "./TopInputIdeate";
import ReviewMode from "./ReviewMode";
import EditMode from "./EditMode";
import ImageView from "./ImageView";
import HomeIcon from "@mui/icons-material/Home";
import { Button } from "@mui/material";
import TopInputDirect from "./TopInputDirect";
import DirectReviewMode from "./DirectReviewMode";
import DirectReviseMode from "./DirectReviseMode";

const Direct = ({
  setErrorMessage,
  errorMessage,
  steps,
  formData,
  setFormData,
  setFormDataError,
  sectionData,
  setSectionData,
  handleSavingChange,
  updateSteps,
  generateSteps,
  regenerateImage,
  generateImage,
  handleStitch,
  formDataError,
  generatedImagesGlobal,
  setGeneratedImagesGlobal,
  validateDuration,
  makeLandingTrue,
  setShowDialog,
  generateDirectSteps,
  reviseDirectSteps,
  regenerateStep,
  generateDirectStoryboard,
  tailoredVisible,
  directVisible,
  ideateVisible,
  setIsStoryGenerated,
  textModel
}) => {
  const [stepArr, setStepArr] = useState(steps);
  const [lastStep, setLastStep] = useState(steps);
  const [stepArrWithAction, setStepArrWithAction] = useState([]);
  const [newStepValue, setNewStepValue] = useState([]);
  const [editStepValue, setEditStepValue] = useState(steps);
  const [editEachClicked, setEditEachClicked] = useState(
    new Array(steps.length).fill(false)
  );
  const [addEachClicked, setAddEachClicked] = useState(
    new Array(steps.length).fill(false)
  );
  const [generatedImages, setGeneratedImages] = useState(false);

  const [updatedSteps, setUpdatedSteps] = useState([]);
  const [showEditingMode, setShowEditingMode] = useState(false);
  const [isDurationFilled, setIsDurationFilled] = useState(false);
  const [deletedAnything, setDeletedAnything] = useState(false);
  const [stepsGenerated, setStepsGenerated] = useState(false);
  useEffect(() => {
    setEditEachClicked(new Array(steps.length).fill(false));
    setAddEachClicked(new Array(steps.length).fill(false));
    setNewStepValue(steps);
    setEditStepValue(steps);
    setStepArr(steps);
    const newArr = [];
    steps.forEach((value, index) => {
      const obj = {
        position: index + 1,
        action: stepArrWithAction[index]
          ? stepArrWithAction[index]?.action
          : "default",
      };
      if (
        index < stepArrWithAction.length &&
        stepArrWithAction[index]?.action === "add"
      ) {
        obj["desc"] = value;
      } else if (
        index < stepArrWithAction.length &&
        stepArrWithAction[index]?.action === "edit"
      ) {
        obj["desired_edit"] = value;
        obj["previous_desc"] = lastStep[index];
      } else if (
        index < stepArrWithAction.length &&
        stepArrWithAction[index]?.action === "regenerate"
      ) {
        obj["desired_edit"] = value;
        obj["previous_desc"] = lastStep[index];
      } else {
        obj["text"] = value;
      }

      newArr.push(obj);
    });
    setStepArrWithAction(newArr);
  }, [steps]);
  useEffect(() => {
    setLastStep(steps);
  }, [sectionData]);
  const handleEditEachButton = (index) => {
    const newArr = editEachClicked.map((value, i) => {
      if (i === index) {
        return true;
      } else {
        return value;
      }
    });
    setEditEachClicked(newArr);
  };
  const handleSaveEachButton = (index) => {
    const newStepArr = [
      ...stepArr.slice(0, index),
      editStepValue[index],
      ...stepArr.slice(index + 1),
    ];
    const newStepWithAction = stepArrWithAction.map((value, i) => {
      if (i === index) {
        return {
          position: i + 1,
          action: "edit",
          desired_edit: editStepValue[index],
          previous_desc: lastStep[index],
        };
      } else {
        return value;
      }
    });
    setStepArrWithAction(newStepWithAction);
    setStepArr(newStepArr);
    handleSavingChange(newStepArr);
    const newArr = editEachClicked.map((value, i) => {
      if (i === index) {
        return false;
      } else {
        return value;
      }
    });
    setEditEachClicked(newArr);
  };
  const handleEditChangeEach = (e, index) => {
    const temp = [
      ...editStepValue.slice(0, index),
      e.target.value,
      ...editStepValue.slice(index + 1),
    ];
    setEditStepValue(temp);
  };
  const handleAddStepEach = (index) => {
    const newArr = addEachClicked.map((value, i) => {
      if (i === index) {
        return !value;
      } else {
        return value;
      }
    });
    if (index !== addEachClicked.length) setAddEachClicked(newArr);
    else setAddEachClicked(newArr.concat(true));
    const temp = [
      ...newStepValue.slice(0, index),
      "",
      ...newStepValue.slice(index),
    ];
    setNewStepValue(temp);
  };
  const handleChangeInNewStep = (e, index) => {
    const temp = newStepValue.map((value, i) => {
      if (i === index) {
        return e.target.value;
      } else {
        return value;
      }
    });
    setNewStepValue(temp);
  };
  const handleSaveAfterAddButton = (index) => {
    const newArr = [
      ...stepArr.slice(0, index),
      newStepValue[index],
      ...stepArr.slice(index),
    ];
    const newLastStep = [
      ...lastStep.slice(0, index),
      "",
      ...lastStep.slice(index),
    ];
    setLastStep(newLastStep);
    const newStepArrWithAction = [
      ...stepArrWithAction.slice(0, index),
      {
        position: index + 1,
        action: "add",
        desc: newStepValue[index],
      },
      ...stepArrWithAction.slice(index),
    ];
    newStepArrWithAction.map((value, i) => {
      if (i > index) {
        value.position = i + 1;
      }
    });
    setStepArrWithAction(newStepArrWithAction);
    const tempNewStep = [
      ...newStepValue.slice(0, index),
      "",
      ...newStepValue.slice(index),
    ];
    setNewStepValue(tempNewStep);
    setStepArr(newArr);
    handleSavingChange(newArr);
    const newAddEachClicked = [
      ...addEachClicked.slice(0, index),
      false,
      false,
      ...addEachClicked.slice(index + 1),
    ];
    const newEditEachClicked = [
      ...editEachClicked.slice(0, index),
      false,
      false,
      ...editEachClicked.slice(index + 1),
    ];
    setAddEachClicked(newAddEachClicked);
    setEditEachClicked(newEditEachClicked);
  };
  const handleGenerateImagesButton = async () => {
    const stepPosition = new Map();
    stepArrWithAction?.forEach((value, index) => {
      //changed from value.position to index + 1
      if (value.action === "default") {
        stepPosition.set(value.text, index + 1);
      } else if (value.action === "edit") {
        stepPosition.set(value["previous_desc"], index + 1);
      } else if (value.action === "regenerate") {
        stepPosition.set(value["desired_edit"], index + 1);
      } else {
        stepPosition.set(value["desc"], index + 1);
      }
    });
    const previousSteps = JSON.parse(JSON.stringify(sectionData.steps.prev));
    previousSteps?.forEach((eachStep, index) => {
      if (eachStep.hasOwnProperty("IMAGE")) delete eachStep["IMAGE"];
      if (eachStep.hasOwnProperty("DURATION_IN_SECONDS"))
        delete eachStep["DURATION_IN_SECONDS"];
      if (stepPosition.has(eachStep["SCENE_DESCRIPTION"])) {
        eachStep["POSITION"] = stepPosition.get(eachStep["SCENE_DESCRIPTION"]);
      }
    });
    stepArrWithAction.forEach((value, index) => {
      if (value.action === "edit") delete value["previous_desc"];
      if (value.action === "regenerate") delete value["previous_desc"];
    });
    const tempUpdatedSteps = stepArrWithAction.filter(
      (each) =>
        each?.action === "add" ||
        each?.action === "edit" ||
        each?.action === "regenerate"
    );
    if (deletedAnything) tempUpdatedSteps.push({ action: "delete" });
    setDeletedAnything(false);
    if (!generatedImagesGlobal) {
      if (tempUpdatedSteps.length) {
        await updateSteps(tempUpdatedSteps, previousSteps, generatedImages);
      } else {
        await generateImage();
      }
    } else {
      const tempData = sectionData.steps.prev;
      const dataMap = new Map();
      tempData?.forEach((value, index) => {
        dataMap.set(value["STEP_NO"], value["IMAGE"]);
      });
      if (tempUpdatedSteps.length) {
        updateSteps(tempUpdatedSteps, previousSteps, generatedImages, dataMap);
      } else {
        setErrorMessage({
          ...errorMessage,
          message: "Try revising steps or editing steps or regenerating steps.",
        });
      }
    }
    setGeneratedImages(true);
    setGeneratedImagesGlobal(true);
    const newStepArrWithAction = stepArrWithAction.map((value, index) => {
      return {
        position: value["position"],
        action: "default",
        text: value["desc"] || value["desired_edit"],
      };
    });
    setStepArrWithAction(newStepArrWithAction);
  };
  const handleDuration = (e) => {
    if (Number(e.target.value) > 7) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        duration: "Maximum 7 mins.",
      }));
    } else {
      setFormDataError((formDataError) => ({
        ...formDataError,
        duration: "",
      }));
    }
    setFormData({
      ...formData,
      duration: Number(e.target.value) > 7 ? "7" : e.target.value,
    });
    setIsDurationFilled(formData?.duration?.trim().length >= 0);
  };

  const handleBlur = () => {
    validateDuration(formData.duration);
  };
  const handleHomeButtonClick = () => {
    if (sectionData?.steps?.prev.length) setShowDialog(true);
    else makeLandingTrue();
  };
  const handleDeleteEachButton = (index) => {
    const newStepArrWithAction = [
      ...stepArrWithAction.slice(0, index),
      ...stepArrWithAction.slice(index + 1),
    ];
    newStepArrWithAction.map((value, i) => {
      if (i > index) {
        value.position = i;
      }
    });
    const newStepArr = [
      ...stepArr.slice(0, index),
      ...stepArr.slice(index + 1),
    ];
    const newSectionData = { ...sectionData };
    const newPrev = [
      ...newSectionData.steps.prev.slice(0, index),
      ...newSectionData.steps.prev.slice(index + 1),
    ];
    const newCurr = [
      ...newSectionData.steps.current.slice(0, index),
      ...newSectionData.steps.current.slice(index + 1),
    ];
    newSectionData.steps.prev = newPrev;
    newSectionData.steps.current = newCurr;
    setSectionData(newSectionData);
    setStepArr(newStepArr);
    handleSavingChange(newStepArr);
    setStepArrWithAction(newStepArrWithAction);
    setDeletedAnything(true);
  };
  const handleRefreshEachButton = (index) => {
    const regenerate_step = stepArr[index];
    const scenes = [];
    stepArr.map((step, i) =>
      scenes.push({ step_no: i + 1, description: step })
    );
    regenerateStep(index, { regenerate_step: regenerate_step, scenes: scenes });
  };
  const handleGenerateStoryBoard = async () => {
    const stepPosition = new Map();
    stepArrWithAction?.forEach((value, index) => {
      //changed from value.position to index + 1
      if (value.action === "default") {
        stepPosition.set(value.text, index + 1);
      } else if (value.action === "edit") {
        stepPosition.set(value["previous_desc"], index + 1);
      } else if (value.action === "regenerate") {
        stepPosition.set(value["previous_desc"], index + 1);
      } else {
        stepPosition.set(value["desc"], index + 1);
      }
    });
    const previousSteps = JSON.parse(JSON.stringify(sectionData.steps.prev));
    previousSteps?.forEach((eachStep, index) => {
      if (eachStep.hasOwnProperty("IMAGE")) delete eachStep["IMAGE"];
      if (eachStep.hasOwnProperty("DURATION_IN_SECONDS"))
        delete eachStep["DURATION_IN_SECONDS"];
      if (stepPosition.has(eachStep["SCENE_DESCRIPTION"])) {
        eachStep["POSITION"] = stepPosition.get(eachStep["SCENE_DESCRIPTION"]);
      }
    });
    stepArrWithAction.forEach((value, index) => {
      if (value.action === "edit") delete value["previous_desc"];
      if (value.action === "regenerate") delete value["previous_desc"];
    });
    const tempUpdatedSteps = stepArrWithAction.filter(
      (each) =>
        each?.action === "add" ||
        each?.action === "edit" ||
        each?.action === "regenerate"
    );
    if (deletedAnything) tempUpdatedSteps.push({ action: "delete" });
    setDeletedAnything(false);
    if (!stepsGenerated) {
      try {
        await generateDirectStoryboard();
        setStepsGenerated(true);
        setGeneratedImagesGlobal(true);
        setGeneratedImages(true);
      } catch (error) {
        console.error(error);
      }
      return;
    }
    if (
      generatedImagesGlobal &&
      sectionData.steps?.prev[0]?.hasOwnProperty("IMAGE")
    ) {
      const tempData = sectionData.steps.prev;
      const dataMap = new Map();
      tempData?.forEach((value, index) => {
        dataMap.set(value["STEP_NO"], value["IMAGE"]);
      });
      if (tempUpdatedSteps.length) {
        await updateSteps(
          tempUpdatedSteps,
          previousSteps,
          generatedImages,
          dataMap
        );
      } else {
        setErrorMessage({
          ...errorMessage,
          message:
            "Images are already generated! Make some change in the steps to generate new results.",
        });
      }
    } else {
      try {
        if (tempUpdatedSteps.length) {
          await updateSteps(tempUpdatedSteps, previousSteps, generatedImages);
        } else {
          await generateImage();
        }
        setGeneratedImages(true);
        setGeneratedImagesGlobal(true);
      } catch (error) {
        console.error(error);
      }
      const newStepArrWithAction = stepArrWithAction.map((value, index) => {
        return {
          position: value["position"],
          action: "default",
          text: value["desc"] || value["desired_edit"],
        };
      });
      setStepArrWithAction(newStepArrWithAction);
    }
  };
  return (
    <>
      <TopInputDirect
        formData={formData}
        setFormData={setFormData}
        formDataError={formDataError}
        setGeneratedImagesGlobal={setGeneratedImagesGlobal}
        setGeneratedImages={setGeneratedImages}
        generateSteps={generateSteps}
        validateDuration={validateDuration}
        handleBlur={handleBlur}
        handleDuration={handleDuration}
        sectionData={sectionData}
        setShowDialog={setShowDialog}
        makeLandingTrue={makeLandingTrue}
        setIsStoryGenerated={setIsStoryGenerated}
      />
      {!showEditingMode ? (
        <DirectReviewMode
          formData={formData}
          setFormData={setFormData}
          setShowEditingMode={setShowEditingMode}
          sectionData={sectionData}
          generateDirectSteps={generateDirectSteps}
          reviseDirectSteps={reviseDirectSteps}
          handleGenerateStoryBoard={handleGenerateStoryBoard}
          stepsGenerated={stepsGenerated}
          setStepsGenerated={setStepsGenerated}
          setGeneratedImagesGlobal={setGeneratedImagesGlobal}
          setGeneratedImages={setGeneratedImages}
          setIsStoryGenerated={setIsStoryGenerated}
        />
      ) : (
        <DirectReviseMode
          steps={steps}
          handleAddStepEach={handleAddStepEach}
          addEachClicked={addEachClicked}
          editEachClicked={editEachClicked}
          handleChangeInNewStep={handleChangeInNewStep}
          handleSaveAfterAddButton={handleSaveAfterAddButton}
          handleEditChangeEach={handleEditChangeEach}
          handleEditEachButton={handleEditEachButton}
          handleSaveEachButton={handleSaveEachButton}
          handleRefreshEachButton={handleRefreshEachButton}
          handleDeleteEachButton={handleDeleteEachButton}
          sectionData={sectionData}
          setShowEditingMode={setShowEditingMode}
          generateDirectSteps={generateDirectSteps}
        />
      )}

      <ImageView
        formData={formData}
        generatedImagesGlobal={generatedImagesGlobal}
        sectionData={sectionData}
        regenerateImage={regenerateImage}
        handleStitch={handleStitch}
        tailoredVisible={tailoredVisible}
        directVisible={directVisible}
        ideateVisible={ideateVisible}
        setIsStoryGenerated={setIsStoryGenerated}
      />
    </>
  );
};

export default Direct;
