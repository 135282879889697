import { TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import * as React from "react";
import { useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const operations = [
  "Core Business Operations",
  "Customer and Marketing",
  "Enterprise Performance",
  "Human Capital",
  "M&A and Restructuring",
  "Strategy and Analytics",
  "Others",
];
const purpose = ["Client Demo", "Testing"];

export default function MultipleSelectCheckmarks({
  tag,
  typeOfSelect,
  handleChangeInSelect,
  formData,
  setFormData,
}) {
  const [othersClicked, setOthersClicked] = useState(false);
  const [clientClicked, setClientClicked] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [textFieldValue, setTextFieldValue] = useState("");

  const handleChange = (event) => {
    setTypeName(event.target.value);
    if (typeOfSelect === 1 && event.target.value === "Others") {
      handleChangeInSelect("", typeOfSelect);
      setOthersClicked(true);
    } else if (typeOfSelect === 2 && event.target.value === "Client Demo") {
      handleChangeInSelect("", typeOfSelect);
      setClientClicked(true);
    } else if (typeOfSelect === 1) {
      setOthersClicked(false);
      handleChangeInSelect(event.target.value, typeOfSelect);
      setFormData({
        ...formData,
        offering_portfolio: event.target.value,
      });
    } else if (typeOfSelect === 2) {
      setClientClicked(false);
      handleChangeInSelect(event.target.value, typeOfSelect);
    }
  };

  return (
    <div class="flex flex-col justify-items-start pb-2">
      <FormControl sx={{ mb: 2, width: 420, height: 44 }}>
        <InputLabel id="demo-multiple-checkbox-label">{tag}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={typeName}
          onChange={handleChange}
          input={<OutlinedInput label={tag} />}
          MenuProps={MenuProps}
          //variant="outlined"
          focused
          color="tertiary"
          sx={{ padding: 0 }}
        >
          {typeOfSelect === 1
            ? operations.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))
            : purpose.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      {othersClicked || clientClicked ? (
        <TextField
          placeholder={
            typeOfSelect === 1
              ? "Enter Offering Portfolio"
              : "Enter Client Detail"
          }
          defaultValue={textFieldValue}
          onChange={(e) => {
            setTextFieldValue(e.target.value);
            handleChangeInSelect(e.target.value, typeOfSelect);
          }}
          focused
          color="tertiary"
          sx={{ m: 0, width: 420, height: 50 }}
        />
      ) : null}
    </div>
  );
}
