import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";

const ConfirmDialog = ({ setShowDialog, handleConfirmDialogClick }) => {
  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle>Confirm the action</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton
          onClick={() => {
            setShowDialog(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>This will delete all your existing data.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setShowDialog(false)}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            handleConfirmDialogClick();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
