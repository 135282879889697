import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Checkbox,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { useState } from "react";
import { withStyles } from "@mui/styles";

const ProjectRenameCard = ({
  handleProjectRenameClosed,
  showProjectConfirmationCard,
  sectionData,
  setProjectConfirmedTabs,
  setShowProjectError,
}) => {
  const GreenCheckbox = withStyles({
    root: {
      color: "#86BC25",
      "&$checked": {
        color: "#26890D",
      },
      "&$disabled": {
        color: "grey",
      },
      "&$disabled + $checked": {
        color: "grey",
      },
    },
    checked: {},
    disabled: {
      color: "grey",
    },
  })((props) => <Checkbox color="default" {...props} />);
  const tabs = [
    "Persona",
    "Social Media",
    "Email",
    "Ad/Print",
    "Creative Concepts",
    "Ad/Banner/Horizontal",
    "Ad/Banner/Vertical",
    "Ad/Flyer",
  ];
  const tabsToSectionData = {
    Persona: "persona",
    Email: "email",
    "Social Media": "socialMedia",
    "Creative Concepts": "creativeConcepts",
    "Ad/Print": "print",
    "Ad/Banner/Horizontal": "bannerHorizontal",
    "Ad/Banner/Vertical": "bannerVertical",
    "Ad/Flyer": "flyerVertical",
  };
  const tabStates = tabs.reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});
  const disabledTabs = tabs.reduce((acc, item) => {
    let truthy;
    let value = sectionData[tabsToSectionData[item]];
    if (Array.isArray(value)) {
      truthy = value.length > 0;
    } else if (typeof value === "object" && value !== null) {
      truthy = Object.keys(value).length > 0;
    }

    if (truthy) {
      acc[item] = false;
    } else {
      acc[item] = true;
    }

    return acc;
  }, {});
  const [selectStates, setSelectStates] = useState(tabStates);
  const [ch, setCh] = useState(true);

  const handleChange = (event) => {
    setSelectStates({
      ...selectStates,
      [event.target.name]: !selectStates[event.target.name],
    });
  };
  const handleChangeAll = (event) => {
    let updatedStates = Object.keys(selectStates).reduce((acc, key) => {
      acc[key] = !ch;
      return acc;
    }, {});
    setSelectStates(updatedStates);
    setCh(!ch);
  };
  const handleStatesTransfer = () => {
    let transferableTabs = [];
    tabs.forEach((item) => {
      if (selectStates[item] && !disabledTabs[item]) {
        transferableTabs.push(tabsToSectionData[item]);
      }
    });
    setProjectConfirmedTabs(transferableTabs);
    return transferableTabs;
  };
  return (
    <div className="bg-black bg-opacity-50 z-50 absolute inset-0 flex justify-center items-center">
      <div className="bg-white rounded-lg w-[50%] h-max flex flex-col space-y-4 relative pl-8 p-4">
        <Button
          sx={{ position: "absolute", top: 5, right: 0 }}
          onClick={handleProjectRenameClosed}
        >
          <img src={require("../assets/close-icon.png")} />
        </Button>
        <Typography variant="subtitle1">
          Please select the pages you want to add in your project
        </Typography>
        <div className="pl-2">
          <FormControl>
            <RadioGroup>
              <FormControlLabel
                value="female"
                control={
                  <GreenCheckbox onChange={handleChangeAll} checked={ch} />
                }
                label="Select All"
              />
              <div className="flex flex-wrap">
                {tabs.map((tab) => (
                  <div key={tab} className="w-1/2 py-2">
                    <div className="flex flex-col">
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            name={tab}
                            checked={selectStates[tab]}
                            onChange={(e) => handleChange(e)}
                            disabled={disabledTabs[tab]}
                          />
                        }
                        label={tab}
                      />
                      <p className="text-slate-500 text-sm pl-4">
                        Save my details for next time
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </RadioGroup>
          </FormControl>
        </div>
        <div className="flex justify-end items-end pr-4">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              let tabs = handleStatesTransfer();
              handleProjectRenameClosed();
              if (tabs.length >= 2 && tabs.includes("persona"))
                showProjectConfirmationCard();
              else {
                setShowProjectError(true);
              }
            }}
          >
            SAVE PROJECT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectRenameCard;
