import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import "./Revise.css";
import greenIcon from "../assets/ReviseIconGreen.svg";
import { edit } from "../utils/translateHelper";
const Revise = ({
  revisedData,
  regenerateData,
  index = null,
  isContentLocked,
  content,
  editClicked,
}) => {
  const [revisedInfo, setRevisedInfo] = useState("");

  return (
    <>
      <div className="flex flex-col items-start  w-full ">
        <div className="flex flex-col pb-5 flex-[80%] w-full">
          <p className="text-label mb-2 text-black">Ask for revisions</p>
          <TextField
            fullWidth
            color="tertiary"
            defaultValue=""
            focused
            value={revisedInfo}
            onChange={(e) => {
              setRevisedInfo(e.target.value);
            }}
            inputProps={{
              sx: {
                height: 12,
              },
            }}
            disabled={isContentLocked || !content || editClicked}
          />
        </div>
        <div className="flex flex-row justify-center sm:justify-end w-full ">
          <div className="flex flex-wrap justify-center">
            <Button
              sx={{
                fontWeight: 600,
                display: "flex",
                width: "100%",
                maxWidth: "12.5rem",
                height: "2.813rem",
                padding: "0.5rem 0.625rem",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                borderRadius: "0.375rem",
              }}
              variant={
                !revisedInfo || isContentLocked || !content || editClicked
                  ? "contained"
                  : "outlined"
              }
              color="secondary"
              onClick={() => {
                index !== null
                  ? revisedData(revisedInfo, index)
                  : revisedData(revisedInfo);
                setRevisedInfo("");
              }}
              disabled={
                !revisedInfo || isContentLocked || !content || editClicked
              }
            >
              <img
                className="mr-1.5 h-6 w-6"
                src={
                  !revisedInfo || isContentLocked || !content || editClicked
                    ? require("../assets/reviseIcon.png")
                    : greenIcon
                }
                alt="Revise"
              />
              REVISE
            </Button>
          </div>
        </div>
      </div>

      <div className="regenerate-button pt-32">
        <Button
          sx={{
            display: "flex",
            padding: "0.5rem 0.625rem",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 600,
            width: "100%",
            height: "44px",
            borderRadius: "0.375rem",
          }}
          variant="contained"
          color="secondary"
          onClick={() => {
            index !== null
              ? regenerateData(index, true)
              : regenerateData(null, true);
          }}
          disabled={!content}
        >
          <img
            className="flex mr-1.5"
            src={require("../assets/regenerateIcon.png")}
            alt="Regenerate"
          />
          REGENERATE
        </Button>
      </div>
    </>
  );
};

export default Revise;
