import React, { useEffect, useState } from "react";
import Error from "../components/Error";
import SignalTitles from "../components/SignalTitles";
import { act } from "react";
import Tailored from "../components/Tailored";

const StoryBoardingTailored = ({
  stepArr,
  setStepArr,
  handleGenerateButtonClick,
  formData,
  setFormData,
  setFormDataError,
  sectionData,
  setSectionData,
  activeTab,
  updateSteps,
  generateSteps,
  regenerateImage,
  generateImage,
  handleStitch,
  formDataError,
  generatedImagesTailored,
  setGeneratedImagesTailored,
  validateDuration,
  errorMessage,
  setErrorMessage,
  makeLandingTrue,
  setShowDialog,
  handleConfirmDialogClick,
  regenerateStep,
  reviseAllSteps,
  reviseAfterUpdating,
  tailoredVisible,
  directVisible,
  ideateVisible,
  regenerateButtonLoader,
  directStoryBoardPopUp,
  setDirectStoryBoardPopUp,
  setIsStoryGenerated,
  titles,
  selectedTitle,
  setSelectedTitle,
  territoryTitles,
  selectedTheme,
  setSelectedTheme,
  themeWasApplied,
  setPrevSelectedTheme,
  prevSelectedTheme,
  isStoryBoardLoading,
  isSidebarOpen,
  handleSidebar,
  textModel
}) => {
  const [ranEffect, setRanEffect] = useState(false);
  useEffect(() => {
    if (
      (!stepArr[selectedTitle] ||
        ranEffect ||
        (themeWasApplied &&
          prevSelectedTheme["StoryboardingTailored"] !== selectedTheme)) &&
      !isStoryBoardLoading
    ) {
      setRanEffect(false);
      setPrevSelectedTheme({
        ...prevSelectedTheme,
        StoryboardingTailored: selectedTheme,
      });
      generateSteps();
    }
  }, [selectedTheme]);
  const handleSavingChange = (newStepArr) => {
    setStepArr([
      ...stepArr.slice(0, selectedTitle),
      newStepArr,
      ...stepArr.slice(selectedTitle + 1),
    ]);
  };
  useEffect(() => {
    setTimeout(() => {
      setErrorMessage(false);
    }, [5000]);
  }, [errorMessage]);
  console.log(activeTab);
  return (
    <div
      className={`flex w-full h-full flex-1 ${
        isSidebarOpen ? "hidden" : "block"
      } sm:block`}
    >
      <div className="flex justify-center w-full mx-5 ">
        <div className="w-full h-full flex flex-col">
          {/* {errorMessage && <Error errorMessage={errorMessage.message} />} */}
          {titles.length > 1 &&
            sectionData.stepsTailored?.[selectedTitle]?.prev?.[0] && (
              <SignalTitles
                titles={titles}
                setSelectedTitle={setSelectedTitle}
                selectedTitle={selectedTitle}
                activeTab={activeTab}
              />
            )}
          <Tailored
            steps={stepArr[selectedTitle]}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            formData={formData}
            setFormData={setFormData}
            setFormDataError={setFormDataError}
            sectionData={sectionData}
            setSectionData={setSectionData}
            handleSavingChange={handleSavingChange}
            updateSteps={updateSteps}
            generateSteps={generateSteps}
            regenerateImage={regenerateImage}
            generateImage={generateImage}
            handleStitch={handleStitch}
            formDataError={formDataError}
            generatedImagesTailored={generatedImagesTailored}
            setGeneratedImagesTailored={setGeneratedImagesTailored}
            validateDuration={validateDuration}
            makeLandingTrue={makeLandingTrue}
            setShowDialog={setShowDialog}
            regenerateStep={regenerateStep}
            reviseAllSteps={reviseAllSteps}
            reviseAfterUpdating={reviseAfterUpdating}
            tailoredVisible={tailoredVisible}
            directVisible={directVisible}
            ideateVisible={ideateVisible}
            regenerateButtonLoader={regenerateButtonLoader}
            directStoryBoardPopUp={directStoryBoardPopUp}
            setDirectStoryBoardPopUp={setDirectStoryBoardPopUp}
            setIsStoryGenerated={setIsStoryGenerated}
            titles={titles}
            setSelectedTitle={setSelectedTitle}
            selectedTitle={selectedTitle}
            territoryTitles={territoryTitles}
            selectedTheme={selectedTheme}
            setSelectedTheme={setSelectedTheme}
            themeWasApplied={themeWasApplied}
            setRanEffect={setRanEffect}
            ranEffect={ranEffect}
            isSidebarOpen={isSidebarOpen}
            handleSidebar={handleSidebar}
            textModel={textModel}
          />
        </div>
      </div>
    </div>
  );
};

export default StoryBoardingTailored;
