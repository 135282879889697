import { Button } from "@mui/material";
import React, { useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ReactPlayer from "react-player";
import myVideo from "../assets/Creative Edge FinalCut 2.mp4";
import pdf from "../assets/Dummy-Data.pdf";
import ppt from "../assets/CreativEdge.pptx";
import guide from "../assets/GuideDemo.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const VideoGuide = () => {
  const playerRef = useRef(null);
  const playing = false;
  const [showVideo, setShowVideo] = useState(true);
  const [showDocument, setShowDocument] = useState(false);
  const [showGuide, setShowGuide] = useState(false);

  const [numPages, setNumPages] = useState(0);
  function onDocumentLoadSuccess({ numPages: newNumPages }) {
    setNumPages(newNumPages);
  }

  const handleDownload = async () => {
    const documentType = showDocument ? pdf : guide;
    const file = showVideo ? myVideo : documentType;
    const response = await fetch(file);
    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = showVideo === true ? "download.mp4" : "download.pptx";

    // Automatically download the file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="flex flex-col p-4">
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <div className="flex flex-row justify-around gap-2 mt-4 sm:mt-0 w-full sm:w-1/2">
          <Button
            sx={{
              backgroundColor: showVideo ? "green" : "",
              color: showVideo ? "white" : "grey",
             height: "max-content",
            }}
            onClick={() => {
              setShowVideo(true);
              setShowDocument(false);
              setShowGuide(false);
            }}
          >
            DEMO VIDEO
          </Button>

          <Button
            sx={{
              backgroundColor: showDocument ? "green" : "",
              color: showDocument ? "white" : "grey",
              height: "max-content",
              
            }}
            onClick={() => {
              setShowVideo(false);
              setShowDocument(true);
              setShowGuide(false);
            }}
          >
            DEMO DECK
          </Button>
          <Button
            style={{
              backgroundColor: showGuide ? "green" : "",
              color: showGuide ? "white" : "grey",
             height: "max-content",
            
            }}
            onClick={() => {
              setShowVideo(false);
              setShowDocument(false);
              setShowGuide(true);
            }}
          >
            DEMO BEST PRACTICES
          </Button>
        </div>
        <div className="my-2 sm:my-0">
          <Button
            variant="contained"
            color="secondary"
            sx={{
              padding: "0.5rem",
              height: "38px",
              width: "2.5rem",
              minWidth: 0,
              borderRadius: "0.4rem",
            }}
            onClick={handleDownload}
          >
            <img
              style={{ height: "1.12494rem", width: "1.02494rem" }}
              src={require("../assets/download-white-bg.png")}
              alt="Download Button"
            />
          </Button>
        </div>
      </div>
      {showVideo && (
        <div className="flex justify-center items-center  rounded-2xl overflow-hidden w-full h-full sm:w-[52rem] sm:h-[28rem] sm:ml-5 gap-4 my-4  ">
          <ReactPlayer
            url={myVideo}
            playing={playing}
            controls
            ref={playerRef}
            width="100%"
            height="100%"
          />
        </div>
      )}
      {(showDocument || showGuide) && (
        <div className="overflow-auto pl-5 h-[450px] w-[860px]">
          <Document
            file={showDocument ? pdf : guide}
            options={{
              workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`,
            }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <div key={`page_${index + 1}`} className="relative max-h-full">
                <Page pageNumber={index + 1} />
              </div>
            ))}
          </Document>
        </div>
      )}
    </div>
  );
};

export default VideoGuide;
