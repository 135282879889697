import { useMsal } from "@azure/msal-react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { formatDate } from '../utils/helper';
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import pdfFile from "../assets/FilePdf.jpg";
import textFile from "../assets/textfile.jpg";
import wordFile from "../assets/word.jpg";
import { loginRequest } from "../authConfigFile/authConfig";
import AiGeneratedContent from "../components/AiGeneratedContent";
import FileTracker from "../components/FileTracker";
import ModelSetting from "../components/ModelSetting";
import SelectMultipleLanguage from "../components/SelectMultipleLanguageBox";
import SuccessBackdrop from "../components/SuccessBackdrop";
import {
  DIRECT_TRANSLATION_MULTIPLEFILEUPLOAD,
  POLLING_STATUS,
  STYLIZE_TRANSLATION,
  TRANSLATE_AUDIO,
  URL,
} from "../utils/constants";
import {
  detectLanguage,
  edit,
  lamdaEndpoint,
  translate,
  translationDirect,
} from "../utils/translateHelper";
import "./Translation.css";
import CustomModal from "../components/Shared/CustomModal";
import { Typography, Box } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
const TranslatePage = ({
  inputText,
  setInputText,
  sectionData,
  setSectionData,
  translationValue,
  localisationValue,
  setIsTranslationLoading,
  setErrorMessage,
  setSelectedLanguage,
  selectedLanguage,
  setTranslatedLanguage,
  translatedLanguage,
  setTranslatedText,
  translatedText,
  selectedButton,
  setSelectedButton,
  selectedEmotion,
  setSelectedEmotion,
  trackFileComponent,
  isSidebarOpen,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [translatedLanguageAnchorEl, setTranslatedLanguageAnchorEl] =
    useState(null);
  const [isStylize, setIsStylize] = useState("");
  const [showPromptEditor, setShowPromptEditor] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [showInputForPrompt, setShowInputForPrompt] = useState(false);
  const [translationModel, setTranslationModel] = useState("Amazon Translate");
  const [localisationModel, setLocalisationModel] = useState("Llama");
  const [showEditArea, setShowEditArea] = useState(false);
  const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);
  const [isAudioUploaded, setIsAudioUploaded] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState("");
  const [fileCount, setFileCount] = useState(0);
  const [showAudio, setShowAudio] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadFileSuccess, setUploadFileSuccess] = useState(false);
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [audioTrackFile, setAudioTrackFile] = useState(false);
  const [activateButton, setActivateButton] = useState(false);
  const [uploadSize, setUploadSize] = useState(false);
  const { instance } = useMsal();
  const [filesData, setFilesData] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [translationChunks, setTranslationChunks] = useState(null);
  const isTranslationRunning = useRef(null);
  const [largeAudio, setLargeAudio] = useState(false);
  const [searchMultipleTerm, setSearchMultipleTerm] = useState("");
  const [selectedAlphabet, setSelectedAlphabet] = useState(null);
  const [selectedHeading, setSelectedHeading] = useState("text");
  const [documentFiles, setDocumentFiles] = useState([]);
  const [audioFiles, setAudioFiles] = useState([]);
  const [audioSubmitButtonClicked, setAudioSubmitButtonClicked] =
    useState(false);
  const [isMultipleLanguageBoxOpen, setIsMultipleLanguageBoxOpen] =
    useState(false);

  const [audioInputText, setAudioInputText] = useState("");
  const [selectedOption, setSelectedOption] = useState("stylize");

  const [recordedAudio, setRecordedAudio] = useState(null);
  const [apiResponseReady, setApiResponseReady] = useState(false);
  const isCastilianSpanish = translatedLanguage === "Castilian Spanish";

  const MultipleLanguageOptions = {
    A: ["Arabic"],
    B: ["Bengali"],
    C: ["Chinese", "Czech"],
    D: ["Danish"],
    E: ["English", "Estonian"],
    F: ["Finnish", "French"],
    G: ["German"],
    H: ["Hindi", "Hungarian"],
    I: ["Indonesian", "Italian"],
    J: ["Japanese"],
    K: ["Korean"],
    L: ["Latvian", "Lithuanian"],
    N: ["Norwegian"],
    P: ["Polish", "Portuguese"],
    R: ["Romanian", "Russian"],
    S: ["Slovak", "Slovenian", "Spanish", "Swedish"],
    T: ["Tamil"],
    V: ["Vietnamese"],
  };

  const handleSearchChange = (e) => {
    setSearchMultipleTerm(e.target.value);
    setSelectedAlphabet(null);
  };

  const handleAlphabetClick = (alphabet) => {
    setSelectedAlphabet(alphabet);
    setSearchMultipleTerm("");
  };
  const handleAllAlphabetsClick = () => {
    setSelectedAlphabet(null);
    setSearchMultipleTerm("");
  };

  const [isFileTypeModalOpen, setIsFileTypeModalOpen] = useState(false);

  const [enableSubmitAudio, setEnableSubmitAudio] = useState(false);

  const filteredMultipleLanguages = Object.keys(MultipleLanguageOptions).reduce(
    (filtered, alphabet) => {
      if (selectedAlphabet && alphabet !== selectedAlphabet) {
        return filtered;
      }
      const filteredAlphabet = MultipleLanguageOptions[alphabet].filter(
        (language) =>
          language.toLowerCase().includes(searchMultipleTerm.toLowerCase())
      );
      if (filteredAlphabet.length > 0) {
        filtered[alphabet] = filteredAlphabet;
      }
      return filtered;
    },
    {}
  );

  const handleCheckboxChange = (language) => {
    setTranslatedLanguage((prevLanguages) => {
      if (prevLanguages.includes(language)) {
        const updatedLanguages = prevLanguages
          .split(",")
          .filter((l) => l !== language)
          .join(",");
        return updatedLanguages;
      } else {
        const updatedLanguages = prevLanguages
          ? `${prevLanguages},${language}`
          : language;
        return updatedLanguages;
      }
    });
  };

  const handleHeadingClick = (heading) => {
    setSelectedHeading(heading);
    if (heading !== "document") {
      // setTranslatedLanguage((prev) => !prev);
      setTranslatedLanguage("English");
    }
  };

  const handleDocumentDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    const acceptedFileTypes = [".doc", ".docx", ".pdf", ".ppt", ".pptx"];
    const filteredFiles = droppedFiles.filter((file) =>
      acceptedFileTypes.some((type) => file.name.endsWith(type))
    );

    if (filteredFiles.length !== droppedFiles.length) {
      setIsFileTypeModalOpen(true);
    }

    setDocumentFiles([...documentFiles, ...filteredFiles]);
  };

  const handleDocumentSelect = (event) => {
    event.preventDefault();

    const selectedFiles = Array.from(event.target.files);
    const acceptedFileTypes = [".doc", ".docx", ".pdf", ".ppt", ".pptx"];
    const filteredFiles = selectedFiles.filter((file) =>
      acceptedFileTypes.some((type) => file.name.endsWith(type))
    );

    if (filteredFiles.length !== selectedFiles.length) {
      setIsFileTypeModalOpen(true);
    } else {
      setIsFileTypeModalOpen(false);
    }

    setDocumentFiles([...documentFiles, ...filteredFiles]);
  };

  const handleAudioDrop = async (event) => {
    event.preventDefault();

    const droppedFiles = Array.from(event.dataTransfer.files);
    const acceptedFileTypes = [".mp3"];
    const filteredFiles = droppedFiles.filter((file) =>
      acceptedFileTypes.some((type) => file.name.endsWith(type))
    );

    if (filteredFiles.length !== droppedFiles.length) {
      setIsFileTypeModalOpen(true);
            return;
    } else {
      setIsFileTypeModalOpen(false);
    }

    const totalFiles = audioFiles.length + filteredFiles.length;
    const SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB

    if (totalFiles === 1 && filteredFiles[0].size <= SIZE_LIMIT) {
      setIsTranslationLoading(true);

      const file = filteredFiles[0];
      setRecordedAudio(file);
      let formData = new FormData();
      formData.append("file", file);
      formData.append("source_language", selectedLanguage);
      try {
        const idToken = await getIdToken();
        const response = await axios.post(URL + TRANSLATE_AUDIO, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: idToken,
          },
        });

        const audioTxt = response.data.transcribed_text;
        setAudioInputText(audioTxt);
        setApiResponseReady(true);
        setIsSubmitPressed(false);
      } catch (err) {
        console.error(err);
      } finally {
        setIsTranslationLoading(false);
      }
    } else {
      setLargeAudio(true);
      setAudioFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    }
  };

  const handleAudioSelect = async (event) => {
    event.preventDefault();
    const selectedFiles = Array.from(event.target.files);
    const acceptedFileTypes = [".mp3"];
    const filteredFiles = selectedFiles.filter((file) =>
      acceptedFileTypes.some((type) => file.name.endsWith(type))
    );

    if (filteredFiles.length !== selectedFiles.length) {
      setIsFileTypeModalOpen(true);
      return;
    } else {
      setIsFileTypeModalOpen(false);
    }

    const totalFiles = audioFiles.length + selectedFiles.length;
    const SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB

    if (totalFiles === 1 && selectedFiles[0].size <= SIZE_LIMIT) {
      setIsTranslationLoading(true);

      const file = selectedFiles[0];
      setRecordedAudio(file);
      let formData = new FormData();
      formData.append("file", file);
      formData.append("source_language", selectedLanguage);
      try {
        const idToken = await getIdToken();
        const response = await axios.post(URL + TRANSLATE_AUDIO, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: idToken,
          },
        });

        const audioTxt = response.data.transcribed_text;
        setAudioInputText(audioTxt);
        setApiResponseReady(true);
        setIsSubmitPressed(false);
        if (audioTxt) {
          setEnableSubmitAudio(true);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsTranslationLoading(false);
      }
    } else {
      setLargeAudio(true);

      setAudioFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    }
  };

  const handleDocumentCancel = (index) => {
    const updatedFiles = [...documentFiles];
    updatedFiles.splice(index, 1);
    setDocumentFiles(updatedFiles);
  };
  const handleAudioCancel = (index) => {
    const updatedFiles = [...audioFiles];
    updatedFiles.splice(index, 1);
    setAudioFiles(updatedFiles);
  };

  const handleDivClick = () => {
    setIsMultipleLanguageBoxOpen(!isMultipleLanguageBoxOpen);
  };

  const handleConfrimClick = (selectedLanguages) => {
    setTranslatedLanguage(selectedLanguages);
  };

  const handleCancelButton = () => {
    setTranslatedLanguage((prev) => prev);
    setIsMultipleLanguageBoxOpen(!isMultipleLanguageBoxOpen);
   
  };

  let activeAccount = null;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const getIdToken = async () => {
    try {
      const { idToken } = await instance.acquireTokenSilent(loginRequest);
      return `Bearer ${idToken}`;
    } catch (error) {
      console.log(error);
    }
  };

  const filesDataRef = useRef(filesData);

  const updateData = (dataObject) => {
    const index = filesDataRef.current.findIndex(
      (obj) => obj.taskId === dataObject.task_id
    );
    const folderNameEpochTime = parseInt(dataObject.folder_name);
    const folderNameUST = new Date(folderNameEpochTime * 1000).toLocaleString(
      "en-US",
      {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }
    );
    const modifiedObject = {
      fileName: folderNameUST,
      progress: dataObject.progress,
      taskId: dataObject.task_id,
      totalFiles: dataObject.number_of_files,
    };

    if (index !== -1) {
      let updatedData = [...filesDataRef.current];
      updatedData[index] = modifiedObject;
      setFilesData(updatedData);
    } else {
      setFilesData([...filesDataRef.current, modifiedObject]);
    }
  };

  useEffect(() => {
    filesDataRef.current = filesData;
  }, [filesData]);

  useEffect(() => {
    getIdToken();
    UploadedFiles();
    const username = activeAccount.username.split("@")[0];
    const socket = new WebSocket(
      `wss://creativedge-aws.deloitte.com/ws/data/?${username}`
    );

    socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      console.log("Message from server: ", data.message);
      updateData(data.message);
    });

    socket.addEventListener("error", (event) => {
      console.log("WebSocket error: ", event);
    });

    socket.addEventListener("close", (event) => {
      console.log("WebSocket is closed now.", event);
    });
    socket.addEventListener("send_failed_status", (event) => {
      const data = JSON.parse(event.data);
      console.log("Message from server: ", data.message);
      updateData(data.message);
    });
  }, []);

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleProceedClick = async (newFile) => {
    setIsTranslationLoading(true);
    const username = activeAccount.username.split("@")[0];
    const source_language = selectedLanguage;
    const target_language = translatedLanguage;
    const user_name = username;
    const token_count = 100;
    const idToken = await getIdToken();
    const localiseModel = localisationModel;
    const translateModel = translationModel;

    const formData = new FormData();
    if (selectedHeading === "audio") {
       audioFiles.forEach((file, index) => {
        formData.append("files", file);
      });
    } else {
        documentFiles.map((file, index) => {
        formData.append("files", file);
      });
    }

    formData.append("source_language", source_language);
    formData.append("target_language", target_language);
    formData.append("user_name", user_name);
    formData.append("token_count", token_count);
    formData.append("translation_model", translateModel);
    formData.append("localization_model", localiseModel);
    console.log(formData)
    console.log("---------------------------------")

    if (selectedHeading === "audio") {
      axios
        .post(URL + DIRECT_TRANSLATION_MULTIPLEFILEUPLOAD, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: idToken,
          },
        })
        .then((response) => {
          UploadedFiles();
          setAudioFiles([]);
          setIsTranslationLoading(false);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);

          setIsTranslationLoading(false);
        });
    } else {
      axios
        .post(URL + DIRECT_TRANSLATION_MULTIPLEFILEUPLOAD, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: idToken,
          },
        })
        .then((response) => {
          UploadedFiles();
          setDocumentFiles([]);
          setIsTranslationLoading(false);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);

          setIsTranslationLoading(false);
        });
    }
  };

  const displayAudioText = async () => {
    const idToken = await getIdToken();
    setIsTranslationLoading(true);
    audioFiles.map((file, index) => {
      if ((file.size / (1024 * 1024)).toFixed(2) >= 5) {
        axios
          .post(
            URL + TRANSLATE_AUDIO,
            { file },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: idToken,
              },
              responseType: "blob",
            }
          )
          .then((response) => {
            const newFileName = file.name.split(".");
            const newFile = new File([response.data], `${newFileName[0]}.pdf`, {
              type: response.data.type,
              lastModified: new Date(),
            });
            handleProceedClick(newFile);
          })
          .catch((error) => {
            console.error("Error extracting text from file:", error);
          })
          .finally(() => {
            setIsTranslationLoading(false);
          });
      } else {
        axios
          .post(
            URL + TRANSLATE_AUDIO,
            { file },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: idToken,
              },
            }
          )
          .then((response) => {
            const audioTxt = response["data"]["transcribed_text"];
            setAudioInputText(audioTxt);
          })
          .catch((error) => {
            console.error("Error extracting text from file:", error);
          })
          .finally(() => {
            setIsTranslationLoading(false);
          });
      }
    });
  };

  async function UploadedFiles() {
    const apiEndpoint = URL + POLLING_STATUS;

    const idToken = await getIdToken();
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      updateUI(responseData);
      setIsTranslationLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setIsTranslationLoading(false);
    }
  }

  const updateUI = (responseData) => {
    const updatedFilesData = responseData?.status?.map((item) => {
      const formattedDate = formatDate(item.created_at);
      const folderNameEpochTime = parseInt(item.folder_name);
      const folderNameUST = new Date(folderNameEpochTime * 1000).toLocaleString(
        "en-US",
        {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      );
      const fileData = {
        fileName: folderNameUST,
        progress: item.progress,
        targetLanguage: item.target_languages,
        originalFileName: item.folder_name,
        taskId: item.task_id,
        totalFiles: item.number_of_files,
        createdAt: formattedDate,
        status: item.status,
        refFile: item.ref_folder,
        isTranslation: item.is_translation,
        sourceLanguage: item.source_language,
      };

      return fileData;
    });

    setFilesData(updatedFilesData);
  };

  function getIconForFileType(fileExtension) {
    switch (fileExtension.toLowerCase()) {
      case "pdf":
        return pdfFile;
      case "txt":
        return textFile;
      case "doc":
      case "docx":
        return wordFile;
      default:
        return textFile;
    }
  }

  //******************************************************** */
  const handleLanguageClick = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };
  const handleTranslatedLanguageClick = (event) => {
    setTranslatedLanguageAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setLanguageAnchorEl(null);
    setTranslatedLanguageAnchorEl(null);
  };
  const handleLanguageSelect = (language) => {
    setSearchTerm("");
    setSelectedLanguage(language);
    handleClose();
  };
  const handleTranslatedLanguageSelect = (language) => {
    setSearchTerm("");
    setTranslatedLanguage(language);
    // if (translatedLanguage === "Castilian Spanish") {
    //   setSwapButton(false);
    // }
    handleClose();
  };
  const handleDirectTranslatedLanguageSelect = (language) => {
    setSearchTerm("");
    setTranslatedLanguage(language);
    handleClose();
  };
  const allLanguages = [
    "Arabic",
    "Bengali",
    "Chinese",
    "Czech",
    "Danish",
    "English",
    "Estonian",
    "Finnish",
    "French",
    "German",
    "Hindi",
    "Hungarian",
    "Indonesian",
    "Italian",
    "Japanese",
    "Korean",
    "Latvian",
    "Lithuanian",
    "Norwegian",
    "Polish",
    "Portuguese",
    "Romanian",
    "Russian",
    "Slovak",
    "Slovenian",
    "Spanish",
    "Swedish",
    "Tamil",
    "Vietnamese",
  ];
  const translatedLanguages = [
    "Arabic",
    "Bengali",
    "Chinese",
    "Czech",
    "Castilian Spanish",
    "Danish",
    "English",
    "Estonian",
    "Finnish",
    "French",
    "German",
    "Hindi",
    "Hungarian",
    "Indonesian",
    "Italian",
    "Japanese",
    "Korean",
    "Latvian",
    "Lithuanian",
    "Norwegian",
    "Polish",
    "Portuguese",
    "Romanian",
    "Russian",
    "Slovak",
    "Slovenian",
    "Spanish",
    "Swedish",
    "Tamil",
    "Vietnamese",
  ];

  const handleOptionChange = (option) => {
    if (option === "translation") {
      // setPromptDisabled(true);
      setShowPromptEditor(false);
    }
    if (translatedLanguage === "Castilian Spanish") {
      setTranslatedLanguage("English");
    }
    setSelectedOption(option);
  };

  useEffect(() => {
    // setPromptDisabled(true);
    setShowPromptEditor(false);
  }, [
    inputText,
    audioInputText,
    selectedLanguage,
    translatedLanguage,
    selectedEmotion,
    selectedButton,
  ]);

  const StylizeFile = async () => {
    const source_language = selectedLanguage;
    const target_language = translatedLanguage;
    const localiseModel = localisationModel;
    const formData = new FormData();

    // Use audioFiles if selectedHeading is 'audio', otherwise use documentFiles
    const files = selectedHeading === "audio" ? audioFiles : documentFiles;
    
    files.map((file, index) => {
      formData.append("files", file);
    });

    formData.append("source_language", source_language);
    formData.append("target_language", target_language);
    formData.append("tone", selectedButton);
    formData.append("emotion", selectedEmotion);
    formData.append("localization_model", localiseModel);

    setIsTranslationLoading(true);

    getIdToken()
      .then((idToken) => {
        axios
          .post(URL + STYLIZE_TRANSLATION, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: idToken,
            },
          })
          .then((res) => {
            console.log(res);
            setIsTranslationLoading(false);
            UploadedFiles();
            setDocumentFiles([]);
          })
          .catch((error) => {
            console.error(error);
            setIsTranslationLoading(false);
          });
      })
      .catch((error) => {
        setIsTranslationLoading(false);
        console.log(error);
      });
  };
  const suggestedLanguages = searchTerm
    ? allLanguages.filter((language) =>
        language.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
    : [];
  const suggestedTranslatedLanguages = searchTerm
    ? translatedLanguages.filter((language) =>
        language.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
    : [];
  const filteredLanguages = allLanguages.filter((language) =>
    language.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredTranslatedLanguages = translatedLanguages.filter((language) =>
    language.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredDirectTranslatedLanguages = allLanguages.filter((language) =>
    language.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCopyText = () => {
    const copyText = translatedText;
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        console.log("Text copied to clipboard: " + copyText);
      })
      .catch((error) => {
        console.error("Failed to copy text: " + error);
      });
  };

  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
  };

  const handleEmotionClick = (emotion) => {
    setSelectedEmotion(emotion);
  };
  const handleDetectLanguageButton = () => {
    detectLanguage(
      inputText,
      setErrorMessage,
      setIsTranslationLoading,
      setSelectedLanguage,
      selectedHeading,
      audioInputText
    );
  };
  const handleSubmitButton = () => {
    setIsSubmitPressed(false);
    if (selectedHeading === "audio"  && apiResponseReady) {
      if (selectedOption === "stylize") { 
        translate(
          audioInputText,
          selectedLanguage,
          translatedLanguage,
          selectedButton,
          selectedEmotion,
          setTranslatedText,
          setIsTranslationLoading,
          translationModel,
          localisationModel,
          setErrorMessage,
          setPrompt,
          setTranslationChunks
        );
        
        setIsSubmitPressed(true);
      } else {
        translationDirect(
          audioInputText,
          selectedLanguage,
          translatedLanguage,
          setSectionData,
          setTranslatedText,
          setIsTranslationLoading,
          setErrorMessage,
          translationModel,
          localisationModel,
          translationChunks,
          setTranslationChunks,
          audioInputText,
          selectedHeading
        );
        
        setIsSubmitPressed(true);
      }
    } else if(selectedHeading === "audio") {
      audioFiles.map((file, index) => {
        setUploadFileSuccess(true);
        if (selectedOption === "stylize") {
            StylizeFile(); 
            setIsSubmitPressed(true);
            setUploadFileSuccess(true);
            setIsDocumentUploaded(false);
            setFileCount((prevCount) => prevCount + 1);
            setFile(null);
      
        } else {
            setUploadFileSuccess(true);
            handleProceedClick();
            setIsDocumentUploaded(false);
            setFileCount((prevCount) => prevCount + 1);
            setFile(null);
    
        }
      });

    } else if (selectedHeading === "document") {
      if (selectedOption === "stylize") {
        StylizeFile();
        setIsSubmitPressed(true);
        setUploadFileSuccess(true);
        setIsDocumentUploaded(false);
        setFileCount((prevCount) => prevCount + 1);
        setFile(null);
      } else {
        setUploadFileSuccess(true);
        handleProceedClick();
        setIsDocumentUploaded(false);
        setFileCount((prevCount) => prevCount + 1);
        setFile(null);
      }
    } else {
      if (selectedOption === "stylize") {
        translate(
          inputText,
          selectedLanguage,
          translatedLanguage,
          selectedButton,
          selectedEmotion,
          setTranslatedText,
          setIsTranslationLoading,
          translationModel,
          localisationModel,
          setErrorMessage,
          setPrompt,
          setTranslationChunks
        );
        setIsSubmitPressed(true);
      } else {
        translationDirect(
          inputText,
          selectedLanguage,
          translatedLanguage,
          setSectionData,
          setTranslatedText,
          setIsTranslationLoading,
          setErrorMessage,
          translationModel,
          localisationModel,
          translationChunks,
          setTranslationChunks,
          audioInputText,
          selectedHeading
        );
        setIsSubmitPressed(true);
      }
      // setPromptDisabled(false);
      setIsDocumentUploaded(false);
      setIsAudioUploaded(false);
    }
    //setIsSubmitPressed(false)
  };
  const handleEditPromptButton = () => {
    setShowEditArea(!showEditArea);
    edit(
      translatedText,
      prompt,
      setTranslatedText,
      setIsTranslationLoading,
      setErrorMessage,
      localisationModel,
      translationModel,
      inputText,
      selectedButton,
      selectedEmotion,
      selectedLanguage,
      translatedLanguage,
      setPrompt,
      setTranslationChunks
    );
  };
  const handleSwap = () => {
    const translate = translatedLanguage;
    const selected = selectedLanguage;
    if (translate !== "Castilian Spanish") {
      setTranslatedLanguage(selectedLanguage);
      setSelectedLanguage(translate);
    }
  };

  const [isShowUploadBox, setIsShowUploadBox] = useState(false);
  const handleUploadButtonClick = () => {
    setIsShowUploadBox(true);
  };

  const handleUploadBoxClose = () => {
    setIsShowUploadBox(false);
  };

  const intervalRef = useRef();

  useEffect(() => {
    if (translationChunks) {
      let initialTimeout = setTimeout(() => {
        intervalRef.current = setInterval(() => {
          lamdaEndpoint(
            translationChunks,
            isTranslationRunning,
            intervalRef,
            setTranslatedText,
            setIsTranslationLoading
          );
        }, 2000);
      }, translationChunks.initialTime * 1000);
      return () => {
        clearInterval(intervalRef.current);
        clearTimeout(initialTimeout);
      };
    }
  }, [translationChunks]);

  const handleFile = (file) => {
    if (
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type === "text/plain"
    ) {
      setFile(file);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    var file = event.target.files[0];
    if (file) {
      handleFile(file);
      var fileSize = file.size;
      var fileSizeInMb = (fileSize / (1024 * 1024)).toFixed(2);
      setUploadSize(true);
      setActivateButton(true);
      setUploadedFiles(file.name);
    }
  };

  const [largeAudioFile, setLargeAudioFile] = useState(null);

  const [language, count] = translatedLanguage?.includes(",")
    ? [
        translatedLanguage?.split(",")[0],
        translatedLanguage?.split(",").length - 1,
      ]
    : [translatedLanguage, 0];

  return (
    <div
      className={`flex flex-col space-x-6 flex-1 ${
        isSidebarOpen ? "hidden" : "block"
      } sm:block`}
    >
      <div className="m-5 bg-white flex flex-col flex-1 rounded-lg p-5">
        <div>
          <FileTracker
            filesData={filesData}
            UploadedFiles={UploadedFiles}
            isClicked={isClicked}
            fileCount={fileCount}
            setFileCount={setFileCount}
          />
        </div>
        <div className="flex flex-row -mt-[30]">
          <div className="flex flex-col sm:flex-row w-full relative p-5 pt-0 pb-0 mt-[1.875rem] ">
            <div className="w-full sm:w-[44%]">
              <div className="md:flex relative md:justify-between mb-[1.0625rem]">
                <Button
                  className="font-bold mb-2 md:mb-0"
                  sx={{
                    padding: "0.25rem 0.625rem",
                    fontSize: "0.875rem",
                    minWidth: "8.4375rem",
                    whiteSpace: "nowrap",
                  }}
                  onClick={handleDetectLanguageButton}
                  variant="contained"
                  color="secondary"
                >
                  Detect Language
                </Button>
                <div className="md:flex md:justify-between w-full">
                  <Button
                    id="menu-button"
                    className="font-bold flex items-center"
                    onClick={handleLanguageClick}
                    sx={{
                      color: "black",
                      textDecoration: "underline",
                      textDecorationColor: "#26890D",
                      paddingBottom: "0.125rem",
                      fontSize: "0.875rem",
                      fontWeight: "600",
                      paddingLeft: "0.625rem",
                      textUnderlineOffset: "0.25rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {selectedLanguage || "Select Language"}
                    <ExpandMoreIcon />
                  </Button>
                  <Menu
                    id="language-menu"
                    anchorEl={languageAnchorEl}
                    keepMounted
                    open={Boolean(languageAnchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      className:
                        "origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5",
                      sx: {
                        maxHeight: "12.5rem",
                        overflow: "auto",
                      },
                    }}
                  >
                    <InputBase
                      placeholder="Search Language"
                      variant="outlined"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      sx={{ margin: "0.25rem", padding: "0.5rem" }}
                    />
                    {allLanguages.length > 0 ? (
                      <div>
                        <ul>
                          {allLanguages
                            .filter((language) =>
                              language
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            )
                            .map((language, index) => (
                              <li
                                className="p-1 pl-2 cursor-pointer"
                                key={index}
                                onClick={() => handleLanguageSelect(language)}
                              >
                                {language}
                              </li>
                            ))}
                        </ul>
                      </div>
                    ) : (
                      <MenuItem disabled>No matching languages found</MenuItem>
                    )}
                  </Menu>
                  <ModelSetting
                    translationModel={translationModel}
                    setTranslationModel={setTranslationModel}
                    localisationModel={localisationModel}
                    setLocalisationModel={setLocalisationModel}
                  />
                </div>
              </div>
              <div className="border border-gray mt-4 rounded-xl relative w-full h-[31.125rem] overflow-y-scroll  p-2">
                <div className="flex justify-around pt-5 border-b border-gray-300">
                  <span
                    onClick={() => handleHeadingClick("text")}
                    className={`cursor-pointer font-sans text-sm  leading-4 ${
                      selectedHeading === "text"
                        ? "text-[var(--CTA,#26890D)] border-b-3 border-[var(--CTA,#26890D)] font-bold"
                        : "text-initial border-none font-normal"
                    }`}
                  >
                    TEXT
                  </span>{" "}
                  <span
                    onClick={() => handleHeadingClick("document")}
                    className={`cursor-pointer font-sans text-sm  leading-4 ${
                      selectedHeading === "document"
                        ? "text-[var(--CTA,#26890D)] border-b-[0.1875rem] border-[var(--CTA,#26890D)] font-bold"
                        : "text-initial border-none font-normal"
                    }`}
                  >
                    DOCUMENT
                  </span>{" "}
                  <span
                    onClick={() => handleHeadingClick("audio")}
                    className={`cursor-pointer pb-2.5 font-sans text-sm  leading-4 ${
                      selectedHeading === "audio"
                        ? "text-[var(--CTA,#26890D)] border-b-3 border-[var(--CTA,#26890D)] font-bold"
                        : "text-initial border-none font-normal"
                    }`}
                  >
                    AUDIO
                  </span>
                </div>
                {selectedHeading === "text" && (
                  <TextField
                    id="textarea"
                    className="w-11/12 h-auto rounded-md border-none resize-none focus:outline-none focus:border-none focus:ring-0 focus:shadow-none"
                    rows={18}
                    placeholder="Enter your text here..."
                    defaultValue={inputText}
                    onChange={(e) => {
                      setInputText(e.target.value);
                    }}
                    sx={{ border: "none", "& fieldset": { border: "none" } }}
                    multiline
                  />
                )}
                {selectedHeading === "document" && (
                  <div>
                    {documentFiles?.length <= 0 && (
                      <div className="flex flex-col justify-between items-center w-725 h-260 ml-5 mr-5 mt-10">
                        <div className="m-4 mt-5">
                          <img
                            src={require("../assets/Inbox.jpg")}
                            alt="inbox-icon"
                          />
                        </div>
                        <div
                          onDrop={handleDocumentDrop}
                          onDragOver={(event) => event.preventDefault()}
                          className="border-dashed border-1 border-gray-300 p-[2.6875rem] cursor-pointer m-2"
                        >
                          Drag and Drop Documents here or{" "}
                          <label
                            htmlFor="fileInput"
                            className="cursor-pointer text-blue-500"
                          >
                            Browse Files
                            <input
                              id="fileInput"
                              type="file"
                              onChange={handleDocumentSelect}
                              accept=".doc, .docx, .pdf, .ppt, .pptx"
                              multiple
                              className="hidden"
                            />
                          </label>
                        </div>
                      </div>
                    )}

                    {documentFiles?.length > 0 && (
                      <div>
                        <p>Documents selected:</p>
                        <ul>
                          {documentFiles.map((file, index) => (
                            <li
                              key={index}
                              className="flex justify-between border-b border-gray-300 py-2.5 px-1.5"
                            >
                              <span>
                                {index + 1}. {file.name}
                              </span>{" "}
                              <button
                                onClick={() => handleDocumentCancel(index)}
                                className="mr-2 text-red-500 flex-shrink-0"
                              >
                                <img
                                  src={require("../assets/Cancel-UploadedFiles-Icon.png")}
                                  alt="Cross-icon"
                                />
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
                {selectedHeading === "audio" && (
                  <div>
                    {!apiResponseReady ? (
                      audioFiles?.length <= 0 && (
                        <div className="flex flex-col justify-between items-center w-725 h-260 ml-5 mr-5 mt-10 ">
                          <div className="m-4 mt-5">
                            <img
                              src={require("../assets/Inbox.jpg")}
                              alt="inbox-icon"
                            />
                          </div>
                          <div className="m-2">
                            <div
                              onDrop={handleAudioDrop}
                              onDragOver={(event) => event.preventDefault()}
                              className="border border-dashed border-gray-300 p-[2.6875rem] cursor-pointer"
                            >
                              Drag and Drop Audio here or{" "}
                              <label
                                htmlFor="fileInput"
                                className="cursor-pointer text-blue-500"
                              >
                                Browse Files
                                <input
                                  id="fileInput"
                                  type="file"
                                  onChange={handleAudioSelect}
                                  accept="audio/*"
                                  multiple
                                  className="hidden"
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <>
                        {recordedAudio && (
                          <div>
                            <p>Audio file selected:</p>
                            <ul>
                              <li className="flex justify-between border-b border-gray-300 py-2.5 px-1.5">
                                <span>1. {recordedAudio.name}</span>{" "}
                                <button
                                  onClick={() => {
                                    setRecordedAudio(null);
                                    setApiResponseReady(false);
                                    setAudioInputText("");
                                  }}
                                  className="mr-2 text-red-500"
                                >
                                  <img
                                    src={require("../assets/Cancel-UploadedFiles-Icon.png")}
                                    alt="Cross-icon"
                                  />
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                        <TextField
                          id="textarea"
                          className="w-11/12 h-auto rounded-md border-none resize-none focus:outline-none focus:border-none focus:ring-0 focus:shadow-none"
                          rows={18}
                          placeholder="Enter your text here..."
                          value={audioInputText}
                          onChange={(e) => {
                            console.log("openFileSelection is called");
                            setAudioInputText(e.target.value);
                          }}
                          sx={{
                            border: "none",
                            "& fieldset": { border: "none" },
                          }}
                          multiline
                        />
                      </>
                    )}

                    {audioFiles?.length > 0 &&
                      (audioSubmitButtonClicked ? (
                        <div></div>
                      ) : (
                        <div>
                          <p>Audio files selected:</p>
                          <ul>
                            {audioFiles.map((file, index) => (
                              <li
                                key={index}
                                className="flex justify-between border-b border-gray-300 py-2.5 px-1.5"
                              >
                                <span>
                                  {index + 1}. {file.name}
                                </span>{" "}
                                <button
                                  onClick={() => handleAudioCancel(index)}
                                  className="mr-2 text-red-500 flex-shrink-0"
                                >
                                  <img
                                    src={require("../assets/Cancel-UploadedFiles-Icon.png")}
                                    alt="Cross-icon"
                                  />
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    {audioSubmitButtonClicked && (
                      <TextField
                        id="textarea"
                        className="w-11/12 h-auto rounded-md border-none resize-none focus:outline-none focus:border-none focus:ring-0 focus:shadow-none"
                        rows={18}
                        placeholder="Enter your text here..."
                        defaultValue={audioInputText}
                        onChange={(e) => {
                          setAudioInputText(e.target.value);
                        }}
                        sx={{
                          border: "none",
                          "& fieldset": { border: "none" },
                        }}
                        multiline
                      />
                    )}
                  </div>
                )}
                {/* {audioSubmitButtonClicked}
                {isShowUploadBox && (
                  <UploadBox
                    handleProceedClick={handleProceedClick}
                    onClose={handleUploadBoxClose}
                    handleFileChange={handleFileChange}
                    handleFile={handleFile}
                    uploadSize={uploadSize}
                    setUploadSize={setUploadSize}
                    audioTrackFile={audioTrackFile}
                    setAudioTrackFile={setAudioTrackFile}
                    activateButton={activateButton}
                    setActivateButton={setActivateButton}
                    file={file}
                    setFile={setFile}
                    setIsDocumentUploaded={setIsDocumentUploaded}
                    setIsAudioUploaded={setIsAudioUploaded}
                    displayAudioText={displayAudioText}
                    setSelectedOption={setSelectedOption}
                    setShowAudio={setShowAudio}
                    showAudio={showAudio}
                  />
                )} */}
              </div>
            </div>
            <div
              id="tune"
              className={`flex flex-col space-y-2 sm:ml-5 mr-5  ${
                selectedOption === "translation" ||
                isDocumentUploaded ||
                isAudioUploaded
                  ? "mb-[20.625rem]"
                  : "mb-[0rem]"
              }sm:items-center`}
            >
              <div className="flex flex-col align-center justify-center">
                <Button
                  disabled={translatedLanguage === "Castilian Spanish"}
                  onClick={handleSwap}
                  sx={{
                    color: "#26890D",
                    paddingBottom: "0.125rem",
                  }}
                >
                  <img
                    alt="Arrow"
                    className="w-[3.125rem] h-[3.125rem]-mt-2"
                    src={require("../assets/arrow.jpg")}
                  />
                </Button>
              </div>
              <div
                id="translation"
                className="flex w-full align-center justify-center"
                onClick={() => handleOptionChange("translation")}
              >
                <Button
                  className={`text-green-700 font-semibold flex`}
                  variant={
                    selectedOption === "translation" ? "contained" : "outlined"
                  }
                  color={
                    selectedOption === "translation" ? "secondary" : "primary"
                  }
                  sx={{
                    width: "100%",
                    padding: "0.625rem 1rem",
                    borderRadius: "0.375rem",
                    borderColor: "#26890D",
                    color: selectedOption === "translation" ? "#fff" : "#000",
                    lineHeight: "1rem",
                    fontSize: "0.875rem",
                    // maxWidth: "160px",
                    fontWeight: 600,
                  }}
                >
                  DIRECT TRANSLATION
                </Button>
              </div>
              <div
                className="flex w-full align-center justify-center mt-2"
                id="stylize"
                onClick={() => handleOptionChange("stylize")}
              >
                <Button
                  variant={
                    selectedOption === "stylize" ? "contained" : "outlined"
                  }
                  color={selectedOption === "stylize" ? "secondary" : "primary"}
                  className={`text-green-700 font-semibold `}
                  sx={{
                    width: "100%",
                    lineHeight: "1rem",
                    fontSize: "0.875rem",
                    padding: "0.625rem 1rem",
                    borderRadius: "0.375rem",
                    borderColor: "#26890D",
                    color: selectedOption === "stylize" ? "#fff" : "#000",
                    // maxWidth: "160px",
                  }}
                  // disabled={selectedHeading === "audio"}
                >
                  STYLIZE
                </Button>
              </div>
              {selectedOption === "stylize" && (
               <>
                      <div                                      
                        className="flex flex-col items-center border border-solid rounded-xl py-2 space-y-2 border-[#26890D] w-full sm:w-[11.875rem] mt-2"
                      >
                        <div className="text-black-700 font-bold">TONE</div>

                        <Button
                          id="casual"
                          className={`text-black font-bold rounded-xl`}
                          onClick={() => handleButtonClick("casual")}
                          sx={{
                            borderRadius: "0.375rem",
                            width: "8.75rem",
                            padding: "0.5rem",
                            fontWeight: 600,
                            borderColor:
                              selectedButton === "casual"
                                ? "#86290D"
                                : "#e1e1e1",
                            color:
                              selectedButton === "casual" ? "#fff" : "#000",
                            backgroundColor:
                              selectedButton === "casual"
                                ? "#26890D"
                                : "#e1e1e1",
                          }}
                        >
                          Casual
                        </Button>
                        <Button
                          id="natural"
                          className={`text-black font-bold rounded-xl`}
                          onClick={() => handleButtonClick("natural")}
                          sx={{
                            borderRadius: "0.375rem",
                            width: "8.75rem",
                            padding: "0.5rem",
                            fontWeight: 600,
                            borderColor:
                              selectedButton === "natural"
                                ? "#86290D"
                                : "#e1e1e1",
                            color:
                              selectedButton === "natural" ? "#fff" : "#000",
                            backgroundColor:
                              selectedButton === "natural"
                                ? "#26890D"
                                : "#e1e1e1",
                          }}
                        >
                          Natural
                        </Button>

                        <Button
                          id="professional"
                          className={`text-black font-bold rounded-xl`}
                          onClick={() => handleButtonClick("professional")}
                          sx={{
                            borderRadius: "0.375rem",
                            width: "8.75rem",
                            padding: "0.5rem",
                            fontWeight: 600,
                            borderColor:
                              selectedButton === "professional"
                                ? "#86290D"
                                : "#e1e1e1",
                            color:
                              selectedButton === "professional"
                                ? "#fff"
                                : "#000",
                            backgroundColor:
                              selectedButton === "professional"
                                ? "#26890D"
                                : "#e1e1e1",
                          }}
                        >
                          Professional
                        </Button>
                      </div>
                      <div
                        id="div-sentiment"
                        className="flex flex-col items-center border border-solid rounded-xl py-1 mt-2 border-[#26890D] w-full sm:w-[11.875rem]"
                      >
                        <div className="text-black-700 font-bold">EMOTION</div>
                        <div className="flex justify-around w-full">
                          <Button
                            id="negative"
                            onClick={() => handleEmotionClick("negative")}
                            sx={{
                              color:
                                selectedEmotion === "negative"
                                  ? "#e8a237"
                                  : "#808080",
                            }}
                          >
                            <SentimentVeryDissatisfiedIcon fontSize="large" />
                          </Button>
                          <Button
                            id="neutral"
                            onClick={() => handleEmotionClick("neutral")}
                            sx={{
                              color:
                                selectedEmotion === "neutral"
                                  ? "#e8a237"
                                  : "#808080",
                            }}
                          >
                            <SentimentNeutralIcon fontSize="large" />
                          </Button>
                          <Button
                            id="positive"
                            onClick={() => handleEmotionClick("positive")}
                            sx={{
                              color:
                                selectedEmotion === "positive"
                                  ? "#e8a237"
                                  : "#808080",
                            }}
                          >
                            <SentimentSatisfiedAltIcon fontSize="large" />
                          </Button>
                        </div>
                      </div>
                </>
              )}
              <div className="flex flex-col align-center justify-center w-full">
                <Button
                  id="submit"
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: "100%",
                    padding: "0.625rem 1rem",
                    borderRadius: "0.375rem",
                    marginTop: "0.5",
                    lineHeight: "1rem",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                  }}
                  onClick={handleSubmitButton}
                  //disabled = {inputText == ''}
                  disabled={
                    !selectedHeading ||
                    (selectedHeading === "text" && !inputText.trim()) ||
                    (selectedHeading === "document" &&
                      !documentFiles?.length) ||
                    (selectedHeading === "audio" &&
                      !audioFiles.length &&
                      audioInputText === "")
                  }
                >
                  SUBMIT
                </Button>
                {uploadFileSuccess && (
                  <SuccessBackdrop
                    uploadFileSuccess={uploadFileSuccess}
                    setUploadFileSuccess={setUploadFileSuccess}
                    setFile={setFile}
                  />
                )}
                {selectedOption === "stylize" && (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        width: "100%",
                        padding: "0.5rem 1rem",
                        borderRadius: "0.375rem",
                        marginTop: "0.5rem",
                        fontWeight: 600,
                      }}
                      id="info"
                      onClick={() => {
                        setShowEditArea(!showEditArea);
                      }}
                    >
                      Edit Prompt
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className="w-full sm:w-[44%]">
              <div className="flex relative ">
                <Button
                  id="translated-menu-button"
                  className="font-bold"
                  onClick={
                    selectedHeading === "document"
                      ? handleDivClick
                      : handleTranslatedLanguageClick
                  }
                  sx={{
                    color: "black",
                    textDecoration: "underline",
                    textDecorationColor: "#26890D",
                    marginBottom: "1.25rem",
                    borderRadius: "0.325rem",
                    transform: "translate(-0.3125rem,0rem)",
                    textUnderlineOffset: "0.25rem",
                    fontWeight: 700,
                  }}
                >
                  {language
                    ? language
                    : translatedLanguage || "Select Translated Language"}
                  <ExpandMoreIcon />
                </Button>
                {selectedHeading === "document" && count > 0 && (
                  <Tooltip
                    placement="bottom-start"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "white",
                          color: "#000",
                          border: "0.5px solid gray",
                          maxWidth: "21.875rem",
                          fontWeight: "400",
                          fontFamily: "Open Sans",
                          height: "max-content",
                          fontSize: "16px",
                          padding: "8px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                        },
                      },
                    }}
                    arrow
                    title={`${translatedLanguage
                      .split(",")
                      .slice(1)
                      .join(", ")} `}
                  >
                    <div className="flex items-center justify-center mt-1 ml-0 bg-[#26890D]  text-white rounded-full w-7 h-7 cursor-pointer">
                      <span className="text-base font-semibold">{count}</span>
                    </div>
                  </Tooltip>
                )}

                {isMultipleLanguageBoxOpen && (
                  <SelectMultipleLanguage
                    searchMultipleTerm={searchMultipleTerm}
                    handleAlphabetClick={handleAlphabetClick}
                    handleAllAlphabetsClick={handleAllAlphabetsClick}
                    handleDivClick={handleDivClick}
                    handleCheckboxChange={handleCheckboxChange}
                    filteredMultipleLanguages={filteredMultipleLanguages}
                    handleSearchChange={handleSearchChange}
                    translatedLanguage={translatedLanguage}
                    handleCancelButton={handleCancelButton}
                    handleConfrimClick={handleConfrimClick}
                  />
                )}
                <Menu
                  id="translated-language-menu"
                  anchorEl={translatedLanguageAnchorEl}
                  keepMounted
                  open={Boolean(translatedLanguageAnchorEl)}
                  onClose={handleClose}
                  PaperProps={{
                    className:
                      "origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5",
                    sx: {
                      maxHeight: "12.5rem",
                      overflow: "auto",
                    },
                  }}
                >
                  <InputBase
                    placeholder="Search Language"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ margin: "0.25rem", padding: "0.5rem" }}
                  />
                  {filteredTranslatedLanguages.length > 0 ? (
                    <div>
                      <ul>
                        {selectedOption === "stylize" &&
                          filteredTranslatedLanguages.map((language, index) => (
                            <li
                              className="p-1 pl-2 cursor-pointer"
                              key={index}
                              onClick={() =>
                                handleTranslatedLanguageSelect(language)
                              }
                            >
                              {language}
                            </li>
                          ))}
                        {selectedOption === "translation" &&
                          filteredDirectTranslatedLanguages.map(
                            (language, index) => (
                              <li
                                className="p-1 pl-2 cursor-pointer"
                                key={index}
                                onClick={() =>
                                  handleDirectTranslatedLanguageSelect(language)
                                }
                              >
                                {language}
                              </li>
                            )
                          )}
                      </ul>
                    </div>
                  ) : (
                    <MenuItem disabled>No matching languages found</MenuItem>
                  )}
                </Menu>
              </div>

              <div className="bg-white border border-gray rounded-xl p-4 relative h-[31.125rem] overflow-y-scroll whitespace-break-spaces">
                <div
                  id="translated"
                  className="mb-4 absolute break-words h-[25.625rem] overflow-y-auto w-[84%]"
                >
                  {translatedText}
                </div>
                <Tooltip title="Copy">
                  <Button
                    onClick={handleCopyText}
                    sx={{
                      position: "absolute",
                      marginTop: "1rem",
                      right: "1.25rem",
                      transform: "translateX(50%) translateY(-50%)",
                      color: "#0a7a2f",
                      padding: "0.25rem",
                      minWidth: "auto",
                      width: "auto",
                      height: "auto",
                    }}
                  >
                    <ContentCopyIcon
                      sx={{ color: "black", fontSize: "1.5rem" }}
                    />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        {translatedText && (
          <div className="flex flex-row-reverse mr-6">
            <AiGeneratedContent />
          </div>
        )}
        <div className="flex flex-col mt-2">
          <div className="flex justify-end items-end mr-5">
            {(isSubmitPressed && translatedLanguage) === "Arabic" ? (
              <div className="flex flex-row">
                <div className="mr-1">Arabic:95%</div>{" "}
                <InfoIcon sx={{ color: "#26890D", marginLeft: "0.313rem" }} />
              </div>
            ) : (
              ""
            )}
            {(isSubmitPressed && translatedLanguage) === "Japanese" ? (
              <div className="flex flex-row">
                <div className="mr-1">Japanese:83%</div>{" "}
                <InfoIcon sx={{ color: "#26890D", marginLeft: "0.313rem" }} />
              </div>
            ) : (
              ""
            )}
            {(isSubmitPressed && translatedLanguage) === "Chinese" ? (
              <div className="flex flex-row">
                <div className="mr-1">Chinese:66%</div>{" "}
                <InfoIcon sx={{ color: "#26890D", marginLeft: "0.313rem" }} />
              </div>
            ) : (
              ""
            )}
            {(isSubmitPressed && translatedLanguage) === "French" ? (
              <div className="flex flex-row">
                <div className="mr-1">French:96%</div>{" "}
                <InfoIcon sx={{ color: "#26890D", marginLeft: "0.313rem" }} />
              </div>
            ) : (
              ""
            )}
            {(isSubmitPressed && translatedLanguage) === "German" ? (
              <div className="flex flex-row">
                <div className="mr-1">German:87%</div>{" "}
                <InfoIcon sx={{ color: "#26890D", marginLeft: "0.313rem" }} />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-row w-full pt-[0.625rem] p-5">
            <div className="relative h-[8.125rem] border border-1 rounded-xl border-gray overflow-hidden flex-[80%]">
              {showEditArea ? (
                <TextField
                  sx={{
                    width: "100%",
                    border: "none",
                    "& fieldset": { border: "none" },
                  }}
                  fullwidth
                  rows={4}
                  multiline
                  defaultValue={prompt}
                  onChange={handlePromptChange}
                />
              ) : (
                <div className="w-full p-1 max-h-full overflow-y-auto">
                  <p className="translation-word-wrap">{prompt}</p>
                </div>
              )}
            </div>
            <div className="border border-1 border-gray ml-5 h-[8.125rem] w-[8.125rem] rounded-xl flex justify-center items-center">
              <img
                src={require("../assets/bridge.png")}
                className="w-[7.5rem] h-[7.5rem]"
              />
            </div>
          </div>
          <div className="pl-5 ml-0">
            <Button
              variant="contained"
              color="secondary"
              id="newPrompt"
              //className="bg-green-700 text-white font-bold py-2 px-2 rounded"
              sx={{
                padding: "0.5rem 1rem",
                width: "11.25rem",
                marginTop: "0.625rem",
              }}
              onClick={() => {
                handleEditPromptButton();
              }}
            >
              USE NEW PROMPT
            </Button>
          </div>
        </div>
        {/* <div className="flex justify-end chatButton">
          <button
            className="bg-green-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2 openbtn"
            style={{ border: "2px solid green", cursor: "pointer" }}
            onclick="openForm()"
          >
            Track Files...
          </button>
          <div className="chat-popup" id="myForm">
            <form action="/action_page.php" className="form-container">
              <div className="text-white font-bold py-2 px-4 chatHeading">
                <h1>Track Files</h1>
                <button
                  type="button"
                  className="cancelButton"
                  onclick="closeForm()"
                >
                  X
                </button>
              </div>
              <label id="fileLabel" htmlFor="msg">
                <b>No file uploaded</b>
              </label>
              <textarea
                id="messageTextarea"
                placeholder="Type message.."
                name="msg"
                required=""
                defaultValue={""}
              />
            </form>
          </div>
        </div> */}

        <CustomModal
          open={isFileTypeModalOpen}
          handleClose={() => setIsFileTypeModalOpen(false)}
        >
          <div className="flex flex-col items-center justify-center p-5 m-2 h-52 rounded-2xl bg-[#fff3f4]">
            <div className="p-2">
              <WarningIcon
                sx={{
                  color: "red",
                  fontSize: "60px",
                  width: "80px",
                  height: "80px",
                }}
              />
            </div>
            <h2 className="p-2 font-medium text-center">
              Please Upload a valid file type.
            </h2>
            <div className="text-center font-semibold my-2">
              {selectedHeading === "audio" ? (
                <p>
                  Accepted audio formats: <span className="mx-2">.mp3</span>
                </p>
              ) : selectedHeading === "document" ? (
                <p>
                  Accepted formats:{" "}
                  <span className="mx-2">.pdf, .docx, .doc, .ppt, .pptx</span>
                </p>
              ) : (
                <p>Select a type to see accepted formats.</p>
              )}
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};

export default TranslatePage;
