import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
const allLanguages = [
  "English",
  "Arabic",
  "Bengali",
  "Chinese",
  "French",
  "German",
  "Hindi",
  "Indonesian",
  "Italian",
  "Japanese",
  "Korean",
  "Latvian",
  "Portuguese",
  "Russian",
  "Spanish",
  "Tamil",
  "Vietnamese",
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TranslateDropdown = ({
  translateLanguage,
  handleTranslateLanguage,
  activeTab,
  selectedTitle,
}) => {
  return (
    <div
      className={`flex flex-row justify-end w-full h-9 mb-1 ${activeTab === "Creative Concepts" ? "mt-5" : "mt-0"}`}
      
    >
      <div
        className="h-4 mt-px ml-2"
      >
        Translate
      </div>
      <FormControl
        sx={{
          width: "140px",
          marginBottom: "10px",
          marginLeft: "20px",
          marginRight: activeTab === "Creative Concepts" ? "20px" : "0px",
        }}
      >
        <Select
          value={
            activeTab === "Flyer"
              ? translateLanguage[activeTab]
              : translateLanguage[activeTab][selectedTitle]
          }
          onChange={(e) => {
            handleTranslateLanguage(e.target.value, activeTab);
          }}
          MenuProps={MenuProps}
          sx={{ width: "100%", height: "100%", background:"white", fontWeight:600 }}
        >
          {allLanguages.map((language) => (
            <MenuItem value={language}>{language}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TranslateDropdown;
