import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import DeleteIcon from "../assets/delete-icon.svg";
import "./MultipleSignals.css";

const MultipleSignals = ({
  signals,
  setSignals,
  formData,
  formDataError,
  handleFormData,
  setSignalDataChanged,
  showHelperText,
}) => {
  const handleAddField = () => {
    setSignals([...signals, ""]);
  };

  const handleSignalChange = (index, signal) => {
    const updatedSignals = signals.map((sig, sigIndex) =>
      sigIndex === index ? signal : sig
    );
    setSignals(updatedSignals);
    return updatedSignals;
  };

  const handleDeleteSignalButton = async (index) => {
    setSignals(signals.filter((signal, sigIndex) => sigIndex !== index));
  };

  useEffect(() => {
    handleFormData({
      ...formData,
      demographicData: signals,
    });
  }, [signals]);
  return (
    <FormControl className="multiple-signals">
      {signals.map((signal, index) => (
        <Box className="field-container" key={index}>
          <TextField
            key={index}
            label="Describe your audience"
            multiline
            fullWidth
            rows={4}
            color="tertiary"
            className="text-field"
            value={signal}
            onChange={(e) => {
              setSignalDataChanged(true);
              const updatedSignals = handleSignalChange(index, e.target.value);
              handleFormData({
                ...formData,
                demographicData: updatedSignals,
              });
            }}
            InputLabelProps={{
              style: {
                color: "#828282",
              },
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "currentColor",
                opacity: 1,
              },
              "& .MuiOutlinedInput-root": {
                overflow: "hidden",
              },
              "& .MuiOutlinedInput-input": {
                overflow: "auto",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              },
            }}
            focused
            placeholder="In this box, describe your audience. You can put in data science points that describe this audience or plain language. Note: This works to make personas, not descriptions of groups"
            error={
              !signal.trim().length && !!formDataError.demographicData[index]
            }
            helperText={
              formDataError.demographicData[index] && !signal.trim().length ? (
                <Box sx={{ marginTop: 1 }}>
                  {formDataError.demographicData[index]}
                </Box>
              ) : (
                ""
              )
            }
          />
          {signals.length <= 1 &&
            showHelperText["audience"] &&
            index === 0 &&
            signals[index].length > 0 && (
              <FormHelperText sx={{ textAlign: "right" }}>
                Extracted From An Uploaded Ad
              </FormHelperText>
            )}
          {signals.length > 1 && (
            <Box
              className={`delete-icon-box ${
                formDataError.demographicData[index]
                  ? "delete-icon-box-mt-error"
                  : "delete-icon-box-mt-no-error"
              }`}
            >
              <IconButton
                onClick={() => handleDeleteSignalButton(index)}
                className="delete-icon-button"
                disabled={signals.length === 1}
              >
                <img src={DeleteIcon} alt="Delete Icon" />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}
      <button
        onClick={handleAddField}
        className={`add-button font-semibold ${
          signals.length >= 3 ? "add-button-disabled" : ""
        }`}
        disabled={signals.length >= 3}
      >
        Add
      </button>
    </FormControl>
  );
};

export default MultipleSignals;
