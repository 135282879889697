import { useState } from "react";
import { getIdToken } from "../utils/translateHelper";
import axios from "axios";
import { DOWNLOAD_GENERATIVE_FILL } from "../utils/constants";
import "./Dropdown.css";

const Dropdown = ({
  image,
  promtClickCount,
  setIsLoading,
  downloadPathImageDownload,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const DownloadButtonClicked = () => {
    setShowDropdown(!showDropdown);
  };

  const downloadGenerativeFillImage = () => {
    setShowDropdown(false);
    setIsLoading(true);
    const requestBody = {
      path: downloadPathImageDownload[promtClickCount],
    };

    getIdToken().then((idToken) => {
      axios
        .post(DOWNLOAD_GENERATIVE_FILL, JSON.stringify(requestBody), {
          headers: { Authorization: idToken },
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/zip" });
          const blobUrl = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = blobUrl;
          anchor.target = "_blank";
          anchor.click();

          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    });
  };

  return (
    <div className="absolute right-0 top-2 mr-2.5">
      <button onClick={DownloadButtonClicked} className="downloadButton_fill">
        Download
        <div className="ml-2">
          {showDropdown ? (
            <img
              src={require("../assets/dropdown_up_arrow.png")}
              alt="arrow_button"
            ></img>
          ) : (
            <img
              src={require("../assets/dropdown_down_arrow.png")}
              alt="arrow_button"
            ></img>
          )}
        </div>
      </button>
      {showDropdown && (
        <div className="dropdown_container">
          <p
            onClick={() => downloadGenerativeFillImage()}
            className="cursor-pointer"
          >
            ZIP
          </p>
        </div>
      )}
    </div>
  );
};
export default Dropdown;
