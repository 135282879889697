import React, { useState } from "react";
import "./SelectMultipleLanguageBox.css";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const SelectMultipleLanguage = ({
  searchMultipleTerm,
  handleAlphabetClick,
  handleAllAlphabetsClick,
  handleDivClick,
  handleCheckboxChange,
  handleSearchChange,
  filteredMultipleLanguages,
  translatedLanguage,
  handleCancelButton,
  handleConfrimClick
}) => {

  const [selectedLanguages, setSelectedLanguages] = useState(translatedLanguage || "");

  const handleCheckbox = (language) => {
    setSelectedLanguages((prevLanguages) => {
      if (prevLanguages.includes(language)) {
        return prevLanguages
          .split(",")
          .filter((l) => l !== language)
          .join(",");
      } else {
        return prevLanguages
          ? `${prevLanguages},${language}`
          : language;
      }
    });
  };
  return (
    <div className="language_container ">
      <div className="overlay">
        <div className="multipleLanguageSelect_popup z-50  mt-24">
          <div className=" max-h-[600px] flex flex-col">
            <div className="flex justify-around items-center border-b border-[#c9c9c9]">
              <TextField
                label="Search Language"
                variant="outlined"
                value={searchMultipleTerm}
                onChange={handleSearchChange}
                sx={{
                  margin: "4px",
                }}
              />

              <div className="mb-2.5">
                <button onClick={handleAllAlphabetsClick} className="mr-[5px]">
                  All
                </button>
                {Array.from({ length: 26 }, (_, i) =>
                  String.fromCharCode(65 + i)
                ).map((alphabet, index) => (
                  <button
                    key={index}
                    onClick={() => handleAlphabetClick(alphabet)}
                    className="mr-[5px]"
                  >
                    {alphabet}
                  </button>
                ))}
              </div>
              <Button onClick={handleCancelButton}>
                <img
                  src={require("../assets/cancel-multipleSelectedLanguage.png")}
                  alt="cancel"
                  className="w-5"
                />
              </Button>
            </div>
            <div className="border-b border-gray-300 grid grid-cols-[repeat(auto-fit,_minmax(150px,_1fr))] gap-3 max-h-[400px] max-w-[800px]">
              {Object.keys(filteredMultipleLanguages).map((alphabet) => (
                <div key={alphabet}>
                  <h3 className="text-sm font-semibold">{alphabet}</h3>
                  <ul className="p-0 m-0">
                    {filteredMultipleLanguages[alphabet].map(
                      (language, index) => (
                        <li key={index} className="list-none">
                          <input
                            type="checkbox"
                            id={`${alphabet}_${index}`}
                            onChange={() => handleCheckbox(language)}
                            checked={selectedLanguages?.includes(language)}
                          />
                          <label htmlFor={`${alphabet}_${index}`}>
                            {language}
                          </label>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              ))}
            </div>
            <div className="flex justify-end mt-3">
              <Button
                // onClick={handleDivClick}
                onClick={()=>{
                  handleConfrimClick(selectedLanguages);
                  handleDivClick()
                }}
                sx={{
                  borderRadius: "6px",
                  background: "var(--CTA, #26890d)",
                  display: "flex",
                  padding: "10px 16px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  color: "#fff",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "24px",
                  "&:hover": {
                    background: "#1e6b0c",
                  },
                }}
              >
                CONFIRM
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectMultipleLanguage;
