import axios from "axios";
import { useEffect } from "react";
import CardProductDescription from "../components/CardProductDescription";
import Error from "../components/Error";
import ReviseProductDescription from "../components/ReviseProductDescription";
import TranslateDropdown from "../components/TranslateDropdown";
import { GENERATE_PROMT_PRODUCT_DESCRIPTION } from "../utils/constants";
import { getIdToken } from "../utils/translateHelper";

const ProductDescription = ({
  setSectionData,
  sectionData,
  activeTab,
  errorMessage,
  setErrorMessage,
  isLoading,
  translateLanguage,
  setSourceLanguage,
  setTranslateLanguage,
  sourceLanguage,
  directTranslate,
  setInputText,
  handleTranslateLanguage,
  setSelectedLanguage,
  titles,
  selectedTitle,
  setIsProductDescriptionLoading,
  productDescriptionContentLocked,
  setProductDescriptionContentLocked,
  file,
  setFile,
  imagePreview,
  setImagePreview,
  dropRef,
  fileInputRef,
  productDescriptionState,
  setProductDescriptionState,
  isSidebarOpen,
  textModel
}) => {
  const onFileChange = (e) => {
    setFile(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = async () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (sectionData.productDescription.length !== 0) {
      if (sourceLanguage[activeTab] !== translateLanguage[activeTab]) {
        directTranslate(
          sectionData.productDescription,
          sourceLanguage[activeTab][selectedTitle],
          translateLanguage[activeTab][selectedTitle],
          activeTab
        );
        setSourceLanguage((prev) => ({
          ...prev,
          [activeTab]: translateLanguage[activeTab],
        }));
      }
    }
  }, [translateLanguage[activeTab]]);
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  useEffect(() => {
    let div = dropRef.current;

    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      let files = e.dataTransfer.files;
      if (files && files.length > 0) {
        const file = files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    div?.addEventListener("dragenter", handleDragEnter);
    div?.addEventListener("dragover", handleDragOver);
    div?.addEventListener("dragleave", handleDragLeave);
    div?.addEventListener("drop", handleDrop);

    return () => {
      div?.removeEventListener("dragenter", handleDragEnter);
      div?.removeEventListener("dragover", handleDragOver);
      div?.removeEventListener("dragleave", handleDragLeave);
      div?.removeEventListener("drop", handleDrop);
    };
  }, []);

  const CancelIcon = () => {
    setFile(null);
    setImagePreview(null);
    setProductDescriptionState({
      brand_name: "",
      product_name: "",
      product_description: "",
      collection: "",
      text_format:"",
      character_limit:""
    });
    setSectionData((previousState) => ({
      ...previousState,
      productDescription: "",
    }));
  };

  const GeneratePromtProductDescription = () => {
    setIsProductDescriptionLoading(true);

    let requestBody = {
      image: imagePreview,
      brand_name: productDescriptionState.brand_name,
      product_name: productDescriptionState.product_name,
      product_description: productDescriptionState.product_description,
      collection: productDescriptionState.collection,
      text_format:productDescriptionState.text_format,
      character_limit:productDescriptionState.character_limit
    };

    getIdToken()
      .then((idToken) => {
        axios
          .post(GENERATE_PROMT_PRODUCT_DESCRIPTION, requestBody, {
            headers: { Authorization: idToken },
          })
          .then((response) => {
            setSectionData((previousState) => ({
              ...previousState,
              productDescription: response.data.product_description,
            }));

            setIsProductDescriptionLoading(false);
          })
          .catch((error) => {
            setIsProductDescriptionLoading(false);
            setErrorMessage(error);
          });
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      className={`w-full flex flex-col flex-1 pt-4 px-10 pb-10 m-5 bg-white rounded-s-xl${
        isSidebarOpen ? "hidden" : "block"
      } sm:block ${textModel==="llama"?"cursor-not-allowed opacity-50":""}`}
    >
      {errorMessage && <Error errorMessage={errorMessage.message} />}
      {sectionData.productDescription.length ? (

        <div>
          <TranslateDropdown
            translateLanguage={translateLanguage}
            handleTranslateLanguage={handleTranslateLanguage}
            activeTab={activeTab}
            selectedTitle={selectedTitle}
          />
        </div>
      ) : null}
      {file ? (
        <>
          <div
            className={`flex flex-col sm:flex-row w-full ${
              !sectionData.productDescription.length
                ? "h-full"
                : "max-h-[26.438rem]"
            }`}
          >
            <div className="w-full sm:w-2/5 mr-0 sm:mr-5 h-full relative my-1">
              {imagePreview && (
                <div className="relative">
                  <img
                    src={require("../assets/Cancel_Icon_Product Description.png")}
                    alt="CancelIcon"
                    className="absolute right-0 p-3 cursor-pointer"
                    onClick={() => CancelIcon()}
                  />
                  <img
                    src={imagePreview}
                    alt="preview"
                    className="h-full w-full object-contain rounded-xl max-h-[423px]"
                  />
                </div>
              )}
            </div>
            <CardProductDescription
              text={sectionData.productDescription}
              activeTab={activeTab}
              setInputText={setInputText}
              sectionData={sectionData}
              setSelectedLanguage={setSelectedLanguage}
              translateLanguage={translateLanguage}
              isLoading={isLoading}
              selectedTitle={selectedTitle}
              setProductDescriptionContentLocked={
                setProductDescriptionContentLocked
              }
              productDescriptionContentLocked={productDescriptionContentLocked}
              GeneratePromtProductDescription={GeneratePromtProductDescription}
              productDescriptionState={productDescriptionState}
              setProductDescriptionState={setProductDescriptionState}
            />
          </div>
          {sectionData.productDescription?.length !== 0 && (
            <div className="w-full pt-7">
              <ReviseProductDescription
                GeneratePromtProductDescription={
                  GeneratePromtProductDescription
                }
                productDescriptionContentLocked={
                  productDescriptionContentLocked
                }
                setIsProductDescriptionLoading={setIsProductDescriptionLoading}
                setSectionData={setSectionData}
                imagePreview={imagePreview}
                sectionData={sectionData}
              />
            </div>
          )}
        </>
      ) : (
        <div
          ref={dropRef}
          className="flex flex-col items-center justify-center gap-7 w-full h-[50vh] border-2 border-[#DFDFDF] rounded-xl"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <img
            src={require("../assets/DragDropIcon.png")}
            alt="Drag_Drop_Icon"
          />
          <p className="text-[#53565A] font-normal">
            Drag and Drop Image here or
          </p>
          <div >
            <input
              id="file-upload"
              accept=".jpg, .png, .jpeg, .webp"
              ref={fileInputRef}
              type={textModel==="claude"?"file":""}
              onChange={onFileChange}
              hidden
              className="cursor-pointer text-blue-500"
            />
            <label
              htmlFor="file-upload"
              className={textModel==="claude"?"cursor-pointer text-[#26890D] font-bold":"cursor-not-allowed text-[#26890D] font-bold"}
            >
              Upload
            </label>
          </div>
        </div>
      )}
    </div>
    /* </div> */
  );
};

export default ProductDescription;
