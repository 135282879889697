import axios from "axios";
import { format, parseISO } from 'date-fns';
import moment from "moment";
import { msal } from "..";
import { loginRequest } from "../authConfigFile/authConfig";
import {
  ADMIN_FILTER_URL,
  AUTH,
  BANNER_HORIZONTAL_DIMENSION,
  BANNER_HORIZONTAL_PREVIEW,
  BANNER_VERTICAL_DIMENSION,
  BANNER_VERTICAL_PREVIEW,
  CREATIVE_IDEATION,
  CREATIVE_IDEATION_EDIT,
  CREATIVE_IDEATION_REVISION,
  DIRECT_TRANSLATION,
  DOWNLOAD_FLYER,
  EMAIL,
  EMAIL_REVISION,
  FLYER,
  FLYER_DIMENSION,
  FLYER_EDIT,
  FLYER_PREVIEW,
  FLYER_REVISION,
  FLYER_SQUARE_PREVIEW,
  FLYER_VERTICAL_PREVIEW,
  FOUNDATION_APPLY,
  FOUNDATION_UNDO,
  MAGAZINE_AD,
  MAGAZINE_AD_EDIT,
  MAGAZINE_AD_REVISION,
  MEDIA_LIKE_API,
  PAGE_FETCH_ALL,
  PAGE_FETCH_CARD,
  PAGE_SAVE,
  PERSONA,
  REVISION,
  SOCIAL_MEDIA,
  SOCIAL_MEDIA_IMAGE,
  SOCIAL_MEDIA_REVISION,
  STORY_DIRECT_VIEW_GENERATE_STEP,
  STORY_DIRECT_VIEW_REVISION,
  STORY_DIRECT_VIEW_STORY_REVISION,
  STORY_GENERATE_IMAGE,
  STORY_GENERATE_PDF,
  STORY_GENERATE_STEP,
  STORY_REGENERATE_IMAGE,
  STORY_REGENERATE_STEP,
  STORY_REVISE_STEP,
  STORY_TAILORED_GENERATE_STEP,
  STORY_UPDATE_STEP,
  STORY_UPDATE_STEP_NO_IMAGE,
  URL,
  VALIDATE_PASSKEY,
  START_TRAINING_URL,
  CHECK_MODEL_STATUS,
  CHECK_MODEL_DATA,
  MODEL_S3_URL,
  SAVE_PROJECT_API,
  FETCH_ALL_CARD_PROJECT,
  FETCH_ALL_PROJECT,
  DELETE_PROJECT,
  CHECK_MODEL
  
} from "../utils/constants";

const errorMessages = {
  429: "Limit Exceeded. Please try after sometime!",
  401: "Session Expired. Please login again!",
  400: "Something Went Wrong!",
};

const getIdToken = async (setErrorMessage) => {
  try {
    const res = await msal.acquireTokenSilent(loginRequest);
    const { idToken } = res;
    return `Bearer ${idToken}`;
  } catch (error) {
    setErrorMessage(error);
  }
};

export const formatDate = (dateString) => {
  try {
      const date = parseISO(dateString);
      return format(date, 'EEE, MMM d, yyyy, h:mm b');
  } catch (e) {
      console.error('Invalid Date: ', dateString);
      return 'Invalid Date';
  }
};

const formattedStringArray = (boolArray) => {
  return boolArray?.map(
    (value) =>
      value?.toString().charAt(0).toUpperCase() + value?.toString().slice(1)
  );
};

const formattedStringVal = (value) => {
  return value?.toString().charAt(0).toUpperCase() + value?.toString().slice(1);
};
export const imageModelApiCall=(setErrorMessage,setLoraModels)=>{
    getIdToken(setErrorMessage)
    .then((idToken)=>{
      axios.post(CHECK_MODEL_DATA,
        {user_name:localStorage.getItem("cred")},
        {headers: { Authorization: idToken }
      }).then((response)=>{
        console.log(response.data.response)
        setLoraModels(response.data.response)
      }).catch((error) => {
        error.message = errorMessages.hasOwnProperty(
          error?.response?.status
        )
          ? errorMessages[error?.response?.status]
          : error.message;
        setErrorMessage(error);
      })
     
    })
}
export const loraCheckModel=(setErrorMessage,modelName)=>{
  getIdToken(setErrorMessage)
    .then((idToken)=>{
      axios.post(CHECK_MODEL,
        {model_name:modelName,user_name:localStorage.getItem("cred")},
        {headers: { Authorization: idToken }
      }).then((response)=>{
        console.log("lora check "+response)
        
      }).catch((error) => {
        console.log("lora check "+error)
        error.message = errorMessages.hasOwnProperty(
          error?.response?.status
        )
          ? errorMessages[error?.response?.status]
          : error.message;
        setErrorMessage(error);
      })
     
    })
 
}
export const apiCall = (
  activeTab,
  formData,
  sectionData,
  flyerHeadline,
  flyerSubtext,
  setFlyerHeadline,
  setFlyerSubtext,
  setIsPersonaLoading,
  setIsPrintLoading,
  isFlyerLoading,
  setIsFlyerLoading,
  isFlyerVerticalLoading,
  setIsFlyerVerticalLoading,
  isFlyerSquareLoading,
  setIsFlyerSquareLoading,
  isBannerHorizontalLoading,
  setIsBannerHorizontalLoading,
  isBannerVerticalLoading,
  setIsBannerVerticalLoading,
  setIsCreativeLoading,
  setIsEmailLoading,
  setIsSocialLoading,
  setSectionData,
  setErrorMessage,
  index,
  translateLanguage,
  imageDimensions,
  horizontal,
  vertical,
  square,
  showingBannerHorizontal,
  showingBannerVertical,
  previewImage,
  setPreviewImage,
  previewAccess,
  setPreviewAccess,
  checkedGeneratedImages,
  setCheckedGeneratedImages,
  personaWasTranslated,
  setTranslateLanguage,
  sourceLanguage,
  printContentLocked,
  printImageLocked,
  socialMediaContentLocked,
  socialMediaImageLocked,
  creativeContentLocked,
  creativeImageLocked,
  verticalFlyerContentLocked,
  verticalFlyerImageLocked,
  horizontalFlyerContentLocked,
  horizontalFlyerImageLocked,
  squareFlyerContentLocked,
  squareFlyerImageLocked,
  horizontalBannerContentLocked,
  horizontalBannerImageLocked,
  verticalBannerContentLocked,
  verticalBannerImageLocked,
  setTitles,
  selectedTitle,
  flagRegenerated,
  setTerritoryTitles,
  selectedTheme,
  textModel,
  imgModel
) => {
  switch (activeTab) {
    case "Persona":
      setIsPersonaLoading(true);
      const signal = flagRegenerated
        ? formData.demographicData[selectedTitle]
        : formData.demographicData;
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(
              PERSONA,
              {
                signal,
                concept_territory:
                  selectedTheme === -1
                    ? ""
                    : sectionData.creativeConcepts["Concepts and Images"][
                        selectedTitle
                      ][selectedTheme]?.["ConceptTerritory"],
                text_model:textModel
              },
              {
                headers: { Authorization: idToken },
              }
            )
            .then((response) => {
              if (flagRegenerated) {
                const newPersona = sectionData.persona;
                newPersona[selectedTitle] = response.data.response.Persona;
                setSectionData((prev) => ({
                  ...prev,
                  persona: newPersona,
                }));
                setTitles((prev) => [
                  ...prev.slice(0, selectedTitle),
                  response.data.response.tab_name,
                  ...prev.slice(selectedTitle + 1),
                ]);
              } else {
                const newPersona = response.data.response?.map(
                  (item, index) => {
                    return item.Persona;
                  }
                );
                setSectionData((previousState) => ({
                  ...previousState,
                  persona: newPersona,
                }));
                const newTitles = response.data.response?.map((item, index) => {
                  return item.tab_name;
                });
                setTitles(newTitles);
              }
              const payload = {
                source_language: "English",
                target_language: translateLanguage?.["Persona"][selectedTitle],
                input: sectionData.persona?.[selectedTitle],
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if ("English" === translateLanguage?.["Persona"][selectedTitle])
                return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if ("English" === translateLanguage?.["Persona"][selectedTitle])
                return;
              const newPersona = sectionData.persona.map((item, index) => {
                if (index === selectedTitle) return res.data["translation"];
                else return item;
              });
              setSectionData((previousState) => ({
                ...previousState,
                persona: newPersona,
              }));
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => {
              setIsPersonaLoading(false);
            });
        })
        .catch((error) => {
          setIsPersonaLoading(false);
          setErrorMessage(error);
        });

      break;
    case "Print":
      setIsPrintLoading(true);
      var jsonData = {};
      jsonData["product_service"] = formData.product;
      jsonData["text_model"]=textModel;
      jsonData["img_model"]=imgModel
      jsonData["objective"] = formData.objective;
      jsonData["tone_mood"] = formData.tone;
      jsonData["key_selling_points"] = formData.sellingPoint;
      jsonData["magazine_ad"] =
        sectionData?.print?.[selectedTitle]?.["Magazine Ad"] || null;
      jsonData["magazine_lock"] = formattedStringVal(
        printContentLocked[selectedTitle]
      );
      jsonData["images_lock"] = formattedStringArray(
        printImageLocked[selectedTitle]
      );
      jsonData["persona"] = flagRegenerated
        ? sectionData.persona[selectedTitle]
        : sectionData.persona;
      jsonData["concept_territory"] =
        sectionData.creativeConcepts?.["Concepts and Images"]?.[
          selectedTitle
        ]?.[selectedTheme]?.["ConceptTerritory"];

      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(MAGAZINE_AD, jsonData, {
              headers: { Authorization: idToken },
            })
            .then((response) => {
              if (!flagRegenerated) {
                let strings = [];
                response.data.response.forEach((data, index) => {
                  let string = "";
                  const obj = data["Magazine Ad"];
                  Object.keys(JSON.parse(obj)).forEach((key) => {
                    string += `${key}: ${JSON.parse(obj)[key]}\n`;
                  });
                  strings[index] = string;
                });
                setSectionData((prevState) => {
                  const sectionDataCopy = { ...prevState };
                  sectionDataCopy.print = response.data.response;
                  strings.forEach((string, index) => {
                    sectionDataCopy.print[index]["Magazine Ad"] = string;
                    sectionDataCopy.print[index]["Images"] =
                      response.data.response[index]["Images"];
                  });
                  return sectionDataCopy;
                });
              } else {
                let string = "";
                const obj = response.data.response["Magazine Ad"];
                Object.keys(JSON.parse(obj)).forEach((key) => {
                  string = string + `${key}: ${JSON.parse(obj)[key]}\n`;
                });
                setSectionData((prevState) => {
                  const sectionDataCopy = { ...prevState };
                  sectionDataCopy.print[selectedTitle]["Magazine Ad"] =
                    !printContentLocked[selectedTitle]
                      ? string
                      : prevState?.print?.[selectedTitle]?.["Magazine Ad"];
                  sectionDataCopy.print[selectedTitle]["Images"] =
                    response.data.response.Images.map((value, idx) => {
                      return value === ""
                        ? prevState?.print?.[selectedTitle]?.Images[idx]
                        : value;
                    });
                  return sectionDataCopy;
                });
              }

              const payload = {
                source_language: "English",
                target_language: translateLanguage?.["Print"][selectedTitle],
                input: sectionData.print?.[selectedTitle]?.["Magazine Ad"],
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };

              if (sectionData.print?.[selectedTitle]?.["Magazine Ad"] !== "") {
                if ("English" === translateLanguage?.["Print"][selectedTitle])
                  return;
                return axios.post(DIRECT_TRANSLATION, payload, {
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: idToken,
                  },
                });
              }
            })
            .then((res) => {
              if ("English" === translateLanguage?.["Print"][selectedTitle])
                return;
              setSectionData((prevState) => ({
                ...prevState,
                print: {
                  ...prevState.print,
                  "Magazine Ad": res.data["translation"],
                },
              }));
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => {
              setIsPrintLoading(false);
            });
        })
        .catch((error) => {
          setIsPrintLoading(false);
          setErrorMessage(error);
        });

      break;
    case "Flyer":
      if (!isFlyerLoading && horizontal) {
        const index = 0;
        setIsFlyerLoading(true);
      }
      if (!isFlyerVerticalLoading && vertical) {
        const index = 1;
        setIsFlyerVerticalLoading(true);
      }
      if (!isFlyerSquareLoading && square) {
        const index = 2;
        setIsFlyerSquareLoading(true);
      }
      if (!isBannerHorizontalLoading && showingBannerHorizontal) {
        const index = 3;
        setIsBannerHorizontalLoading(true);
      }
      if (!isBannerVerticalLoading && showingBannerVertical) {
        const index = 4;
        setIsBannerVerticalLoading(true);
      }
      var jsonData = {};
      if (formData.demographicData) {
        jsonData["signal"] = formData.demographicData;
      }
      jsonData["text_model"]=textModel
      jsonData["img_model"]=imgModel
      jsonData["product_service"] = formData.product;
      jsonData["objective"] = formData.objective;
      jsonData["tone_mood"] = formData.tone;
      jsonData["key_selling_points"] = formData.sellingPoint;
      if (showingBannerHorizontal) {
        jsonData["image_dimension"] = "768x1408 ";
      } else if (showingBannerVertical) {
        jsonData["image_dimension"] = "1408x768";
      } else {
        jsonData["image_dimension"] = FLYER_DIMENSION;
      }
      if (horizontal) {
        jsonData["magazine_ad"] =
          sectionData.flyer?.["Flyer_Translated"] || null;
      } else if (vertical) {
        jsonData["magazine_ad"] =
          sectionData.flyerVertical?.["Flyer_Translated"] || null;
      } else {
        jsonData["magazine_ad"] =
          sectionData.flyerSquare?.["Flyer_Translated"] || null;
      }
      // jsonData["magazine_ad"] = sectionData?.flyer?.["Magazine Ad"] || null;
      jsonData["magazine_lock"] = vertical
        ? formattedStringVal(verticalFlyerContentLocked)
        : horizontal
        ? formattedStringVal(horizontalFlyerContentLocked)
        : square
        ? formattedStringVal(squareFlyerContentLocked)
        : showingBannerHorizontal
        ? formattedStringVal(horizontalBannerContentLocked)
        : formattedStringVal(verticalBannerContentLocked);
      jsonData["images_lock"] = vertical
        ? formattedStringArray(verticalFlyerImageLocked)
        : horizontal
        ? formattedStringArray(horizontalFlyerImageLocked)
        : square
        ? formattedStringArray(squareFlyerImageLocked)
        : showingBannerHorizontal
        ? formattedStringArray(horizontalBannerImageLocked)
        : formattedStringArray(verticalBannerImageLocked);
      jsonData["concept_territory"] =
        sectionData.creativeConcepts?.["Concepts and Images"]?.[
          selectedTitle
        ]?.[selectedTheme]?.["ConceptTerritory"];
      
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(FLYER, jsonData, {
              headers: { Authorization: idToken },
            })
            .then((response) => {
              let string = "";
              let storageString = "";
              let visual = "";
              const obj = response.data["Flyer"];
              Object.keys(JSON.parse(obj)).forEach((key) => {
                if (key === "Headline" || key === "Subtext") {
                  string = string + `${key}: ${JSON.parse(obj)[key]}\n`;
                  if (key === "Headline") {
                    if (horizontal) {
                      const index1 = 0;
                      setFlyerHeadline([
                        ...flyerHeadline.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerHeadline.slice(index1 + 1),
                      ]);
                    } else if (vertical) {
                      const index1 = 1;
                      setFlyerHeadline([
                        ...flyerHeadline.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerHeadline.slice(index1 + 1),
                      ]);
                    } else if (square) {
                      const index1 = 2;
                      setFlyerHeadline([
                        ...flyerHeadline.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerHeadline.slice(index1 + 1),
                      ]);
                    } else if (showingBannerHorizontal) {
                      const index1 = 3;
                      setFlyerHeadline([
                        ...flyerHeadline.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerHeadline.slice(index1 + 1),
                      ]);
                    } else if (showingBannerVertical) {
                      const index1 = 4;
                      setFlyerHeadline([
                        ...flyerHeadline.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerHeadline.slice(index1 + 1),
                      ]);
                    }
                  } else {
                    if (horizontal) {
                      const index1 = 0;
                      setFlyerSubtext([
                        ...flyerSubtext.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerSubtext.slice(index1 + 1),
                      ]);
                    }
                    if (vertical) {
                      const index1 = 1;
                      setFlyerSubtext([
                        ...flyerSubtext.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerSubtext.slice(index1 + 1),
                      ]);
                    }
                    if (square) {
                      const index1 = 2;
                      setFlyerSubtext([
                        ...flyerSubtext.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerSubtext.slice(index1 + 1),
                      ]);
                    }
                    if (showingBannerHorizontal) {
                      const index1 = 3;
                      setFlyerHeadline([
                        ...flyerSubtext.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerSubtext.slice(index1 + 1),
                      ]);
                    }
                    if (showingBannerVertical) {
                      const index1 = 4;
                      setFlyerHeadline([
                        ...flyerSubtext.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerSubtext.slice(index1 + 1),
                      ]);
                    }
                  }
                }
                if (key === "Visual")
                  visual = `${key}: ${JSON.parse(obj)[key]}\n`;
                storageString =
                  storageString + `${key}: ${JSON.parse(obj)[key]}\n`;
              });

              if (horizontal) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyer: {
                    Images: response.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.flyer?.Images[idx]
                        : value;
                    }),
                    ["Flyer"]: !horizontalFlyerContentLocked
                      ? string
                      : previousState?.flyer?.["Flyer"],
                    ["Storage"]: !horizontalFlyerContentLocked
                      ? storageString
                      : previousState?.flyer?.["Storage"],
                    ["Visual"]: !horizontalFlyerContentLocked
                      ? visual
                      : previousState?.flyer?.["Visual"],
                    ["Flyer_Translated"]: !horizontalFlyerContentLocked
                      ? string
                      : previousState?.flyer?.["Flyer_Translated"],
                  },
                }));
                const newPreview = previewImage.map((item, index) => {
                  if (index === 0) return "";
                  else return item;
                });
                setPreviewImage(newPreview);
                const newPreviewAccess = previewAccess.map((item, index) => {
                  if (index === 0) return "";
                  else return item;
                });
                setPreviewAccess(newPreviewAccess);
                const newChecked = checkedGeneratedImages.map((item, index) => {
                  if (index === 0) return [];
                  else return item;
                });
                setCheckedGeneratedImages(newChecked);
              } else if (vertical) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyerVertical: {
                    Images: response.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.flyerVertical?.Images[idx]
                        : value;
                    }),
                    ["Flyer"]: !verticalFlyerContentLocked
                      ? string
                      : previousState?.flyerVertical?.["Flyer"],
                    ["Flyer"]: !verticalFlyerContentLocked
                      ? string
                      : previousState?.flyerVertical?.["Flyer"],
                    ["Storage"]: !verticalFlyerContentLocked
                      ? storageString
                      : previousState?.flyerVertical?.["Storage"],
                    ["Visual"]: !verticalFlyerContentLocked
                      ? visual
                      : previousState?.flyerVertical?.["Visual"],
                    ["Flyer_Translated"]: !verticalFlyerContentLocked
                      ? string
                      : previousState?.flyerVertical?.["Flyer_Translated"],
                  },
                }));
                const newPreview = previewImage.map((item, index) => {
                  if (index === 1) return "";
                  else return item;
                });
                setPreviewImage(newPreview);
                const newPreviewAccess = previewAccess.map((item, index) => {
                  if (index === 1) return "";
                  else return item;
                });
                setPreviewAccess(newPreviewAccess);
                const newChecked = checkedGeneratedImages.map((item, index) => {
                  if (index === 1) return [];
                  else return item;
                });
                setCheckedGeneratedImages(newChecked);
              } else if (square) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyerSquare: {
                    Images: response.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.flyerSquare?.Images[idx]
                        : value;
                    }),
                    ["Flyer"]: !squareFlyerContentLocked
                      ? string
                      : previousState?.flyerSquare?.["Flyer"],
                    ["Flyer"]: !squareFlyerContentLocked
                      ? string
                      : previousState?.flyerSquare?.["Flyer"],
                    ["Storage"]: !squareFlyerContentLocked
                      ? storageString
                      : previousState?.flyerSquare?.["Storage"],
                    ["Visual"]: !squareFlyerContentLocked
                      ? visual
                      : previousState?.flyerSquare?.["Visual"],
                    ["Flyer_Translated"]: !squareFlyerContentLocked
                      ? string
                      : previousState?.flyerSquare?.["Flyer_Translated"],
                  },
                }));
                const newPreview = previewImage.map((item, index) => {
                  if (index === 2) return "";
                  else return item;
                });
                setPreviewImage(newPreview);
                const newPreviewAccess = previewAccess.map((item, index) => {
                  if (index === 2) return "";
                  else return item;
                });
                setPreviewAccess(newPreviewAccess);
                const newChecked = checkedGeneratedImages.map((item, index) => {
                  if (index === 2) return [];
                  else return item;
                });
                setCheckedGeneratedImages(newChecked);
              } else if (showingBannerHorizontal) {
                setSectionData((previousState) => ({
                  ...previousState,
                  bannerHorizontal: {
                    Images: response.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.bannerHorizontal?.Images[idx]
                        : value;
                    }),
                    ["Flyer"]: !horizontalBannerContentLocked
                      ? string
                      : previousState?.bannerHorizontal?.["Flyer"],
                    ["Storage"]: !horizontalBannerContentLocked
                      ? storageString
                      : previousState?.bannerHorizontal?.["Storage"],
                    ["Visual"]: !horizontalBannerContentLocked
                      ? visual
                      : previousState?.bannerHorizontal?.["Visual"],
                    ["Flyer_Translated"]: !horizontalBannerContentLocked
                      ? string
                      : previousState?.bannerHorizontal?.["Flyer_Translated"],
                  },
                }));
                const key = 3;
                const newPreview = previewImage.map((item, index) => {
                  if (index === key) return "";
                  else return item;
                });
                setPreviewImage(newPreview);
                const newPreviewAccess = previewAccess.map((item, index) => {
                  if (index === key) return "";
                  else return item;
                });
                setPreviewAccess(newPreviewAccess);
                const newChecked = checkedGeneratedImages.map((item, index) => {
                  if (index === key) return [];
                  else return item;
                });
                setCheckedGeneratedImages(newChecked);
              } else if (showingBannerVertical) {
                setSectionData((previousState) => ({
                  ...previousState,
                  bannerVertical: {
                    Images: response.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.bannerVertical?.Images[idx]
                        : value;
                    }),
                    ["Flyer"]: !verticalBannerContentLocked
                      ? string
                      : previousState?.bannerVertical?.["Flyer"],
                    ["Storage"]: !verticalBannerContentLocked
                      ? storageString
                      : previousState?.bannerVertical?.["Storage"],
                    ["Visual"]: !verticalBannerContentLocked
                      ? visual
                      : previousState?.bannerVertical?.["Visual"],
                    ["Flyer_Translated"]: !verticalBannerContentLocked
                      ? string
                      : previousState?.bannerVertical?.["Flyer_Translated"],
                  },
                }));
                const key = 4;
                const newPreview = previewImage.map((item, index) => {
                  if (index === key) return "";
                  else return item;
                });
                setPreviewImage(newPreview);
                const newPreviewAccess = previewAccess.map((item, index) => {
                  if (index === key) return "";
                  else return item;
                });
                setPreviewAccess(newPreviewAccess);
                const newChecked = checkedGeneratedImages.map((item, index) => {
                  if (index === key) return [];
                  else return item;
                });
                setCheckedGeneratedImages(newChecked);
              }
              const payload = {
                source_language: "English",
                target_language: horizontal
                  ? translateLanguage.Flyer
                  : vertical
                  ? translateLanguage.FlyerVertical
                  : square
                  ? translateLanguage.FlyerSquare
                  : showingBannerHorizontal
                  ? translateLanguage.BannerHorizontal
                  : translateLanguage.BannerVertical,
                input: horizontal
                  ? sectionData.flyer?.["Flyer"]
                  : vertical
                  ? sectionData.flyerVertical?.["Flyer"]
                  : square
                  ? sectionData.flyerSquare?.["Flyer"]
                  : showingBannerHorizontal
                  ? sectionData.bannerHorizontal?.["Flyer"]
                  : sectionData.bannerVertical?.["Flyer"],
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if (
                "English" ===
                (horizontal
                  ? translateLanguage.Flyer
                  : vertical
                  ? translateLanguage.FlyerVertical
                  : square
                  ? translateLanguage.FlyerSquare
                  : showingBannerHorizontal
                  ? translateLanguage.BannerHorizontal
                  : translateLanguage.BannerVertical)
              )
                return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if (
                "English" ===
                (horizontal
                  ? translateLanguage.Flyer
                  : vertical
                  ? translateLanguage.FlyerVertical
                  : square
                  ? translateLanguage.FlyerSquare
                  : showingBannerHorizontal
                  ? translateLanguage.BannerHorizontal
                  : translateLanguage.BannerVertical)
              )
                return;
              if (horizontal) {
                setSectionData((prev) => ({
                  ...prev,
                  flyer: {
                    ...prev.flyer,
                    Flyer_Translated: res?.data?.["translation"],
                  },
                }));
              } else if (vertical) {
                setSectionData((prev) => ({
                  ...prev,
                  flyerVertical: {
                    ...prev.flyerVertical,
                    Flyer_Translated: res?.data?.["translation"],
                  },
                }));
              } else if (square) {
                setSectionData((prev) => ({
                  ...prev,
                  flyerSquare: {
                    ...prev.flyerSquare,
                    Flyer_Translated: res?.data?.["translation"],
                  },
                }));
              } else if (showingBannerHorizontal) {
                setSectionData((prev) => ({
                  ...prev,
                  bannerHorizontal: {
                    ...prev.bannerHorizontal,
                    Flyer_Translated: res?.data?.["translation"],
                  },
                }));
              } else if (showingBannerVertical) {
                setSectionData((prev) => ({
                  ...prev,
                  bannerVertical: {
                    ...prev.bannerVertical,
                    Flyer_Translated: res?.data?.["translation"],
                  },
                }));
              }
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => {
              if (horizontal) setIsFlyerLoading(false);
              if (vertical) setIsFlyerVerticalLoading(false);
              if (square) setIsFlyerSquareLoading(false);
              if (showingBannerHorizontal) setIsBannerHorizontalLoading(false);
              if (showingBannerVertical) setIsBannerVerticalLoading(false);
            });
        })
        .catch((error) => {
          if (horizontal) setIsFlyerLoading(false);
          if (vertical) setIsFlyerVerticalLoading(false);
          if (square) setIsFlyerSquareLoading(false);
          if (showingBannerHorizontal) setIsBannerHorizontalLoading(false);
          if (showingBannerVertical) setIsBannerVerticalLoading(false);
          setErrorMessage(error);
        });
      break;
    case "Creative Concepts":
      setIsCreativeLoading(true);

      let creative_json_data = {
        product: formData.product,
        objective: formData.objective,
        tone: formData.tone,
        key_selling_points: formData.sellingPoint,
        number_of_concepts: index !== null ? 1 : 3,
        persona: flagRegenerated
          ? sectionData.persona[selectedTitle]
          : sectionData.persona,
        concept_request:
          index !== null
            ? sectionData?.creativeConcepts?.["Concepts and Images"]?.[
                selectedTitle
              ]?.[index]?.["ConceptTerritory"]
            : null,
        concept_lock:
          index !== null
            ? formattedStringVal(creativeContentLocked[selectedTitle][index])
            : null,
        images_lock:
          index !== null
            ? formattedStringArray(creativeImageLocked[selectedTitle][index])
            : null,
        text_model:textModel,
        img_model:imgModel
      };
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(CREATIVE_IDEATION, creative_json_data, {
              headers: { Authorization: idToken },
            })
            .then((response) => {
              if (index !== null) {
                setSectionData((previousState) => {
                  let sectionDataCopy = { ...previousState };
                  sectionDataCopy.creativeConcepts["Concepts and Images"][
                    selectedTitle
                  ][index]["ConceptTerritory"] = !creativeContentLocked[
                    selectedTitle
                  ][index]
                    ? response.data["Concepts and Images"][0][
                        "ConceptTerritory"
                      ]
                    : sectionDataCopy?.creativeConcepts?.[
                        "Concepts and Images"
                      ]?.[selectedTitle]?.[index]?.["ConceptTerritory"];

                  if (
                    response.data["Concepts and Images"][0]["Images"].length !=
                    0
                  ) {
                    sectionDataCopy.creativeConcepts["Concepts and Images"][
                      selectedTitle
                    ][index]["Images"] = response.data[
                      "Concepts and Images"
                    ][0]["Images"].map((value, idx) => {
                      return value === ""
                        ? sectionDataCopy?.creativeConcepts?.[
                            "Concepts and Images"
                          ]?.[selectedTitle]?.[index]?.["Images"][idx]
                        : value;
                    });
                  }
                  return sectionDataCopy;
                });
              } else {
                setSectionData((previousState) => ({
                  ...previousState,
                  creativeConcepts: response.data,
                }));
                const newTitles = [];
                response.data["Concepts and Images"].forEach((data, index) => {
                  const eachTitles = [];
                  data.forEach((territory, idx) => {
                    const t = territory.ConceptTerritory;
                    const regex = /Title:\s*"([^"]+)"/;
                    const regex2 = /Title:\s*(.+)/;
                    const match = regex.exec(t);
                    const match2 = regex2.exec(t);
                    if (match && match[1]) {
                      eachTitles.push(match[1]);
                    } else if (match2 && match2[1]) {
                      eachTitles.push(match2[1]);
                    }
                  });
                  newTitles.push(eachTitles);
                });
                setTerritoryTitles(newTitles);
              }
              const payload = {
                source_language: "English",
                target_language:
                  translateLanguage?.["Creative Concepts"][selectedTitle],
                input:
                  sectionData.creativeConcepts?.["Concepts and Images"]?.[
                    selectedTitle
                  ]?.[index]?.ConceptTerritory,
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if (
                "English" ===
                translateLanguage?.["Creative Concepts"][selectedTitle]
              )
                return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if (
                "English" ===
                translateLanguage?.["Creative Concepts"][selectedTitle]
              )
                return;
              setSectionData((previousState) => {
                let sectionDataCopy = { ...previousState };
                if (
                  sectionDataCopy.creativeConcepts?.["Concepts and Images"]?.[
                    selectedTitle
                  ][index]
                )
                  sectionDataCopy.creativeConcepts["Concepts and Images"][
                    selectedTitle
                  ][index].ConceptTerritory = res.data["translation"];
                return sectionDataCopy;
              });
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => {
              setIsCreativeLoading(false);
            });
        })
        .catch((error) => {
          setIsCreativeLoading(false);
          setErrorMessage(error);
        });

      break;
    case "Email":
      setIsEmailLoading(true);
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(
              EMAIL,
              {
                persona: flagRegenerated
                  ? sectionData.persona[selectedTitle]
                  : sectionData.persona,
                "Product/Service": formData.product,
                Objective: formData.objective,
                "Tone/Mood Desired": formData.tone,
                "Key Selling Points": formData.sellingPoint,
                concept_territory:
                  selectedTheme === -1
                    ? ""
                    : sectionData.creativeConcepts["Concepts and Images"][
                        selectedTitle
                      ][selectedTheme]?.["ConceptTerritory"],
                text_model:textModel
              },
              { headers: { Authorization: idToken } }
            )
            .then((response) => {
              if (personaWasTranslated) {
                setTranslateLanguage((prev) => ({
                  ...prev,
                  Email: sourceLanguage.Persona,
                }));
              }
              if (flagRegenerated) {
                const email = response.data.response.Email;
                setSectionData((previousState) => {
                  const sectionDataCopy = { ...previousState };
                  sectionDataCopy.email[selectedTitle] = email;
                  return sectionDataCopy;
                });
              } else {
                const email = response.data.response?.map((item) => item.Email);
                setSectionData((previousState) => ({
                  ...previousState,
                  email: email,
                }));
              }
              const payload = {
                source_language: "English",
                target_language: translateLanguage.Email[selectedTitle],
                input: sectionData.email[selectedTitle],
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if ("English" === translateLanguage.Email[selectedTitle]) return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if ("English" === translateLanguage.Email[selectedTitle]) return;
              const newEmail = sectionData.email.map((item, index) => {
                if (index === selectedTitle) return res.data["translation"];
                else return item;
              });
              setSectionData((prev) => ({
                ...prev,
                email: newEmail,
              }));
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => setIsEmailLoading(false));
        })
        .catch((error) => {
          setIsEmailLoading(false);
          setErrorMessage(error);
        });

      break;
    case "Social Media":
      setIsSocialLoading(true);
      let platform = null;
      if (index != null) {
        platform = Object.keys(
          sectionData?.socialMedia[selectedTitle]?.["caption"]
        )[index];
      }
      let socialMediaRequestJson = {
        persona: sectionData.persona,
        product_service: formData.product,
        objective: formData.objective,
        regenerate: index != null ? "True" : "False",
        caption_request:
          index !== null
            ? sectionData?.socialMedia[selectedTitle]?.["caption"][platform]
                ?.caption
            : null,
        caption_lock: formattedStringVal(
          socialMediaContentLocked[selectedTitle][index]
        ),
        concept_territory:
          selectedTheme === -1
            ? ""
            : sectionData?.creativeConcepts?.["Concepts and Images"]?.[
                selectedTitle
              ]?.[selectedTheme]?.["ConceptTerritory"],
        images_lock:
          index == null
            ? null
            : formattedStringArray(
                socialMediaImageLocked[selectedTitle][index]
              ),
        image_generated: sectionData.socialMedia?.[selectedTitle]?.[
          "caption"
        ]?.[platform]?.images
          ? "True"
          : "False",
        image_prompt:
          sectionData.socialMedia?.[selectedTitle]?.["caption"]?.[platform]
            ?.image_prompt,
        text_model:textModel,
        img_model:imgModel
      };

      if (index != null) {
        socialMediaRequestJson["platform"] = platform;
      }
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(SOCIAL_MEDIA, socialMediaRequestJson, {
              headers: { Authorization: idToken },
            })
            .then((response) => {
              if (personaWasTranslated) {
                setTranslateLanguage((prev) => ({
                  ...prev,
                  ["Social Media"]: sourceLanguage.Persona,
                }));
              }
              if (index != null) {
                setSectionData((previousState) => {
                  let newSocialMedia = { ...previousState };
                  let platform = Object.keys(
                    newSocialMedia?.socialMedia?.[selectedTitle]?.["caption"]
                  )[index];
                  let imageResponse =
                    response.data.response?.[platform]?.images;
                  newSocialMedia.socialMedia[selectedTitle]["caption"][
                    platform
                  ].caption = response.data.response?.[platform]?.caption;
                  newSocialMedia.socialMedia[selectedTitle]["caption"][
                    platform
                  ].image_prompt =
                    response.data.response?.[platform]?.image_prompt;
                  newSocialMedia.socialMedia[selectedTitle]["caption"][
                    platform
                  ].images =
                    imageResponse && imageResponse.length
                      ? imageResponse.map(
                          (ele, index) =>
                            ele ||
                            newSocialMedia.socialMedia[selectedTitle].caption[
                              platform
                            ].images[index]
                        )
                      : null;

                  return newSocialMedia;
                });
              } else {
                const prevState = sectionData;
                const newSocialMedias = response.data.response?.map(
                  (socialMedia, index) => {
                    return {
                      caption: socialMedia,
                    };
                  }
                );
                setSectionData((previousState) => ({
                  ...previousState,
                  socialMedia: newSocialMedias,
                }));
              }
              const payload = {
                source_language: "English",
                target_language:
                  translateLanguage?.["Social Media"][selectedTitle],
                input: sectionData.socialMedia?.[selectedTitle]?.caption,
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if (
                "English" === translateLanguage?.["Social Media"][selectedTitle]
              )
                return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if (
                "English" === translateLanguage?.["Social Media"][selectedTitle]
              )
                return;
              const newSocialMedia = sectionData.socialMedia.map(
                (item, index) => {
                  if (index === selectedTitle) {
                    return {
                      ...item,
                      caption: res.data?.["translation"],
                    };
                  } else return item;
                }
              );
              setSectionData((prev) => ({
                ...prev,
                socialMedia: newSocialMedia,
              }));
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => {
              setIsSocialLoading(false);
            });
        })
        .catch((error) => {
          setIsSocialLoading(false);
          setErrorMessage(error);
        });

      break;

    default:
      break;
  }
};

export const regenerateCall = (
  activeTab,
  sectionData,
  setSectionData,
  flyerHeadline,
  flyerSubtext,
  setFlyerHeadline,
  setFlyerSubtext,
  userData,
  setIsPersonaLoading,
  setIsPrintLoading,
  isFlyerLoading,
  setIsFlyerLoading,
  isFlyerVerticalLoading,
  setIsFlyerVerticalLoading,
  isFlyerSquareLoading,
  setIsFlyerSquareLoading,
  isBannerHorizontalLoading,
  setIsBannerHorizontalLoading,
  isBannerVerticalLoading,
  setIsBannerVerticalLoading,
  setIsCreativeLoading,
  setIsEmailLoading,
  setIsProductDescriptionLoading,
  setIsSocialLoading,
  setErrorMessage,
  index,
  formData = null,
  translateLanguage,
  horizontal,
  vertical,
  square,
  showingBannerHorizontal,
  showingBannerVertical,
  sourceLanguage,
  setTranslateLanguage,
  socialMediaImageLocked,
  socialMediaContentLocked,
  printImageLocked,
  printContentLocked,
  creativeImageLocked,
  creativeContentLocked,
  verticalFlyerContentLocked,
  verticalFlyerImageLocked,
  horizontalFlyerContentLocked,
  horizontalFlyerImageLocked,
  squareFlyerContentLocked,
  squareFlyerImageLocked,
  horizontalBannerContentLocked,
  horizontalBannerImageLocked,
  verticalBannerContentLocked,
  verticalBannerImageLocked,
  selectedTitle,
  textModel,
  imgModel
) => {
  switch (activeTab) {
    case "Persona":
      setIsPersonaLoading(true);
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(
              REVISION,
              {
                content: sectionData.persona[selectedTitle],
                user_revision: userData,
                text_model:textModel
              },
              { headers: { Authorization: idToken } }
            )
            .then((response) => {
              const newPersona = sectionData.persona.map((item, index) => {
                if (index === selectedTitle) return response.data["Persona"];
                else return item;
              });
              setSectionData((previousState) => ({
                ...previousState,
                persona: newPersona,
              }));
              const payload = {
                source_language: "English",
                target_language: translateLanguage?.["Persona"][selectedTitle],
                input: sectionData.persona[selectedTitle],
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if ("English" === translateLanguage?.["Persona"][selectedTitle])
                return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if ("English" === translateLanguage?.["Persona"][selectedTitle])
                return;
              const newPersona = sectionData.persona.map((item, index) => {
                if (index === selectedTitle) return res.data["translation"];
                else return item;
              });
              setSectionData((previousState) => ({
                ...previousState,
                persona: newPersona,
              }));
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => setIsPersonaLoading(false));
        })
        .catch((error) => {
          setIsPersonaLoading(false);
          setErrorMessage(error);
        });

      break;

    case "Product Description":
      setIsProductDescriptionLoading(true);
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(
              REVISION,
              {
                content: sectionData.productDescription[selectedTitle],
                user_revision: userData,
              },
              { headers: { Authorization: idToken } }
            )
            .then((response) => {
              const newProductDescription = sectionData.productDescription.map(
                (item, index) => {
                  if (index === selectedTitle)
                    return response.data["product_description"];
                  else return item;
                }
              );
              setSectionData((previousState) => ({
                ...previousState,
                productDescription: newProductDescription,
              }));
              const payload = {
                source_language: "English",
                target_language:
                  translateLanguage?.["productDescription"][selectedTitle],
                input: sectionData.productDescription[selectedTitle],
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if (
                "English" ===
                translateLanguage?.["productDescription"][selectedTitle]
              )
                return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if (
                "English" ===
                translateLanguage?.["productDescription"][selectedTitle]
              )
                return;
              const newProductDescription = sectionData.productDescription.map(
                (item, index) => {
                  if (index === selectedTitle) return res.data["translation"];
                  else return item;
                }
              );
              setSectionData((previousState) => ({
                ...previousState,
                productDescription: newProductDescription,
              }));
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => setIsProductDescriptionLoading(false));
        })
        .catch((error) => {
          setIsProductDescriptionLoading(false);
          setErrorMessage(error);
        });

      break;

    case "Print":
      setIsPrintLoading(true);
      const obj = {};
      const magazineAd = sectionData.print?.[selectedTitle]?.["Magazine Ad"];
      let firstColon = false;
      let prev = "",
        runner = "";
      for (let i = 0; i < magazineAd.length; i++) {
        if (!firstColon && magazineAd[i] === ":") {
          firstColon = true;
          prev = runner;
          runner = "";
          obj[prev] = "";
          continue;
        } else {
          if (firstColon && magazineAd[i] === "\n") {
            obj[prev] = runner;
            runner = "";
            prev = "";
            firstColon = false;
            continue;
          }
        }
        runner = runner + magazineAd[i];
      }
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(
              MAGAZINE_AD_REVISION,
              {
                magazine_ad: JSON.stringify(obj),
                revision: userData,
                magazine_lock: formattedStringVal(
                  printContentLocked[selectedTitle]
                ),
                images_lock: formattedStringArray(
                  printImageLocked[selectedTitle]
                ),
                text_model:textModel,
                img_model:imgModel
              },
              { headers: { Authorization: idToken } }
            )
            .then((response) => {
              let string = "";
              const obj = response.data["Magazine Ad"];
              Object.keys(JSON.parse(obj)).forEach((key) => {
                string = string + `${key}: ${JSON.parse(obj)[key]}\n`;
              });
              setSectionData((prevState) => {
                const sectionDataCopy = { ...prevState };
                sectionDataCopy.print[selectedTitle]["Magazine Ad"] =
                  !printContentLocked[selectedTitle]
                    ? string
                    : prevState?.print?.[selectedTitle]?.["Magazine Ad"];
                sectionDataCopy.print[selectedTitle]["Images"] =
                  response.data.Images.map((value, idx) => {
                    return value === ""
                      ? prevState?.print?.[selectedTitle]?.Images[idx]
                      : value;
                  });
                return sectionDataCopy;
              });
              const payload = {
                source_language: "English",
                target_language: translateLanguage?.["Print"][selectedTitle],
                input: sectionData.print?.[selectedTitle]?.["Magazine Ad"],
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if ("English" === translateLanguage?.["Print"][selectedTitle])
                return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if ("English" === translateLanguage?.["Print"][selectedTitle])
                return;
              setSectionData((prevState) => {
                const sectionDataCopy = { ...prevState };
                sectionDataCopy.print[selectedTitle]["Magazine Ad"] =
                  res.data["translation"];
                return sectionDataCopy;
              });
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => setIsPrintLoading(false));
        })
        .catch((error) => {
          setIsPrintLoading(false);
          setErrorMessage(error);
        });

      break;
    case "Flyer":
      let imageDimensions = FLYER_DIMENSION;
      if (horizontal) {
        setIsFlyerLoading(true);
      }
      if (vertical) {
        setIsFlyerVerticalLoading(true);
      }
      if (square) {
        setIsFlyerSquareLoading(true);
      }
      if (!isBannerHorizontalLoading && showingBannerHorizontal) {
        // const index = 3;
        setIsBannerHorizontalLoading(true);
        imageDimensions = BANNER_HORIZONTAL_DIMENSION;
      }
      if (!isBannerVerticalLoading && showingBannerVertical) {
        // const index = 4;
        setIsBannerVerticalLoading(true);
        imageDimensions = BANNER_VERTICAL_DIMENSION;
      }
      const obj1 = {};
      let magazineAd1 = horizontal
        ? sectionData.flyer?.["Storage"]
        : vertical
        ? sectionData.flyerVertical?.["Storage"]
        : square
        ? sectionData.flyerSquare?.["Storage"]
        : showingBannerHorizontal
        ? sectionData.bannerHorizontal?.["Storage"]
        : sectionData.bannerVertical?.["Storage"];
      let firstColon1 = false;
      let prev1 = "";
      let runner1 = "";
      for (let i = 0; i < magazineAd1?.length; i++) {
        if (!firstColon1 && magazineAd1[i] === ":") {
          firstColon1 = true;
          prev1 = runner1;
          runner1 = "";
          obj1[prev1] = "";
          continue;
        } else {
          if (firstColon1 && magazineAd1[i] === "\n") {
            obj1[prev1] = runner1;
            runner1 = "";
            prev1 = "";
            firstColon1 = false;
            continue;
          }
        }
        runner1 = runner1 + magazineAd1[i];
      }
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(
              FLYER_REVISION,
              {
                magazine_ad: JSON.stringify(obj1),
                revision: userData,
                image_dimension: imageDimensions,
                magazine_lock: vertical
                  ? formattedStringVal(verticalFlyerContentLocked)
                  : horizontal
                  ? formattedStringVal(horizontalFlyerContentLocked)
                  : square
                  ? formattedStringVal(squareFlyerContentLocked)
                  : showingBannerHorizontal
                  ? formattedStringVal(horizontalBannerContentLocked)
                  : formattedStringVal(verticalBannerContentLocked),
                images_lock: vertical
                  ? formattedStringArray(verticalFlyerImageLocked)
                  : horizontal
                  ? formattedStringArray(horizontalFlyerImageLocked)
                  : square
                  ? formattedStringArray(squareFlyerImageLocked)
                  : showingBannerHorizontal
                  ? formattedStringArray(horizontalBannerImageLocked)
                  : formattedStringArray(verticalBannerImageLocked),
                // images_lock: formattedStringArray(verticalFlyerImageLocked),
                text_model:textModel,
                img_model:imgModel
              },
              { headers: { Authorization: idToken } }
            )
            .then((response) => {
              let string = "";
              let storageString = "";
              let visual = "";
              const obj = response.data["Flyer"];
              Object.keys(JSON.parse(obj)).forEach((key) => {
                if (key === "Headline" || key === "Subtext") {
                  string = string + `${key}: ${JSON.parse(obj)[key]}\n`;
                  if (key === "Headline") {
                    if (horizontal) {
                      const index = 0;
                      setFlyerHeadline([
                        ...flyerHeadline.slice(0, index),
                        JSON.parse(obj)[key],
                        ...flyerHeadline.slice(index + 1),
                      ]);
                    }
                    if (vertical) {
                      const index = 1;
                      setFlyerHeadline([
                        ...flyerHeadline.slice(0, index),
                        JSON.parse(obj)[key],
                        ...flyerHeadline.slice(index + 1),
                      ]);
                    }
                    if (square) {
                      const index = 2;
                      setFlyerHeadline([
                        ...flyerHeadline.slice(0, index),
                        JSON.parse(obj)[key],
                        ...flyerHeadline.slice(index + 1),
                      ]);
                    }
                    if (showingBannerHorizontal) {
                      const index = 3;
                      setFlyerHeadline([
                        ...flyerHeadline.slice(0, index),
                        JSON.parse(obj)[key],
                        ...flyerHeadline.slice(index + 1),
                      ]);
                    }
                    if (showingBannerVertical) {
                      const index = 4;
                      setFlyerHeadline([
                        ...flyerHeadline.slice(0, index),
                        JSON.parse(obj)[key],
                        ...flyerHeadline.slice(index + 1),
                      ]);
                    }
                  } else {
                    if (horizontal) {
                      const index1 = 0;
                      setFlyerSubtext([
                        ...flyerSubtext.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerSubtext.slice(index1 + 1),
                      ]);
                    }
                    if (vertical) {
                      const index1 = 1;
                      setFlyerSubtext([
                        ...flyerSubtext.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerSubtext.slice(index1 + 1),
                      ]);
                    }
                    if (square) {
                      const index1 = 2;
                      setFlyerSubtext([
                        ...flyerSubtext.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerSubtext.slice(index1 + 1),
                      ]);
                    }
                    if (showingBannerHorizontal) {
                      const index1 = 3;
                      setFlyerSubtext([
                        ...flyerSubtext.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerSubtext.slice(index1 + 1),
                      ]);
                    }
                    if (showingBannerVertical) {
                      const index1 = 4;
                      setFlyerSubtext([
                        ...flyerSubtext.slice(0, index1),
                        JSON.parse(obj)[key],
                        ...flyerSubtext.slice(index1 + 1),
                      ]);
                    }
                  }
                }
                if (key === "Visual")
                  visual = `${key}: ${JSON.parse(obj)[key]}\n`;
                storageString =
                  storageString + `${key}: ${JSON.parse(obj)[key]}\n`;
              });
              if (horizontal) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyer: {
                    Images: response.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.flyer?.Images[idx]
                        : value;
                    }),
                    ["Flyer"]: !horizontalFlyerContentLocked
                      ? string
                      : previousState?.flyer?.["Flyer"],
                    ["Flyer"]: !horizontalFlyerContentLocked
                      ? string
                      : previousState?.flyer?.["Flyer"],
                    ["Storage"]: !horizontalFlyerContentLocked
                      ? storageString
                      : previousState?.flyer?.["Storage"],
                    ["Visual"]: !horizontalFlyerContentLocked
                      ? visual
                      : previousState?.flyer?.["Visual"],
                    ["Flyer_Translated"]: !horizontalFlyerContentLocked
                      ? string
                      : previousState?.flyer?.["Flyer_Translated"],
                  },
                }));
              }
              if (vertical) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyerVertical: {
                    Images: response.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.flyerVertical?.Images[idx]
                        : value;
                    }),
                    ["Flyer"]: !verticalFlyerContentLocked
                      ? string
                      : previousState?.flyerVertical?.["Flyer"],
                    ["Flyer"]: !verticalFlyerContentLocked
                      ? string
                      : previousState?.flyerVertical?.["Flyer"],
                    ["Storage"]: !verticalFlyerContentLocked
                      ? storageString
                      : previousState?.flyerVertical?.["Storage"],
                    ["Visual"]: !verticalFlyerContentLocked
                      ? visual
                      : previousState?.flyerVertical?.["Visual"],
                    ["Flyer_Translated"]: !verticalFlyerContentLocked
                      ? string
                      : previousState?.flyerVertical?.["Flyer_Translated"],
                  },
                }));
              }
              if (square) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyerSquare: {
                    Images: response.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.flyerSquare?.Images[idx]
                        : value;
                    }),
                    ["Flyer"]: !squareFlyerContentLocked
                      ? string
                      : previousState?.flyerSquare?.["Flyer"],
                    ["Flyer"]: !squareFlyerContentLocked
                      ? string
                      : previousState?.flyerSquare?.["Flyer"],
                    ["Storage"]: !squareFlyerContentLocked
                      ? storageString
                      : previousState?.flyerSquare?.["Storage"],
                    ["Visual"]: !squareFlyerContentLocked
                      ? visual
                      : previousState?.flyerSquare?.["Visual"],
                    ["Flyer_Translated"]: !squareFlyerContentLocked
                      ? string
                      : previousState?.flyerSquare?.["Flyer_Translated"],
                  },
                }));
              }
              if (showingBannerHorizontal) {
                setSectionData((previousState) => ({
                  ...previousState,
                  bannerHorizontal: {
                    Images: response.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.bannerHorizontal?.Images[idx]
                        : value;
                    }),
                    ["Flyer"]: !horizontalBannerContentLocked
                      ? string
                      : previousState?.bannerHorizontal?.["Flyer"],
                    ["Storage"]: !horizontalBannerContentLocked
                      ? storageString
                      : previousState?.bannerHorizontal?.["Storage"],
                    ["Visual"]: !horizontalBannerContentLocked
                      ? visual
                      : previousState?.bannerHorizontal?.["Visual"],
                    ["Flyer_Translated"]: !horizontalBannerContentLocked
                      ? string
                      : previousState?.bannerHorizontal?.["Flyer_Translated"],
                  },
                }));
              }
              if (showingBannerVertical) {
                setSectionData((previousState) => ({
                  ...previousState,
                  bannerVertical: {
                    Images: response.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.bannerVertical?.Images[idx]
                        : value;
                    }),
                    ["Flyer"]: !verticalBannerContentLocked
                      ? string
                      : previousState?.bannerVertical?.["Flyer"],
                    ["Storage"]: !verticalBannerContentLocked
                      ? storageString
                      : previousState?.bannerVertical?.["Storage"],
                    ["Visual"]: visual,
                    ["Flyer_Translated"]: !verticalBannerContentLocked
                      ? string
                      : previousState?.bannerVertical?.["Flyer_Translated"],
                  },
                }));
              }
              const payload = {
                source_language: "English",
                target_language: horizontal
                  ? translateLanguage.Flyer
                  : vertical
                  ? translateLanguage.FlyerVertical
                  : square
                  ? translateLanguage.FlyerSquare
                  : showingBannerHorizontal
                  ? translateLanguage.BannerHorizontal
                  : translateLanguage.BannerVertical,
                input: horizontal
                  ? sectionData.flyer?.["Flyer"]
                  : vertical
                  ? sectionData.flyerVertical?.["Flyer"]
                  : square
                  ? sectionData.flyerSquare?.["Flyer"]
                  : showingBannerHorizontal
                  ? sectionData.bannerHorizontal?.["Flyer"]
                  : sectionData.bannerVertical?.["Flyer"],
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if (
                "English" ===
                (horizontal
                  ? translateLanguage.Flyer
                  : vertical
                  ? translateLanguage.FlyerVertical
                  : square
                  ? translateLanguage.FlyerSquare
                  : showingBannerHorizontal
                  ? translateLanguage.BannerHorizontal
                  : translateLanguage.bannerVertical)
              )
                return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if (
                "English" ===
                (horizontal
                  ? translateLanguage.Flyer
                  : vertical
                  ? translateLanguage.FlyerVertical
                  : square
                  ? translateLanguage.FlyerSquare
                  : showingBannerHorizontal
                  ? translateLanguage.BannerHorizontal
                  : translateLanguage.bannerVertical)
              )
                return;
              if (horizontal) {
                setSectionData((prev) => ({
                  ...prev,
                  flyer: {
                    ...prev.flyer,
                    Flyer_Translated: res?.data?.["translation"],
                  },
                }));
              }
              if (vertical) {
                setSectionData((prev) => ({
                  ...prev,
                  flyerVertical: {
                    ...prev.flyerVertical,
                    Flyer_Translated: res?.data?.["translation"],
                  },
                }));
              }
              if (square) {
                setSectionData((prev) => ({
                  ...prev,
                  flyerSquare: {
                    ...prev.flyerSquare,
                    Flyer_Translated: res?.data?.["translation"],
                  },
                }));
              }
              if (showingBannerHorizontal) {
                setSectionData((prev) => ({
                  ...prev,
                  bannerHorizontal: {
                    ...prev.bannerHorizontal,
                    Flyer_Translated: res?.data?.["translation"],
                  },
                }));
              }
              if (showingBannerVertical) {
                setSectionData((prev) => ({
                  ...prev,
                  bannerVertical: {
                    ...prev.bannerVertical,
                    Flyer_Translated: res?.data?.["translation"],
                  },
                }));
              }
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => {
              if (horizontal) setIsFlyerLoading(false);
              if (vertical) setIsFlyerVerticalLoading(false);
              if (square) setIsFlyerSquareLoading(false);
              if (showingBannerHorizontal) setIsBannerHorizontalLoading(false);
              if (showingBannerVertical) setIsBannerVerticalLoading(false);
            });
        })
        .catch((error) => {
          if (horizontal) setIsFlyerLoading(false);
          if (vertical) setIsFlyerVerticalLoading(false);
          if (square) setIsFlyerSquareLoading(false);
          if (showingBannerHorizontal) setIsBannerHorizontalLoading(false);
          if (showingBannerVertical) setIsBannerVerticalLoading(false);
          setErrorMessage(error);
        });

      break;
    case "Creative Concepts":
      setIsCreativeLoading(true);

      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(
              CREATIVE_IDEATION_REVISION,
              {
                product: formData?.product,
                concept:
                  sectionData.creativeConcepts["Concepts and Images"][
                    selectedTitle
                  ][index]["ConceptTerritory"],
                revision_data: userData,
                concept_lock: formattedStringVal(
                  creativeContentLocked[selectedTitle][index]
                ),
                images_lock: formattedStringArray(
                  creativeImageLocked[selectedTitle][index]
                ),
                text_model:textModel,
                img_model:imgModel
              },
              { headers: { Authorization: idToken } }
            )
            .then((response) => {
              setSectionData((previousState) => {
                let sectionDataCopy = { ...previousState };
                sectionDataCopy.creativeConcepts["Concepts and Images"][
                  selectedTitle
                ][index]["ConceptTerritory"] = !creativeContentLocked[
                  selectedTitle
                ][index]
                  ? response.data["Concept and Images"]["ConceptTerritory"]
                  : sectionDataCopy.creativeConcepts["Concepts and Images"][
                      selectedTitle
                    ][index]["ConceptTerritory"];
                sectionDataCopy.creativeConcepts["Concepts and Images"][
                  selectedTitle
                ][index]["Images"] = response.data["Concept and Images"][
                  "Images"
                ].map((value, idx) => {
                  return value == ""
                    ? sectionDataCopy.creativeConcepts["Concepts and Images"][
                        selectedTitle
                      ][index]["Images"][idx]
                    : value;
                });
                return sectionDataCopy;
              });
              const payload = {
                source_language: "English",
                target_language:
                  translateLanguage?.["Creative Concepts"][selectedTitle],
                input:
                  sectionData.creativeConcepts?.["Concepts and Images"]?.[
                    selectedTitle
                  ]?.[index]?.ConceptTerritory,
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if (
                "English" ===
                translateLanguage?.["Creative Concepts"][selectedTitle]
              )
                return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if (
                "English" ===
                translateLanguage?.["Creative Concepts"][selectedTitle]
              )
                return;
              setSectionData((previousState) => {
                let sectionDataCopy = { ...previousState };
                if (
                  sectionDataCopy.creativeConcepts?.["Concepts and Images"]?.[
                    selectedTitle
                  ][index]
                )
                  sectionDataCopy.creativeConcepts["Concepts and Images"][
                    selectedTitle
                  ][index].ConceptTerritory = res.data["translation"];
                return sectionDataCopy;
              });
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => setIsCreativeLoading(false));
        })
        .catch((error) => {
          setIsCreativeLoading(false);
          setErrorMessage(error);
        });

      break;

    case "Email":
      setIsEmailLoading(true);

      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(
              EMAIL_REVISION,
              {
                content: sectionData.email[selectedTitle],
                user_revision: userData,
                text_model:textModel
              },
              { headers: { Authorization: idToken } }
            )
            .then((response) => {
              const newEmail = sectionData.email.map((item, index) => {
                if (index === selectedTitle) return response.data["Email"];
                else return item;
              });
              setSectionData((previousState) => ({
                ...previousState,
                email: newEmail,
              }));
              const payload = {
                source_language: "English",
                target_language: translateLanguage?.Email[selectedTitle],
                input: sectionData.email[selectedTitle],
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if ("English" === translateLanguage.Email[selectedTitle]) return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if ("English" === translateLanguage.Email[selectedTitle]) return;
              const newEmail = sectionData.email.map((item, index) => {
                if (index === selectedTitle) return res.data["Email"];
                else return item;
              });
              setSectionData((prev) => ({
                ...prev,
                email: newEmail,
              }));
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => setIsEmailLoading(false));
        })
        .catch((error) => {
          setIsEmailLoading(false);
          setErrorMessage(error);
        });

      break;

    case "Social Media":
      setIsSocialLoading(true);
      let platform = Object.keys(
        sectionData?.socialMedia[selectedTitle]?.caption
      )[index];
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(
              SOCIAL_MEDIA_REVISION,
              {
                persona: sectionData.persona,
                product_service: formData?.product,
                objective: formData?.objective,
                revise_comment: userData,
                caption_request:
                  sectionData?.socialMedia[selectedTitle]?.caption?.[platform]
                    ?.caption,
                platform: platform,
                images_lock: formattedStringArray(
                  socialMediaImageLocked[selectedTitle][index]
                ),
                caption_lock: formattedStringVal(
                  socialMediaContentLocked[selectedTitle][index]
                ),
                image_generated: sectionData?.socialMedia[selectedTitle]
                  ?.caption?.[platform]?.images
                  ? "True"
                  : "False",
                text_model:textModel,
                img_model:imgModel
              },
              { headers: { Authorization: idToken } }
            )
            .then((response) => {
              setSectionData((prev) => {
                let newSocialMedia = { ...prev };
                newSocialMedia.socialMedia[selectedTitle].caption[
                  platform
                ].caption = response.data.caption;
                newSocialMedia.socialMedia[selectedTitle].caption[
                  platform
                ].image_prompt = response.data.image_prompt;
                if (
                  response.data.images.some(
                    (imageElement) => imageElement !== ""
                  )
                ) {
                  newSocialMedia.socialMedia[selectedTitle].caption[
                    platform
                  ].images = response.data.images.map((ele, index) =>
                    ele == ""
                      ? newSocialMedia.socialMedia[selectedTitle].caption[
                          platform
                        ].images[index]
                      : ele
                  );
                }
                return newSocialMedia;
              });
              const payload = {
                source_language: "English",
                target_language:
                  translateLanguage?.["Social Media"][selectedTitle],
                input: sectionData.socialMedia[selectedTitle]?.caption,
                translation_model: "Google Translate",
                localization_model: "GPT-4",
              };
              if (
                "English" === translateLanguage?.["Social Media"][selectedTitle]
              )
                return;
              return axios.post(DIRECT_TRANSLATION, payload, {
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  Authorization: idToken,
                },
              });
            })
            .then((res) => {
              if (
                "English" === translateLanguage?.["Social Media"][selectedTitle]
              )
                return;
              const newSocial = sectionData.socialMedia.map((item, index) => {
                if (index === selectedTitle) {
                  return {
                    ...item,
                    caption: res.data?.["translation"],
                  };
                } else return item;
              });
              setSectionData((prev) => ({
                ...prev,
                socialMedia: newSocial,
              }));
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })

            .finally(() => {
              setIsSocialLoading(false);
            });
        })
        .catch((error) => {
          setIsSocialLoading(false);
          setErrorMessage(error);
        });

      break;

    default:
      console.log("default");
  }
};
export const editResponseCall = (
  data,
  sectionData,
  setSectionData,
  activeTab,
  isPrintLoading,
  setIsPrintLoading,
  isFlyerLoading,
  setIsFlyerLoading,
  isFlyerVerticalLoading,
  setIsFlyerVerticalLoading,
  isFlyerSquareLoading,
  setIsFlyerSquareLoading,
  isBannerHorizontalLoading,
  setIsBannerHorizontalLoading,
  isBannerVerticalLoading,
  setIsBannerVerticalLoading,
  setIsCreativeLoading,
  formData,
  setErrorMessage,
  index,
  setFlyerHeadline,
  setFlyerSubtext,
  showingHorizontal,
  showingVertical,
  showingSquare,
  showingBannerHorizontal,
  showingBannerVertical,
  printImageLocked,
  socialMediaImageLocked,
  creativeImageLocked,
  verticalFlyerContentLocked,
  verticalFlyerImageLocked,
  horizontalFlyerImageLocked,
  squareFlyerImageLocked,
  horizontalBannerImageLocked,
  verticalBannerImageLocked,
  selectedTitle,
  textModel,
  imgModel
) => {
  const createJSON = (data) => {
    const obj = {};
    let entries = data.trim().split("\n");

    for (let entry of entries) {
      let [key, ...value] = entry.split(": ");
      value = value.join(": ");
      obj[key] = value.trim();
    }
    return obj;
  };

  switch (activeTab) {
    case "Print":
      const obj = createJSON(data);
      const mag_ad_value = JSON.stringify(obj);
      const request_body = {
        magazine_ad: mag_ad_value,
        images_lock: formattedStringArray(printImageLocked[selectedTitle]),
        text_model:textModel,
        img_model:imgModel
      };
      setIsPrintLoading(true);
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(MAGAZINE_AD_EDIT, request_body, {
              headers: { Authorization: idToken },
            })
            .then((res) => {
              setSectionData((prevState) => {
                const sectionDataCopy = { ...prevState };
                sectionDataCopy.print[selectedTitle]["Images"] =
                  res.data.Images.map((value, idx) => {
                    return value === ""
                      ? prevState?.print?.[selectedTitle]?.Images[idx]
                      : value;
                  });
                return sectionDataCopy;
              });
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => {
              setIsPrintLoading(false);
            });
        })
        .catch((error) => {
          setIsPrintLoading(false);
          setErrorMessage(error);
        });

      break;
    case "Flyer":
      const ob = createJSON(data);
      const mag_ad = JSON.stringify(ob);
      const req_body = {
        magazine_ad: mag_ad,
        image_dimension: FLYER_DIMENSION,
        images_lock: showingVertical
          ? formattedStringArray(verticalFlyerImageLocked)
          : showingHorizontal
          ? formattedStringArray(horizontalFlyerImageLocked)
          : showingSquare
          ? formattedStringArray(squareFlyerImageLocked)
          : showingHorizontal
          ? formattedStringArray(horizontalBannerImageLocked)
          : formattedStringArray(verticalBannerImageLocked),
        text_model:textModel,
        img_model:imgModel
      };
      if (showingHorizontal) {
        setIsFlyerLoading(true);
      }
      if (showingVertical) {
        setIsFlyerVerticalLoading(true);
      }
      if (showingSquare) {
        setIsFlyerSquareLoading(true);
      }
      if (showingBannerHorizontal) {
        req_body["image_dimension"] = BANNER_HORIZONTAL_DIMENSION;
        setIsBannerHorizontalLoading(true);
      }
      if (showingBannerVertical) {
        req_body["image_dimension"] = BANNER_VERTICAL_DIMENSION;
        setIsBannerVerticalLoading(true);
      }
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(FLYER_EDIT, req_body, { headers: { Authorization: idToken } })
            .then((res) => {
              if (showingHorizontal) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyer: {
                    ...sectionData.flyer,
                    Images: res.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.flyer?.Images[idx]
                        : value;
                    }),
                  },
                }));
              }
              if (showingVertical) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyerVertical: {
                    ...sectionData.flyerVertical,
                    Images: res.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.flyerVertical?.Images[idx]
                        : value;
                    }),
                  },
                }));
              }
              if (showingSquare) {
                setSectionData((previousState) => ({
                  ...previousState,
                  flyerSquare: {
                    ...sectionData.flyerSquare,
                    Images: res.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.flyerSquare?.Images[idx]
                        : value;
                    }),
                  },
                }));
              }
              if (showingBannerHorizontal) {
                setSectionData((previousState) => ({
                  ...previousState,
                  bannerHorizontal: {
                    ...sectionData.bannerHorizontal,
                    Images: res.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.bannerHorizontal?.Images[idx]
                        : value;
                    }),
                  },
                }));
              }
              if (showingBannerVertical) {
                setSectionData((previousState) => ({
                  ...previousState,
                  bannerVertical: {
                    ...sectionData.bannerVertical,
                    Images: res.data.Images.map((value, idx) => {
                      return value == ""
                        ? previousState?.bannerVertical?.Images[idx]
                        : value;
                    }),
                  },
                }));
              }
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => {
              if (showingHorizontal) {
                setIsFlyerLoading(false);
              }
              if (showingVertical) {
                setIsFlyerVerticalLoading(false);
              }
              if (showingSquare) {
                setIsFlyerSquareLoading(false);
              }
              if (showingBannerHorizontal) {
                setIsBannerHorizontalLoading(false);
              }
              if (showingBannerVertical) {
                setIsBannerVerticalLoading(false);
              }
            });
        })
        .catch((error) => {
          if (showingHorizontal) {
            setIsFlyerLoading(false);
          }
          if (showingVertical) {
            setIsFlyerVerticalLoading(false);
          }
          if (showingSquare) {
            setIsFlyerSquareLoading(false);
          }
          if (showingBannerHorizontal) {
            setIsBannerHorizontalLoading(false);
          }
          if (showingBannerVertical) {
            setIsBannerVerticalLoading(false);
          }
          setErrorMessage(error);
        });

      break;
    case "Creative Concepts":
      setIsCreativeLoading(true);
      getIdToken(setErrorMessage)
        .then((idToken) => {
          axios
            .post(
              CREATIVE_IDEATION_EDIT,
              {
                product: formData?.product,
                concept_territory: data,
                images_lock: formattedStringArray(
                  creativeImageLocked[selectedTitle][index]
                ),
                text_model:textModel,
                img_model:imgModel
              },
              { headers: { Authorization: idToken } }
            )
            .then((res) => {
              setSectionData((previousState) => {
                let newObj = { ...previousState };
                newObj.creativeConcepts["Concepts and Images"][selectedTitle][
                  index
                ]["ConceptTerritory"] = res.data["ConceptTerritory"];
                newObj.creativeConcepts["Concepts and Images"][selectedTitle][
                  index
                ]["Images"] = res.data["Images"].map((value, idx) => {
                  return value === ""
                    ? newObj?.creativeConcepts["Concepts and Images"][
                        selectedTitle
                      ][index]["Images"][idx]
                    : value;
                });
                return newObj;
              });
            })
            .catch((error) => {
              error.message = errorMessages.hasOwnProperty(
                error?.response?.status
              )
                ? errorMessages[error?.response?.status]
                : error.message;
              setErrorMessage(error);
            })
            .finally(() => {
              setIsCreativeLoading(false);
            });
        })
        .catch((error) => {
          setIsCreativeLoading(false);
          setErrorMessage(error);
        });

      break;
    default:
      console.log("default");
  }
};

export const storyBoardingRegenerateImage = (
  narration,
  step_no,
  setErrorMessage,
  setIsLoading,
  setSectionData,
  tailoredVisible,
  selectedTitle,
  imgModel
) => {
  setIsLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          STORY_REGENERATE_IMAGE,
          {
            step_no: step_no,
            desc: narration,
            img_model:imgModel
          },
          {
            headers: { Authorization: idToken },
          }
        )
        .then((res) => {
          if (tailoredVisible) {
            setSectionData((previousState) => {
              let regenerateImageData = { ...previousState };
              regenerateImageData.stepsTailored[selectedTitle].current[
                step_no - 1
              ]["IMAGE"] = res.data["BASE_64_IMAGE"];
              regenerateImageData.stepsTailored[selectedTitle].prev[
                step_no - 1
              ]["IMAGE"] = res.data["BASE_64_IMAGE"];
              return regenerateImageData;
            });
          } else {
            setSectionData((previousState) => {
              let regenerateImageData = { ...previousState };
              regenerateImageData.steps.current[step_no - 1]["IMAGE"] =
                res.data["BASE_64_IMAGE"];
              regenerateImageData.steps.prev[step_no - 1]["IMAGE"] =
                res.data["BASE_64_IMAGE"];
              return regenerateImageData;
            });
          }
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const storyBoardingUpdateSteps = (
  formData,
  updatedSteps,
  previousSteps,
  generatedImages,
  sectionData,
  setSectionData,
  activeTab,
  isLoading,
  setIsLoading,
  setErrorMessage,
  dataMap,
  tailoredVisible,
  selectedTitle
) => {
  setIsLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          STORY_UPDATE_STEP,
          {
            duration: Number(formData.duration),
            step_updates: updatedSteps,
            previous_steps: previousSteps,
            images_generated: generatedImages,
          },
          {
            headers: { Authorization: idToken },
          }
        )
        .then((res) => {
          const obj = { ...sectionData };
          if (generatedImages) {
            try {
              res?.data?.["STEP"]?.forEach((value, index) => {
                if (!value.hasOwnProperty("IMAGE")) {
                  const prevImage = dataMap.get(value["STEP_NO"]);
                  value["IMAGE"] = prevImage;
                }
                value["STEP_NO"] = index + 1;
              });
            } catch (error) {
              console.error(error);
            }
          }
          if (tailoredVisible) {
            obj.stepsTailored[selectedTitle].prev = res.data["STEP"];
            obj.stepsTailored[selectedTitle].current = res.data["STEP"];
          } else {
            obj.steps.prev = res.data["STEP"];
            obj.steps.current = res.data["STEP"];
          }
          setSectionData(obj);
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const storyBoardingGenerateSteps = (
  formData,
  sectionData,
  setSectionData,
  stepArr,
  setStepArr,
  activeTab,
  isLoading,
  setIsLoading,
  setErrorMessage,
  setGeneratedImages,
  textModel
) => {
  setIsLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          STORY_GENERATE_STEP,
          {
            storyboarding: formData.storyBoardTitle,
            duration: formData.duration,
            text_model:textModel
          },
          {
            headers: { Authorization: idToken },
          }
        )
        .then((res) => {
          setGeneratedImages(false);
          setGeneratedImages(false);
          const obj = { ...sectionData };
          obj.steps.prev = res.data;
          obj.steps.current = res.data;
          setSectionData(obj);
          const stepArr = [];
          res.data.forEach((step) => {
            stepArr.push(step["SCENE_DESCRIPTION"]);
          });
          setStepArr(stepArr);
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};
export const storyBoardingTailoredGenerateSteps = (
  formData,
  sectionData,
  setSectionData,
  stepArrTailored,
  setStepArrTailored,
  activeTab,
  isLoading,
  setIsLoading,
  setErrorMessage,
  setGeneratedImagesTailored,
  selectedTitle,
  flag,
  textModel,
  selectedTheme,
  personaHindi = null,
  
) => {
  setIsLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          STORY_TAILORED_GENERATE_STEP,
          {
            persona: personaHindi === null ? sectionData.persona : personaHindi,
            duration: Number(formData.duration),
            product_service: formData.product,
            objective: formData.objective,
            tone_mood_desire: formData.tone,
            key_selling_points: formData.sellingPoint,
            settings: formData.settings,
            text_model:textModel,
            concept_territory:
              selectedTheme === -1
                ? ""
                : sectionData?.creativeConcepts?.["Concepts and Images"]?.[
                    selectedTitle
                  ]?.[selectedTheme]?.["ConceptTerritory"],
            
          },
          {
            headers: { Authorization: idToken },
          }
        )
        .then((res) => {
          setGeneratedImagesTailored((prev) => {
            const newArr = [...prev];
            newArr[selectedTitle] = false;
            return newArr;
          });
          if (flag) {
            const obj = { ...sectionData };
            res.data.forEach((stepsForTitle, index) => {
              if (index == selectedTitle) {
                obj.stepsTailored[index].prev = stepsForTitle;
                obj.stepsTailored[index].current = stepsForTitle;
              }
            });
            setSectionData(obj);
            const newStepArr = [];
            obj.stepsTailored[selectedTitle].prev.forEach((step) => {
              newStepArr.push(step["SCENE_DESCRIPTION"]);
            });
            setStepArrTailored([
              ...stepArrTailored.slice(0, selectedTitle),
              newStepArr,
              ...stepArrTailored.slice(selectedTitle + 1),
            ]);
            return;
          }
          const obj = { ...sectionData };
          res.data.forEach((stepsForTitle, index) => {
            obj.stepsTailored[index].prev = stepsForTitle;
            obj.stepsTailored[index].current = stepsForTitle;
          });
          setSectionData(obj);
          const stepArr = [];
          res.data.forEach((stepsForTitle, index) => {
            const newSteps = [];
            stepsForTitle.forEach((step) => {
              newSteps.push(step["SCENE_DESCRIPTION"]);
            });
            stepArr.push(newSteps);
          });
          setStepArrTailored(stepArr);
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const storyBoardingGenerateImageTailored = (
  formData,
  sectionData,
  setSectionData,
  isLoading,
  setIsLoading,
  setErrorMessage,
  setGeneratedImages,
  selectedTitle,
  imgModel
) => {
  setIsLoading(true);
  const newArr = sectionData.stepsTailored[selectedTitle].prev;
  newArr.forEach((step, index) => {
    step["STEP_NO"] = index + 1;
  });
  const obj = {
    duration: Number(formData.duration),
    steps: newArr,
    img_model:imgModel
  };

  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(STORY_GENERATE_IMAGE, obj, {
          headers: { Authorization: idToken },
        })
        .then((res) => {
          setGeneratedImages(true);
          setSectionData((previousState) => {
            const obj = { ...previousState };
            obj.stepsTailored[selectedTitle].prev = res.data;
            obj.stepsTailored[selectedTitle].current = res.data;
            return obj;
          });
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const storyBoardingGenerateImage = (
  formData,
  sectionData,
  setSectionData,
  isLoading,
  setIsLoading,
  setErrorMessage,
  setGeneratedImages,
  imgModel
) => {
  setIsLoading(true);
  const newArr = sectionData.steps.prev;
  newArr.forEach((step, index) => {
    step["STEP_NO"] = index + 1;
  });
  const obj = {
    duration: Number(formData.duration),
    steps: newArr,
    img_model:imgModel
  };

  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(STORY_GENERATE_IMAGE, obj, {
          headers: { Authorization: idToken },
        })
        .then((res) => {
          setGeneratedImages(true);
          setSectionData((previousState) => {
            const obj = { ...previousState };
            obj.steps.prev = res.data;
            obj.steps.current = res.data;
            return obj;
          });
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const storyBoardingStitch = (
  sectionData,
  setSectionData,
  isStoryBoardLoading,
  setIsStoryBoardLoading,
  setSuccessFullDownload,
  setErrorMessage,
  formData,
  reqBody
) => {
  setIsStoryBoardLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      // let pdfObj = {

      //   content: sectionData.steps.prev,
      // };
      axios
        .post(STORY_GENERATE_PDF, reqBody, {
          headers: { Authorization: idToken },
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/zip" });
          const blobUrl = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = blobUrl;
          anchor.target = "_blank";
          anchor.click();

          window.setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            anchor.remove();
          }, 0);
          setSuccessFullDownload(true);
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsStoryBoardLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const regenerateIndividualStep = (
  setIsStoryBoardLoading,
  sectionData,
  setSectionData,
  index,
  stepArr,
  setStepArr,
  stepArrTailored,
  setStepArrTailored,
  req_body,
  setErrorMessage,
  tailoredVisible,
  selectedTitle
) => {
  setIsStoryBoardLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(STORY_REGENERATE_STEP, req_body, {
          headers: { Authorization: idToken },
        })
        .then((res) => {
          if (tailoredVisible) {
            const newSectionData = { ...sectionData };
            newSectionData.stepsTailored[selectedTitle].prev.forEach(
              (step, i) => {
                if (step["SCENE_DESCRIPTION"] === req_body["regenerate_step"]) {
                  step["SCENE_DESCRIPTION"] = res.data["regenerated_step"];
                }
              }
            );
            newSectionData.stepsTailored[selectedTitle].current.forEach(
              (step, i) => {
                if (step["SCENE_DESCRIPTION"] === req_body["regenerate_step"]) {
                  step["SCENE_DESCRIPTION"] = res.data["regenerated_step"];
                }
              }
            );
            setSectionData(newSectionData);
            const newStepArr = stepArrTailored[selectedTitle].map(
              (value, i) => {
                if (i + 1 === res.data["step_no"]) {
                  return res.data["regenerated_step"];
                } else return value;
              }
            );
            setStepArrTailored([
              ...stepArrTailored.slice(0, selectedTitle),
              newStepArr,
              ...stepArrTailored.slice(selectedTitle + 1),
            ]);
          } else {
            const newSectionData = { ...sectionData };
            newSectionData.steps.prev.forEach((step, i) => {
              if (step["SCENE_DESCRIPTION"] === req_body["regenerate_step"]) {
                step["SCENE_DESCRIPTION"] = res.data["regenerated_step"];
              }
            });
            newSectionData.steps.current.forEach((step, i) => {
              if (step["SCENE_DESCRIPTION"] === req_body["regenerate_step"]) {
                step["SCENE_DESCRIPTION"] = res.data["regenerated_step"];
              }
            });
            setSectionData(newSectionData);
            const newStepArr = stepArr.map((value, i) => {
              if (i + 1 === res.data["step_no"]) {
                return res.data["regenerated_step"];
              } else return value;
            });
            setStepArr(newStepArr);
          }
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsStoryBoardLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const reviseSteps = (
  setIsStoryBoardLoading,
  sectionData,
  setSectionData,
  stepArr,
  setStepArr,
  req_body,
  setErrorMessage,
  tailoredVisible,
  selectedTitle,
  setStepArrTailored,
  stepArrTailored,
) => {
  setIsStoryBoardLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(STORY_REVISE_STEP, req_body, {
          headers: { Authorization: idToken },
        })
        .then((res) => {
          if (tailoredVisible) {
            const newSectionData = { ...sectionData };
            newSectionData.stepsTailored[selectedTitle].prev = res.data;
            newSectionData.stepsTailored[selectedTitle].current = res.data;
            setSectionData(newSectionData);
            const newStepArr = [];
            res.data.forEach((step) => {
              newStepArr.push(step["SCENE_DESCRIPTION"]);
            });

            setStepArrTailored([
              ...stepArrTailored.slice(0, selectedTitle),
              newStepArr,
              ...stepArrTailored.slice(selectedTitle + 1),
            ]);
          } else {
            const newSectionData = { ...sectionData };
            newSectionData.steps.prev = res.data;
            newSectionData.steps.current = res.data;
            setSectionData(newSectionData);
            const stepArr = [];
            res.data.forEach((step) => {
              stepArr.push(step["SCENE_DESCRIPTION"]);
            });
            setStepArr(stepArr);
          }
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsStoryBoardLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};
export const reviseAfterUpdatingSteps = (
  formData,
  tempUpdatedSteps,
  previousSteps,
  generatedImages,
  sectionData,
  setSectionData,
  activeTab,
  isStoryBoardLoading,
  setIsStoryBoardLoading,
  setErrorMessage,
  dataMap,
  reviseComment,
  stepArr,
  setStepArr,
  tailoredVisible,
  selectedTitle,
  setStepArrTailored,
  stepArrTailored
) => {
  setIsStoryBoardLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          STORY_UPDATE_STEP_NO_IMAGE,
          {
            duration: Number(formData.duration),
            step_updates: tempUpdatedSteps,
            previous_steps: previousSteps,
            images_generated: generatedImages,
          },
          {
            headers: { Authorization: idToken },
          }
        )
        .then((res) => {
          if (tailoredVisible) {
            const obj = { ...sectionData };
            obj.stepsTailored[selectedTitle].prev = res.data["STEP"];
            obj.stepsTailored[selectedTitle].current = res.data["STEP"];
            setSectionData(obj);
            const newObj = { ...obj };
            newObj.stepsTailored[selectedTitle].prev?.forEach((step, i) => {
              if (step.hasOwnProperty("IMAGE")) delete step["IMAGE"];
            });
            newObj.stepsTailored[selectedTitle].current?.forEach((step, i) => {
              if (step.hasOwnProperty("IMAGE")) delete step["IMAGE"];
            });
            const reqBody = {
              storyboard_script: newObj?.stepsTailored[selectedTitle]?.prev,
              duration: Number(formData.duration),
              revision_comment: reviseComment,
              no_of_steps: stepArrTailored[selectedTitle].length,
            };
            return axios.post(STORY_REVISE_STEP, reqBody, {
              headers: { Authorization: idToken },
            });
          } else {
            const obj = { ...sectionData };
            obj.steps.prev = res.data["STEP"];
            obj.steps.current = res.data["STEP"];
            setSectionData(obj);
            const newObj = { ...obj };
            newObj.steps.prev?.forEach((step, i) => {
              if (step.hasOwnProperty("IMAGE")) delete step["IMAGE"];
            });

            newObj.steps.current?.forEach((step, i) => {
              if (step.hasOwnProperty("IMAGE")) delete step["IMAGE"];
            });
            const reqBody = {
              storyboard_script: newObj?.steps?.prev,
              duration: Number(formData.duration),
              revision_comment: reviseComment,
              no_of_steps: stepArr.length,
            };
            return axios.post(STORY_REVISE_STEP, reqBody, {
              headers: { Authorization: idToken },
            });
          }
        })
        .then((res) => {
          if (tailoredVisible) {
            const newSectionData = { ...sectionData };
            newSectionData.stepsTailored[selectedTitle].prev = res.data;
            newSectionData.stepsTailored[selectedTitle].current = res.data;
            setSectionData(newSectionData);
            const newStepArrTailored = [];
            res.data.forEach((step) => {
              newStepArrTailored.push(step["SCENE_DESCRIPTION"]);
            });
            setStepArrTailored([
              ...stepArrTailored.slice(0, selectedTitle),
              newStepArrTailored,
              ...stepArrTailored.slice(selectedTitle + 1),
            ]);
          } else {
            const newSectionData = { ...sectionData };
            newSectionData.steps.prev = res.data;
            newSectionData.steps.current = res.data;
            setSectionData(newSectionData);
            const stepArr = [];
            res.data.forEach((step) => {
              stepArr.push(step["SCENE_DESCRIPTION"]);
            });
            setStepArr(stepArr);
          }
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsStoryBoardLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};
export const reviseDirectSteps = (
  setIsStoryBoardLoading,
  sectionData,
  setSectionData,
  stepArr,
  setStepArr,
  req_body,
  setErrorMessage
) => {
  setIsStoryBoardLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(STORY_DIRECT_VIEW_REVISION, req_body, {
          headers: { Authorization: idToken },
        })
        .then((res) => {
          const newSectionData = { ...sectionData };
          newSectionData.steps.prev = res.data;
          newSectionData.steps.current = res.data;
          setSectionData(newSectionData);
          const stepArr = [];
          res.data.forEach((step) => {
            stepArr.push(step["SCENE_DESCRIPTION"]);
          });

          setStepArr(stepArr);
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsStoryBoardLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const directStoryStepGeneration = (
  formData,
  sectionData,
  setSectionData,
  stepArr,
  setStepArr,
  activeTab,
  isStoryBoardLoading,
  setIsStoryBoardLoading,
  setErrorMessage,
  textModel
) => {
  setIsStoryBoardLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          STORY_DIRECT_VIEW_GENERATE_STEP,
          {
            duration: Number(formData.duration),
            story: formData.story,
            text_model:textModel
          },
          {
            headers: { Authorization: idToken },
          }
        )
        .then((res) => {
          const obj = { ...sectionData };
          obj.steps.prev = res.data;
          obj.steps.current = res.data;
          setSectionData(obj);
          const stepArr = [];
          res.data.forEach((step) => {
            stepArr.push(step["SCENE_DESCRIPTION"]);
          });
          setStepArr(stepArr);
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsStoryBoardLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const directStoryRevise = (
  revision_comment,
  formData,
  setFormData,
  sectionData,
  setSectionData,
  stepArr,
  setStepArr,
  activeTab,
  isStoryBoardLoading,
  setIsStoryBoardLoading,
  setErrorMessage,
  textModel
) => {
  setIsStoryBoardLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          STORY_DIRECT_VIEW_STORY_REVISION,
          // STORY_DIRECT_VIEW_REVISION,
          {
            duration: Number(formData.duration),
            story: formData.story,
            revision_comment: revision_comment,
            text_model:textModel
          },
          {
            headers: { Authorization: idToken },
          }
        )
        .then((res) => {
          const obj = { ...formData };
          obj.story = res.data;

          setFormData(obj);
          // const stepArr = [];
          // res.data.forEach((step) => {
          //   stepArr.push(step["SCENE_DESCRIPTION"]);
          // });
          // setStepArr(stepArr);
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsStoryBoardLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};
export const generateDirectStoryboard = (
  formData,
  sectionData,
  setSectionData,
  setStepArr,
  activeTab,
  isStoryBoardLoading,
  setIsStoryBoardLoading,
  setErrorMessage,
  setGeneratedImages,
  textModel,
  imgModel
) => {
  setIsStoryBoardLoading(true);
  const newArr = sectionData.steps.prev;
  newArr.forEach((step, index) => {
    step["STEP_NO"] = index + 1;
  });
  const obj = {
    duration: Number(formData.duration),
    steps: newArr,
  };
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          STORY_DIRECT_VIEW_GENERATE_STEP,
          {
            duration: Number(formData.duration),
            story: formData.story,
            text_model:textModel
          },
          { headers: { Authorization: idToken } }
        )
        .then((resForSteps) => {
          const newArr = resForSteps.data;
          newArr.forEach((step, index) => {
            step["STEP_NO"] = index + 1;
          });
          const obj = {
            duration: Number(formData.duration),
            steps: newArr,
            img_model:imgModel
          };
          return axios.post(STORY_GENERATE_IMAGE, obj, {
            headers: { Authorization: idToken },
          });
        })
        .then((resForImage) => {
          setGeneratedImages(true);
          const newStepArr = [];
          resForImage.data.forEach((step, i) => {
            newStepArr.push(step["SCENE_DESCRIPTION"]);
          });
          setStepArr(newStepArr);
          setSectionData((previousState) => {
            const obj = { ...previousState };
            obj.steps.prev = resForImage.data;
            obj.steps.current = resForImage.data;
            return obj;
          });
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          setIsStoryBoardLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

export const sendRecordPortfolio = (
  op,
  purpose,
  setIsLoading,
  setErrorMessage,
  setProceedButtonClicked,
  setCred
) => {
  setIsLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          AUTH,
          {
            offering_portfolio: op,
            purpose: purpose,
          },
          { headers: { Authorization: idToken } }
        )
        .then((res) => {
          setProceedButtonClicked(true);
          setCred(res.data["User_Record"]["username"]);
          localStorage.setItem(
            "cred",
            JSON.stringify(res.data["User_Record"]["username"])
          );
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
          setProceedButtonClicked(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};
export const flyerPreview = (
  isFlyerLoading,
  setIsFlyerLoading,
  isFlyerVerticalLoading,
  setIsFlyerVerticalLoading,
  isFlyerSquareLoading,
  setIsFlyerSquareLoading,
  isBannerHorizontalLoading,
  setIsBannerHorizontalLoading,
  isBannerVerticalLoading,
  setIsBannerVerticalLoading,
  setErrorMessage,
  req_body,
  previewImage,
  setPreviewImage,
  previewButtonClicked,
  setPreviewButtonClicked,
  previewAccess,
  setPreviewAccess,
  horizontal,
  vertical,
  square,
  showingBannerHorizontal,
  showingBannerVertical
) => {
  if (horizontal) {
    setIsFlyerLoading(true);
  }
  if (vertical) {
    setIsFlyerVerticalLoading(true);
  }
  if (square) {
    setIsFlyerSquareLoading(true);
  }
  if (showingBannerHorizontal) {
    setIsBannerHorizontalLoading(true);
  }
  if (showingBannerVertical) {
    setIsBannerVerticalLoading(true);
  }
  let URL = "";
  if (horizontal) {
    URL = FLYER_PREVIEW;
  } else if (vertical) {
    URL = FLYER_VERTICAL_PREVIEW;
  } else if (square) {
    URL = FLYER_SQUARE_PREVIEW;
  } else if (showingBannerHorizontal) {
    URL = BANNER_HORIZONTAL_PREVIEW;
  } else if (showingBannerVertical) {
    URL = BANNER_VERTICAL_PREVIEW;
  }
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(URL, req_body, { headers: { Authorization: idToken } })
        .then((res) => {
          if (horizontal) {
            const index = 0;
            setPreviewImage([
              ...previewImage.slice(0, index),
              res.data["base64_image"],
              ...previewImage.slice(index + 1),
            ]);
            setPreviewAccess([
              ...previewAccess.slice(0, index),
              res.data["access_link"],
              ...previewAccess.slice(index + 1),
            ]);
            setPreviewButtonClicked([
              ...previewButtonClicked.slice(0, index),
              true,
              ...previewButtonClicked.slice(index + 1),
            ]);
          }
          if (vertical) {
            const index = 1;
            setPreviewImage([
              ...previewImage.slice(0, index),
              res.data["base64_image"],
              ...previewImage.slice(index + 1),
            ]);
            setPreviewAccess([
              ...previewAccess.slice(0, index),
              res.data["access_link"],
              ...previewAccess.slice(index + 1),
            ]);
            setPreviewButtonClicked([
              ...previewButtonClicked.slice(0, index),
              true,
              ...previewButtonClicked.slice(index + 1),
            ]);
          }
          if (square) {
            const index = 2;
            setPreviewImage([
              ...previewImage.slice(0, index),
              res.data["base64_image"],
              ...previewImage.slice(index + 1),
            ]);
            setPreviewAccess([
              ...previewAccess.slice(0, index),
              res.data["access_link"],
              ...previewAccess.slice(index + 1),
            ]);
            setPreviewButtonClicked([
              ...previewButtonClicked.slice(0, index),
              true,
              ...previewButtonClicked.slice(index + 1),
            ]);
          }
          if (showingBannerHorizontal) {
            const index = 3;
            setPreviewImage([
              ...previewImage.slice(0, index),
              res.data["base64_image"],
              ...previewImage.slice(index + 1),
            ]);
            setPreviewAccess([
              ...previewAccess.slice(0, index),
              res.data["access_link"],
              ...previewAccess.slice(index + 1),
            ]);
            setPreviewButtonClicked([
              ...previewButtonClicked.slice(0, index),
              true,
              ...previewButtonClicked.slice(index + 1),
            ]);
          }
          if (showingBannerVertical) {
            const index = 4;
            setPreviewImage([
              ...previewImage.slice(0, index),
              res.data["base64_image"],
              ...previewImage.slice(index + 1),
            ]);
            setPreviewAccess([
              ...previewAccess.slice(0, index),
              res.data["access_link"],
              ...previewAccess.slice(index + 1),
            ]);
            setPreviewButtonClicked([
              ...previewButtonClicked.slice(0, index),
              true,
              ...previewButtonClicked.slice(index + 1),
            ]);
          }
          // setPreviewImage(res.data["base64_image"]);
          // setPreviewButtonClicked(true);
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          if (horizontal) setIsFlyerLoading(false);
          if (vertical) setIsFlyerVerticalLoading(false);
          if (square) setIsFlyerSquareLoading(false);
          if (showingBannerHorizontal) setIsBannerHorizontalLoading(false);
          if (showingBannerVertical) setIsBannerVerticalLoading(false);
        });
    })
    .catch((error) => {
      if (horizontal) setIsFlyerLoading(false);
      if (vertical) setIsFlyerVerticalLoading(false);
      if (square) setIsFlyerSquareLoading(false);
      if (showingBannerHorizontal) setIsBannerHorizontalLoading(false);
      if (showingBannerVertical) setIsBannerVerticalLoading(false);
      setErrorMessage(error);
    });
};

export const flyerDownload = (
  isFlyerLoading,
  setIsFlyerLoading,
  isFlyerVerticalLoading,
  setIsFlyerVerticalLoading,
  isFlyerSquareLoading,
  setIsFlyerSquareLoading,
  isBannerHorizontalLoading,
  setIsBannerHorizontalLoading,
  isBannerVerticalLoading,
  setIsBannerVerticalLoading,
  setErrorMessage,
  previewAccess,
  setDownloadButtonClicked,
  horizontal,
  vertical,
  square,
  showingBannerHorizontal,
  showingBannerVertical
) => {
  if (horizontal) {
    setIsFlyerLoading(true);
  }
  if (vertical) {
    setIsFlyerVerticalLoading(true);
  }
  if (square) {
    setIsFlyerSquareLoading(true);
  }
  if (showingBannerHorizontal) {
    setIsBannerHorizontalLoading(true);
  }
  if (showingBannerVertical) {
    setIsBannerVerticalLoading(true);
  }
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          DOWNLOAD_FLYER,
          {
            url: previewAccess[
              horizontal
                ? 0
                : vertical
                ? 1
                : square
                ? 2
                : showingBannerHorizontal
                ? 3
                : 4
            ],
          },
          { headers: { Authorization: idToken }, responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/zip" });
          const blobUrl = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = blobUrl;
          anchor.target = "_blank";
          anchor.click();

          window.setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            anchor.remove();
          }, 0);

          setDownloadButtonClicked(true);
        })
        .catch((error) => {
          error.message = errorMessages.hasOwnProperty(error?.response?.status)
            ? errorMessages[error?.response?.status]
            : error.message;
          setErrorMessage(error);
        })
        .finally(() => {
          if (horizontal) setIsFlyerLoading(false);
          if (vertical) setIsFlyerVerticalLoading(false);
          if (square) setIsFlyerSquareLoading(false);
          if (showingBannerHorizontal) setIsBannerHorizontalLoading(false);
          if (showingBannerVertical) setIsBannerVerticalLoading(false);
        });
    })
    .catch((error) => {
      if (horizontal) setIsFlyerLoading(false);
      if (vertical) setIsFlyerVerticalLoading(false);
      if (square) setIsFlyerSquareLoading(false);
      if (showingBannerHorizontal) setIsBannerHorizontalLoading(false);
      if (showingBannerVertical) setIsBannerVerticalLoading(false);
      setErrorMessage(error);
    });
};

function update_duration(list_floor_time_duration, response) {
  // update duration timestamp in response
  let end_time_seconds = 0;
  let end_time_minute = 0;
  for (
    let duration_index = 0;
    duration_index < list_floor_time_duration.length;
    duration_index++
  ) {
    let start_time_seconds = end_time_seconds;
    let start_time_minute = end_time_minute;
    end_time_seconds += list_floor_time_duration[duration_index];
    if (end_time_seconds > 59) {
      end_time_seconds = end_time_seconds % 60;
      end_time_minute += 1;
    }
    let duration = `${("0" + start_time_minute).slice(-2)}:${(
      "0" + start_time_seconds
    ).slice(-2)} - ${("0" + end_time_minute).slice(-2)}:${(
      "0" + end_time_seconds
    ).slice(-2)}`;
    response[duration_index]["DURATION_IN_SECONDS"] = duration;
  }
  return response;
}

export const passcodeVerifictation = (
  setErrorMessage,
  passcode,
  setVerificationMessage,
  setIsLoading,
  setCorrectPasscode
) => {
  setIsLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          VALIDATE_PASSKEY,
          {
            passkey: passcode,
          },
          { headers: { Authorization: idToken } }
        )
        .then((res) => {
          if (res.data.hasOwnProperty("message")) {
            setVerificationMessage(res.data["message"]);
            setCorrectPasscode(true);
            window.location.reload(false);
          }
        })
        .catch((error) => {
          setVerificationMessage("Error Occurred");
        })
        .finally(() => {
          setIsLoading(false);
        });
    })
    .catch((error) => {
      setErrorMessage(error);
      setIsLoading(false);
    });
};

export const savePage = (
  activeTab,
  setErrorMessage,
  setIsPersonaLoading,
  setIsPrintLoading,
  setIsEmailLoading,
  setIsSocialLoading,
  setIsCreativeLoading,
  setIsFlyerLoading,
  setIsFlyerVerticalLoading,
  setIsFlyerSquareLoading,
  setIsBannerHorizontalLoading,
  setIsBannerVerticalLoading,
  draft_name,
  sectionData,
  formData,
  translateLanguage,
  template_id,
  primary_colour,
  secondary_colour,
  logo,
  logoFileName,
  showingHorizontal,
  showingVertical,
  showingSquare,
  showingBannerHorizontal,
  showingBannerVertical,
  setShowRenameCard,
  setFullPageLoading,
  setShowFileSaved,
  titles,
  setTitles,
  selectedTitle
) => {
  const URL = PAGE_SAVE;
  const userName = localStorage.getItem("cred");
  const payload = {
    name: draft_name,
    signals: formData.demographicData[selectedTitle],
    product_service: formData.product,
    objective: formData.objective,
    tone_mood: formData.tone,
    key_selling_points: formData.sellingPoint,
    username: userName,
  };
  Object.keys(payload).forEach((key) => {
    if (payload[key] === "") delete payload[key];
  });
  // setFullPageLoading(true);
  if (activeTab === "Persona") {
    setIsPersonaLoading(true);
    payload.tab_name = "persona";
    if (sectionData.persona?.[selectedTitle])
      payload.persona = sectionData.persona[selectedTitle];
    payload.language = translateLanguage[activeTab]?.[selectedTitle];
  } else if (activeTab === "Email") {
    setIsEmailLoading(true);
    payload.tab_name = "email";
    if (sectionData.persona?.[selectedTitle]) {
      payload.persona = sectionData.persona[selectedTitle];
      if (sectionData.email?.[selectedTitle]) {
        payload.email = sectionData.email[selectedTitle];
      }
    }
    payload.language = translateLanguage[activeTab]?.[selectedTitle];
  } else if (activeTab === "Creative Concepts") {
    setIsCreativeLoading(true);
    payload.tab_name = "creative_concept";
    if (
      sectionData.creativeConcepts?.["Concepts and Images"]?.[
        selectedTitle
      ]?.[0]?.["Images"]
    ) {
      payload.image_list1 =
        sectionData.creativeConcepts["Concepts and Images"][selectedTitle][0]?.[
          "Images"
        ];
      payload.image_list2 =
        sectionData.creativeConcepts["Concepts and Images"][selectedTitle][1]?.[
          "Images"
        ];
      payload.image_list3 =
        sectionData.creativeConcepts["Concepts and Images"][selectedTitle][2]?.[
          "Images"
        ];
      payload.creative_concept1 =
        sectionData.creativeConcepts["Concepts and Images"][selectedTitle][0]?.[
          "ConceptTerritory"
        ];
      payload.creative_concept2 =
        sectionData.creativeConcepts["Concepts and Images"][selectedTitle][1]?.[
          "ConceptTerritory"
        ];
      payload.creative_concept3 =
        sectionData.creativeConcepts["Concepts and Images"][selectedTitle][2]?.[
          "ConceptTerritory"
        ];
    }
    if(sectionData.persona?.[selectedTitle]){
      payload.persona = sectionData.persona[selectedTitle];
   }
    payload.language = translateLanguage["Creative Concepts"]?.[selectedTitle];
  } else if (activeTab === "Print") {
    setIsPrintLoading(true);
    payload.tab_name = "magazine_ad";
    if (sectionData.print?.[selectedTitle]?.["Magazine Ad"]) {
      payload.ad = sectionData.print?.[selectedTitle]?.["Magazine Ad"];
      payload.images = sectionData.print?.[selectedTitle]?.Images;
    }
    payload.language = translateLanguage[activeTab]?.[selectedTitle];
  } else if (activeTab === "Social Media") {
    setIsSocialLoading(true);
    payload.tab_name = "social_media";
    payload.persona = sectionData.persona[selectedTitle];
    payload.instagram_caption =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["instagram"]?.[
        "caption"
      ];
    payload.instagram_prompt =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["instagram"]?.[
        "image_prompt"
      ];
    payload.pinterest_caption =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["pinterest"]?.[
        "caption"
      ];
    payload.pinterest_prompt =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["pinterest"]?.[
        "image_prompt"
      ];
    payload.blog_caption =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["blog"]?.[
        "caption"
      ];
    payload.blog_prompt =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["blog"]?.[
        "image_prompt"
      ];
    payload.facebook_caption =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["facebook"]?.[
        "caption"
      ];
    payload.facebook_prompt =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["facebook"]?.[
        "image_prompt"
      ];
    payload.twitter_caption =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["twitter"]?.[
        "caption"
      ];
    payload.twitter_prompt =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["twitter"]?.[
        "image_prompt"
      ];
    payload.facebook_images =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["facebook"][
        "images"
      ] || null;
    payload.twitter_images =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["twitter"][
        "images"
      ] || null;
    payload.pinterest_images =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["pinterest"][
        "images"
      ] || null;
    payload.instagram_images =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["instagram"][
        "images"
      ] || null;
    payload.blog_images =
      sectionData.socialMedia[selectedTitle]?.["caption"]?.["blog"]["images"] ||
      null;
    payload.language = translateLanguage[activeTab]?.[selectedTitle];
  } else if (activeTab === "Flyer") {
    if (showingHorizontal) {
      setIsFlyerLoading(true);
      payload.tab_name = "flyer";
      payload.flyer_type = "Horizontal";
      payload.ad = sectionData.flyer["Flyer"];
      payload.images = sectionData.flyer["Images"];
      payload.language = translateLanguage["Flyer"];
      payload.template_id = template_id;
      payload.primary_colour = primary_colour;
      payload.secondary_colour = secondary_colour;
      payload.logo = logo ? logo.substring(logo.indexOf(",") + 1) : "";
      payload.logo_name = logoFileName || "";
    }
    if (showingVertical) {
      setIsFlyerVerticalLoading(true);
      payload.tab_name = "flyer";
      payload.flyer_type = "Vertical";
      payload.ad = sectionData.flyerVertical["Flyer"];
      payload.images = sectionData.flyerVertical["Images"];
      payload.language = translateLanguage["FlyerVertical"];
      payload.template_id = template_id;
      payload.primary_colour = primary_colour;
      payload.secondary_colour = secondary_colour;
      payload.logo = logo ? logo.substring(logo.indexOf(",") + 1) : "";
      payload.logo_name = logoFileName || "";
    }
    if (showingSquare) {
      setIsFlyerSquareLoading(true);
      payload.tab_name = "flyer";
      payload.flyer_type = "Square";
      payload.ad = sectionData.flyerSquare["Flyer"];
      payload.images = sectionData.flyerSquare["Images"];
      payload.language = translateLanguage["FlyerSquare"];
      payload.template_id = template_id;
      payload.primary_colour = primary_colour;
      payload.secondary_colour = secondary_colour;
      payload.logo = logo ? logo.substring(logo.indexOf(",") + 1) : "";
      payload.logo_name = logoFileName || "";
    }
    if (showingBannerHorizontal) {
      setIsBannerHorizontalLoading(true);
      payload.tab_name = "banner";
      payload.banner_type = "Horizontal";
      payload.ad = sectionData.bannerHorizontal["Flyer"];
      payload.images = sectionData.bannerHorizontal["Images"];
      payload.language = translateLanguage["BannerHorizontal"];
      payload.template_id = template_id;
      payload.primary_colour = primary_colour;
      payload.secondary_colour = secondary_colour;
      payload.logo = logo ? logo.substring(logo.indexOf(",") + 1) : "";
      payload.logo_name = logoFileName || "";
    }
    if (showingBannerVertical) {
      setIsBannerVerticalLoading(true);
      payload.tab_name = "banner";
      payload.banner_type = "Vertical";
      payload.ad = sectionData.bannerVertical["Flyer"];
      payload.images = sectionData.bannerVertical["Images"];
      payload.language = translateLanguage["BannerVertical"];
      payload.template_id = template_id;
      payload.primary_colour = primary_colour;
      payload.secondary_colour = secondary_colour;
      payload.logo = logo ? logo.substring(logo.indexOf(",") + 1) : "";
      payload.logo_name = logoFileName || "";
    }
  }
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(URL, payload, { headers: { Authorization: idToken } })
        .then((res) => {
          setShowRenameCard(false);
          setShowFileSaved(true);
        })
        .catch((error) => {
          error.message = error?.response?.data?.Status;
          setErrorMessage(error);
        })
        .finally(() => {
          setFullPageLoading(false);
          if (activeTab === "Persona") setIsPersonaLoading(false);
          if (activeTab === "Email") setIsEmailLoading(false);
          if (activeTab === "Social Media") setIsSocialLoading(false);
          if (activeTab === "Print") setIsPrintLoading(false);
          if (activeTab === "Creative Concepts") setIsCreativeLoading(false);
          if (activeTab === "Flyer") {
            if (showingHorizontal) setIsFlyerLoading(false);
            if (showingVertical) setIsFlyerVerticalLoading(false);
            if (showingSquare) setIsFlyerSquareLoading(false);
            if (showingBannerHorizontal) setIsBannerHorizontalLoading(false);
            if (showingBannerVertical) setIsBannerVerticalLoading(false);
          }
        });
    })
    .catch((error) => {
      setFullPageLoading(false);

      setErrorMessage(error);
      if (activeTab === "Persona") setIsPersonaLoading(false);
      if (activeTab === "Email") setIsEmailLoading(false);
      if (activeTab === "Social Media") setIsSocialLoading(false);
      if (activeTab === "Print") setIsPrintLoading(false);
      if (activeTab === "Creative Concepts") setIsCreativeLoading(false);
      if (activeTab === "Flyer") {
        if (showingHorizontal) setIsFlyerLoading(false);
        if (showingVertical) setIsFlyerVerticalLoading(false);
        if (showingSquare) setIsFlyerSquareLoading(false);
        if (showingBannerHorizontal) setIsBannerHorizontalLoading(false);
        if (showingBannerVertical) setIsBannerVerticalLoading(false);
      }
    });
};
export const fetchDrafts = (
  setErrorMessage,
  setSectionData,
  setSavedDrafts,
  setIsSavePageLoading
) => {
  const userName = localStorage.getItem("cred");

  const URL = PAGE_FETCH_ALL;
  setIsSavePageLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          URL,
          { username: userName },
          { headers: { Authorization: idToken } }
        )
        .then((res) => {
          setSavedDrafts(res.data?.["Response"]);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsSavePageLoading(false);
        });
    })
    .catch((error) => {
      setIsSavePageLoading(false);
    });
};
export const resumeWork = (
  setClickedOnSocialOrPrint,
  setActiveTab,
  fileName,
  tabName,
  tabType,
  setErrorMessage,
  sectionData,
  setSectionData,
  formData,
  setFormData,
  setIsSavePageLoading,
  selectedTemplate,
  setSelectedTemplate,
  primaryColor,
  setPrimaryColor,
  secondaryColor,
  setSecondaryColor,
  imageState,
  setImageState,
  setTranslateLanguage,
  setShowResumeCard,
  setFullPageLoading,
  setShowingHorizontal,
  setShowingVertical,
  setShowingSquare,
  setShowingBannerHorizontal,
  setShowingBannerVertical,
  titles,
  setTitles,
  selectedTitle,
  signals,
  setSignals,
  setSelectedTitle
) => {
  const userName = localStorage.getItem("cred");
  const URL = PAGE_FETCH_CARD;
  const payload = {};
  payload.username = userName;
  payload.name = fileName;
  payload.tab_name = tabName;
  if (tabName === "creative_concept") {
    payload.tab_name = "creative_ideation";
  }
  if (tabName === "flyer") payload.flyer_type = tabType;
  if (tabName === "banner") payload.banner_type = tabType;
  setFullPageLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(URL, payload, { headers: { Authorization: idToken } })
        .then((res) => {
          const newResponse = res.data?.["Response"];
          const newFormData = { ...formData };
          newFormData.demographicData = [newResponse["signals"]] || "";
          setSignals([newResponse["signals"]]);
          newFormData.product = newResponse["product_service"] || "";
          newFormData.objective = newResponse["objective"] || "";
          newFormData.tone = newResponse["tone_mood"] || "";
          newFormData.sellingPoint = newResponse["key_selling_points"] || "";
          setFormData(newFormData);
          const newSectionData = { ...sectionData };
          if (tabName === "persona") {
            newSectionData.persona = [newResponse.persona];
            setTitles([""]);
            setTranslateLanguage((prev) => ({
              ...prev,
              Persona: [newResponse.language],
            }));
            setActiveTab("Persona");
            setClickedOnSocialOrPrint(false);
          }
          if (tabName === "email") {
            newSectionData.email = [newResponse.email];
            newSectionData.persona = [newResponse.persona];
            setTitles([""]);
            setTranslateLanguage((prev) => ({
              ...prev,
              Email: [newResponse.language],
            }));
            setActiveTab("Email");
            setClickedOnSocialOrPrint(false);
          }
          if (tabName === "magazine_ad") {
            newSectionData.print = [
              {
                "Magazine Ad": newResponse.ad,
                Images: newResponse.images,
              },
            ];
            // newSectionData?.print?.["Magazine Ad"] = newResponse.ad;
            // newSectionData?.print?.["Images"] = newResponse.images;
            setTranslateLanguage((prev) => ({
              ...prev,
              Print: [newResponse.language],
            }));
            setActiveTab("Print");
            setClickedOnSocialOrPrint(true);
          }
          if (tabName === "social_media") {
            newSectionData.socialMedia = [
              {
                caption: newResponse.caption,
                images: newResponse.images,
              },
            ];
            newSectionData.persona = [newResponse.persona];
            setTranslateLanguage((prev) => ({
              ...prev,
              "Social Media": newResponse.language,
            }));
            setActiveTab("Social Media");
            setClickedOnSocialOrPrint(true);
          }
          if (tabName === "creative_concept") {
            newSectionData.creativeConcepts = [
              {
                "Concepts and Images": [
                  {
                    ConceptTerritory: newResponse.creative_concept1,
                    Images: newResponse.images_list1,
                  },
                  {
                    ConceptTerritory: newResponse.creative_concept2,
                    Images: newResponse.images_list2,
                  },
                  {
                    ConceptTerritory: newResponse.creative_concept3,
                    Images: newResponse.images_list3,
                  },
                ],
              },
            ];
            newSectionData.persona = [newResponse.persona];
            setTranslateLanguage((prev) => ({
              ...prev,
              "Creative Concepts": [newResponse.language],
            }));
            setActiveTab("Creative Concepts");
            setClickedOnSocialOrPrint(false);
          }
          if (tabName === "flyer") {
            newResponse.primary_colour = newResponse.primary_colour.replace(
              /'/g,
              '"'
            );
            newResponse.secondary_colour = newResponse.secondary_colour.replace(
              /'/g,
              '"'
            );
            setActiveTab("Flyer");
            setClickedOnSocialOrPrint(false);
            if (newResponse.flyer_type === "Horizontal") {
              newSectionData.flyer = {
                ...newSectionData.flyer,
                Images: newResponse.images,
                Flyer: newResponse.ad,
                Flyer_Translated: newResponse.ad,
              };
              const _index = 0;
              const newSelectedTemplate = selectedTemplate.map(
                (item, index) => {
                  if (_index === index) {
                    return Number(newResponse.template_id);
                  } else return item;
                }
              );
              const newPrimaryColor = primaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(newResponse.primary_colour);
                  return obj;
                } else return item;
              });
              const newSecondaryColor = secondaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(newResponse.secondary_colour);
                  return obj;
                } else return item;
              });
              const newImageState = imageState.map((item, index) => {
                if (_index === index) {
                  item.logoBase64 = newResponse.logo;
                  item.logoFileName = newResponse.logo_name;
                  return item;
                } else return item;
              });
              setSelectedTemplate(newSelectedTemplate);
              setPrimaryColor(newPrimaryColor);
              setSecondaryColor(newSecondaryColor);
              setImageState(newImageState);
              setShowingHorizontal(true);
              setShowingVertical(false);
              setShowingSquare(false);
              setShowingBannerHorizontal(false);
              setShowingBannerVertical(false);
            } else if (newResponse.flyer_type === "Vertical") {
              newSectionData.flyerVertical = {
                ...newSectionData.flyerVertical,
                Images: newResponse.images,
                Flyer: newResponse.ad,
                Flyer_Translated: newResponse.ad,
              };
              const _index = 1;
              const newSelectedTemplate = selectedTemplate.map(
                (item, index) => {
                  if (_index === index) return Number(newResponse.template_id);
                  else return item;
                }
              );
              const newPrimaryColor = primaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(newResponse.primary_colour);
                  return obj;
                } else return item;
              });
              const newSecondaryColor = secondaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(newResponse.secondary_colour);
                  return obj;
                } else return item;
              });
              const newImageState = imageState.map((item, index) => {
                if (_index === index) {
                  item.logoBase64 = newResponse.logo;
                  item.logoFileName = newResponse.logo_name;
                  return item;
                } else return item;
              });
              setSelectedTemplate(newSelectedTemplate);
              setPrimaryColor(newPrimaryColor);
              setSecondaryColor(newSecondaryColor);
              setImageState(newImageState);
              setShowingHorizontal(false);
              setShowingVertical(true);
              setShowingSquare(false);
              setShowingBannerHorizontal(false);
              setShowingBannerVertical(false);
            } else if (newResponse.flyer_type === "Square") {
              newSectionData.flyerSquare = {
                ...newSectionData.flyerSquare,
                Images: newResponse.images,
                Flyer: newResponse.ad,
                Flyer_Translated: newResponse.ad,
              };
              const _index = 2;
              const newSelectedTemplate = selectedTemplate.map(
                (item, index) => {
                  if (_index === index) {
                    return Number(newResponse.template_id);
                  } else return item;
                }
              );
              const newPrimaryColor = primaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(newResponse.primary_colour);
                  return obj;
                } else return item;
              });
              const newSecondaryColor = secondaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(newResponse.secondary_colour);
                  return obj;
                } else return item;
              });
              const newImageState = imageState.map((item, index) => {
                if (_index === index) {
                  item.logoBase64 = newResponse.logo;
                  item.logoFileName = newResponse.logo_name;
                  return item;
                } else return item;
              });
              setSelectedTemplate(newSelectedTemplate);
              setPrimaryColor(newPrimaryColor);
              setSecondaryColor(newSecondaryColor);
              setImageState(newImageState);
              setShowingHorizontal(false);
              setShowingVertical(false);
              setShowingSquare(true);
              setShowingBannerHorizontal(false);
              setShowingBannerVertical(false);
            }
          }
          if (tabName === "banner") {
            newResponse.primary_colour = newResponse.primary_colour.replace(
              /'/g,
              '"'
            );
            newResponse.secondary_colour = newResponse.secondary_colour.replace(
              /'/g,
              '"'
            );
            setActiveTab("Flyer");
            setClickedOnSocialOrPrint(false);
            if (newResponse.banner_type === "Horizontal") {
              newSectionData.bannerHorizontal = {
                ...newSectionData.bannerHorizontal,
                Images: newResponse.images,
                Flyer: newResponse.ad,
                Flyer_Translated: newResponse.ad,
              };
              const _index = 3;
              const newSelectedTemplate = selectedTemplate.map(
                (item, index) => {
                  if (_index === index) {
                    return Number(newResponse.template_id);
                  } else return item;
                }
              );
              const newPrimaryColor = primaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(newResponse.primary_colour);
                  return obj;
                } else return item;
              });
              const newSecondaryColor = secondaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(newResponse.secondary_colour);
                  return obj;
                } else return item;
              });
              const newImageState = imageState.map((item, index) => {
                if (_index === index) {
                  item.logoBase64 = newResponse.logo;
                  item.logoFileName = newResponse.logo_name;
                  return item;
                } else return item;
              });
              setSelectedTemplate(newSelectedTemplate);
              setPrimaryColor(newPrimaryColor);
              setSecondaryColor(newSecondaryColor);
              setImageState(newImageState);
              setShowingHorizontal(false);
              setShowingVertical(false);
              setShowingSquare(false);
              setShowingBannerHorizontal(true);
              setShowingBannerVertical(false);
            }
            if (newResponse.banner_type === "Vertical") {
              newSectionData.bannerVertical = {
                ...newSectionData.bannerVertical,
                Images: newResponse.images,
                Flyer: newResponse.ad,
                Flyer_Translated: newResponse.ad,
              };
              const _index = 4;
              const newSelectedTemplate = selectedTemplate.map(
                (item, index) => {
                  if (_index === index) {
                    return Number(newResponse.template_id);
                  } else return item;
                }
              );
              const newPrimaryColor = primaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(newResponse.primary_colour);
                  return obj;
                } else return item;
              });
              const newSecondaryColor = secondaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(newResponse.secondary_colour);
                  return obj;
                } else return item;
              });
              const newImageState = imageState.map((item, index) => {
                if (_index === index) {
                  item.logoBase64 = newResponse.logo;
                  item.logoFileName = newResponse.logo_name;
                  return item;
                } else return item;
              });
              setSelectedTemplate(newSelectedTemplate);
              setPrimaryColor(newPrimaryColor);
              setSecondaryColor(newSecondaryColor);
              setImageState(newImageState);
              setShowingHorizontal(false);
              setShowingVertical(false);
              setShowingSquare(false);
              setShowingBannerHorizontal(false);
              setShowingBannerVertical(true);
            }
          }
          setSectionData(newSectionData);
          setShowResumeCard(false);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setFullPageLoading(false);
        });
    })
    .catch((error) => {
      setFullPageLoading(false);
      console.error(error);
    });
};

export const deleteDraft = (
  setErrorMessage,
  setIsSavePageLoading,
  fileName,
  tabName,
  tabType,
  setSavedDrafts
) => {
  const userName = localStorage.getItem("cred");
  let URLL = `${URL}/page/delete/${tabName}/${userName}/${fileName}`;
  if (tabName === "creative_concept") {
    URLL = `${URL}/page/delete/creative_ideation/${userName}/${fileName}`;
  }
  if (tabName === "flyer" || tabName === "banner") {
    URLL = `${URL}/page/delete/${tabName}/${userName}/${fileName}/${tabType}`;
  }
  setIsSavePageLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .delete(URLL, {
          headers: { Authorization: idToken },
        })
        .then((res) => {
          return axios.post(
            PAGE_FETCH_ALL,
            { username: userName },
            { headers: { Authorization: idToken } }
          );
        })
        .then((res) => {
          setSavedDrafts(res.data?.["Response"]);
        })
        .catch((error) => {
          setErrorMessage(error);
          console.error(error);
        })
        .finally(() => {
          setIsSavePageLoading(false);
        });
    })
    .catch((error) => {
      setIsSavePageLoading(false);
      setErrorMessage(error);
      console.error(error);
    });
};

export const foundationUndo = (
  setErrorMessage,
  setIsCreativeLoading,
  setSectionData,
  sectionData,
  setStepArrTailored,
  setGeneratedImagesTailored,
  formData,
  setImageState,
  setSelectedTemplate,
  setPrimaryColor,
  setSecondaryColor
) => {
  const payload = {
    username: localStorage.getItem("cred"),
    name: "undo",
    persona: sectionData.persona,
  };
  setIsCreativeLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(FOUNDATION_UNDO, payload, { headers: { Authorization: idToken } })
        .then((res) => {
          const newResponse = res.data;
          const newSectionData = { ...sectionData };
          if (newResponse.hasOwnProperty("email"))
            newSectionData.email = newResponse.email.map(
              (emailResponse) => emailResponse?.email
            );
          if (newResponse.hasOwnProperty("magazine_ad"))
            newSectionData.print = newResponse.magazine_ad?.map(
              (printResponse) => ({
                "Magazine Ad": printResponse?.magazine_ad,
                Images: printResponse?.Images || null,
              })
            );

          if (newResponse.hasOwnProperty("horizontal_flyer")) {
            newSectionData.flyer["Flyer_Translated"] =
              newResponse.horizontal_flyer?.ad;
            newSectionData.flyer["Images"] =
              newResponse.horizontal_flyer?.images_link;
            setImageState((prev) => {
              let imageState = [...prev];
              imageState[0].logoBase64 =
                newResponse.horizontal_flyer?.logo_link;
              imageState[0].logoFileName =
                newResponse.horizontal_flyer?.logo_name;
              return imageState;
            });
            setSelectedTemplate((prev) => {
              let selectedTemplate = [...prev];
              selectedTemplate[0] = newResponse.horizontal_flyer?.template_id;
              return selectedTemplate;
            });
            setPrimaryColor((prev) => {
              let primaryColor = [...prev];
              primaryColor[0] = newResponse.horizontal_flyer?.primary_colour;
              return primaryColor;
            });
            setSecondaryColor((prev) => {
              let secondaryColor = [...prev];
              secondaryColor[0] =
                newResponse.horizontal_flyer?.secondary_colour;
              return secondaryColor;
            });
          }
          if (newResponse.hasOwnProperty("vertical_flyer")) {
            const obj = newResponse.vertical_flyer;
            newSectionData.flyerVertical["Flyer_Translated"] = obj?.ad;
            newSectionData.flyerVertical["Images"] = obj?.images_link;
            setImageState((prev) => {
              let imageState = [...prev];
              imageState[1].logoBase64 = obj?.logo_link;
              imageState[1].logoFileName = obj?.logo_name;
              return imageState;
            });
            setSelectedTemplate((prev) => {
              let selectedTemplate = [...prev];
              selectedTemplate[1] = obj?.template_id;
              return selectedTemplate;
            });
            setPrimaryColor((prev) => {
              let primaryColor = [...prev];
              primaryColor[1] = obj?.primary_colour;
              return primaryColor;
            });
            setSecondaryColor((prev) => {
              let secondaryColor = [...prev];
              secondaryColor[1] = obj?.secondary_colour;
              return secondaryColor;
            });
          }
          if (newResponse.hasOwnProperty("square_flyer")) {
            const obj = newResponse.square_flyer;
            newSectionData.flyerSquare["Flyer_Translated"] = obj?.ad;
            newSectionData.flyerSquare["Images"] = obj?.images_link;
            setImageState((prev) => {
              let imageState = [...prev];
              imageState[2].logoBase64 = obj?.logo_link;
              imageState[2].logoFileName = obj?.logo_name;
              return imageState;
            });
            setSelectedTemplate((prev) => {
              let selectedTemplate = [...prev];
              selectedTemplate[2] = obj?.template_id;
              return selectedTemplate;
            });
            setPrimaryColor((prev) => {
              let primaryColor = [...prev];
              primaryColor[2] = obj?.primary_colour;
              return primaryColor;
            });
            setSecondaryColor((prev) => {
              let secondaryColor = [...prev];
              secondaryColor[2] = obj?.secondary_colour;
              return secondaryColor;
            });
          }
          if (newResponse.hasOwnProperty("horizontal_banner")) {
            const obj = newResponse.horizontal_banner;
            newSectionData.bannerHorizontal["Flyer_Translated"] = obj?.ad;
            newSectionData.bannerHorizontal["Images"] = obj?.images_link;
            setImageState((prev) => {
              let imageState = [...prev];
              imageState[3].logoBase64 = obj?.logo_link;
              imageState[3].logoFileName = obj?.logo_name;
              return imageState;
            });
            setSelectedTemplate((prev) => {
              let selectedTemplate = [...prev];
              selectedTemplate[3] = obj?.template_id;
              return selectedTemplate;
            });
            setPrimaryColor((prev) => {
              let primaryColor = [...prev];
              primaryColor[3] = obj?.primary_colour;
              return primaryColor;
            });
            setSecondaryColor((prev) => {
              let secondaryColor = [...prev];
              secondaryColor[3] = obj?.secondary_colour;
              return secondaryColor;
            });
          }
          if (newResponse.hasOwnProperty("vertical_banner")) {
            const obj = newResponse.vertical_banner;
            newSectionData.bannerVertical["Flyer_Translated"] = obj?.ad;
            newSectionData.bannerVertical["Images"] = obj?.images_link;
            setImageState((prev) => {
              let imageState = [...prev];
              imageState[4].logoBase64 = obj?.logo_link;
              imageState[4].logoFileName = obj?.logo_name;
              return imageState;
            });
            setSelectedTemplate((prev) => {
              let selectedTemplate = [...prev];
              selectedTemplate[4] = obj?.template_id;
              return selectedTemplate;
            });
            setPrimaryColor((prev) => {
              let primaryColor = [...prev];
              primaryColor[4] = obj?.primary_colour;
              return primaryColor;
            });
            setSecondaryColor((prev) => {
              let secondaryColor = [...prev];
              secondaryColor[4] = obj?.secondary_colour;
              return secondaryColor;
            });
          }

          if (newResponse.hasOwnProperty("social_media"))
            newSectionData.socialMedia = newResponse["social_media"].map(
              (socialResponse) => {
                let platformItem = {};

                platformItem.caption = {
                  instagram: {
                    caption: socialResponse.instagram.caption,
                    image_propmt: socialResponse.instagram.image_prompt,
                    images: socialResponse.instagram?.images,
                  },
                  twitter: {
                    caption: socialResponse.twitter?.caption,
                    image_propmt: socialResponse.twitter?.image_prompt,
                    images: socialResponse.twitter?.images,
                  },
                  facebook: {
                    caption: socialResponse.facebook?.caption,
                    image_propmt: socialResponse.facebook?.image_prompt,
                    images: socialResponse.facebook?.images,
                  },
                  pinterest: {
                    caption: socialResponse.pinterest?.caption,
                    image_propmt: socialResponse.pinterest?.image_prompt,
                    images: socialResponse.pinterest?.images,
                  },
                  blog: {
                    caption: socialResponse.blog?.caption,
                    image_propmt: socialResponse.blog?.image_prompt,
                    images: socialResponse.blog?.images,
                  },
                };

                return platformItem;
              }
            );

          let storyBoardResponse = newResponse.storyBoard.map(
            (story) => story["story_board"]
          );
          setGeneratedImagesTailored((prev) => {
            const newArr = [...prev];
            storyBoardResponse.forEach((stepsForTitle, index) => {
              newArr[index] = false;
            });
            return newArr;
          });

          storyBoardResponse.forEach((stepsForTitle, index) => {
            newSectionData.stepsTailored[index].prev = stepsForTitle;
            newSectionData.stepsTailored[index].current = stepsForTitle;
          });
          const stepArr = [];
          storyBoardResponse.forEach((stepsForTitle, index) => {
            const newSteps = [];
            stepsForTitle.forEach((step) => {
              newSteps.push(step["SCENE_DESCRIPTION"]);
            });
            stepArr.push(newSteps);
          });
          setStepArrTailored(stepArr);
          let newFormData = { ...formData };
          newFormData["duration"] =
            newResponse.storyBoard?.[0]?.["duration"].toString();
          setSectionData(newSectionData);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsCreativeLoading(false);
        });
    })
    .catch(() => {
      setIsCreativeLoading(false);
    });
};

export const foundationApply = (
  setErrorMessage,
  setIsCreativeLoading,
  formData,
  sectionData,
  selectedTitle,
  imageState,
  selectedTemplate,
  primaryColor,
  secondaryColor
) => {
  const payload = {
    common_data: {
      tone_mood: formData.tone,
      objective: formData.objective,
      signals: formData.demographicData[selectedTitle],
      product_service: formData.product,
      key_selling_points: formData.sellingPoint,
      language: "English",
      username: localStorage.getItem("cred"),
      name: "undo",
      persona: sectionData.persona,
    },
    data: {
      Email: sectionData?.email.map((emailData) => ({
        Email: emailData,
      })),
      MagazineAd: sectionData?.print.map((printItem) => ({
        ad: printItem?.["Magazine Ad"],
        images_link: printItem?.["Images"],
      })),
      SocialMedia: sectionData.socialMedia.map((socialItem) => {
        let socialJson = {};
        socialJson.instagram = {
          caption: socialItem?.caption?.["instagram"]?.caption,
          image_prompt: socialItem?.caption?.["instagram"]?.["image_prompt"],
          images_link: socialItem?.caption?.["instagram"]?.["images"],
        };
        socialJson.twitter = {
          caption: socialItem?.caption?.["twitter"]?.caption,
          image_prompt: socialItem?.caption?.["twitter"]?.["image_prompt"],
          images_link: socialItem?.caption?.["twitter"]?.["images"],
        };
        socialJson.facebook = {
          caption: socialItem?.caption?.["facebook"]?.caption,
          image_prompt: socialItem?.caption?.["facebook"]?.["image_prompt"],
          images_link: socialItem?.caption?.["facebook"]?.["images"],
        };
        socialJson.pinterest = {
          caption: socialItem?.caption?.["pinterest"]?.caption,
          image_prompt: socialItem?.caption?.["pinterest"]?.["image_prompt"],
          images_link: socialItem?.caption?.["pinterest"]?.["images"],
        };
        socialJson.blog = {
          caption: socialItem?.caption?.["blog"]?.caption,
          image_prompt: socialItem?.caption?.["blog"]?.["image_prompt"],
          images_link: socialItem?.caption?.["blog"]?.["images"],
        };
        return socialJson;
      }),
      SquareFlyer: {
        flyer_type: "Square",
        ad: sectionData.flyerSquare?.["Flyer"],
        images_link: sectionData.flyerSquare?.["Images"],
        logo_link: imageState[2]?.logoBase64,
        template_id: selectedTemplate[2],
        primary_colour: primaryColor[2],
        secondary_colour: secondaryColor[2],
        logo_name: imageState[2]?.logoFileName,
      },
      HorizontalFlyer: {
        flyer_type: "Horizontal",
        ad: sectionData.flyer?.["Flyer"],
        images_link: sectionData.flyer?.["Images"],
        logo_link: imageState[0]?.logoBase64,
        template_id: selectedTemplate[0],
        primary_colour: primaryColor[0],
        secondary_colour: secondaryColor[0],
        logo_name: imageState[0]?.logoFileName,
      },
      VerticalFlyer: {
        flyer_type: "Vertical",
        ad: sectionData.flyerVertical?.["Flyer"],
        images_link: sectionData.flyerVertical?.["Images"],
        logo_link: imageState[1]?.logoBase64,
        template_id: selectedTemplate[1],
        primary_colour: primaryColor[1],
        secondary_colour: secondaryColor[1],
        logo_name: imageState[1]?.logoFileName,
      },
      HorizontalBanner: {
        banner_type: "HorizontalBanner",
        ad: sectionData.bannerHorizontal?.["Flyer"],
        images_link: sectionData.bannerHorizontal?.["Images"],
        logo_link: imageState[3]?.logoBase64,
        template_id: selectedTemplate[3],
        primary_colour: primaryColor[3],
        secondary_colour: secondaryColor[3],
        logo_name: imageState[3]?.logoFileName,
      },
      VerticalBanner: {
        banner_type: "VerticalBanner",
        ad: sectionData.bannerVertical?.["Flyer"],
        images_link: sectionData.bannerVertical?.["Images"],
        logo_link: imageState[4]?.logoBase64,
        template_id: selectedTemplate[4],
        primary_colour: primaryColor[4],
        secondary_colour: secondaryColor[4],
        logo_name: imageState[4]?.logoFileName,
      },
      StoryBoard: sectionData?.stepsTailored.map((stepsItem, index) => {
        let storyResponse = {};
        storyResponse.duration = Number(formData.duration);
        // storyResponse.settings = formData?.settings?.[index];
        let stepsArr = [];
        stepsItem.current.map((steps) => {
          stepsArr.push(steps);
        });
        storyResponse["story_board"] = stepsArr;
        return storyResponse;
      }),
    },
  };
  setIsCreativeLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(FOUNDATION_APPLY, payload, {
          headers: { Authorization: idToken },
        })
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsCreativeLoading(false);
        });
    })
    .catch(() => {
      setIsCreativeLoading(false);
    });
};

export function calculate_duration(response, duration) {
  // Calculate the total duration
  let total_duration = 0;
  response.forEach((item) => {
    total_duration += parseFloat(item["DURATION"].replace("%", ""));
  });

  let total_time = 0;
  let total_floor_time = 0;
  let list_time_duration = [];
  let list_floor_time_duration = [];
  let duration_difference = {};
  response.forEach((item, index) => {
    let item_percentage_duration = parseFloat(
      item["DURATION"].replace("%", "")
    );
    // calculating time for each step in seconds
    let time_duration =
      (item_percentage_duration / total_duration) * duration * 60;
    let time_duration_floor = Math.floor(time_duration);
    list_time_duration.push(time_duration);
    list_floor_time_duration.push(time_duration_floor);
    duration_difference[index + 1] = time_duration - time_duration_floor;
    total_time += time_duration;
    total_floor_time += time_duration_floor;
  });

  // sort duration difference
  duration_difference = Object.entries(duration_difference).sort(
    (a, b) => b[1] - a[1]
  );

  let time_difference = Math.round(total_time - total_floor_time);
  duration_difference.forEach((item) => {
    // update difference in duration
    if (time_difference > 0) {
      list_floor_time_duration[item[0] - 1] += 1;
      time_difference -= 1;
    }
  });

  let updated_response = update_duration(list_floor_time_duration, response);

  return updated_response;
}

export const handleDisplayfilter = (
  tableStatus,
  setLoginData,
  setStartDate,
  selectedValue
) => {
  let status = false;
  if (tableStatus === "archive") {
    status = true;
  }
  let duration = 0;

  if (selectedValue === "option1") {
    duration = 30;
  } else if (selectedValue === "option2") {
    duration = 120;
  } else if (selectedValue === "option3") {
    duration = 365;
  } else {
    duration = 7;
  }

  const apiUrl = ADMIN_FILTER_URL;
  const requestBody = {
    duration: duration,
    archival: status,
  };

  getIdToken().then((idToken) => {
    axios
      .post(apiUrl, JSON.stringify(requestBody), {
        headers: { Authorization: idToken },
      })
      .then((response) => {
        setLoginData(response.data["user_records"]);
        const startDatesFromResponse = {};
        const statusFromResponse = {};
        response.data["user_records"].forEach((row) => {
          startDatesFromResponse[
            `${row.email}_${
              row.offering_portfolio ? row.offering_portfolio : "a"
            }_${row.project ? row.project : "p"}`
          ] = row.end_date ? moment(row.end_date) : null;
          statusFromResponse[
            `${row.email}_${
              row.offering_portfolio ? row.offering_portfolio : "a"
            }_${row.project ? row.project : "p"}`
          ] = row.status_call;
        });
        setStartDate(startDatesFromResponse);
      })
      .catch((error) => {
        console.error("API call error", error);
      });
  });
};
export const SocialMediaImages = (
  setIsSocialLoading,
  setErrorMessage,
  selectedTitle,
  setSectionData,
  sectionData,
  index,
  imgModel
) => {
  setIsSocialLoading(true);
  let platform = Object.keys(sectionData.socialMedia[selectedTitle].caption)[
    index
  ];
  getIdToken()
    .then((idToken) => {
      axios
        .post(
          SOCIAL_MEDIA_IMAGE,
          {
            image_prompt:
              sectionData.socialMedia[selectedTitle].caption[platform]
                .image_prompt,
            img_model:imgModel
          },
          {
            headers: { Authorization: idToken },
          }
        )
        .then((response) => {
          setSectionData((prev) => {
            let newSocialMedia = { ...prev };
            newSocialMedia.socialMedia[selectedTitle].caption[platform].images =
              response.data.images;
            return newSocialMedia;
          });
        })
        .catch((error) => {
          setErrorMessage(error);
        })
        .finally(() => {
          setIsSocialLoading(false);
        });
    })
    .catch((error) => {
      setIsSocialLoading(false);
      setErrorMessage(error);
    });
};

export const mediaLikeApiCall = (
  setShowMediaLikeLoading,
  setErrorMessage,
  mediaAdImage,
  setShowMediaAdImage,
  setShowMediaLikeDragDrop,
  setMediaAdValues
) => {
  setShowMediaLikeLoading(true);
  getIdToken(setErrorMessage)
    .then((idToken) => {
      axios
        .post(
          MEDIA_LIKE_API,
          { image: mediaAdImage },
          { headers: { Authorization: idToken } }
        )
        .then((res) => {
          setMediaAdValues(res.data["Media_Response"]);
          setShowMediaAdImage(true);
          setShowMediaLikeDragDrop(false);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setShowMediaLikeLoading(false);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const startTraining =(images,productName,folder_id,setModelPage)=>{
  axios.post(START_TRAINING_URL,{
    images:[],
    username:localStorage.getItem("cred"),
    model_name:productName,
    folder_id:folder_id
  })
  .then((response)=>{
    setModelPage("successStarted")
  })
  .catch((error)=>{
    console.log(error)
  })
}

export const checkTrainingStatus =(folder,model,setCurrentStatus)=>{
  axios.post(CHECK_MODEL_STATUS,{
    file_id:folder,
    user_name:localStorage.getItem("cred"),
    model_name:model
  })
  .then((response)=>{
    setCurrentStatus(response.data.response)
  })
  .catch((error)=>{
    console.log(error)
  })
}

export const getModelData=(setModelData)=>{
  axios.post(CHECK_MODEL_DATA,{
    user_name:localStorage.getItem("cred"),
  },
  {headers: {
    'Content-Type': 'application/json',

  }})
  .then(response=>{
    setModelData(response.data.response)
  })
  .catch((error)=>{
    console.log(error)
  })
}
export const getModelPresignedUrls=(files,images,productName,setModelPage,setIsModelLoading)=>{
  const filesMetadata = files.map(file => ({
    name: file.name,
    type: file.type,
  }));
  setIsModelLoading(true)
  axios.post(MODEL_S3_URL,{
     files:filesMetadata
  },
  {headers: {
    'Content-Type': 'application/json',

  }})
  .then(async (res)=>{
    const { presigned_urls, folder_id } = res.data;
    const uploadPromises = presigned_urls.map(async(presignedUrl, index) => {
      const file = files[index];
      return axios.put(presignedUrl.url, file, {
        headers: {
          'Content-Type': file.type,
        },
      }).then(() => presignedUrl.key);
    });

    const uploadResults = await Promise.all(uploadPromises);
    localStorage.setItem("folder_id",folder_id)
    localStorage.setItem("model",productName)
    startTraining(images,productName,folder_id,setModelPage)
  })
  .catch((error)=>{
    setIsModelLoading(false)
    console.log("error")
  })
  .finally(()=>{
    setIsModelLoading(false)
  })
}
export const saveProject =(sectionData,
  formData,
  projectName,
  errorMessage, 
  imageState,
  selectedTemplate,
  primaryColor,
  secondaryColor,
  setIsPersonaLoading,
  setIsCreativeLoading,
  setIsEmailLoading,
  setIsSocialLoading,
  setIsPrintLoading,
  projectConfirmedTabs,
  setShowFileSaved,
  setIsFlyerLoading,
  setIsFlyerVerticalLoading,
  setIsFlyerSquareLoading,
  setIsBannerHorizontalLoading,
  setIsBannerVerticalLoading,
  setShowMaxCapacityCard

)=>{
  let payload ={
    "project_name":projectName,
    "common_data":{
      tone_mood: formData.tone,
      objective: formData.objective,
      signals: formData.demographicData,
      product_service: formData.product,
      key_selling_points: formData.sellingPoint,
      language: "English",
      username: localStorage.getItem("cred"),
      name: "",
      persona: sectionData.persona,

    },
    data:{}
  }
  if(projectConfirmedTabs.includes("email")){
    payload.data.Email = sectionData?.email.map((emailData) => ({
      Email: emailData,
    }))
  }
  if(projectConfirmedTabs.includes("socialMedia")){
    payload.data.SocialMedia = sectionData.socialMedia.map((socialItem) => {
      let socialJson = {};
      socialJson.instagram = {
        caption: socialItem?.caption?.["instagram"]?.caption,
        image_prompt: socialItem?.caption?.["instagram"]?.["image_prompt"],
        images_link: socialItem?.caption?.["instagram"]?.["images"],
      };
      socialJson.twitter = {
        caption: socialItem?.caption?.["twitter"]?.caption,
        image_prompt: socialItem?.caption?.["twitter"]?.["image_prompt"],
        images_link: socialItem?.caption?.["twitter"]?.["images"],
      };
      socialJson.facebook = {
        caption: socialItem?.caption?.["facebook"]?.caption,
        image_prompt: socialItem?.caption?.["facebook"]?.["image_prompt"],
        images_link: socialItem?.caption?.["facebook"]?.["images"],
      };
      socialJson.pinterest = {
        caption: socialItem?.caption?.["pinterest"]?.caption,
        image_prompt: socialItem?.caption?.["pinterest"]?.["image_prompt"],
        images_link: socialItem?.caption?.["pinterest"]?.["images"],
      };
      socialJson.blog = {
        caption: socialItem?.caption?.["blog"]?.caption,
        image_prompt: socialItem?.caption?.["blog"]?.["image_prompt"],
        images_link: socialItem?.caption?.["blog"]?.["images"],
      };
      return socialJson;
    })
  }
  if(projectConfirmedTabs.includes("print")){
    payload.data.MagazineAd = sectionData?.print.map((printItem) => ({
      ad: printItem?.["Magazine Ad"],
      images_link: printItem?.["Images"],
    }))
  }
  if(projectConfirmedTabs.includes("creativeConcepts")){
    payload.data.CreativeIdeation=sectionData?.creativeConcepts?.["Concepts and Images"].map((creativeItem,index)=>({
      signals:formData.demographicData,
      product_service: formData.product,
      key_selling_points: formData.sellingPoint,
      tone_mood: formData.tone,
      objective: formData.objective,
      creative_concept1:creativeItem?.[0]?.[
        "ConceptTerritory"
      ],
      creative_concept2:creativeItem?.[1]?.[
        "ConceptTerritory"
      ],
      creative_concept3:creativeItem?.[2]?.[
        "ConceptTerritory"
      ],
      images_link1:creativeItem?.[0]?.[
        "Images"
      ],
      images_link2:creativeItem?.[1]?.[
        "Images"
      ],
      images_link3:creativeItem?.[2]?.[
        "Images"
      ]


    }))
  }
  if(projectConfirmedTabs.includes("bannerHorizontal")){
    payload.data.HorizontalBanner=[{"HorizontalBanner":{
      banner_type: "HorizontalBanner",
      ad: sectionData.bannerHorizontal?.["Flyer"],
      images_link: sectionData.bannerHorizontal?.["Images"],
      logo_link: imageState[3]?.logoBase64,
      template_id: selectedTemplate[3],
      primary_colour: primaryColor[3],
      secondary_colour: secondaryColor[3],
      logo_name: imageState[3]?.logoFileName,
    }}]
  }
  if(projectConfirmedTabs.includes("bannerVertical")){
    payload.data.VerticalBanner=[{"VerticalBanner":{
      banner_type: "VerticalBanner",
      ad: sectionData.bannerVertical?.["Flyer"],
      images_link: sectionData.bannerVertical?.["Images"],
      logo_link: imageState[4]?.logoBase64,
      template_id: selectedTemplate[4],
      primary_colour: primaryColor[4],
      secondary_colour: secondaryColor[4],
      logo_name: imageState[4]?.logoFileName,
    }}]
  }
  if(projectConfirmedTabs.includes("flyerVertical")){
    payload.data.VerticalFlyer=[{"VerticalFlyer":{
      flyer_type: "Vertical",
      ad: sectionData.flyerVertical?.["Flyer"],
      images_link: sectionData.flyerVertical?.["Images"],
      logo_link: imageState[1]?.logoBase64,
      template_id: selectedTemplate[1],
      primary_colour: primaryColor[1],
      secondary_colour: secondaryColor[1],
      logo_name: imageState[1]?.logoFileName,
    }}]
  }
 
  setIsPersonaLoading(true)
  setIsCreativeLoading(true)
  setIsEmailLoading(true)
  setIsSocialLoading(true)
  setIsPrintLoading(true)
  setIsFlyerLoading(true)
  setIsFlyerVerticalLoading(true)
  setIsBannerHorizontalLoading(true)
  setIsBannerVerticalLoading(true)
  getIdToken(errorMessage)
  .then((IdToken)=>{
    axios.post(SAVE_PROJECT_API,payload,{headers:{Authorization:IdToken}})
    .then((response)=>{
      console.log(response)
      setShowFileSaved(true)
    })
    .catch((error)=>{
      console.log("error is",error)
      if(error?.response?.data?.Status === "Invalid Request, You are at max capacity cannot save more than 10 projects."){
        setShowMaxCapacityCard(true)
      }
      
    })
    .finally(()=>{
      setIsPersonaLoading(false)
      setIsCreativeLoading(false)
      setIsEmailLoading(false)
      setIsSocialLoading(false)
      setIsPrintLoading(false)
      setIsFlyerLoading(false)
      setIsFlyerVerticalLoading(false)
      setIsBannerHorizontalLoading(false)
      setIsBannerVerticalLoading(false)
    })
  })
  .catch((error)=>{
    console.log(error)
    setIsPersonaLoading(false)
    setIsCreativeLoading(false)
    setIsEmailLoading(false)
    setIsSocialLoading(false)
    setIsPrintLoading(false)
    setIsFlyerLoading(false)
    setIsFlyerVerticalLoading(false)
    setIsBannerHorizontalLoading(false)
    setIsBannerVerticalLoading(false)
  })
}

export const fetchProjectCard=(errorMessage,setProjectDrafts,setIsSavePageLoading)=>{
  setIsSavePageLoading(true)
  getIdToken(errorMessage)
  .then((IdToken)=>{
    axios.post(FETCH_ALL_CARD_PROJECT,
      {
        "username": localStorage.getItem("cred"),
      },
      {headers:{Authorization:IdToken}}
    )
    .then((res)=>{
      console.log(res)

      setProjectDrafts(res.data?.["Response"])
    })
    .catch((error)=>{
      console.log(error)
    })
    .finally(()=>{
      setIsSavePageLoading(false)
    })

  })
  .catch((error)=>{
    console.log(error)
    setIsSavePageLoading(false)
  })
}

export const fetchProject = (
  errorMessage,
  sectionData,
  formData,
  setFormData,
  setSectionData,
  setSignals,
  setTitles,
  projectName,
  setIsSavePageLoading,
  setActiveTab,
  selectedTemplate,
  primaryColor,
  secondaryColor,
  imageState,
  setSelectedTemplate,
  setPrimaryColor,
  setSecondaryColor,
  setImageState
) => {
  setIsSavePageLoading(true);
  getIdToken(errorMessage)
    .then((IdToken) => {
      axios
        .post(
          FETCH_ALL_PROJECT,
          {

            username: localStorage.getItem("cred"),
            project_name: projectName,
          },
          { headers: { Authorization: IdToken } }
        )
        .then((response) => {
          let responseData = response.data?.["Response"];
          const newFormData = { ...formData };
          newFormData.demographicData = responseData?.["signals"];
          setSignals(responseData?.["signals"]);
          newFormData.product = responseData?.["product_service"];
          newFormData.objective = responseData?.["objective"];
          newFormData.tone = responseData?.["tone_mood"];
          newFormData.sellingPoint = responseData?.["key_selling_points"];
          setFormData(newFormData);
          let newTitles = newFormData.demographicData.map(
            (item, index) => `title ${index + 1}`
          );
          setTitles(newTitles);
          const newSectionData = { ...sectionData };
          if (responseData.hasOwnProperty("persona")) {
            newSectionData.persona = responseData?.["persona"].map(
              (item) => item.persona
            );
          }

          if (responseData.hasOwnProperty("email")) {
            newSectionData.email = responseData?.["email"].map(
              (item) => item.email
            );
          }

          if (responseData.hasOwnProperty("magazine_ad")) {
            newSectionData.print = responseData?.["magazine_ad"].map((item) => {
              return {
                "Magazine Ad": item.ad,
                Images: item.image,
              };
            });
          }

          if (responseData.hasOwnProperty("creative_concept")) {
            let creativeData = responseData?.["creative_concept"].map(
              (item) => [
                {
                  ConceptTerritory: item.creative_concept1,
                  Images: item.images_link1,
                },
                {
                  ConceptTerritory: item.creative_concept2,
                  Images: item.images_link2,
                },
                {
                  ConceptTerritory: item.creative_concept3,
                  Images: item.images_link3,
                },
              ]
            );
            newSectionData.creativeConcepts = {
              "Concepts and Images": creativeData,
            };
          }
          if (responseData.hasOwnProperty("social_media")) {
            newSectionData.socialMedia = responseData?.["social_media"].map(
              (item) => {
                return {
                  caption: {
                    instagram: {
                      caption: item?.instagram?.caption,
                      image_prompt: item?.instagram?.image_prompt,
                      ...(item.blog.images_link && {
                        images: item.blog.images_link,
                      }),
                    },
                    twitter: {
                      caption: item?.twitter?.caption,
                      image_prompt: item?.twitter?.image_prompt,
                      ...(item.twitter.images_link && {
                        images: item.twitter.images_link,
                      }),
                    },
                    facebook: {
                      caption: item?.facebook?.caption,
                      image_prompt: item?.facebook?.image_prompt,
                      ...(item.facebook.images_link && {
                        images: item.facebook.images_link,
                      }),
                    },
                    pinterest: {
                      caption: item?.pinterest?.caption,
                      image_prompt: item?.pinterest?.image_prompt,
                      ...(item.pinterest.images_link && {
                        images: item.pinterest.images_link,
                      }),
                    },
                    blog: {
                      caption: item?.blog?.caption,
                      image_prompt: item?.blog?.image_prompt,
                      ...(item.blog.images_link && {
                        images: item.blog.images_link,
                      }),
                    },
                  },
                };
              }
            );
          }
          if (responseData.hasOwnProperty("flyer")) {
            let flyerData = responseData?.["flyer"][0];
            flyerData.primary_colour = flyerData.primary_colour.replace(
              /'/g,
              '"'
            );
            flyerData.secondary_colour = flyerData.secondary_colour.replace(
              /'/g,
              '"'
            );
            newSectionData.flyerVertical = {
              ...newSectionData.flyerVertical,
              Images: flyerData.image,
              Flyer: flyerData.ad,
              Flyer_Translated: flyerData.ad,
            };
            const _index = 1;
            const newSelectedTemplate = selectedTemplate.map((item, index) => {
              if (_index === index) return Number(flyerData.template_id);
              else return item;
            });
            const newPrimaryColor = primaryColor.map((item, index) => {
              if (_index === index) {
                const obj = JSON.parse(flyerData.primary_colour);
                return obj;
              } else return item;
            });
            const newSecondaryColor = secondaryColor.map((item, index) => {
              if (_index === index) {
                const obj = JSON.parse(flyerData.secondary_colour);
                return obj;
              } else return item;
            });
            const newImageState = imageState.map((item, index) => {
              if (_index === index) {
                item.logoBase64 = flyerData.logo;
                item.logoFileName = flyerData.logo_name;
                return item;
              } else return item;
            });
            setSelectedTemplate(newSelectedTemplate);
            setPrimaryColor(newPrimaryColor);
            setSecondaryColor(newSecondaryColor);
            setImageState(newImageState);
          }

          if(responseData.hasOwnProperty("banner")){
            let flyerData = responseData?.["flyer"][0];
            flyerData.primary_colour = flyerData.primary_colour.replace(
              /'/g,
              '"'
            );
            flyerData.secondary_colour = flyerData.secondary_colour.replace(
              /'/g,
              '"'
            );

            if (flyerData.banner_type === "Horizontal") {
              newSectionData.bannerHorizontal = {
                ...newSectionData.bannerHorizontal,
                Images: flyerData.images,
                Flyer: flyerData.ad,
                Flyer_Translated: flyerData.ad,
              };
              const _index = 3;
              const newSelectedTemplate = selectedTemplate.map(
                (item, index) => {
                  if (_index === index) {
                    return Number(flyerData.template_id);
                  } else return item;
                }
              );
              const newPrimaryColor = primaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(flyerData.primary_colour);
                  return obj;
                } else return item;
              });
              const newSecondaryColor = secondaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(flyerData.secondary_colour);
                  return obj;
                } else return item;
              });
              const newImageState = imageState.map((item, index) => {
                if (_index === index) {
                  item.logoBase64 = flyerData.logo;
                  item.logoFileName = flyerData.logo_name;
                  return item;
                } else return item;
              });
              setSelectedTemplate(newSelectedTemplate);
              setPrimaryColor(newPrimaryColor);
              setSecondaryColor(newSecondaryColor);
              setImageState(newImageState);
            }
            if (flyerData.banner_type === "Vertical") {
              newSectionData.bannerVertical = {
                ...newSectionData.bannerVertical,
                Images: flyerData.images,
                Flyer: flyerData.ad,
                Flyer_Translated: flyerData.ad,
              };
              const _index = 4;
              const newSelectedTemplate = selectedTemplate.map(
                (item, index) => {
                  if (_index === index) {
                    return Number(flyerData.template_id);
                  } else return item;
                }
              );
              const newPrimaryColor = primaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(flyerData.primary_colour);
                  return obj;
                } else return item;
              });
              const newSecondaryColor = secondaryColor.map((item, index) => {
                if (_index === index) {
                  const obj = JSON.parse(flyerData.secondary_colour);
                  return obj;
                } else return item;
              });
              const newImageState = imageState.map((item, index) => {
                if (_index === index) {
                  item.logoBase64 = flyerData.logo;
                  item.logoFileName = flyerData.logo_name;
                  return item;
                } else return item;
              });
              setSelectedTemplate(newSelectedTemplate);
              setPrimaryColor(newPrimaryColor);
              setSecondaryColor(newSecondaryColor);
              setImageState(newImageState);
            }

          }
          setSectionData(newSectionData);
          setActiveTab("Persona");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsSavePageLoading(false);
        });
    })
    .catch((error) => {
      console.log(error);
      setIsSavePageLoading(false);
    });
};

export const deletePoject =(errorMessage,setProjectDrafts,projectId)=>{
  getIdToken(errorMessage)
  .then((IdToken)=>{
    axios.post(DELETE_PROJECT,{
      "project_id":projectId
    },
      {headers:{Authorization:IdToken}}
    )
    .then((res)=>{
      console.log(res)
    })
    .catch((error)=>{
      console.log(error)
    })

  })
  .catch((error)=>{
    console.log(error)
  })
}
