import React from "react";
import { Button } from "@mui/material";
import Box from "./Box";
import { calculate_duration } from "../utils/helper";
import AiGeneratedContent from "./AiGeneratedContent";
const ImageView = ({
  formData,
  generatedImagesGlobal,
  sectionData,
  regenerateImage,
  handleStitch,
  setGeneratedImagesGlobal,
  tailoredVisible,
  directVisible,
  ideateVisible,
  setIsStoryGenerated,
  selectedTitle,
}) => {
  const newArr = tailoredVisible
    ? [...sectionData.stepsTailored[selectedTitle].prev]
    : [...sectionData.steps.prev];
  const iterable = calculate_duration(newArr, Number(formData.duration));
  return (
    <>
      <div
        
        className="bg-white rounded-xl m-5"
      >
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pl-4 pr-4 mt-2">
          {generatedImagesGlobal &&
            (tailoredVisible
              ? sectionData.stepsTailored[selectedTitle].prev[0]
              : sectionData?.steps?.prev[0]
            )?.hasOwnProperty("IMAGE") &&
            iterable?.map((step, index) => (
              <div className=" m-1 rounded-lg mt-5 mb-5 bg-[#F2F2F2] border border-[#E4E4E4]">
                <Box
                  key={index}
                  title={step?.["TITLE"]}
                  duration={step?.["DURATION_IN_SECONDS"]}
                  image={step?.["IMAGE"]}
                  sound={step?.["AUDIO_DESCRIPTION"]?.["sound"]}
                  dialogue={step?.["AUDIO_DESCRIPTION"]?.["dialogue"]}
                  step_no={index + 1}
                  prompt={step?.["IMAGE_PROMPT"]}
                  regenerateImage={regenerateImage}
                />
              </div>
            ))}
        </div>
        {generatedImagesGlobal &&
          (tailoredVisible
            ? sectionData.stepsTailored[selectedTitle].prev[0]
            : sectionData?.steps?.prev[0]
          )?.hasOwnProperty("IMAGE") && (
            <div className="flex flex-row-reverse mr-5 pb-2">
              <AiGeneratedContent />
            </div>
          )}
      </div>
      {(tailoredVisible
        ? sectionData.stepsTailored[selectedTitle].prev
        : sectionData?.steps?.prev
      ).length ? (
        <div className="flex justify-end items-end mr-5">
          <Button
            sx={{
              marginBottom: "2rem",
              fontWeight: 600,
              width: "12.4375rem",
              padding: "0.9375rem 0.625rem",
              borderRadius: "0.5rem",
              height: "46px",
            }}
            variant="contained"
            color="secondary"
            disabled={!generatedImagesGlobal}
            onClick={() => {
              setIsStoryGenerated(true);
              const reqBody = {};
              reqBody.content = tailoredVisible
                ? sectionData.stepsTailored?.[selectedTitle]?.prev
                : sectionData.steps.prev;
              reqBody.tailored_or_direct = false;
              if (tailoredVisible || directVisible) {
                reqBody.tailored_or_direct = true;
              } else {
                reqBody.title = formData.storyBoardTitle;
              }
              handleStitch(reqBody);
            }}
          >
            EXPORT AS PDF
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default ImageView;
