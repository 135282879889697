import Dropdown from "./Dropdown";
import "./ImageWithDropdown.css";

const ImageWithDropdown = ({
  image,
  promtClickCount,
  setIsLoading,
  downloadPathImageDownload,
}) => {
  return (
    <div className="ImageWithDropdown_container">
      <Dropdown
        image={image}
        promtClickCount={promtClickCount}
        setIsLoading={setIsLoading}
        downloadPathImageDownload={downloadPathImageDownload}
      />
      <img
        src={`data:image/jpeg;base64,${image[promtClickCount]}`}
        alt="img"
        className="rounded-lg"
      />
    </div>
  );
};
export default ImageWithDropdown;
