import { LogLevel } from "@azure/msal-browser";
const qaClientID = "278175b7-048f-472b-a3c0-38d71c3061f2";
const prodClientID = "941a1314-7662-4dc4-840a-2aac34fd529f";
export const msalConfig = {
  auth: {
    clientId: prodClientID,
    authority:
      "https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
export const loginRequest = {
  scopes: ["User.Read"],
  forceRefresh: true,
};
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
