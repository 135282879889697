import { React, useEffect, useState } from "react";
import Revise from "../components/Revise";
import Card from "../components/Card";
import Error from "../components/Error";
import Feedback from "../components/Feedback";
import FlyerDownloader from "../components/FlyerDownloader";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { Alert, Button } from "@mui/material";
import PageviewIcon from "@mui/icons-material/Pageview";
import TranslateDropdown from "../components/TranslateDropdown";
import BackDrop from "../components/BackDrop";
import SaveCard from "../components/SaveCard";
import AiGeneratedContent from "../components/AiGeneratedContent";
import SignalTitles from "../components/SignalTitles";
import MenuConceptTheme from "../components/MenuConceptTheme";
import CreativeHoverCard from "../components/CreativeHoverCard";

const Print = ({
  Images,
  MagazineAd,
  handleGenerateButtonClick,
  revisedData,
  regenerateData,
  setSectionData,
  sectionData,
  editedData,
  activeTab,
  printRequirementChanged,
  errorMessage,
  setErrorMessage,
  isLoading,
  magnifiedImage,
  setMagnifiedImage,
  setShowMagnifiedTemplate,
  setTabName,
  translateLanguage,
  setSourceLanguage,
  setTranslateLanguage,
  sourceLanguage,
  directTranslate,
  setInputText,
  handleTranslateLanguage,
  setSelectedLanguage,
  setPrintLocked,
  printLocked,
  setPrintContentLocked,
  printContentLocked,
  handleSaveButtonClicked,
  titles,
  setSelectedTitle,
  selectedTitle,
  territoryTitles,
  selectedTheme,
  setSelectedTheme,
  themeWasApplied,
  prevSelectedTheme,
  setPrevSelectedTheme,
  isSidebarOpen,
  handleSaveProjectButtonClicked,
}) => {
  const [editIconIsClicked, setEditIconIsClicked] = useState(false);
  const [selectedValue, setSelectedValue] = useState(-1);
  const [selectedTerritory, setSelectedTerritory] = useState(-1);
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    setMousePosition({
      x: event.clientX,
      y: event.clientY,
    });
  };
  const [ranEffect, setRanEffect] = useState(false);

  useEffect(() => {
    //setLockImages(printLocked.map(val=>val?'lock':'unlock'))
    if (
      (!(Images && MagazineAd) ||
        printRequirementChanged ||
        ranEffect ||
        (themeWasApplied && prevSelectedTheme["Print"] !== selectedTheme)) &&
      !isLoading
    ) {
      setRanEffect(false);
      setPrevSelectedTheme({
        ...prevSelectedTheme,
        Print: selectedTheme,
      });
      handleGenerateButtonClick();
    }
  }, [selectedTheme]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleSavingResponseValue = (data) => {
    let newObj = { ...sectionData };
    newObj.print[selectedTitle]["Magazine Ad"] = data;
    setSectionData(newObj);
  };
  const handleMagnify = (tabName, index, path) => {
    setTabName(tabName);
    setMagnifiedImage(path);
    setShowMagnifiedTemplate(true);
  };
  const handleLocked = (index) => {
    setPrintLocked((previousState) => {
      let lockedState = previousState;
      lockedState[selectedTitle][index] = !lockedState[selectedTitle][index];
      return lockedState;
    });
  };
  useEffect(() => {
    if (
      sourceLanguage[activeTab][selectedTitle] !==
      translateLanguage[activeTab][selectedTitle]
    ) {
      directTranslate(
        sectionData.print[selectedTitle]["Magazine Ad"],
        sourceLanguage[activeTab][selectedTitle],
        translateLanguage[activeTab][selectedTitle],
        activeTab
      );
      const newSrc = [...sourceLanguage[activeTab]];
      newSrc[selectedTitle] = translateLanguage[activeTab][selectedTitle];
      setSourceLanguage((prev) => ({
        ...prev,
        [activeTab]: newSrc,
      }));
    }
  }, [translateLanguage[activeTab][selectedTitle]]);

  return (
    <div
      className={`w-full h-full flex flex-col flex-1 ${
        isSidebarOpen ? "hidden" : "block"
      } sm:block`}
    >
      {titles.length > 1 &&
        sectionData.print?.[selectedTitle]?.["Magazine Ad"] && (
          <SignalTitles
            titles={titles}
            selectedTitle={selectedTitle}
            setSelectedTitle={setSelectedTitle}
          />
        )}
      <div className="flex">
        <div
          className={`w-full pt-4 px-10 pb-10 m-5 bg-white ${
            titles?.length > 1 &&
            sectionData.print?.[selectedTitle]?.["Magazine Ad"]
              ? "mt-0 rounded-tl-none rounded-tr-xl rounded-b-xl"
              : "rounded-xl"
          }`}
        >
          {errorMessage && <Alert severity="error" sx={{ marginTop: 3, marginBottom: 3 }}>
                  {errorMessage.message}
                </Alert>}
          <div className="flex flex-col justify-center sm:flex-row sm:justify-between">
            <MenuConceptTheme
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              setIsHovered={setIsHovered}
              handleMouseMove={handleMouseMove}
              territoryTitles={territoryTitles[selectedTitle]}
              selectedTheme={selectedTheme}
              setSelectedTheme={setSelectedTheme}
              setSelectedTerritory={setSelectedTerritory}
              setRanEffect={setRanEffect}
              themeWasApplied={themeWasApplied}
              ranEffect={ranEffect}
              setPrevSelectedTheme={setPrevSelectedTheme}
            />
            {sectionData.print?.[selectedTitle]?.["Magazine Ad"] && (
              <TranslateDropdown
                translateLanguage={translateLanguage}
                handleTranslateLanguage={handleTranslateLanguage}
                activeTab={activeTab}
                selectedTitle={selectedTitle}
              />
            )}
          </div>
          {isHovered && selectedTerritory !== -1 && (
            <CreativeHoverCard
              position={mousePosition}
              territoryTitle={
                territoryTitles[selectedTitle]?.[selectedTerritory]
              }
              content={
                sectionData?.creativeConcepts?.["Concepts and Images"]?.[
                  selectedTitle
                ]?.[selectedTerritory]?.["ConceptTerritory"]
              }
              images={
                sectionData?.creativeConcepts?.["Concepts and Images"]?.[
                  selectedTitle
                ]?.[selectedTerritory]?.["Images"]
              }
            />
          )}
          <Card
            text={
              sectionData.print?.[selectedTitle]?.["Magazine Ad"]
                ? sectionData.print?.[selectedTitle]?.["Magazine Ad"]
                : ""
            }
            handleSavingResponseValue={handleSavingResponseValue}
            editedData={editedData}
            activeTab={activeTab}
            sectionData={sectionData}
            content={sectionData?.print?.[selectedTitle]?.["Magazine Ad"]}
            setInputText={setInputText}
            setSelectedLanguage={setSelectedLanguage}
            translateLanguage={translateLanguage}
            isLoading={isLoading}
            contentLocked={printContentLocked}
            setContentLocked={setPrintContentLocked}
            selectedTitle={selectedTitle}
            setEditIconIsClicked={setEditIconIsClicked}
          />
          <div className="mt-5 pt-5">
            <div className="flex flex-nowrap justify-evenly overflow-x-auto w-full">
              {Images?.length &&
                Images?.map((item, key) => (
                  <div className=" flex-shrink-0 justify-center px-2">
                    <img
                      className="h-[225px] w-[225px] rounded-lg"
                      key={key}
                      src={`data:image/png;base64,${item}`}
                      alt="fs"
                    />
                    {/* <Button
                    style={{
                      position: "relative",
                      zindex: 1,
                      bottom: 35,
                      left: 140,
                      padding: 0,
                      color: "grey",
                    }}
                    onClick={() => handleLocked(key)}
                  >
                    <img
                      src={require(`../assets/${
                        printLocked[key] ? "lock" : "unlock"
                      }.png`)}
                      alt="locked-Icon"
                    />
                  </Button> */}
                    {/* <Button
                    style={{
                      position: "relative",
                      zindex: 1,
                      bottom: 35,
                      left: 140,
                      padding: 0,
                      color: "grey",
                    }}
                    onClick={() => handleLocked(key)}
                  >
                    <img
                      src={require(`../assets/${lockImages[key]}.png`)}
                      alt="locked-Icon"
                    /> */}
                    <Button
                      sx={{
                        position: "relative",
                        zindex: 1,
                        bottom: 35,
                        left: 195,
                        padding: 0,
                        color: "grey",
                        transform: "translateX(-30%)",
                      }}
                      onClick={() => handleLocked(key)}
                    >
                      <img
                        src={require(`../assets/${
                          printLocked[selectedTitle][key] ? "lock" : "unlock"
                        }.png`)}
                        alt="locked-Icon"
                      />
                    </Button>
                    <Button
                      sx={{
                        position: "relative",
                        zIndex: 1,
                        bottom: 217,
                        left: -10,
                        padding: 0,
                        color: "grey",
                        transform: "translateX(-95%)",
                      }}
                      onClick={() => handleMagnify("generated", key, item)}
                    >
                      <img
                        src={require("../assets/magnify.png")}
                        alt="magnify-Icon"
                      />
                    </Button>
                  </div>
                ))}
            </div>
          </div>
          {sectionData.print?.[selectedTitle]?.["Magazine Ad"] && (
            <AiGeneratedContent />
          )}
          <div className="w-full">
            <Revise
              revisedData={revisedData}
              regenerateData={regenerateData}
              selectedTitle={selectedTitle}
              isContentLocked={printContentLocked[selectedTitle]}
              content={sectionData.print.length}
              editClicked={editIconIsClicked}
            />
          </div>
        </div>
      </div>
      <div className="mx-5 mt-6">
        <SaveCard
          download={true}
          handleSaveButtonClicked={handleSaveButtonClicked}
          handleSaveProjectButtonClicked={handleSaveProjectButtonClicked}
          content={sectionData.print.length}
        />
      </div>
      {/* <Feedback /> */}
    </div>
  );
};
export default Print;
