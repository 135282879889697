import { Typography,Button } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";

const SaveProjectError = ({setShowProjectError}) => {
  const handleClose = ()=>{
    setShowProjectError(false)
  }
  return (
    <div className="bg-black bg-opacity-50 z-50 absolute inset-0 flex justify-center items-center">
      <div className="bg-white flex items-center justify-center text-black rounded-2xl w-[50.75rem] h-[28.563rem] relative">
      <Button sx={{ position: "absolute", top: 5, right: 0 }} onClick={handleClose}>
          <img src={require("../assets/close-icon.png")} />
        </Button>
        <div className="bg-[#F9F2ED] w-[80%] h-[80%] flex flex-col items-center justify-center space-y-12 p-4 rounded-xl">
          <img
            src={require("../assets/notVerified.png")}
            className="w-20 h-20 text-[80px] text-[#26890D]"
            alt="save project error"
          />
          <Typography variant="h5" sx={{color:"#E85E5E",alignContent:"center"}}>You cannot save single page in a project</Typography>
          <div>
          <Typography variant ="subtitle1" sx={{wordWrap:"break-word",alignContent:"center"}}>To save this work as a project,the user must generate at</Typography>
          <Typography sx={{paddingLeft:12}}>least a minimum of two pages</Typography>

          </div>
          
        </div>
      </div>
    </div>
  );
};
export default SaveProjectError;
