import CloseIcon from '@mui/icons-material/Close';
import VerifiedIcon from "@mui/icons-material/Verified";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import React from 'react';
const SuccessBackdrop = ({ uploadFileSuccess, setUploadFileSuccess, setFile }) => {

  const handleClose = () => {
    setUploadFileSuccess(false);
    setFile(null);
  };

  return (
    <Modal
      open={uploadFileSuccess}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          borderRadius: "10px",
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 8,
            right: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
        <div
          className="flex flex-col items-center justify-center p-5 m-5"
          style={{
            height: "200px",
            borderRadius: "20px",
            backgroundColor: "#F1F9ED",
          }}
        >
          <div className="p-2">
            <VerifiedIcon
              style={{
                color: "green",
                fontSize: "80px",
                width: "80px",
                height: "80px",
              }}
            />
          </div>
          <h2 className="p-2 font-medium">
            Keep track of your translation progress in the 'Track Files'
            section.
          </h2>
        </div>
      </Box>
    </Modal>
  );
}

export default SuccessBackdrop