import { Button } from "@mui/material";
import React, { useState } from "react";
import BackDrop from "./BackDrop";
const Box = ({
  title,
  duration,
  image,
  sound,
  dialogue,
  step_no,
  prompt,
  regenerateImage,
  key,
}) => {
  const [regenerateButtonLoader, setRegenerateButtonLoader] = useState(false);
  return (
    <>
      {regenerateButtonLoader ? (
        <div className="flex flex-col h-full items-center justify-center relative">
          <div className="absolute inset-0 flex items-center justify-center bg-opacity-50 bg-gray-400 z-10 ">
            <BackDrop type={"image"} />
          </div>
          <div className="flex-1">
            <div className="p-1 flex items-center justify-center">
              <span className="font-[600]">Title : </span> {title}
            </div>
            <div className="p-1 flex items-center justify-center">
              <span className="font-[600]">Duration : </span>
              {duration}
            </div>
          </div>
          <div className="flex-2 flex items-center justify-center p-5">
            <img
              className="h-[300px] w-[300px] rounded-lg"
              src={`data:image/png;base64,${image}`}
              alt="fs"
            />
          </div>
          <div className="flex-2 ">
            <div className="p-1 pl-5 pr-5">
              <span className="font-[600]">Dialogue : </span> {dialogue}
            </div>
            <div className="p-1 pl-5 pr-5 mb-2.5">
              <span className="font-[600]">Sound : </span> {sound}
            </div>
          </div>
          <div className="flex items-end justify-center mb-4">
            <Button
              sx={{
                borderRadius: "6px",
                border: "1px solid #468529",
                background: "#FFF",
                color: "#468529",
                padding: "8px 10px",
                width: "300px",
                maxWidth: "260px",
              }}
              onClick={() =>
                regenerateImage(prompt, step_no, setRegenerateButtonLoader)
              }
            >
              Regenerate image
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-full  relative ">
          <div className="flex-1 mt-3">
            <div className="p-1 flex items-center justify-center">
              <span className="font-[600]">Title: {title} </span>
            </div>
            <div className="p-1 flex items-center justify-center">
              <span className="font-[600]">Duration: {duration}</span>
            </div>
          </div>
          <div className="flex-2 p-5 flex items-center justify-center">
            <img
              className="h-[300px] w-[300px] rounded-lg"
              src={`data:image/png;base64,${image}`}
              alt="fs"
            />
          </div>
          <div className="flex-2">
            <div className="pl-5 pr-5 ">
              <span className="font-[600]">Dialogue: {dialogue}</span>
            </div>
            <div className="pl-5 pr-5 mb-2.5 ">
              <span className="font-[600] ">Sound: {sound} </span>
            </div>
          </div>
          <div className="flex items-end justify-center flex-1 mb-4">
            <Button
              sx={{
                borderRadius: "6px",
                border: "1px solid #468529",
                background: "#FFF",
                color: "#468529",
                padding: "8px 10px",
                width: "300px",
                maxWidth: "260px",
              }}
              onClick={() =>
                regenerateImage(prompt, step_no, setRegenerateButtonLoader)
              }
            >
              Regenerate image
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Box;
