import React from "react"
import Unauthorized from "../../assets/Unauthorized.png"
const  Fallback = () => {
  return (
  <div className="flex flex-col items-center h-screen justify-center p-4 ">
  <img src={Unauthorized} alt="Unauthorized" className="max-w-full h-1/2 mb-8" />
  <h1 className="text-5xl font-semibold  text-[#26890D] mb-4">Something went wrong</h1>
  <p className="text-xl text-black font-bold mb-8">
   Please Try after sometime.
  </p>
 
</div>
  )
}

export default Fallback