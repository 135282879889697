import React, { useState, useEffect } from 'react';
import { useTimeoutFn } from 'react-use';
import { Button, Popover, MenuItem, Select, Typography, FormControl, InputLabel, Menu, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const SquareFab = styled(Button)(({ theme }) => ({
  width: 44,
  height: 44,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "10px",
  borderRadius: 4,
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  boxShadow: theme.shadows[4],
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Callout = styled(Popover)(({ theme }) => ({
  padding: theme.spacing(2),
  '& .MuiPaper-root': {
    borderRadius: 6,
    width: "300px",
    height: "200px",
    boxShadow: theme.shadows[5],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '90%',
  margin: theme.spacing(1, 0),
  '& .MuiSelect-root': {
    height: 40,
  },
}));

const fadeInOut = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
}));

const TimerText = styled(Typography)(({ theme }) => ({
  animation: `${fadeInOut} 1s linear infinite`,
}));

const FloatingActionButton = ({
  textModel, setTextModel, setIsGenerateButtonDisabled, imgModel, setImageModel,
  loraModel, setErrorMessage, loraCheckModel
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [nestedMenuAnchorEl, setNestedMenuAnchorEl] = useState(null);
  const [disabledOptions, setDisabledOptions] = useState(false);
  const [selectedLora, setSelectedLora] = useState(null);
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTimeLeft = localStorage.getItem('timeLeft');
    return savedTimeLeft ? parseInt(savedTimeLeft, 10) : 0;
  });

  useEffect(() => {
    const savedSelectedLora = localStorage.getItem('selectedLora');
    if (savedSelectedLora) {
      setSelectedLora(savedSelectedLora);
      setDisabledOptions(true);
      startTimer();
    }
  }, []);

  useEffect(() => {
    if (selectedLora && timeLeft > 0) {
      localStorage.setItem('selectedLora', selectedLora);
      localStorage.setItem('timeLeft', timeLeft);
    } else {
      localStorage.removeItem('selectedLora');
      localStorage.removeItem('timeLeft');
    }
  }, [selectedLora, timeLeft]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (!nestedMenuAnchorEl) {
      setAnchorEl(null);
    }
  };

  const handleModelChange = (event) => {
    setTextModel(event.target.value);
    setIsGenerateButtonDisabled(false);
  };

  const handleVersionChange = (event) => {
    setImageModel(event.target.value);
  };

  const handleNestedMenuClick = (event) => {
    setNestedMenuAnchorEl(event.currentTarget);
  };

  const handleNestedMenuClose = () => {
    setNestedMenuAnchorEl(null);
  };

  const handleNestedSelect = (version) => {
    setSelectedLora(version);
    setImageModel(version);
    handleNestedMenuClose();
    handleClose();
    setTimeLeft(180); // 3 minutes countdown
    startTimer();
    setDisabledOptions(true);
    loraCheckModel(setErrorMessage, version);
  };

  const startTimer = () => {
    reset();
    if (!isReady()) {
      cancel(); // Cancel any active timeout
    }
    reset(); // Start the new timeout
  };

  const formatTimeRemaining = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const [isReady, cancel, reset] = useTimeoutFn(() => {
    setDisabledOptions(false);
    setSelectedLora(null);
    setTimeLeft(0);
    localStorage.removeItem('selectedLora');
    localStorage.removeItem('timeLeft');
  }, timeLeft * 1000);

  useEffect(() => {
    let timer;
    if (selectedLora && timeLeft > 0 && !isReady()) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [selectedLora, timeLeft, isReady]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <SquareFab
        onClick={handleClick}
        variant="contained"
        sx={{ backgroundColor: "#458a25ff" }}
      >
        <SettingsIcon sx={{ color: "white", margin: 0, padding: 0 }} />
      </SquareFab>
      <Callout
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{ margin: "0 0 15px 8px" }}
      >
        <Typography variant="h6">
          Model Settings
        </Typography>
        <StyledFormControl>
          <InputLabel>Text Model</InputLabel>
          <Select
            value={textModel}
            onChange={handleModelChange}
            label="Text Model"
            sx={{ width: "90%", height: "40px" }}
          >
            <MenuItem value="claude"><b>Claude</b> &nbsp; sonnet-3.5</MenuItem>
            <MenuItem value="llama"><b>Meta</b> &nbsp; Llama3</MenuItem>
          </Select>
        </StyledFormControl>
        <StyledFormControl>
          <InputLabel>Image Model</InputLabel>
          <Select
            value={imgModel}
            onChange={handleVersionChange}
            label="Image Model"
            sx={{ width: "90%", height: "40px" }}
            renderValue={(value) => value}
          >
            <MenuItem value="Titan">Titan</MenuItem>
            {loraModel && (
              <MenuItem
                onMouseDown={(event) => event.stopPropagation()} // Prevent the dropdown from closing
                onMouseOver={handleNestedMenuClick}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                value="lora"
              >
                Lora
                <ArrowRightIcon />
              </MenuItem>
            )}
            <MenuItem value="Stable Diffusion(default)">Stable Diffusion(default)</MenuItem>
          </Select>
          {loraModel && (
            <Menu
              anchorEl={nestedMenuAnchorEl}
              open={Boolean(nestedMenuAnchorEl)}
              onClose={handleNestedMenuClose}
              MenuListProps={{ onMouseLeave: handleNestedMenuClose }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {disabledOptions && (
                <Overlay>
                  <TimerText variant="body2">
                    {formatTimeRemaining(timeLeft)}
                  </TimerText>
                </Overlay>
              )}
              {loraModel.map((lora) => (
                lora["status"] === "SUCCESS" &&
                <MenuItem
                  key={lora["model_name"]}
                  onClick={() => handleNestedSelect(lora["model_name"])}
                  disabled={selectedLora !== null || disabledOptions}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    wordBreak: 'break-word' 
                  }}
                >
                  {lora["model_name"]}
                </MenuItem>
              ))}
            </Menu>
          )}
        </StyledFormControl>
      </Callout>
    </>
  );
};

export default FloatingActionButton;