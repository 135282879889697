import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React from "react";
import CookieIcon from "@mui/icons-material/Cookie";
import UserIcon from "./UserIcon";
import Box from "@mui/material/Box";

const UserProfile = ({
  onClickOpenFeedbackHandlerForFeedback,
  handleAdmin,
  handleLogout,
  userName,
  name,
  icon,
  page,
}) => {
  const email = userName + "@deloitte.com";
  const UI_REFERENCE =
    "https://amedeloitte.sharepoint.com/:w:/r/sites/GenAI147/Shared%20Documents/AMC/AM%26C/User%20Guide%20for%20Image%20and%20Text%20Generation.docx?d=w054273ded049408a951eafd714dcd31c&csf=1&web=1&e=G0lPG9";
  const onClickOpenLinkHandler = () => {
    window.open(UI_REFERENCE, "_blank");
  };

  const COOKIE_URL = "https://cookienotice.deloitte.com";
  const handleCookie = () => {
    window.open(COOKIE_URL, "_blank");
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          {page === "admin" ? (
            <Button
              variant="contained"
              color="secondary"
              sx={{ height: "44px" }}
              {...bindTrigger(popupState)}
            >
              <img
                src={require("../assets/admin-white-bg.png")}
                className="h-4 w-4 text-black mr-[22px]"
                alt="profile-icon"
              />
              Admin
            </Button>
          ) : (
            <Button variant="text" {...bindTrigger(popupState)}>

              <Box sx={{ marginRight: "0.7rem" }}>
                <UserIcon />
              </Box>
              {page}
            </Button>
          )}

          <Menu
            {...bindMenu(popupState)}
            sx={{ position: "absolute", left: "-13px" }}
          >
            {localStorage.getItem("cred") !== null &&
              localStorage.getItem("isAdmin") === "true" && (
                <MenuItem
                  onClick={() => {
                    handleAdmin();
                    popupState.close();
                  }}
                  sx={{
                    borderBottom: "1px solid #CFCFCF",
                    color: "#787878",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "16px",
                  }}
                >
                  <img
                    src={require("../assets/admin-icon.png")}
                    className="mr-[1.24rem] w-[1.25rem] h-[1.25rem]"
                  />
                  {name}
                </MenuItem>
              )}
            <MenuItem
              onClick={() => {
                onClickOpenLinkHandler();
                popupState.close();
              }}
              sx={{
                borderBottom: "1px solid #CFCFCF",
                color: "#787878",
                fontFamily: "Open Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "16px",
              }}
            >
              <img
                src={require("../assets/info-icon.png")}
                className="mr-5 w-5 h-5"
              />
              User Guide
            </MenuItem>
            <MenuItem
              onClick={() => {
                onClickOpenFeedbackHandlerForFeedback();
                popupState.close();
              }}
              sx={{
                borderBottom: "1px solid #CFCFCF",
                color: "#787878",
                fontFamily: "Open Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "16px",
              }}
            >
              <img
                src={require("../assets/Feedback-Icon.png")}
                alt="FeedBack"
                className="flex mr-5 w-5 h-5 text-[#787878]"
              />
              Feedback
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCookie();
                popupState.close();
              }}
              sx={{
                borderBottom: "1px solid #CFCFCF",
                color: "#787878",
                fontFamily: "Open Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "16px",
              }}
            >
              <img
                src={require("../assets/cookie-icon.png")}
                className=" mr-5 w-5 h-5 text-[#787878]"
              />
              Cookies
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleLogout();
                popupState.close();
              }}
              sx={{
                color: "#787878",
                fontFamily: "Open Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "16px",
              }}
            >
              <img
                src={require("../assets/logout-iconn.png")}
                className="flex mr-5 w-5 h-5 text-[#468529]"
              />
              Logout
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};
export default UserProfile;
