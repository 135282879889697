import { Button,Menu,MenuItem} from "@mui/material";
import  {useState} from 'react'
import React from "react";
import {KeyboardArrowDown} from '@mui/icons-material';

const SaveCard = ({ download, handleSaveButtonClicked,content,handleSaveProjectButtonClicked}) => {
  const [anchorEl,setAnchorEl] = useState(null)
  const [open,setOpen]  = useState(false)


  const handleOpen  = (e)=>{
    setOpen(true)
    setAnchorEl(e.currentTarget)
  }
  const handleClose =(e)=>{
    setOpen(false)
    setAnchorEl(null)
  }
  return (
    <div className="flex justify-center items-center sm:justify-end sm:items-end">
      <Button
        id ="save-button"
        aria-controls= {open?"save-menu":undefined}
        aria-haspopup= "true"
        aria-expanded = {open?'true':undefined}
        fullWidth
        variant="outlined"
        sx={{
          display: "flex",
          width: "11.25rem",
          height: "2.75rem",
          padding: "14px 12px",
          justifyContent: "center",
          alignItems: "center",
          color: "#26890D",
          borderRadius: "6px",
          border: "1px solid var(--CTA, #26890D)",
          fontWeight:600
        }}
        onClick={handleOpen}
        disabled={!content}
      >
        Save 
        <KeyboardArrowDown/>
      </Button>
      <Menu
      id = "save-menu"
      anchorEl= {anchorEl}
      onClose = {handleClose}
      open = {open}
      MenuListProps={{
        'aria-labelledby': 'save-button',
      }}
      slotProps={{
        paper:{
          sx:{
            width:250
          }
        }
      }}
      >
        
        <MenuItem onClick={()=>{
          handleSaveButtonClicked();
          handleClose()}}>Save this page</MenuItem>
        <MenuItem onClick = {()=>{
          handleSaveProjectButtonClicked();
          handleClose()
        }}>Save as project</MenuItem>
      </Menu>
      {false && (
        <Button
          fullwidth
          variant="contained"
          sx={{
            marginLeft: "1.5rem",
            display: "flex",
            width: "11.25rem",
            height: "2.75rem",
            padding: "14px 12px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "6px",
            border: "1px solid var(--CTA, #26890D)",
            backgroundColor: "#26890D",
            color: "#FFF",
          }}
          disabled={true}
        >
          Download
        </Button>
      )}
    </div>
  );
};

export default SaveCard;
