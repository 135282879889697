import React from "react";
import { Button, Typography } from "@mui/material";
import Undo from "./Undo";
import UndoGray from "./UndoGray";
const ThemeButtons = ({
  foundationUndoCall,
  foundationApplyCall,
  index,
  setSelectedTheme,
  setThemeWasApplied,
  themeWasApplied,
  setPrevSelectedTheme,
  errorMessage,
}) => {
  const conceptThemeStylesForButton = {
    color: "#26890D",
    borderRadius: "0.375rem",
    padding: "0.25rem 0.625rem 0.25rem 0.625rem",
    fontWeight: 600,
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
  };
  return (
    <div className="flex flex-col md:flex-row justify-end pb-4 gap-4">
      <Button
        variant="outlined"
        sx={conceptThemeStylesForButton}
        onClick={async () => {
          await foundationUndoCall();
          if (!errorMessage) {
            setThemeWasApplied(false);
            setPrevSelectedTheme({
              Print: -1,
              SocialMedia: -1,
              Email: -1,
              FlyerHorizontal: -1,
              FlyerVertical: -1,
              FlyerSquare: -1,
              BannerHorizontal: -1,
              BannerVertical: -1,
              StoryboardingTailored: -1,
            });
          }
        }}
        disabled={!themeWasApplied}
      >
        <Typography sx={{ marginRight: "0.625rem" }}>Undo</Typography>{" "}
        {!themeWasApplied ? <UndoGray /> : <Undo />}
      </Button>
      <Button
        variant="outlined"
        sx={conceptThemeStylesForButton}
        onClick={async () => {
          setThemeWasApplied(true);
          await foundationApplyCall();
          setSelectedTheme(index);
        }}
        disabled={themeWasApplied}
      >
        Apply concept theme to all
      </Button>
    </div>
  );
};

export default ThemeButtons;
