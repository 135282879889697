import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
const MediaLikeAdImage = ({
  image,
  setShowMediaAdImage,
  mediaAdValues,
  setFormData,
  formData,
  setSignals,
  setShowHelperText,
}) => {
  const requirements = [
    "Product/Service",
    "Objective",
    "Tone/Mood Desired",
    "Key Selling Points",
  ];
  const mapper = {
    "Product/Service": "Product",
    Objective: "Objective",
    "Tone/Mood Desired": "Tone",
    "Key Selling Points": "Selling_Point",
  };
  const [product, setProduct] = useState("");
  const [objective, setObjective] = useState("");
  const [tone, setTone] = useState("");
  const [sellingPoint, setSellingPoint] = useState("");
  const [audience, setAudience] = useState("");

  const handleSendToInput = () => {
    const newFormData = { ...formData };
    newFormData.product = product;
    newFormData.objective = objective;
    newFormData.tone = tone;
    newFormData.sellingPoint = sellingPoint;
    setSignals([audience]);
    setShowHelperText((prev) => ({
      ...prev,
      audience: audience.length ? true : false,
      product: product.length ? true : false,
      objective: objective.length ? true : false,
      tone: tone.length ? true : false,
      sellingPoint: sellingPoint.length ? true : false,
    }));
    setFormData(newFormData);

    setShowMediaAdImage(false);
  };

  useEffect(() => {
    setProduct(mediaAdValues["Product"]);
    setObjective(mediaAdValues["Objective"]);
    setSellingPoint(mediaAdValues["Selling_Point"]);
    setTone(mediaAdValues["Tone"]);
    setAudience(mediaAdValues["Audience"]);
  }, [mediaAdValues]);

  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className=" relative w-[70rem] h-[35rem] bg-[#FFFFFF] rounded-lg shadow-md flex flex-col justify-center items-center">
        <Button
          onClick={() => setShowMediaAdImage(false)}
          sx={{ position: "absolute", top: 0, right: -15 }}
        >
          <img src={require("../assets/close-icon.png")} />
        </Button>
        <div className="flex flex-row gap-10">
          <div className="w-[28rem] h-[22rem] m-2 rounded-lg">
            <img src={image} className="h-full"></img>
          </div>
          <div className="w-[32.5rem] flex flex-col">
            <div className="flex flex-row gap-2 text-center items-center">
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "0.875rem",
                  lineHeight: "1rem",
                }}
              >
                SIGNALS
              </Typography>
              <TextField
                sx={{
                  width: "28.25rem",
                  "& .MuiInputBase-root": {
                    height: "2.063rem",
                    borderRadius: "0.5rem",
                  },
                  border: "1px ##DFDFDF",
                }}
                value={mediaAdValues["Audience"]}
              ></TextField>
              <Checkbox
                defaultChecked
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: "#26890D",
                  },
                }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAudience(mediaAdValues["Audience"]);
                  } else {
                    setAudience("");
                  }
                }}
              />
            </div>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "0.875rem",
                lineHeight: "1rem",
                marginTop: "2.2rem",
              }}
            >
              BUSINESS REQUIREMENTS
            </Typography>
            {requirements.map((requirement, index) => (
              <FormControl
                sx={
                  index === 0 ? { marginTop: "2rem" } : { marginTop: "0.5rem" }
                }
                key={index}
              >
                <FormLabel
                  sx={{
                    marginBottom: "0.3rem",
                    fontWeight: 400,
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    color: "#828282",
                  }}
                >
                  {requirement}
                </FormLabel>
                <div className="flex flex-row text-center items-center">
                  <TextField
                    sx={{
                      width: "29.4rem",
                      "& .MuiInputBase-root": {
                        height: "2.063rem",
                        borderRadius: "0.5rem",
                      },
                      border: "1px ##DFDFDF",
                    }}
                    value={mediaAdValues[mapper[requirement]]}
                  />
                  <Checkbox
                    defaultChecked
                    sx={{
                      marginLeft: "0.5rem",
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: "#26890D",
                      },
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        if (requirement === "Product/Service") {
                          setProduct(mediaAdValues[mapper[requirement]]);
                        } else if (requirement === "Objective") {
                          setObjective(mediaAdValues[mapper[requirement]]);
                        } else if (requirement === "Tone/Mood Desired") {
                          setTone(mediaAdValues[mapper[requirement]]);
                        } else {
                          setSellingPoint(mediaAdValues[mapper[requirement]]);
                        }
                      } else {
                        if (requirement === "Product/Service") {
                          setProduct("");
                        } else if (requirement === "Objective") {
                          setObjective("");
                        } else if (requirement === "Tone/Mood Desired") {
                          setTone("");
                        } else {
                          setSellingPoint("");
                        }
                      }
                    }}
                  />
                </div>
              </FormControl>
            ))}
            <div className="flex justify-end">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#26890D",
                  color: "white",
                  marginTop: "2rem",
                  width: "50%",
                }}
                onClick={handleSendToInput}
                disabled={
                  audience.length +
                    tone.length +
                    product.length +
                    sellingPoint.length +
                    objective.length ===
                  0
                }
              >
                SEND TO INPUT SECTION
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaLikeAdImage;
