import React, { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import BackDrop from "./BackDrop";

const DragDropMediaLikeAds = ({
  setShowMediaLikeDragDrop,
  dropRef,
  fileInputRef,
  setImagePreview,
  setFile,
  mediaLikeSuggestionsCall,
  isLoading,
  errorMessage,
}) => {
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const onFileChange = async (e) => {
    setFile(e.target.files[0]);
    await getBase64(e.target.files[0]).then((res) => {
      setImagePreview(res);
      mediaLikeSuggestionsCall(res);
    });
  };

  useEffect(() => {
    let div = dropRef.current;

    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      let files = e.dataTransfer.files;
      if (files && files.length > 0) {
        const file = files[0];
        setFile(file);
        await getBase64(file).then((res) => {
          setImagePreview(res);
          mediaLikeSuggestionsCall(res);
        });
      }
    };

    div?.addEventListener("dragenter", handleDragEnter);
    div?.addEventListener("dragover", handleDragOver);
    div?.addEventListener("dragleave", handleDragLeave);
    div?.addEventListener("drop", handleDrop);

    return () => {
      div?.removeEventListener("dragenter", handleDragEnter);
      div?.removeEventListener("dragover", handleDragOver);
      div?.removeEventListener("dragleave", handleDragLeave);
      div?.removeEventListener("drop", handleDrop);
    };
  }, []);
  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div
        className=" relative w-[70rem] h-[35rem] bg-[#fff] rounded-lg shadow-md flex flex-col justify-center items-center"
        ref={dropRef}
      >
        {isLoading && <BackDrop />}
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          Please upload your reference advertisement here to get signals and
          business requirements
        </Typography>
        <img src={require("../assets/DragDropIcon.png")} alt="Drag_Drop_Icon" />
        <p className="text-[#53565A] font-normal">
          Drag and Drop Image here or
        </p>
        <div>
          <input
            id="file-upload"
            accept=".jpg, .png, .jpeg, .webp"
            ref={fileInputRef}
            type="file"
            onChange={onFileChange}
            hidden
            className="cursor-pointer text-blue-500"
          />
          <label
            htmlFor="file-upload"
            className="cursor-pointer text-[#26890D] font-bold"
          >
            Upload
          </label>
          <Button
            onClick={() => setShowMediaLikeDragDrop(false)}
            sx={{ position: "absolute", top: 0, right: -15 }}
          >
            <img src={require("../assets/close-icon.png")} />
          </Button>
        </div>
        
      </div>
    </div>
  );
};

export default DragDropMediaLikeAds;
