import { Typography } from "@mui/material";
import React from "react";

const ZeroDrafts = () => {
  return (
    <div className="zero w-full h-full flex flex-col justify-center items-center">
      <img src={require("../assets/illustration.png")} />
      <Typography
        sx={{
          fontSize: "2.25rem",
          fontWeight: 700,
          lineHeight: "1rem",
          color: "#26890D",
          marginTop: "5rem",
        }}
      >
        Oops! No Saved Jobs
      </Typography>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "1rem",
          lineHeight: "1rem",
          marginTop: "2rem",
        }}
      >
        It looks like you haven’t added any jobs yet
      </Typography>
    </div>
  );
};

export default ZeroDrafts;
