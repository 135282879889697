import { Button, Typography } from "@mui/material";
import React from "react";

const ResumeWork = ({
  setShowResumeCard,
  handleResumeWork,
  tabNameForResume,
  fileNameForResume,
  tabTypeForResume,
}) => {
  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-10 flex items-center justify-center">
      <div className="bg-white flex items-center justify-center text-black rounded-2xl w-[43.1875rem] h-[21rem] relative">
        <Button
          sx={{ position: "absolute", top: "7px", left: "635px" }}
          onClick={() => setShowResumeCard((prev) => !prev)}
        >
          <img src={require("../assets/close-icon.png")} />
        </Button>
        <div className="flex flex-col items-center w-[38.8125rem] h-[14.75rem] rounded-[1.1875rem] bg-[#F0F9ED] m-[3.19rem_2.19rem]">
          <Typography
            sx={{
              color: "var(--Neutral-13, #000)",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "1.5rem",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "1.75rem",
              marginTop: "2.88rem",
            }}
          >
            Do you want to resume this work?
          </Typography>
          <Typography
            sx={{
              color: "var(--Neutral-13, #000)",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "1.125rem",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "1.75rem",
              marginTop: "0.58rem",
            }}
          >
            Your current progress will be lost
          </Typography>
          <div className="flex flex-row mt-[2.69rem]">
            <Button
              sx={{
                display: "flex",
                width: "7.5rem",
                padding: "0.625rem",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.625rem",
                borderRadius: "0.375rem",
                border: "1px solid var(--CTA, #26890D)",
                background: "#FFF",
                color: "var(--CTA, #26890D)",

                fontFamily: "Open Sans",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "1.5rem" /* 150% */,
              }}
              variant="outlined"
              onClick={() => setShowResumeCard(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{
                display: "flex",
                width: "7.5rem",
                padding: "0.625rem",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.625rem",
                borderRadius: "0.375rem",
                border: "1px solid var(--CTA, #26890D)",
                background: "var(--CTA, #26890D)",
                color: "#FFF",
                fontFamily: "Open Sans",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "1.5rem",
                marginLeft: "1.88rem" /* 150% */,
              }}
              variant="contained"
              onClick={() => {
                handleResumeWork(
                  fileNameForResume,
                  tabNameForResume,
                  tabTypeForResume
                );
              }}
            >
              YES
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeWork;
