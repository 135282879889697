import React from "react";
import { useState } from "react";
import GenerativeFillUpload from "../components/GenerativeFillUpload";
import GenFillLoader from "../components/GenFillLoader";
import GenFillPrompt from "../components/GenFillPrompt";
import ImageWithDropdown from "../components/ImageWithDropdown";
import PromtCard from "../components/PromptCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./GenerativeFill.css";
import "../components/GenFillLoader";

const GenerativeFill = () => {
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [currentTab, setCurrentTab] = useState("Upload");
  const [steps, setSteps] = useState(undefined);
  const [promtClickCount, setPromtClickCount] = useState(0);
  const [image, setImage] = useState([]);
  const [downloadPathImageDownload, setDownloadPathImageDownload] = useState(
    []
  );

  let genSteps = [];
  if (steps !== undefined) {
    genSteps = Object.values(steps);
  }

  const [isLoading, setIsLoading] = useState(false);

  const dynamicCode = () => {
    return image.map((item, index) => (
      <div
        key={index}
        className={`w-4 h-1 ml-4 rounded-2xl${
          promtClickCount === index ? " bg-green-600" : " bg-gray-300"
        }`}
      ></div>
    ));
  };

  return (
    <div className="generative-fill h-screen w-full flex flex-col">
      <div className="bg-[#F2F2F2] h-full w-full flex justify-center items-center">
        <div className="fixed top-0 left-0 right-0 h-[8%] bg-white text-white flex flex-row items-center justify-start">
          <img
            src={require("../assets/NEW_APP_LOGO.png")}
            alt="header"
            className="w-[15%]"
          />
        </div>
        <div className="h-[80%] w-[95%] bg-white pl-4 pr-1 pb-4 border-2 border-gray-200 rounded-lg flex justify-center items-center">
          {currentTab === "Upload" && (
            <GenerativeFillUpload
              file={file}
              setFile={setFile}
              imagePreview={imagePreview}
              setImagePreview={setImagePreview}
              setCurrentTab={setCurrentTab}
            />
          )}
          {currentTab === "Steps" && (
            <GenFillPrompt
              imagePreview={imagePreview}
              setCurrentTab={setCurrentTab}
              steps={steps}
              setSteps={setSteps}
              setImage={setImage}
              setDownloadPathImageDownload={setDownloadPathImageDownload}
            />
          )}

          {currentTab === "Select" &&
            (image.length === 0 ? (
              <GenFillLoader />
            ) : (
              <div className="flex flex-col items-center">
                <div className="flex justify-center items-center space-x-5 ">
                  <button
                    className={` ${
                      promtClickCount === 0
                        ? "genFillDisabled"
                        : "Arrow_container"
                    }`}
                    onClick={() => setPromtClickCount((prev) => prev - 1)}
                    disabled={promtClickCount === 0 || isLoading}
                  >
                    <ArrowBackIcon sx={{ color: "white", cursor: "pointer" }} />
                  </button>
                  <ImageWithDropdown
                    image={image}
                    promtClickCount={promtClickCount}
                    setIsLoading={setIsLoading}
                    downloadPathImageDownload={downloadPathImageDownload}
                  />
                  <PromtCard
                    steps={steps}
                    setSteps={setSteps}
                    genSteps={genSteps}
                    promtClickCount={promtClickCount}
                    imagePreview={imagePreview}
                    setImage={setImage}
                    image={image}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                  />
                  <button
                    className={` ${
                      promtClickCount === 2
                        ? "genFillDisabled"
                        : "Arrow_container"
                    }`}
                    onClick={() => setPromtClickCount((prev) => prev + 1)}
                    disabled={promtClickCount === 2 || isLoading}
                  >
                    <ArrowForwardIcon
                      sx={{ color: "white", cursor: "pointer" }}
                    />
                  </button>
                </div>
                <div className="mt-6 z-10 flex">{dynamicCode()}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default GenerativeFill;
