import React from "react";
import { Typography } from "@mui/material";

function GenFillLoader() {
  return (
    <div className="h-full" >
      <div className="flex flex-col justify-center items-center">
        <img
          src={require("../assets/loader.gif")}
          alt="loading"

          className="h-[100px] mt-[198px]"
        />
        <div className="text-base font-normal mt-16">
          Please wait for your Image to generate inside
        </div>
      </div>
    </div>
  );
}

export default GenFillLoader;
