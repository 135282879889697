import { useEffect, useState } from "react";
import CardEmail from "../components/CardEmail";
import Error from "../components/Error";
import Revise from "../components/Revise";
import SaveCard from "../components/SaveCard";
import TranslateDropdown from "../components/TranslateDropdown";
import AiGeneratedContent from "../components/AiGeneratedContent";
import SignalTitles from "../components/SignalTitles";
import MenuConceptTheme from "../components/MenuConceptTheme";
import CreativeHoverCard from "../components/CreativeHoverCard";
import { Alert } from "@mui/material";

const Email = ({
  handleGenerateButtonClick,
  revisedData,
  regenerateData,
  email,
  sectionData,
  setSectionData,
  activeTab,
  setIsPersonaEdited,
  isPersonaEdited,
  emailRequirementChanged,
  setEmailRequirementChanged,
  errorMessage,
  setErrorMessage,
  isLoading,
  translateLanguage,
  handleTranslateLanguage,
  setInputText,
  sourceLanguage,
  directTranslate,
  setSourceLanguage,
  setSelectedLanguage,
  emailContentLocked,
  setEmailContentLocked,
  handleSaveButtonClicked,
  titles,
  setSelectedTitle,
  selectedTitle,
  territoryTitles,
  selectedTheme,
  setSelectedTheme,
  themeWasApplied,
  prevSelectedTheme,
  setPrevSelectedTheme,
  isSidebarOpen,
  handleSaveProjectButtonClicked,
}) => {
  const [editIconIsClicked, setEditIconIsClicked] = useState(false);
  const [selectedValue, setSelectedValue] = useState(-1);
  const [selectedTerritory, setSelectedTerritory] = useState(-1);
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    setMousePosition({
      x: event.clientX,
      y: event.clientY,
    });
  };
  const [ranEffect, setRanEffect] = useState(false);

  useEffect(() => {
    if (
      (!sectionData.email[selectedTitle] ||
        isPersonaEdited ||
        emailRequirementChanged ||
        ranEffect ||
        (themeWasApplied && prevSelectedTheme["Email"] != selectedTheme)) &&
      !isLoading
    ) {
      setPrevSelectedTheme({
        ...prevSelectedTheme,
        Email: selectedTheme,
      });
      setRanEffect(false);
      handleGenerateButtonClick();
      setIsPersonaEdited(false);
    }
  }, [selectedTheme]);

  useEffect(() => {
    if (
      sourceLanguage[activeTab][selectedTitle] !==
      translateLanguage[activeTab][selectedTitle]
    ) {
      directTranslate(
        sectionData.email[selectedTitle],
        sourceLanguage[activeTab][selectedTitle],
        translateLanguage[activeTab][selectedTitle],
        activeTab
      );
      const newSrc = [...sourceLanguage[activeTab]];
      newSrc[selectedTitle] = translateLanguage[activeTab][selectedTitle];
      setSourceLanguage((prev) => ({
        ...prev,
        [activeTab]: newSrc,
      }));
    }
  }, [translateLanguage[activeTab][selectedTitle]]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleSavingResponseValue = (data) => {
    const newEmail = sectionData.email.map((email, index) =>
      index === selectedTitle ? data : email
    );
    email = data;
    setSectionData({
      ...sectionData,
      email: newEmail,
    });
  };
  return (
    <div
      className={`w-full h-full flex flex-col relative flex-1 ${
        isSidebarOpen ? "hidden" : "block"
      } md:block`}
    >
      {titles.length > 1 && sectionData.email?.[selectedTitle] && (
        <SignalTitles
          titles={titles}
          selectedTitle={selectedTitle}
          setSelectedTitle={setSelectedTitle}
        />
      )}
      <div className="flex bg-white m-5 rounded-xl">
        <div
          className={`w-full pt-4 px-6 sm:px-10 pb-10 sm:mx-5  ${
            titles?.length > 1 && sectionData.email?.[selectedTitle]
              ? "mt-0"
              : "rounded-xl"
          } ${
            titles?.length > 1 && sectionData.email?.[selectedTitle]
              ? "rounded-tr-xl rounded-br-xl rounded-bl-xl"
              : ""
          } bg-white m-0`}
        >
          {errorMessage && <Alert severity="error" sx={{ marginTop: 3, marginBottom: 3 }}>
                  {errorMessage.message}
                </Alert>}
          <div className="flex flex-col md:flex-row justify-center items-center md:justify-between">
            <MenuConceptTheme
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              setIsHovered={setIsHovered}
              handleMouseMove={handleMouseMove}
              territoryTitles={territoryTitles[selectedTitle]}
              selectedTheme={selectedTheme}
              setSelectedTheme={setSelectedTheme}
              setSelectedTerritory={setSelectedTerritory}
              setRanEffect={setRanEffect}
              themeWasApplied={themeWasApplied}
              ranEffect={ranEffect}
              setPrevSelectedTheme={setPrevSelectedTheme}
            />
            {sectionData?.email?.[selectedTitle] && (
              <TranslateDropdown
                translateLanguage={translateLanguage}
                handleTranslateLanguage={handleTranslateLanguage}
                activeTab={activeTab}
                selectedTitle={selectedTitle}
              />
            )}
          </div>
          {isHovered && selectedTerritory !== -1 && (
            <CreativeHoverCard
              position={mousePosition}
              territoryTitle={
                territoryTitles[selectedTitle]?.[selectedTerritory]
              }
              content={
                sectionData?.creativeConcepts?.["Concepts and Images"]?.[
                  selectedTitle
                ]?.[selectedTerritory]?.["ConceptTerritory"]
              }
              images={
                sectionData?.creativeConcepts?.["Concepts and Images"]?.[
                  selectedTitle
                ]?.[selectedTerritory]?.["Images"]
              }
            />
          )}
          <CardEmail
            text={
              sectionData.email.length ? sectionData.email[selectedTitle] : ""
            }
            handleSavingResponseValue={handleSavingResponseValue}
            setInputText={setInputText}
            sectionData={sectionData}
            content={sectionData.email?.[selectedTitle]?.Email}
            activeTab={activeTab}
            setSelectedLanguage={setSelectedLanguage}
            translateLanguage={translateLanguage}
            isLoading={isLoading}
            contentLocked={emailContentLocked}
            setContentLocked={setEmailContentLocked}
            selectedTitle={selectedTitle}
            setEditIconIsClicked={setEditIconIsClicked}
          />
          {sectionData.email[selectedTitle] && <AiGeneratedContent />}
          <div className="w-full pt-7">
            <Revise
              revisedData={revisedData}
              regenerateData={regenerateData}
              selectedTitle={selectedTitle}
              isContentLocked={emailContentLocked[selectedTitle]}
              content={sectionData.email.length}
              editClicked={editIconIsClicked}
            />
          </div>
          {/* <div className="text-sm ml-2 mt-1 text-gray-500">
            Email customized with data from Persona tab
          </div> */}
        </div>
      </div>
      <div className="m-5">
        <SaveCard
          download={true}
          handleSaveButtonClicked={handleSaveButtonClicked}
          handleSaveProjectButtonClicked={handleSaveProjectButtonClicked}
          content={sectionData.email.length}
        />
      </div>
      {/* <Feedback /> */}
    </div>
  );
};
export default Email;
