import { useEffect, useState } from "react";
import "./PromptCard.css";
import { getIdToken } from "../utils/translateHelper";
import axios from "axios";
import { FILL_BACKGROUND } from "../utils/constants";
import CircularProgress from "@mui/material/CircularProgress";

const PromptCard = ({
  genSteps,
  promtClickCount,
  imagePreview,
  setImage,
  image,
  setIsLoading,
  isLoading,
}) => {
  const [editPromptClicked, setEditPromptClicked] = useState(false);
  const [finalPrompt, setFinalPrompt] = useState([]);

  useEffect(() => {
    setFinalPrompt(genSteps);
  }, []);

  const handleSetFinalPrompt = (newValue) => {
    const tempFinalPrompt = [...finalPrompt];
    tempFinalPrompt[promtClickCount] = newValue;
    setFinalPrompt(tempFinalPrompt);
  };

  const GenerateNewImage = () => {
    setEditPromptClicked(!editPromptClicked);
    if (editPromptClicked) {
      setIsLoading(true);
      let requestBody = {
        image: imagePreview,
        prompt: [finalPrompt[promtClickCount]],
      };

      getIdToken()
        .then((idToken) => {
          axios
            .post(FILL_BACKGROUND, JSON.stringify(requestBody), {
              headers: { Authorization: idToken },
            })
            .then((response) => {
              const finalImage = response?.data?.Response?.base64_images[0];
              const tempImage = [...image];
              tempImage[promtClickCount] = finalImage;
              setImage(tempImage);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
            });
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="prompt_Container">
      <div className="flex flex-col">
        {editPromptClicked ? (
          <div>
            <textarea
              color="tertiary"
              className="w-full editArea"
              value={finalPrompt[promtClickCount]}
              onChange={(e) => handleSetFinalPrompt(e.target.value)}
            />
          </div>
        ) : (
          <div className="displayedPromt">
            <p>{finalPrompt[promtClickCount]}</p>
          </div>
        )}
        <div className="flex justify-end">
          {isLoading ? (
            <button className="border-solid border-2 p-1.5 rounded-lg">
              <CircularProgress color="success" />
            </button>
          ) : (
            <button
              onClick={() => GenerateNewImage()}
              className={`border-solid border-2 p-1.5 rounded-lg ${
                editPromptClicked
                  ? "promptbuttonClicked"
                  : "finalpromtbuttonClicked"
              }`}
            >
              {editPromptClicked ? "GEN PROMPT" : "EDIT PROMPT"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default PromptCard;
