import React from "react";

const Logo = () => {
  return (
    <div className="flex justify-center align-center">
      <img
        src={require("../assets/NEW_APP_LOGO.png")}
        alt="deloitte-logo"
        className="w-[18.875rem] h-[3.1875rem] mt-[3.12rem] mb-8"
      />
    </div>
  );
};

export default Logo;
