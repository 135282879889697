import { React, useState } from "react";
import { useEffect } from "react";
import Revise from "../components/Revise";
import Card from "../components/Card";
import Error from "../components/Error";
import TranslateDropdown from "../components/TranslateDropdown";
import SaveCard from "../components/SaveCard";
import SignalTitles from "../components/SignalTitles";
import AiGeneratedContent from "../components/AiGeneratedContent";
import MenuConceptTheme from "../components/MenuConceptTheme";
import CreativeHoverCard from "../components/CreativeHoverCard";
import BackDrop from "../components/BackDrop";

const Persona = ({
  revisedData,
  regenerateData,
  handleGenerateButtonClick,
  setSectionData,
  sectionData,
  isTabChanged,
  formValidation,
  activeTab,
  setIsPersonaEdited,
  setIsSocialMediaPersonaEdited,
  personaRequirementChanged,
  errorMessage,
  setErrorMessage,
  isLoading,
  translateLanguage,
  setSourceLanguage,
  setTranslateLanguage,
  sourceLanguage,
  directTranslate,
  setInputText,
  handleTranslateLanguage,
  setSelectedLanguage,
  personaWasTranslated,
  setPersonaWasTranslated,
  personaContentLocked,
  setPersonaContentLocked,
  handleSaveButtonClicked,
  titles,
  setSelectedTitle,
  selectedTitle,
  territoryTitles,
  selectedTheme,
  setSelectedTheme,
  handleSaveProjectButtonClicked,
  isSidebarOpen,
}) => {
  const [editIconIsClicked, setEditIconIsClicked] = useState(false);
  useEffect(() => {
    if (
      ((!sectionData.persona[selectedTitle] && isTabChanged) ||
        personaRequirementChanged) &&
      !isLoading
    ) {
      handleGenerateButtonClick();
      setIsPersonaEdited(true);
      setIsSocialMediaPersonaEdited(true);
    } else if (isTabChanged) {
      formValidation();
    }
  }, [selectedTheme]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleSavingResponseValue = (data) => {
    const newPersona = sectionData.persona.map((persona, index) =>
      index === selectedTitle ? data : persona
    );
    setSectionData({
      ...sectionData,
      persona: newPersona,
    });
    setIsPersonaEdited(true);
    setIsSocialMediaPersonaEdited(true);
  };

  useEffect(() => {
    if (
      sourceLanguage[activeTab][selectedTitle] !==
      translateLanguage[activeTab][selectedTitle]
    ) {
      directTranslate(
        sectionData.persona[selectedTitle],
        sourceLanguage[activeTab][selectedTitle],
        translateLanguage[activeTab][selectedTitle],
        activeTab
      );
      const newSrc = [...sourceLanguage[activeTab]];
      newSrc[selectedTitle] = translateLanguage[activeTab][selectedTitle];
      setSourceLanguage((prev) => ({
        ...prev,
        [activeTab]: newSrc,
      }));
    }
  }, [translateLanguage[activeTab][selectedTitle]]);

  return (
    <div
      className={`flex-1 w-full ${isSidebarOpen ? "hidden" : "block"} md:block`}
    >
      <div className="w-full h-full ">
        {titles.length > 1 ? (
          <SignalTitles
            titles={titles}
            selectedTitle={selectedTitle}
            setSelectedTitle={setSelectedTitle}
          />
        ) : (
          setSelectedTitle(0)
        )}{" "}
        {/* this might be the problem of setting the state here in persona rather
        thn app //TODO: Check if this state change is required or not */}
        <div className="flex  flex-col">
          <div className="flex">
            <div
              className={`w-full p-10 pt-4 mx-5 my-4 bg-white ${
                titles?.length > 1
                  ? "mt-0 rounded-t-none rounded-b-xl"
                  : "rounded-xl"
              } `}
            >
              {errorMessage && <Error errorMessage={errorMessage.message} />}
              <div className="flex flex-row justify-between relative">
                {sectionData?.persona?.[selectedTitle] && (
                  <TranslateDropdown
                    translateLanguage={translateLanguage}
                    handleTranslateLanguage={handleTranslateLanguage}
                    activeTab={activeTab}
                    selectedTitle={selectedTitle}
                  />
                )}
              </div>
              <Card
                text={
                  sectionData.persona.length
                    ? sectionData.persona[selectedTitle]
                    : ""
                }
                handleSavingResponseValue={handleSavingResponseValue}
                activeTab={activeTab}
                setInputText={setInputText}
                sectionData={sectionData}
                content={sectionData.persona?.[selectedTitle]?.Persona}
                setSelectedLanguage={setSelectedLanguage}
                translateLanguage={translateLanguage}
                isLoading={isLoading}
                contentLocked={personaContentLocked}
                setContentLocked={setPersonaContentLocked}
                selectedTitle={selectedTitle}
                setEditIconIsClicked={setEditIconIsClicked}
              />
              {sectionData.persona?.[selectedTitle] && <AiGeneratedContent />}
              <div className="w-full pt-7">
                <Revise
                  revisedData={revisedData}
                  regenerateData={regenerateData}
                  selectedTitle={selectedTitle}
                  isContentLocked={personaContentLocked[selectedTitle]}
                  content={sectionData.persona.length}
                  editClicked={editIconIsClicked}
                />
              </div>
            </div>
          </div>
          <div className="m-2 sm:m-5">
            <SaveCard
              download={true}
              handleSaveButtonClicked={handleSaveButtonClicked}
              content={sectionData.persona.length}
              handleSaveProjectButtonClicked={handleSaveProjectButtonClicked}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Persona;
