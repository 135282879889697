import { Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const RenameCard = ({ setShowRenameCard, saveDraft, errorMessage }) => {
  const [draftName, setDraftName] = useState("");
  useEffect(() => {
    if (
      errorMessage.message ===
      "Invalid Request, You are at max capacity cannot save more than 10 files."
    )
      setShowRenameCard(false);
  }, [errorMessage.message]);
  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className=" bg-white flex items-center justify-center text-black rounded-2xl w-[41.75rem] h-[28.563rem] relative">
        <Button
          sx={{
            width: "2.5rem",
            height: "2.5rem",
            position: "absolute",
            left: "605px",
            top: "7px",
          }}
          onClick={() => setShowRenameCard(false)}
        >
          <img src={require("../assets/close-icon.png")} />
        </Button>
        <div className="h-[22.438rem] w-[34.875rem] my-12 mx-10 bg-[#F1F9ED] flex justify-center items-center">
          <div className="w-[30.5rem] h-[17.4375rem] my-10 mx-[3.12rem]">
            <Typography
              sx={{
                width: "30.5rem",
                height: "1.5rem",
                color: "var(--CTA, #26890D)",

                fontFamily: "Open Sans",
                fontSize: "1.5rem",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "1.5rem",
                marginBottom: "1.75rem",
              }}
            >
              Rename
            </Typography>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                fontSize: "1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "1.5rem",
                marginBottom: "0.56rem",
              }}
            >
              Please enter the name for this work
            </Typography>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Open Sans",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "1.5rem",
                marginBottom: "1.75rem",
              }}
            >
              You can find this work on the saved tab
            </Typography>
            <TextField
              margin="normal"
              sx={{
                display: "flex",
                width: "30.5rem",
                // padding: "0.375rem 1.25rem 0.375rem 1rem",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "0.625rem",
                borderRadius: "0.375rem",
                border: "1px solid var(--CTA, #26890D)",
                background: "#FFF",
                height: "2.25rem",
                "& fieldset": { border: "none" },
                color: "#000",

                fontFamily: "Open Sans",
                fontSize: "1.125rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "1.5rem",
                marginBottom: "0.56rem",
              }}
              InputProps={{
                style: {
                  height: "2.25rem",
                  width: "30.5rem",
                  border: "none",
                  gap: "0.625rem",
                  //   display: "none",
                },
                disableUnderline: true,
              }}
              fullWidth
              onChange={(e) => setDraftName(e.target.value)}
            />
            {errorMessage &&
              errorMessage.message ===
                "Invalid Request, You are at max capacity cannot save more than 10 files." && (
                <Typography
                  sx={{
                    color: "#DA291C",
                    fontFamily: "Open Sans",
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "1.5rem",
                    marginBottom: "0.56rem",
                  }}
                >
                  You are at max capacity can not save more than 10 files.
                </Typography>
              )}
            {errorMessage &&
              errorMessage.message !==
                "Invalid Request, You are at max capacity cannot save more than 10 files." && (
                <Typography
                  sx={{
                    color: "#DA291C",
                    fontFamily: "Open Sans",
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "1.5rem",
                    marginBottom: "0.56rem",
                  }}
                >
                  {errorMessage.message}
                </Typography>
              )}
            <div className="flex flex-row justify-end items-end w-[30.5rem] h-16 p-[0.625rem_0] gap-[0.625rem]">
              <Button
                sx={{
                  width: "7.5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0.375rem",
                  border: "1px solid var(--CTA, #26890D)",
                  background: "#FFF",
                  height: "2.75rem",
                  color: "var(--CTA, #26890D)",
                  fontFamily: "Open Sans",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "1.5rem",
                }}
                variant="outlined"
                onClick={() => setShowRenameCard(false)}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: "7.5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0.375rem",
                  background: "var(--CTA, #26890D)",
                  marginLeft: "1.88rem",
                  height: "2.75rem",
                  color: "#FFF",
                  fontFamily: "Open Sans",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "1.5rem" /* 150% */,
                }}
                variant="contained"
                onClick={() => saveDraft(draftName)}
                 disabled={!draftName.trim() || draftName.length >90}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenameCard;
