import React from "react";
import VideoGuide from "./VideoGuide";
import Logo from "./Logo";
import "./LoginFlow.css";
const RequestSent = ({ project }) => {
  return (
    <div className="login h-screen w-full flex justify-center items-center">
      <div className="flex justify-center items-center bg-green myContainerClass">
        <div className="flex flex-row">
          <VideoGuide />
          <div className="rightBox">
            <div className="flex flex-col">
              <Logo />
              <div className="rounded-2xl m-5 p-5 bg-[#fafafa] border border-[#e0e0e0] mt-[55px]">
                <div className="flex flex-col items-center justify-center">
                  <img
                    src={require("../assets/verified.png")}
                    className="imgComponent"
                  />
                  <div className="text-lg font-semibold mb-4">
                    REQUEST SENT FOR DEMO
                  </div>
                  <div className="text-[#727272] text-sm font-normal mb-[1.8rem]">
                    AWAITING RESPONSE FROM ADMIN
                  </div>
                  <hr className="w-[25rem] h-0 stroke-[0.5px] stroke-[#b8b8b8] mb-[1.88rem]"></hr>
                  <div className="text-base font-normal mb-4">
                    Demo request has been sent for {project} project
                  </div>
                  <div className="text-[#26890D] text-xs font-normal"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestSent;
