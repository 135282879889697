import { useMsal } from "@azure/msal-react";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import React, { useState } from "react";
import "../pages/Intermediate.css";
import MultipleSelectCheckmarks from "./MultipleSelectCheckmarks";
const Portfolio = ({
  proceedButtonClicked,
  setProceedButtonClicked,
  sendRecord,
}) => {
  const [changeInOP, setChangeInOP] = useState(false);
  const [changeInClient, setChangeInClient] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [op, setOP] = useState("");
  const { instance } = useMsal();
  const handleChangeInSelectPurpose = (valueString, value) => {
    if (!valueString.length) {
      if (value === 1) setChangeInOP(false);
      else setChangeInClient(false);
      return;
    }
    if (value === 1) {
      setChangeInOP(true);
    } else {
      setChangeInClient(true);
    }
    setPurpose(valueString);
  };
  const handleChangeInSelectOP = (valueString, value) => {
    if (!valueString.length) {
      if (value === 1) setChangeInOP(false);
      else setChangeInClient(false);
      return;
    }
    if (value === 1) {
      setChangeInOP(true);
    } else {
      setChangeInClient(true);
    }
    setOP(valueString);
  };
  const useHandleProceed = async () => {
    setProceedButtonClicked(true);
    let newPurpose = "";
    if (purpose === "Testing") {
      newPurpose = "testing";
    } else {
      newPurpose = `Client-${purpose}`;
    }
    setPurpose(newPurpose);
    await sendRecord(op, newPurpose);
  };
  const logoutHandler = () => {
    localStorage.setItem("cred", "");
    localStorage.removeItem("valid_user");
    instance.logoutRedirect().catch(console.log);
  };
  return (
    <div
      id="portfolio"
      className="absolute inset-0 bg-black bg-opacity-50 z-30 flex items-center justify-center drop-shadow-lg"
    >
      <div className="container">
        <div className="sub-container-intermediate">
          <div className="logout">
            <LogoutIcon onClick={() => logoutHandler()} />
          </div>
          <div className="mt-2 flex flex-col items-center justify-center">
            <div className="mb-2">
              <MultipleSelectCheckmarks
                tag="Offering Portfolio"
                typeOfSelect={1}
                handleChangeInSelect={handleChangeInSelectOP}
              />
            </div>

            <div className="mb-2">
              <MultipleSelectCheckmarks
                tag="Purpose"
                typeOfSelect={2}
                handleChangeInSelect={handleChangeInSelectPurpose}
              />
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "266px" }}
                disabled={
                  !changeInClient || !changeInOP || proceedButtonClicked
                }
                onClick={useHandleProceed}
              >
                Proceed
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
