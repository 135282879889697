import React from "react";

const UndoGray = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9958 9.9909C14.0251 9.22005 13.9007 8.45114 13.6298 7.73025C13.3589 7.00936 12.9473 6.35134 12.4195 5.79565C11.8917 5.23995 11.2586 4.79802 10.5583 4.49635C9.85798 4.19467 9.10476 4.03947 8.34383 4.04005H2.21818L4.33901 1.88999C4.42453 1.80352 4.48982 1.6987 4.53008 1.58325C4.57034 1.4678 4.58453 1.34467 4.57162 1.22292C4.5587 1.10118 4.51901 0.983918 4.45547 0.879786C4.39192 0.775654 4.30614 0.687301 4.20444 0.621238C4.04287 0.521584 3.85231 0.481548 3.66498 0.507897C3.47764 0.534247 3.30503 0.625365 3.17632 0.765849L0.616793 3.36065C0.221855 3.76127 0 4.30448 0 4.87087C0 5.43726 0.221855 5.98048 0.616793 6.3811L3.19786 8.99772C3.27462 9.07734 3.36694 9.13982 3.4689 9.18116C3.57086 9.22251 3.68022 9.24181 3.78997 9.23783C3.89964 9.2346 4.00752 9.20874 4.10701 9.16182C4.2065 9.11491 4.29552 9.04794 4.36862 8.96498C4.50888 8.7972 4.58059 8.58142 4.56909 8.3618C4.55758 8.14218 4.46372 7.93532 4.30672 7.78354L2.21818 5.67714H8.23348C9.28531 5.66033 10.3031 6.05493 11.0754 6.77898C11.8478 7.50303 12.3153 8.50088 12.3809 9.56525C12.4083 10.1197 12.3242 10.674 12.1336 11.1944C11.943 11.7148 11.65 12.1905 11.2723 12.5925C10.8947 12.9945 10.4403 13.3144 9.93686 13.5327C9.43341 13.7511 8.89141 13.8633 8.34383 13.8626H4.57586C4.46268 13.8621 4.35064 13.8856 4.24701 13.9318C4.14339 13.9779 4.0505 14.0457 3.97436 14.1306C3.89823 14.2155 3.84055 14.3156 3.80507 14.4246C3.7696 14.5336 3.75711 14.6489 3.76843 14.763C3.79621 14.9708 3.89862 15.1608 4.05606 15.2968C4.21349 15.4327 4.41487 15.505 4.62161 15.4997H8.19849C11.2802 15.4997 13.8774 13.0277 13.9958 9.9909Z"
        fill="#BEBEBE"
      />
    </svg>
  );
};

export default UndoGray;
