import React from "react";

const AiGeneratedContent = () => {
  return (
    <div className="flex flex-row-reverse text-xs text-[#000000] mt-1">
      Note: This content was created with the assistance of generative AI
      technology.
    </div>
  );
};

export default AiGeneratedContent;
