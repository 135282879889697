import { React } from "react";
import { Typography } from "@mui/material";



const BackDrop = ({ type }) => {
  const LoaderDiv = () => {
    return (
      <Typography
        display="block"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        }}
      >
        <img
          src={require("../assets/3WFM.gif")}
          alt="loading"
          className="h-[6.5rem] w-auto"
          
        />
        <div>Please wait..This might take a while</div>
      </Typography>
    );
  };
  return (
    <div
      className={`fixed ${type === "container" ? "top-[7.688rem] overflow-y-scroll left-0 right-0 bottom-0" : "inset-0"} z-50 flex items-center justify-center bg-black bg-opacity-50`}
    
    >
      {LoaderDiv()}
    </div>
  );
};
export default BackDrop;