import React from "react";

const ProductDetails = ({ highlighted }) => {
  return (
    <img
      className="h-[1.1rem] w-[1.1rem]"
      src={
        highlighted
          ? require("../assets/Group-5.png")
          : require("../assets/Group-4.png")
      }
    />
  );
};

export default ProductDetails;
