import React, { useEffect, useState } from "react";
import "./SavePage.css";
import SavedWorkCard from "../components/SavedWorkCard";
import SavedTabsPanel from "../components/SavedTabsPanel";
import ZeroDrafts from "../components/ZeroDrafts";
import { Button, Typography } from "@mui/material";
import SaveProjectCard from "../components/SaveProjectCard";
import ProjectConfirmationBox from "../components/ProjectConfirmationBox";
import { unstable_useBlocker } from "react-router-dom";

const SavePage = ({
  fetchAllDrafts,
  savedDrafts,
  handleResumeWork,
  handleDeleteDraft,
  setShowResumeCard,
  tabNameForResume,
  setTabNameForResume,
  tabTypeForResume,
  setTabTypeForResume,
  fileNameForResume,
  setFileNameForResume,
  handleResumeProjectCards,
  projectDrafts,
  handleResumeProject,
  handleDeleteProject,
}) => {
  const [tabKeys, setTabKeys] = useState({});
  const [tempKey, setTempKey] = useState([]);
  const [selectedTab, setSelectedTab] = useState("persona");
  const [clickedTab, setClickedTab] = useState([]);
  const [saveStatus, setSaveStatus] = useState("page");
  const [selectedSignal, setSelectedSignal] = useState(1);
  const [projectTabNames, setProjectTabNames] = useState([]);
  const [selectedProjectTab, setSelectedProjectTab] = useState(null);
  const [currentProjectDraft, setCurrentProjectDraft] = useState(null);
  const [currentSignalTab, setCurrentSignalTab] = useState(0);
  useEffect(() => {
    fetchAllDrafts();
    //handleResumeProjectCards()
  }, []);
  useEffect(() => {
    let projectTabs = projectDrafts.map((item) => item.project_name);
    setProjectTabNames(projectTabs);
    if (projectTabs.length > 0) {
      setSelectedProjectTab(0);
      handleSelectedProjectTab(0);
    }
  }, [projectDrafts]);
  useEffect(() => {
    const newTabKeys = {};
    const bannerHorizontalArr = [];
    const bannerVerticalArr = [];
    const flyerHorizontalArr = [];
    const flyerVerticalArr = [];
    const flyerSquareArr = [];
    Object.keys(savedDrafts).forEach((key) => {
      if (key === "flyer") {
        savedDrafts[key].forEach((item) => {
          // if (item.flyer_type === "Horizontal") {
          //   flyerHorizontalArr.push(item);
          // }
          if (item.flyer_type === "Vertical") {
            flyerVerticalArr.push(item);
          }
          // if (item.flyer_type === "Square") {
          //   flyerSquareArr.push(item);
          // }
        });
        // if (flyerHorizontalArr.length)
        //   newTabKeys["flyer_horizontal"] = flyerHorizontalArr.reverse();
        if (flyerVerticalArr.length)
          newTabKeys["flyer_vertical"] = flyerVerticalArr.reverse();
        // if (flyerSquareArr.length)
        //   newTabKeys["flyer_square"] = flyerSquareArr.reverse();
      } else if (key === "banner") {
        savedDrafts[key].forEach((item) => {
          if (item.banner_type === "Horizontal") {
            bannerHorizontalArr.push(item);
          }
          if (item.banner_type === "Vertical") {
            bannerVerticalArr.push(item);
          }
        });
        if (bannerHorizontalArr.length)
          newTabKeys["banner_horizontal"] = bannerHorizontalArr.reverse();
        if (bannerVerticalArr.length)
          newTabKeys["banner_vertical"] = bannerVerticalArr.reverse();
      } else {
        newTabKeys[key] = savedDrafts[key].reverse();
      }
    });
    setTabKeys(newTabKeys);
    setTempKey(Object.keys(newTabKeys));
    setClickedTab(new Array(Object.keys(newTabKeys).length).fill(false));
  }, [savedDrafts]);
  const handleProjectStatus = (status) => {
    if (status == "project") {
      handleResumeProjectCards();
    }
    setSaveStatus(status);
  };
  const tabNames = {
    persona: "Persona",
    email: "Email",
    magazine_ad: "Ad / Print",
    social_media: "Ad / Social Media",
    creative_concept: "Creative Concepts",
    flyer_vertical: "Ad / Flyer",
    banner_horizontal: "Ad / Banner / Horizontal",
    banner_vertical: "Ad / Banner / Vertical",
  };
  const handleTabClicked = (tabKey) => {
    setSelectedTab(tabKey);
  };
  const handleSelectedProjectTab = (key) => {
    setCurrentSignalTab(0);
    if (
      projectDrafts[key] != null &&
      projectDrafts[key].hasOwnProperty("persona")
    )
      setCurrentProjectDraft(projectDrafts[key]);
    else {
      setCurrentProjectDraft(null);
    }
    setSelectedProjectTab(key);
  };
  return (
    <div className="w-[95%] mt-0 mx-auto" style={{ marginTop: "4" }}>
      <div className="flex flex-col justify-center items-center w-full h-full bg-[#F2F2F2] ">
        <div className="w-full h-[3.8rem] bg-white rounded-lg my-6 flex">
          {saveStatus === "project" && (
            <div className="flex items-center pl-4 ">
              {projectTabNames && projectTabNames[selectedProjectTab] && (
                <div className="space-x-8 flex flex-row">
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  >
                    Project Name
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  >
                    {" "}
                    -{" "}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  >
                    {projectTabNames[selectedProjectTab]}
                  </Typography>
                </div>
              )}
            </div>
          )}
          <div
            className={`flex ${
              saveStatus === "project" ? "justify-end" : "justify-end"
            } p-2 w-full ${
              saveStatus === "project" ? "pl-4 space-x-4" : "space-x-4"
            }`}
          >
            <Button
              variant={saveStatus == "project" ? "contained" : "outlined"}
              color={saveStatus == "project" ? "secondary" : "primary"}
              sx={{ borderRadius: 2, fontWeight: 600, padding: "20px 25px" }}
              onClick={() => handleProjectStatus("project")}
            >
              Saved Projects
            </Button>
            <Button
              variant={saveStatus == "page" ? "contained" : "outlined"}
              color={saveStatus == "page" ? "secondary" : "primary"}
              onClick={() => handleProjectStatus("page")}
              sx={{ borderRadius: 2, fontWeight: 600, padding: "20px 25px" }}
            >
              Saved Pages
            </Button>
          </div>
        </div>
        <div className=" flex flex-row justify-start items-start overflow-x-hidden overflow-y-scroll w-full">
          <div className="flex flex-col justify-center h-full items-start">
            {saveStatus == "page"
              ? Object.keys(tabNames).map((key) => (
                  <SavedTabsPanel
                    tabName={tabNames[key]}
                    tabKey={key}
                    handleTabClicked={handleTabClicked}
                    selectedTab={selectedTab}
                  />
                ))
              : projectTabNames.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`box flex justify-start items-center ${
                        selectedProjectTab === index
                          ? "text-white bg-[#26890D]"
                          : "text-[#26890D] bg-white"
                      }`}
                      onClick={() => {
                        handleSelectedProjectTab(index);
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
          </div>
          {saveStatus == "page" ? (
            <div
              className={
                tabKeys?.[selectedTab] && tabKeys?.[selectedTab]?.length !== 0
                  ? "drafts grid grid-cols-3 gap-4"
                  : "no-drafts justify-center items-center"
              }
            >
              {tabKeys?.[selectedTab]?.length ? (
                tabKeys?.[selectedTab]?.map((draft, index) => (
                  <SavedWorkCard
                    key={index}
                    tabName={tabNames.selectedTab}
                    tabKey={selectedTab}
                    draft={draft}
                    handleResumeWork={handleResumeWork}
                    handleDeleteDraft={handleDeleteDraft}
                    tabNameForResume={tabNameForResume}
                    setTabNameForResume={setTabNameForResume}
                    tabTypeForResume={tabTypeForResume}
                    setTabTypeForResume={setTabTypeForResume}
                    fileNameForResume={fileNameForResume}
                    setFileNameForResume={setFileNameForResume}
                    setShowResumeCard={setShowResumeCard}
                  />
                ))
              ) : (
                <ZeroDrafts />
              )}
            </div>
          ) : (
            <div className="w-full h-[49rem] flex flex-col">
              {currentProjectDraft !== null ? (
                 <>
                  <div className="bg-white rounded-xl ml-4 flex-1 h-[90%] flex flex-col">
                    <div className="w-full h-[15%] flex justify-start items-center space-x-4 mx-8 p-2">
                      {currentProjectDraft?.persona?.map((item, key) => {
                        return (
                          <Button
                            variant={
                              currentSignalTab == key ? "contained" : "outlined"
                            }
                            color={
                              currentSignalTab == key ? "secondary" : "primary"
                            }
                            onClick={() => {
                              setCurrentSignalTab(key);
                            }}
                          >
                            Signal-{key + 1}
                          </Button>
                        );
                      })}
                    </div>
                    <div className="h-[85%] w-[95%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 overflow-y-auto mx-8 pb-2">
                      {currentProjectDraft !== null ? (
                        Object.entries(currentProjectDraft).map(
                          ([key, value]) => {
                            if (
                              key !== "project_id" &&
                              key !== "project_name" &&
                              key != "signals" &&
                              key != "product_service" &&
                              key != "objective" &&
                              key != "tone_mood" &&
                              key != "key_selling_points" &&
                              value.length - 1 >= currentSignalTab
                            ) {
                              return (
                                <SaveProjectCard
                                  tab={key}
                                  data={value}
                                  signal={currentSignalTab}
                                />
                              );
                            }
                          }
                        )
                      ) : (
                        <ZeroDrafts />
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end py-4 space-x-2 h-[10%] items-end ">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleDeleteProject(
                          projectDrafts?.[selectedProjectTab]?.["project_id"]
                        );
                        handleResumeProjectCards();
                      }}
                      disabled={
                        !projectDrafts && !projectDrafts[selectedProjectTab]
                      }
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        handleResumeProject(projectTabNames[selectedProjectTab])
                      }
                      disabled={!currentProjectDraft}
                    >
                      Resume Project
                    </Button>
                  </div>
                </>
              ) : (
                <ZeroDrafts />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SavePage;
