import React from "react";

const SignalTitles = ({
  titles,
  setSelectedTitle,
  selectedTitle,
  activeTab,
}) => {
  return (
    <div className={activeTab === "Creative Concepts" ? "pt-0" : "pt-5"}>
      <div
        className={`flex flex-row w-full ${
          activeTab === "Story Boarding" ? "ml-[1.65rem]" : "ml-5"
        } mb-0`}
      >
        {titles?.map((title, index) => (
          <p
            key={index}
            className={`px-5 py-3 cursor-pointer 
              ${
                index === selectedTitle
                  ? "text-white bg-[#86BC25]"
                  : "text-gray-500 bg-white"
              }
              ${"rounded-t-lg"}`}
            onClick={() => setSelectedTitle(index)}
          >
            {title}
          </p>
        ))}
      </div>
    </div>
  );
};

export default SignalTitles;
