import { Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import { USER_REQUEST_ACCESS } from "../utils/constants";
import { getIdToken } from "../utils/translateHelper";
import "./LoginFlow.css";
import Logo from "./Logo";
import "./RequestForm.css";

import VideoGuide from "./VideoGuide";

import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

const RequestForm = ({ setUserState, handleFormSubmit, updateProject }) => {
  const errorMessage = "";

  let changeInOP = false;
  let changeInClient = false;

  const handleChangeInSelect = (valueString, value) => {
    if (!valueString.length) {
      if (value === 1) changeInOP = false;
      else changeInClient = false;
      return;
    }
    if (value === 1) {
      changeInOP = true;
    } else {
      changeInClient = true;
    }
  };
  const useHandleProceed = () => {
    setUserState("Pending");
    let jsonData = formData;
    jsonData.end_date = endDate.format("YYYY-MM-DD");
    jsonData.start_date = startDate.format("YYYY-MM-DD");
    getIdToken(errorMessage)
      .then((idToken) => {
        fetch(USER_REQUEST_ACCESS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify(jsonData),
        })
          .then((response) => {
            response.json();
            updateProject(formData.project);
            handleFormSubmit();
          })
          .then((data) => {
            console.log("Success:", data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const maxLength = 320;
  const [usedCharsUseCase, setUsedCharsUseCase] = useState(0);
  const handleChangeUseCase = (event) => {
    const length = event.target.value.length;
    setUsedCharsUseCase(length);
  };
  const [usedCharsClient, setUsedCharsClient] = useState(0);
  const handleChangeClient = (event) => {
    const length = event.target.value.length;
    setUsedCharsClient(length);
  };
  let dateNow = new Date();
  const [startDate, setStartDate] = useState(
    moment(dateNow.toISOString().split("T")[0])
  );
  const [endDate, setEndDate] = useState(
    moment(moment().add(7, "days").toISOString().split("T")[0])
  );
  const [days, setDays] = useState("");
  let defaultEndDate = moment().add(7, "days");
  useEffect(() => {
    if (startDate && endDate) {
      const diffTime = Math.abs(
        new Date(startDate).getTime() - new Date(endDate).getTime()
      );
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const diffMonths = Math.floor(diffDays / 30);
      const remainingDays = diffDays % 30;
      setDays(
        `You have selected ${diffMonths} months and ${remainingDays} days`
      );
    }
  }, [endDate]);

  const [formData, setFormData] = useState({
    offering_portfolio: "",
    project: "",
    case_description: "",
    client_or_pursuit: "Yes",
    end_date: null,
    start_date: null,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="login h-screen w-full flex justify-center items-center">
      <div className="myContainerClass flex justify-center items-center bg-green">
        <div className="flex flex-row">
          <div className="rounded-2xl bg-white w-[55rem] h-[35rem] mr-6">
            <VideoGuide />
          </div>
          <div className="rounded-2xl bg-white w-[31.5625rem] h-[35rem]">
            <div className="flex flex-col">
              <Logo />
              <div className="flex flex-col p-5 m-5 mt-0 pt-2">
                <div className="max-h-[350px] overflow-y-scroll w-[440px]">
                  <div>
                    <div className="text-[#797979] text-xs font-normal">
                      Consulting Offering Portfolio
                      <span className="text-red-500">*</span>
                    </div>
                    <MultipleSelectCheckmarks
                      typeOfSelect={1}
                      focused
                      required
                      color="tertiary"
                      handleChangeInSelect={handleChangeInSelect}
                      name="operating_portfolio"
                      formData={formData}
                      setFormData={setFormData}
                    />
                  </div>
                  <div className="mb-5 mt-1 rounded-md">
                    <div className="text-[#797979] text-xs font-normal">
                      Enter your project name
                      <span className="text-red-500">*</span>
                    </div>
                    <TextField
                      InputProps={{
                        style: {
                          height: "55px",
                          width: "420px",
                        },
                      }}
                      focused
                      required
                      color="tertiary"
                      fullWidth
                      placeholder="Type here.."
                      name="project"
                      value={formData.project}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      //onChange={handleChangeUseCase}
                    />
                  </div>
                  <div className="mb-2 flex flex-row">
                    <div className="mr-1">
                      <div className="text-[#797979] text-xs font-normal">
                        Start Date<span className="text-red-500">*</span>
                      </div>
                      <DatePicker
                        style={{ width: "200px" }}
                        inputFormat="MM/dd/yyyy"
                        value={startDate}
                        onChange={(newDate) => setStartDate(newDate)}
                        renderInput={(params) => (
                          <TextField {...params} required />
                        )}
                        disablePast
                      />
                    </div>
                    <div className="ml-1">
                      <div className="text-[#797979] text-xs font-normal">
                        End Date<span className="text-red-500">*</span>
                      </div>
                      <DatePicker
                        inputFormat="MM/dd/yyyy"
                        value={endDate}
                        onChange={(newDate) => setEndDate(newDate)}
                        renderInput={(params) => (
                          <TextField {...params} required />
                        )}
                        slotProps={{
                          actionBar: {
                            actions: ["clear"],
                          },
                        }}
                        disablePast
                      />
                    </div>
                  </div>
                  <div className="mb-4 mr-4 text-right font-normal text-xs text-[#26890D]">
                    {endDate ? days : null}
                  </div>
                  <div className="flex flex-row mb-4">
                    <div className="text-xs font-normal mr-10 mt-2">
                      Is this for existing client or pursuit?
                      <span className="text-red-500">*</span>
                    </div>
                    <div className="mb-2">
                      <RadioGroup
                        row
                        aria-label="position"
                        name="client_or_pursuit"
                        value={formData.client_or_pursuit}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              color="primary"
                              sx={{ "& svg": { fontSize: 18 } }}
                            />
                          }
                          label={<Typography variant="caption">Yes</Typography>}
                          sx={{ ml: 1, mr: 6 }}
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              color="primary"
                              sx={{ "& svg": { fontSize: 18 } }}
                            />
                          }
                          label={<Typography variant="caption">No</Typography>}
                        />
                      </RadioGroup>
                    </div>
                    {/* <TextField
                      name="case_description"
                      focused
                      color="tertiary"
                      fullWidth
                      placeholder="Type here.."
                      value={formData.case_description}
                      onChange={(e) => {
                        handleChange(e);
                        handleChangeUseCase(e);
                      }}
                      //onChange={handleChangeUseCase}
                      inputProps={{
                        style: {
                          height: "20px",
                        },
                        maxLength: maxLength,
                      }}
                      helperText={`${usedCharsUseCase} / 320`}
                      FormHelperTextProps={{ style: { textAlign: "right" } }}
                    /> */}
                  </div>
                  <div className="mb-2">
                    <div className="text-[#797979] text-xs font-normal">
                      Describe the specific use case and description of the
                      engagement.<span className="text-red-500">*</span>
                    </div>
                    <TextField
                      name="case_description"
                      focused
                      color="tertiary"
                      placeholder="Type here.."
                      value={formData.case_description}
                      onChange={(e) => {
                        handleChange(e);
                        handleChangeClient(e);
                      }}
                      fullWidth
                      //onChange={handleChangeClient}
                      InputProps={{
                        style: {
                          height: "55px",
                          width: "420px",
                        },
                        maxLength: maxLength,
                      }}
                      helperText={`${usedCharsClient} / 320`}
                      FormHelperTextProps={{ style: { textAlign: "right" } }}
                    />
                  </div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ width: "26.5rem", marginTop: "1rem" }}
                    onClick={useHandleProceed}
                    disabled={
                      !(
                        formData.project &&
                        formData.offering_portfolio &&
                        formData.case_description &&
                        endDate &&
                        startDate
                      )
                    }
                  >
                    Proceed
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestForm;
