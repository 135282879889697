import React from "react";
import { Button } from "@mui/material";
import VideoGuide from "./VideoGuide";
import Logo from "./Logo";
import "./LoginFlow.css";

const RequestDenied = ({ setUserState, handleRejectRedirect }) => {
  const handleReenter = () => {
    handleRejectRedirect();
  };

  return (
    <div className="login h-screen w-full flex justify-center items-center">
      <div className="flex justify-center items-center bg-green myContainerClass">
        <div className="flex flex-row">
          <VideoGuide />

          <div className="rightBox">
            <div className="flex flex-col">
              <Logo />

              <div className="rounded-2xl m-5 p-5 mt-[55px] border-[#e0e0e0] bg-[#fafafa]">
                <div className="flex flex-col items-center justify-center">
                  <img
                    src={require("../assets/AccessDenied.png")}
                    className="imgComponent"
                  />
                  <div className="text-lg font-semibold mb-4">
                    REQUEST DENIED
                  </div>

                  <hr className="w-[25rem] h-0 stroke-[#b8b8b8] mb-7"></hr>

                  <Button
                    onClick={handleReenter}
                    variant="contained"
                    color="secondary"
                  >
                    re - Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestDenied;
