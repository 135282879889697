import React from "react";

const AdIcon = ({highlighted}) => {
  return (
    <img
      className="h-[1.1rem] w-[1.1rem]"
      src={
        highlighted
          ? require("../assets/Group 1000001172.png")
          : require("../assets/Group 1000001169.png")
      }
    />
  );
};

export default AdIcon;
