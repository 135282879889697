import React, { useState } from "react";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import EditSteps from "./EditSteps";
import AiGeneratedContent from "./AiGeneratedContent";
const ReviewMode = ({
  steps,
  setShowEditingMode,
  handleGenerateImagesButton,
  regenerateImage,
  setGeneratedImagesGlobal,
  handleReviseButton,
  generateSteps,
  setIsStoryGenerated,
  tailoredVisible,
  selectedTitle,
  generatedImages,
  generatedImagesTailored,
  setGeneratedImagesTailored,
}) => {
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [reviseComment, setReviseComment] = useState("");
  const handleSaveClick = () => {
    setIsEditVisible(false);
  };

  const handleEditClick = () => {
    setIsEditVisible(true);
    setShowEditingMode(true);
  };

  return (
    <div className="bg-white p-5 rounded-lg m-5 mt-[-20px] ">
      <div className="p-1 pl-5 pr-4">
        {steps.map((step, index) => (
          <div
            
            className="text-xl mb-1 leading-9 "
            key={index}
          >
            {step}
          </div>
        ))}
        <div className="flex justify-end items-end mr-5">
          <Button onClick={handleEditClick}>
            <img
              
              className="h-[1.375rem] w-[1.375rem]"
              src={require("../assets/Group.jpg")}
              alt="edit-icon"
            />
          </Button>
        </div>
      </div>
      <div className="mr-11 mt-4">
        <AiGeneratedContent />
      </div>
      <div className="flex justify-between mt-5">
        <div className=" w-full">
          <TextField
            fullWidth
            defaultValue=""
            color="tertiary"
            onChange={(e) => {
              setReviseComment(e.target.value);
            }}
            focused
            sx={{
              //paddingRight: "5rem",
              // marginBottom: "2rem",
              // width: "30rem",
              paddingLeft: "1rem",
              fontWeight: 600,
              marginLeft: "1px",
              width: "100%",

              marginRight: "1rem",
            }}
          />
        </div>
        <div>
          <Button
            sx={{
              fontWeight: 600,
              margin: "1rem",
              marginTop: "0px",
              height: "46px",
              width: "10.9375rem",
              padding: "0.9375rem 1.75rem",
              borderRadius: "0.375rem",
            }}
            variant="contained"
            color="secondary"
            disabled={!reviseComment?.length}
            onClick={() => {
              handleReviseButton(reviseComment);
            }}
          >
            revise
          </Button>
        </div>
      </div>
      <div className="pl-4 m-4 flex justify-end items-end">
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              generateSteps(true);
            }}
            sx={{
              width: "18rem",
              padding: "0.9375rem 0.625rem",
              height: "46px",
              borderRadius: "0.5rem",
            }}
          >
            regenerate script
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (tailoredVisible) {
                setGeneratedImagesTailored((prev) => {
                  const neww = [...prev];
                  neww[selectedTitle] = true;
                  return neww;
                });
              } else {
                setGeneratedImagesGlobal(true);
              }
              handleGenerateImagesButton();
              setIsStoryGenerated(false);
            }}
            sx={{
              width: "18rem",
              padding: "0.9375rem 0.625rem",
              height: "46px",
              marginLeft: "2rem",
              borderRadius: "0.5rem",
            }}
          >
            generate storyboard
          </Button>
        </>
      </div>
    </div>
  );
};

export default ReviewMode;
