import React from "react";
import { Alert, AlertTitle } from "@mui/material";

const Error = ({ errorMessage }) => {
  return (
    <>
      <Alert
        severity="error"
        sx={{
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        <AlertTitle>
          {errorMessage}{" "}
        </AlertTitle>
      </Alert>
    </>
  );
};

export default Error;
