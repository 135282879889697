import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
const DirectReviewMode = ({
  formData,
  setFormData,
  setShowEditingMode,
  sectionData,
  generateDirectSteps,
  reviseDirectSteps,
  handleGenerateStoryBoard,
  stepsGenerated,
  setStepsGenerated,
  setGeneratedImagesGlobal,
  setGeneratedImages,
}) => {
  const [isEditVisible, setIsEditVisible] = useState(false);

  const [revisedComment, setRevisedComment] = useState("");
  const handleSaveClick = () => {
    setIsEditVisible(false);
  };

  const handleReviewClick = () => {
    setIsEditVisible(true);
    setShowEditingMode(true);
  };

  return (
    <div className="mb-10 p-2.5 ">
  <div className="w-full">
    <div className="bg-white p-6 pt-5 rounded-lg">
      <p className="ml-4 text-gray-600 font-normal text-xs leading-4">
        Set the scene
      </p>
      <TextField
        fullWidth
        multiline
        defaultValue=""
        color="tertiary"
        InputLabelProps={{
          sx: {
            color: "#828282",
          },
        }}
        focused
        value={formData?.story}
        rows={10}
        sx={{
          paddingRight: { xs: "0", md: "2rem" },
          marginBottom: "2rem",
          marginLeft: { xs: "0", md: "1rem" },
        }}
        onChange={(e) => {
          setFormData({
            ...formData,
            story: e.target.value,
          });
        }}
      />
      <div className="w-full pb-5 h-28 flex flex-col md:flex-row justify-between items-center">
        <TextField
          defaultValue=""
          color="tertiary"
          InputLabelProps={{
            sx: {
              color: "#828282",
            },
          }}
          focused
          sx={{
            marginLeft: "16px",
            marginBottom: "2rem",
            width: "calc(100% - 32px)",
            md: {
              width: "auto",
              flexGrow: 1,
            },
            height: "16px",
          }}
          onChange={(e) => setRevisedComment(e.target.value)}
        />
        <Button
          sx={{
            fontWeight: 600,
            marginTop: { xs: "1rem", md: "0" },
            marginLeft: { xs: "0", md: "1rem" },
            marginRight: { xs: "0", md: "1rem" },
            width: { xs: "100%", md: "11rem" },
            height: "54px",
            padding: "0.9375rem 1.75rem",
          }}
          disabled={!revisedComment.length}
          variant="contained"
          color="secondary"
          onClick={() => {
            setStepsGenerated(false);
            setGeneratedImages(false);
            setGeneratedImagesGlobal(false);
            reviseDirectSteps(revisedComment);
          }}
        >
          REVISE
        </Button>
      </div>
      <div className="flex flex-col md:flex-row justify-end items-center">
        <Button
          sx={{
            fontWeight: 600,
            marginTop: { xs: "1rem", md: "0" },
            marginLeft: { xs: "0", md: "1rem" },
            marginRight: { xs: "0", md: "2rem" },
            width: { xs: "100%", md: "18rem" },
            height: "54px",
            padding: "0.9375rem 0.625rem",
          }}
          variant="contained"
          onClick={() => {
            if (!stepsGenerated) {
              setStepsGenerated(true);
              generateDirectSteps();
            }
            handleReviewClick();
          }}
          color="secondary"
          disabled={!formData.story.length || !formData.duration.length}
        >
          REVIEW PLAN
        </Button>
        <Button
          sx={{
            fontWeight: 600,
            marginTop: { xs: "1rem", md: "0" },
            marginLeft: { xs: "0", md: "1rem" },
            marginRight: { xs: "0", md: "1rem" },
            width: { xs: "100%", md: "18rem" },
            padding: "0.9375rem 0.625rem",
          }}
          variant="contained"
          color="secondary"
          disabled={!formData.story.length || !formData.duration.length}
          onClick={handleGenerateStoryBoard}
        >
          GENERATE STORYBOARD
        </Button>
      </div>
    </div>
  </div>
</div>
  );
};

export default DirectReviewMode;
