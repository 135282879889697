import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Popover from "@mui/material/Popover";
import Radio from "@mui/material/Radio";
import React, { useState } from "react";
const ModelSetting = ({
  translationModel,
  setTranslationModel,
  localisationModel,
  setLocalisationModel,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsClicked(!isClicked);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsClicked(!isClicked);
  };

  const open = Boolean(anchorEl);

  const handleTranslationChange = (event) => {
    setTranslationModel(event.target.value);
  };

  const handleLocalisationChange = (event) => {
    setLocalisationModel(event.target.value);
  };
  const popoverContent = (
    <Box sx={{ p: 1, fontSize: "16px" }}>
      <FormGroup>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <div className="mr-[15px]">Translation Model</div>
            <FormControlLabel
              control={<Radio />}
              label="NLLB"
              value="NLLB"
              checked={translationModel === "NLLB"}
              onChange={handleTranslationChange}
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "15px",
                },
              }}
            />
            <FormControlLabel
              control={<Radio />}
              label="Amazon Translate"
              value="Amazon Translate"
              checked={translationModel === "Amazon Translate"}
              onChange={handleTranslationChange}
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "15px",
                },
              }}
            />
          </div>
          <div className="flex flex-col">
            <div>Localisation Model</div>
            <FormControlLabel
              control={<Radio />}
              label="Llama"
              value="Llama"
              checked={localisationModel === "Llama"}
              onChange={handleLocalisationChange}
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "15px",
                },
              }}
            />
           {/*  <FormControlLabel
              control={<Radio />}
              label="Gemini-Pro"
              value="Gemini-Pro"
              checked={localisationModel === "Gemini-Pro"}
              onChange={handleLocalisationChange}
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "15px",
                },
              }}
            /> */}
          </div>
        </div>
      </FormGroup>
    </Box>
  );
  return (
    <div>
      <Button
        className=""
        onClick={handleClick}
        sx={{
          fontSize: "0.875rem",
          //marginLeft:"30px",
          color: isClicked ? "#26890D" : "#000",
          lineHeight: "1rem",
          fontWeight: "700",
          marginTop: "2px",
          whiteSpace: "normal",
          width: "100%",
          padding: "5px",

          transform: "translate(12px,0px)",
        }}
      >
        Model Settings
        <ExpandMoreIcon />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {popoverContent}
        {/* <TranslatePage translationModel={translationModel} localisationModel={localisationModel}/> */}
      </Popover>
    </div>
  );
};
export default ModelSetting;
